import { IconInfo } from 'components/icons/IconInfo'
import { translate } from 'i18n/i18n'
import { colors } from 'settings/colors'

export const BlockLacksTrapsMessage = ({ blockName }: { blockName: string }) => {
  return (
    <div
      css={{
        display: 'flex',
        background: colors.grey50,
        boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
        borderRadius: 3,
        padding: '20px',
        alignItems: 'center',
        justifyContent: 'space-between',
        fontSize: 16,
        width: '100%',
        marginTop: -10,
      }}
    >
      <span css={{ color: colors.yellow, marginRight: 12, marginTop: '0.2em' }}>
        <IconInfo />
      </span>
      <span css={{ fontWeight: 500, flex: 'auto' }}>
        {translate.phrases.banyanApp('No traps available for this block ({{blockName}}).', {
          blockName,
        })}
      </span>
    </div>
  )
}
