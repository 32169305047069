import { DropdownSelectorProperty } from 'App/Map/PanelDetails/SectionTitleBars/DropdownSelectorProperty/DropdownSelectorProperty'
import { SoilSettingsDropdownButton } from 'components/SoilSettingsDropdownButton/SoilSettingsDropdownButton'
import { useScreenSize } from 'utils/useScreenSize'

export const SoilTitleChildren = () => {
  const { isWideScreen } = useScreenSize()

  return (
    <span
      style={{
        display: 'flex',
        alignItems: isWideScreen ? 'center' : 'flex-start',
        gap: isWideScreen ? '18px' : '4px',
        flex: 'wrap',
        flexDirection: isWideScreen ? 'row' : 'column',
      }}
    >
      <DropdownSelectorProperty />
      <SoilSettingsDropdownButton />
    </span>
  )
}
