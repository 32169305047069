import type { routes } from '@semios/app-platform-banyan-route-definitions'
import {
  VIEW_PESTS_DEGREE_DAYS_ID_insectId,
  VIEW_PESTS_TRAP_CATCHES_ID_insectId,
} from '@semios/app-platform-value-type-definitions'
import { blockLacksDataSectionMaker } from 'App/Map/PanelDetails/_utils/blockLacksDataSectionMaker'
import { propertyLacksPermissionSectionMaker } from 'App/Map/PanelDetails/_utils/propertyLacksPermissionSectionMaker'
import { regionLacksPermissionSectionMaker } from 'App/Map/PanelDetails/_utils/regionLacksPermissionSectionMaker'
import { selectedPropertyHasPermission } from 'App/Map/PanelDetails/_utils/selectedPropertyHasPermission'
import { selectedRegionHasPermission } from 'App/Map/PanelDetails/_utils/selectedRegionHasPermission'
import type { TPestSectionCategory } from 'App/Map/PanelDetails/_utils/sortPestSections'
import type { StackedChartPestSection, TChartSeries } from 'components/StackedChart/types'
import type { YAxisOptions } from 'highcharts'
import { translate } from 'i18n/i18n'
import moment from 'moment-timezone'
import { detailsPanelStore } from 'stores/detailsPanelStore'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import type {
  selectedValueGroupsStore,
  TValueGroup,
} from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import type { TPossibleValuesToCheck } from 'utils/doesSelectedFieldAssetHaveValueTypes'
import { getInsectNameFromId } from 'utils/getInsectNameFromId'
import { isUserOnlyAFreeRegionalUser } from 'utils/isUserOnlyAFreeRegionalUser'
import { sortByKey } from 'utils/sortByKey'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import { getColorRulesForPestSeries } from '../../../../createContent/_utils/getColorRulesForSeries'
import { getXDateFormat } from '../../../_utils/getXDateFormat'
import { BlockLacksTrapsMessage } from '../BlockLacksTrapsMessage'
import { buildDegreeDaySeries } from './buildSeries/buildDegreeDaySeries'
import { buildTrapCatchSeries } from './buildSeries/buildTrapCatchSeries'
import {
  getDegreeDayValueTypeIdForInsectId,
  getTrapPercentageValueTypeIdForInsectId,
  getTrapValueTypeIdForInsectId,
} from './getValueTypesForInsectId'
import { AlmaSummaries } from './summary/AlmaSummaries'

export const insectTrapAndDegreeContent = ({
  insects,
  selectedValueGroups,
  targetScope,
  targetScopeId,
  targetScopeData,
  regionName,
  regionsData,
  compareSeasonsTargetScopeData,
  doesTargetHaveValueTypes,
  sectionTitleSuffix,
  TitleChildren,
  pestSectionCategory,
  showRegionalData,
  selectedProperty,
  dateFrom,
  dateTo,
  timezone,
}: {
  insects: Record<number, routes.UserAppStartup.TFieldAssetValueTypes.TInsect> | undefined
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
  targetScope: 'PROPERTY' | 'BLOCK' | 'REGION'
  targetScopeId: string
  targetScopeData:
    | routes.Values.Response['blocks']
    | routes.Values.Response['properties']
    | routes.Values.Response['regions']
  regionName?: string | null
  regionsData?: NonNullable<routes.Values.Response['regions']>['string']
  compareSeasonsTargetScopeData?: routes.Values.Response['blocks'] | routes.Values.Response['properties']
  doesTargetHaveValueTypes: ({ valuesCurrent, valuesTimeseries }: TPossibleValuesToCheck) => boolean
  sectionTitleSuffix?: string
  TitleChildren?: React.FC<{ valuesTimeseriesToFilterOn: string[]; insectId?: number }>
  pestSectionCategory: TPestSectionCategory
  showRegionalData: boolean
  selectedProperty: number | null
  dateFrom: string
  dateTo: string
  timezone: string
}): StackedChartPestSection[] => {
  // builds series for DD
  // builds series for catches
  // puts into a chart
  // returns section with header etc
  if (!insects || !targetScopeId) return []

  const { compareSeasonsInterval } = detailsPanelStore.getState()
  const isFreeRegionalUser = isUserOnlyAFreeRegionalUser()
  const insectTrapCatchesConverter = unitConverter.insectTrapCatches

  const blockName =
    fieldAssetStore.getState()?.properties?.[Number(selectedProperty)]?.blocks?.[Number(targetScopeId)]
      ?.name ?? translate.phrases.banyanApp('Unknown Block')

  const trapAndDegreeContent: StackedChartPestSection[] = Object.values(insects)
    .filter(
      (insect) =>
        selectedValueGroups[`trap_catches_insect_id_${insect.insectId}` as TValueGroup] ||
        selectedValueGroups[`degree_days_insect_id_${insect.insectId}` as TValueGroup],
    )
    .map(({ insectId }) => {
      const trapCatchValueTypeKey = isFreeRegionalUser
        ? getTrapPercentageValueTypeIdForInsectId(insectId)
        : getTrapValueTypeIdForInsectId(insectId)

      const trapsValueGroupSelected = Boolean(
        selectedValueGroups[`trap_catches_insect_id_${insectId}` as TValueGroup],
      )

      const degreeDaysValueGroupSelected = Boolean(
        selectedValueGroups[`degree_days_insect_id_${insectId}` as TValueGroup],
      )

      const degreeDayValueTypeKey = getDegreeDayValueTypeIdForInsectId(insectId)
      const trapPermission = VIEW_PESTS_TRAP_CATCHES_ID_insectId(insectId)
      const degreeDaysPermission = VIEW_PESTS_DEGREE_DAYS_ID_insectId(insectId)

      const hasTrapPermission = selectedPropertyHasPermission({
        permission: trapPermission,
      })

      const hasRegionTrapPermission = selectedRegionHasPermission({
        permission: trapPermission,
        regionId: targetScopeId,
      })

      const hasDegreeDayPermission = selectedPropertyHasPermission({
        permission: degreeDaysPermission,
      })

      const hasRegionalDegreeDayPermission = selectedRegionHasPermission({
        permission: degreeDaysPermission,
      })

      const valueTypesToCheck = []

      if (trapsValueGroupSelected) valueTypesToCheck.push(trapCatchValueTypeKey)

      if (degreeDaysValueGroupSelected) valueTypesToCheck.push(degreeDayValueTypeKey)

      const title =
        targetScope === 'REGION'
          ? translate.phrases.banyanApp('{{insectName}}', {
              insectName: getInsectNameFromId(insectId),
            })
          : translate.phrases.templates('{{labelA}} - {{labelB}}', {
              labelA: getInsectNameFromId(insectId),
              labelB: sectionTitleSuffix,
            })

      const commonReturnItems = {
        title,
        titleChildren: TitleChildren ? (
          <TitleChildren valuesTimeseriesToFilterOn={valueTypesToCheck} insectId={insectId} />
        ) : null,
        id: `insect-charts-insect-id-${insectId}-${targetScopeId}`,
        pestSectionCategory,
        insectId,
      }

      // permissions checks
      if (isFreeRegionalUser) {
        if (!hasRegionTrapPermission && !hasRegionalDegreeDayPermission) {
          return { ...commonReturnItems, ...regionLacksPermissionSectionMaker(commonReturnItems) }
        }
      } else if (!hasTrapPermission && !hasDegreeDayPermission) {
        return { ...commonReturnItems, ...propertyLacksPermissionSectionMaker(commonReturnItems) }
      } else if (
        targetScope === 'BLOCK' &&
        !doesTargetHaveValueTypes({ valuesTimeseries: valueTypesToCheck })
      ) {
        return { ...commonReturnItems, ...blockLacksDataSectionMaker(commonReturnItems) }
      }

      let trapSeries: TChartSeries[] = []

      const doesTargetHaveTrapValueTypes = doesTargetHaveValueTypes({
        valuesTimeseries: [trapCatchValueTypeKey],
      })

      const colorRules = getColorRulesForPestSeries({
        hasTrapSeries: hasTrapPermission,
        hasDegreeDaySeries: hasDegreeDayPermission,
        hasRegionTrapSeries: hasRegionTrapPermission,
        hasRegionDegreeDaySeries: hasRegionalDegreeDayPermission,
      })

      if (doesTargetHaveTrapValueTypes && trapsValueGroupSelected) {
        trapSeries = buildTrapCatchSeries({
          insectId,
          targetScope,
          targetScopeId,
          targetScopeData,
          trapCatchValueTypeKey,
          regionName,
          regionsData,
          compareSeasonsInterval,
          compareSeasonsTargetScopeData,
          isFreeRegionalUser,
          showRegionalData: showRegionalData && hasRegionTrapPermission,
          colorRules,
        })
      }

      const doesTargetHaveDegreeDayValueTypes = doesTargetHaveValueTypes({
        valuesTimeseries: [degreeDayValueTypeKey],
      })

      const { biofixPlotLines, forecastMoments, DDSeries } = buildDegreeDaySeries({
        insectId,
        DDvalueTypeKey: degreeDayValueTypeKey,
        targetScope,
        targetScopeId,
        targetScopeData,
        targetScopeHasDDValueTypes: doesTargetHaveDegreeDayValueTypes && degreeDaysValueGroupSelected,
        regionName,
        regionsData,
        compareSeasonsInterval,
        compareSeasonsTargetScopeData,
        showRegionalData: showRegionalData && degreeDaysValueGroupSelected && hasRegionalDegreeDayPermission,
        dateFrom,
        dateTo,
        timezone,
        colorRules,
      })

      const allForecastMoments = forecastMoments.filter(Boolean).map((d) => moment.tz(d, timezone))
      const firstForecastTimestamp = allForecastMoments.length ? +moment.min(allForecastMoments) : +new Date()

      const yAxisForDegreeDays: YAxisOptions = {
        title: {
          text: translate.phrases.banyanApp('Degree Days'),
          x: 5,
        },
        opposite: false,
        allowDecimals: false,
        gridLineWidth: 1,
        offset: -12,
      }

      let yAxisForTrapCatches: YAxisOptions = {
        title: {
          text: translate.phrases.banyanApp('Trap Catches'),
        },
        opposite: true,
        allowDecimals: false,
        gridLineWidth: 1,
      }

      const percentageSeries: Partial<YAxisOptions> = {
        min: 0,
        max: 100,
      }

      if (targetScope === 'REGION') {
        yAxisForTrapCatches = {
          ...yAxisForTrapCatches,
          ...percentageSeries,
        }
      }

      const yAxis: YAxisOptions[] = [yAxisForDegreeDays]

      if (trapSeries.length) {
        yAxis.push(yAxisForTrapCatches)

        if (targetScope !== 'REGION' && showRegionalData) {
          yAxis.push({
            ...yAxisForTrapCatches,
            ...percentageSeries,
            visible: false,
          })
        }
      }

      const renderSummary = () => {
        if (targetScope === 'REGION') {
          return <AlmaSummaries insectId={insectId} regionId={targetScopeId} />
        } else if (targetScope === 'PROPERTY') {
          return <AlmaSummaries insectId={insectId} propertyId={selectedProperty} />
        }
      }

      yAxis.length > 1 &&
        detailsPanelStore.setState((state) => ({
          ...state,
          hasExtraRightPadding: true,
        }))

      const blockDoesntHaveTraps =
        targetScope === 'BLOCK' && !doesTargetHaveTrapValueTypes && hasTrapPermission

      return {
        ...commonReturnItems,
        fullWidthItems: blockDoesntHaveTraps
          ? [
              {
                height: 60,
                content: <BlockLacksTrapsMessage blockName={blockName} />,
              },
            ]
          : undefined,
        items: [
          {
            childrenUpper: <div css={{ textAlign: 'right' }}>{renderSummary()}</div>,
            chartConfig: {
              semiosHighchartsAdditions: {
                id: insectTrapCatchesConverter(null, { insectId }).titleWithoutUnit(),
                firstForecastTimestamp,
              },
              yAxis,
              chart: {
                type: 'line',
                height: 350,
              },
              tooltip: {
                xDateFormat: getXDateFormat(true),
              },
              xAxis: {
                plotLines: biofixPlotLines,
              },
              series: [...trapSeries, ...DDSeries].sort(sortByKey('name')),
            },
          },
        ],
      }
    })

  return trapAndDegreeContent
}
