// TODO: we should incorporate this into value type definitions somehow
export const INSECT_TRAP_CATCHES_VALUE_KEY_PREFIX = 'insectTrapCatches_'

export const INSECT_TRAP_CATCHES_PERCENTAGE_VALUE_KEY_PREFIX = 'insectTrapCatchesPercentage_'

export const INSECT_LARVAL_TREND_VALUE_KEY_PREFIX = 'insectLarvalTrend_'

export const INSECT_DEGREE_DAY_VALUE_KEY_PREFIX = 'insectDegreeDaysCumulativeSinceBiofix_'

export const INSECT_PHENOLOGY_VALUE_KEY_PREFIX = 'insectPhenology_'
