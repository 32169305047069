import { makeBaseSvgIconProps } from './_utils/makeBaseSvgIconProps'

export const IconBluetoothCircle = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 29 29')}>
      <circle cx="14.5" cy="14.5" r="14.5" fill="currentColor" fillOpacity="0.2" />
      <path
        d="M13.5417 23.3048V16.5264L9.13335 20.6291L7.79169 19.3804L13.1584 14.3858L7.79169 9.39116L9.13335 8.1425L13.5417 12.2452V5.4668H14.5L19.9625 10.5506L15.8417 14.3858L19.9625 18.221L14.5 23.3048H13.5417ZM15.4584 12.2452L17.2792 10.5506L15.4584 8.90061V12.2452ZM15.4584 19.871L17.2792 18.221L15.4584 16.5264V19.871Z"
        fill="currentColor"
      />
    </svg>
  )
}
