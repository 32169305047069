import { ActionIcon, Badge, CloseButton, Flex } from '@mantine/core'
import type { routes } from '@semios/app-platform-banyan-route-definitions'
import { APP_PLATFORM_VERSION } from '@semios/app-platform-version'
import { translate } from 'i18n/i18n'
import { isEmpty, merge } from 'lodash'
import { useEffect, useState } from 'react'
import { colors } from 'settings/colors'
import type { TFeedbackEventStore } from 'stores/feedbackEventStore'
import { feedbackEventStore } from 'stores/feedbackEventStore'
import { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { apiFetch } from 'utils/apiFetch'
import { useApiREST } from 'utils/useApiREST'
import { IconAlma } from './IconAlma'
import { IconThumbDown } from './IconThumbDown'
import { IconThumbDownInverse } from './IconThumbDownInverse'
import { IconThumbUp } from './IconThumbUp'
import { IconThumbUpInverse } from './IconThumbUpInverse'

type PropsForSummary = {
  queryParams: routes.ValuesCurrent.Request
  renderFunction: (summaryTemplate: routes.ValuesCurrent.Response) => React.ReactNode
  getStatementId: (summaryTemplate: routes.ValuesCurrent.Response) => string | undefined
}

export type TAlmaSummaryProps = {
  summaries: Record<string, PropsForSummary | null>
  context?: string
}

const HoverIcon = (props: { onMouseEnter: () => void; onMouseLeave: () => void }) => {
  return <IconAlma {...props} />
}

const logFeedbackEvent = (params: routes.LogSidekickFeedback.Request) => {
  apiFetch({ url: '/log-sidekick-feedback', body: params })
}

export const AlmaSummary = (props: TAlmaSummaryProps) => {
  const mergedParams = merge(
    {},
    ...Object.values(props.summaries).map((summary) => summary?.queryParams ?? {}),
  )

  const { data, loading } = useApiREST({
    body: mergedParams,
    url: '/values-current',
    preventFetch: isEmpty(mergedParams),
    watchers: [JSON.stringify(mergedParams)],
    initialState: null,
  })

  const [showHoverInfo, setShowHoverInfo] = useState(false)
  const [renderedStatements, setRenderedStatements] = useState<Record<string, React.ReactNode>>({})
  const [statementIds, setStatementIds] = useState<Record<string, string | undefined>>({})
  const [showSummaries, setShowSummaries] = useState(false)

  const feedbackMeta = {
    appName: import.meta.env.VITE_APP_NAME,
    appVersion: APP_PLATFORM_VERSION,
    appContext: props.context ?? 'summary',
    selectedValueGroups: Object.keys(selectedValueGroupsStore.getState().selectedValueGroups ?? {}),
  }

  useEffect(() => {
    if (data) {
      const newRenderedStatements: Record<string, React.ReactNode> = {}
      const newStatementIds: Record<string, string | undefined> = {}

      for (const [key, summary] of Object.entries(props.summaries)) {
        const statementId = summary?.getStatementId(data)
        const renderedStatement = summary?.renderFunction(data)

        if (statementId) {
          newStatementIds[key] = statementId
        }

        if (renderedStatement) {
          newRenderedStatements[key] = renderedStatement
        }
      }

      setStatementIds(newStatementIds)

      setRenderedStatements(newRenderedStatements)
    }
  }, [loading, data])

  const handleView = () => {
    const statementIdsBeingViewed = Object.values(statementIds).filter(Boolean) as string[]

    if (statementIdsBeingViewed.length > 0) {
      logFeedbackEvent({
        statementIds: statementIdsBeingViewed,
        meta: {
          appName: import.meta.env.VITE_APP_NAME,
          appVersion: APP_PLATFORM_VERSION,
          appContext: 'summary',
          selectedValueGroups: Object.keys(selectedValueGroupsStore.getState().selectedValueGroups ?? {}),
        },
        feedbackType: 'view',
      })

      feedbackEventStore.setState((state) => {
        return {
          ...state,
          views: new Set([...state.views, ...statementIdsBeingViewed]),
        }
      })
    }
  }

  if (data && !loading && Object.keys(renderedStatements).length > 0) {
    if (!showSummaries) {
      // show just the icon
      return (
        <Badge
          variant="outline"
          size="xl"
          rightSection={
            <HoverIcon
              onMouseEnter={() => setShowHoverInfo(true)}
              onMouseLeave={() => setShowHoverInfo(false)}
            />
          }
          onClick={() => {
            setShowSummaries(true)

            handleView()
          }}
          css={{
            'paddingRight': 0,
            'marginRight': 5,
            'marginTop': 5,
            'color': colors.almaDark,
            'borderColor': showHoverInfo ? colors.almaDark : 'transparent',
            'fontWeight': 500,
            'textTransform': 'unset',
            'fontSize': 14,
            '.mantine-Badge-rightSection': {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: 0,
            },
          }}
        >
          {showHoverInfo && (
            <span css={{ marginRight: 2 }}>{translate.phrases.banyanApp('View Summary')}</span>
          )}
        </Badge>
      )
    } else {
      // expand to show the summaries
      return (
        <div
          css={{
            background: colors.almaBackground,
            margin: 10,
            padding: 10,
            fontSize: 14,
            textAlign: 'left',
            borderRadius: 3,
          }}
        >
          <Flex direction="row" align="center" justify="space-between">
            <Flex direction="row" align="center" justify="flex-start" gap="sm">
              <IconAlma />
              <span>{translate.phrases.banyanApp('Summary')}</span>
            </Flex>
            <CloseButton
              // TODO: TS2590 error happens here when upgrading from typescript 4.8.4 to 5.6.3 ... can't figure it out...
              // @ts-ignore
              onClick={() => setShowSummaries(false)}
              size="lg"
              variant="transparent"
              css={{ color: colors.grey800 }}
            />
          </Flex>

          {Object.keys(props.summaries).map((key) => {
            const summaryStatement = renderedStatements[key]
            const statementId = statementIds[key]

            if (summaryStatement) {
              return (
                <AlmaSummaryInner
                  key={key}
                  statementId={statementId ?? ''}
                  renderedStatement={summaryStatement}
                  feedbackMeta={feedbackMeta}
                />
              )
            }

            return null
          })}
        </div>
      )
    }
  }

  return null
}

const AlmaSummaryInner = ({
  statementId,
  renderedStatement,
  feedbackMeta,
}: {
  statementId: string
  renderedStatement: React.ReactNode
  feedbackMeta: routes.LogSidekickFeedback.Request['meta']
}) => {
  const { feedbackType } = feedbackEventStore.useSelector((state) => {
    const feedbackType = statementId ? state.feedback[statementId] : undefined

    return { feedbackType }
  })

  const handleFeedback = (feedbackType: TFeedbackEventStore['feedback'][string] | null) => {
    if (statementId) {
      logFeedbackEvent({
        statementId,
        meta: feedbackMeta,
        feedbackType,
      })

      feedbackEventStore.setState((state) => {
        return {
          ...state,
          feedback: {
            ...state.feedback,
            [statementId]: feedbackType,
          },
        }
      })
    }
  }

  return (
    <>
      <div css={{ margin: 20, lineHeight: '1.5em' }}>{renderedStatement}</div>
      <Flex direction="row" align="center" justify="flex-start" gap="sm" css={{ marginLeft: 20 }}>
        <ActionIcon
          variant="subtle"
          css={{ 'borderRadius': '50%', ':hover': { backgroundColor: colors.grey50 }, 'fontSize': 24 }}
          onClick={() => {
            if (feedbackType === 'like') {
              handleFeedback(null)
            } else {
              handleFeedback('like')
            }
          }}
        >
          {feedbackType === 'like' ? <IconThumbUpInverse /> : <IconThumbUp />}
        </ActionIcon>
        <ActionIcon
          variant="subtle"
          css={{
            'borderRadius': '50%',
            ':hover': { backgroundColor: colors.grey50 },
            'fontSize': 24,
          }}
          onClick={() => {
            if (feedbackType === 'dislike') {
              handleFeedback(null)
            } else {
              handleFeedback('dislike')
            }
          }}
        >
          {feedbackType === 'dislike' ? <IconThumbDownInverse /> : <IconThumbDown />}
        </ActionIcon>
      </Flex>
    </>
  )
}
