import { notifications } from '@mantine/notifications'
import { ErrorBoundary } from '@sentry/react'
import { AboveAllModal } from 'components/AboveAllModalOverlay/AboveAllModalOverlay'
import { IconWarning } from 'components/icons/IconWarning'
import { translate } from 'i18n/i18n'
import type { GeoJSONPosition } from 'wellknown'
import { stringify } from 'wellknown'
import NodePositioningModal from '../NodePositioningModal'
import type { TActiveGateway, TActiveNode, TPlannedGateway, TPlannedNode } from '../types'
import { isSemiosGatewayType } from './getNodeNetworkType'
import { isPlannedNode } from './isPlannedNode'
import { repositionActiveGateway } from './repositionActiveGateway'
import { repositionActiveNode } from './repositionActiveNode'
import { repositionPlannedGateway } from './repositionPlannedGateway'
import { repositionPlannedNodes } from './repositionPlannedNodes'

const MODAL_ID = 'reposition-node-modal'

export function openNodeRepositionModal(node: TActiveNode | TActiveGateway | TPlannedNode | TPlannedGateway) {
  const selectedNodeLocation = JSON.parse(node.location)

  const mapDefaultCenter = {
    lat: selectedNodeLocation.coordinates[1],
    lng: selectedNodeLocation.coordinates[0],
  }

  const handleSubmit = async (mapCenter: google.maps.LatLngLiteral | undefined) => {
    if (!mapCenter) return

    const { lat, lng } = mapCenter
    const coordinates = [lng, lat] as GeoJSONPosition

    const stringLocation = stringify({
      type: 'Point',
      coordinates,
    })

    try {
      if (isPlannedNode(node)) {
        if ((node as TPlannedNode).nodeType.includes('gtwy') && (node as TPlannedNode)?.pseudogatewayId) {
          const params = {
            pseudogatewayId: (node as TPlannedNode).pseudogatewayId as number,
            location: stringLocation,
          }

          await repositionPlannedGateway(params)
        } else {
          const params = { [(node as TPlannedNode).id]: stringLocation }

          await repositionPlannedNodes(params)
        }
      } else if ((node as TActiveGateway).gatewayType && (node as TActiveGateway).gatewayIdentifier) {
        const params = {
          gatewayIdentifier: (node as TActiveGateway).gatewayIdentifier,
          location: stringLocation,
          propertyId: (node as TActiveGateway).propertyIds[0],
        }

        await repositionActiveGateway(params)
      } else {
        const params = {
          nodeIdentifier: (node as TActiveNode).nodeIdentifier,
          location: stringLocation,
          propertyId: (node as TActiveNode).propertyId,
        }

        await repositionActiveNode(params)
      }

      AboveAllModal.close(MODAL_ID)

      notifications.show({ title: 'Success', message: 'Device has been successfully repositioned' })
    } catch (e) {
      notifications.show({
        id: 'reposition-error',
        withCloseButton: true,
        icon: <IconWarning />,
        autoClose: 3000,
        title: 'Error',
        message: (e as Error).message,
      })
    }
  }

  AboveAllModal.open({
    modalId: MODAL_ID,
    fullScreen: true,
    withCloseButton: false,
    padding: 0,
    children: (
      <ErrorBoundary>
        <NodePositioningModal
          showInitialLocationMarker={true}
          headerTitle={translate.phrases.placeholder('Reposition Node')}
          initialCenter={mapDefaultCenter}
          onSubmit={handleSubmit}
          onClose={() => AboveAllModal.close(MODAL_ID)}
          disableSubmitButtonOnDefaultLocation
          nodeType={
            isSemiosGatewayType(node) ? (node as TActiveGateway).gatewayType : (node as TActiveNode).nodeType
          }
        />
      </ErrorBoundary>
    ),
    styles: {
      content: {
        paddingTop: 'env(safe-area-inset-top)',
        boxShadow: 'none',
        transform: 'none !important',
      },
    },
  })
}
