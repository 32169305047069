import { Popover } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { IconInfo } from 'components/icons/IconInfo'
import { IconWarning } from 'components/icons/IconWarning'
import type { ReactNode } from 'react'
import { colors } from 'settings/colors'

const darkModeStyle = {
  backgroundColor: colors.midnight,
  color: colors.white,
}

export const InfoPopoverOnClick = ({
  popoverContent,
  outsideContent = null,
  width = 350,
  darkMode = false,
  yellowInfoIcon = false,
}: {
  popoverContent: ReactNode
  outsideContent?: ReactNode
  width?: number
  darkMode?: boolean
  yellowInfoIcon?: boolean
}) => {
  const [opened, { close, open }] = useDisclosure(false)

  return (
    <Popover withArrow shadow="md" radius={5} width={width} opened={opened} withinPortal={true}>
      <Popover.Target>
        <div onClick={opened ? close : open} css={{ marginLeft: 5 }} style={{ cursor: 'pointer' }}>
          {!yellowInfoIcon && <IconInfo />}
          {yellowInfoIcon && <IconWarning />}
          <span>{outsideContent}</span>
        </div>
      </Popover.Target>
      <Popover.Dropdown css={[{ maxWidth: '180px' }, darkMode && darkModeStyle]} onMouseLeave={close}>
        {popoverContent}
      </Popover.Dropdown>
    </Popover>
  )
}
