import { Button } from 'components/Button/Button'
import { SpinningRefreshIcon } from 'components/SpinningRefreshIcon/SpinningRefreshIcon'
import { translate } from 'i18n/i18n'
import { fetchFertigationSettings } from '../../fetchFertigationSettings'
import type { TFertigationSettingsData } from '../../types'

export const RefreshWiseconnDataButton = ({
  propertyIds,
  setFertigationSettings,
  loading,
  setLoading,
}: {
  propertyIds: number[]
  setFertigationSettings: React.Dispatch<React.SetStateAction<TFertigationSettingsData[]>>
  loading: boolean
  setLoading: (arg: boolean) => void
}) => {
  return (
    <Button
      css={{ height: 35 }}
      variant="secondary"
      disabled={loading}
      onClick={() => {
        if (propertyIds.length === 0 || loading) return

        fetchFertigationSettings({
          setLoading,
          setFertigationSettings,
          propertyIds,
        })
      }}
      leftIcon={
        <div css={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <SpinningRefreshIcon loading={loading} />
        </div>
      }
    >
      {loading
        ? translate.phrases.banyanApp('Update In Progress')
        : translate.phrases.banyanApp('Update List')}
    </Button>
  )
}
