import { redirectRoute } from 'utils/redirectRoute'
import { setToken } from 'utils/setToken'

export const setTokenAndPushHome = async (token: string) => {
  if (token) {
    setToken(token)

    redirectRoute('/')
  }
}
