import { CloseButton } from '@mantine/core'
import { Button } from 'components/Button/Button'
import { DatePicker } from 'components/DatePicker/DatePicker'
import { IconEdit } from 'components/icons/IconEdit'
import { IconPlusCircle } from 'components/icons/IconPlusCircle'
import { IconTrashCan } from 'components/icons/IconTrashCan'
import { MultiSelect } from 'components/MultiSelect/MultiSelect'
import { translate } from 'i18n/i18n'
import { isEmpty } from 'lodash'
import moment from 'moment-timezone'
import type { FC } from 'react'
import { colors } from 'settings/colors'
import { useScreenSize } from 'utils/useScreenSize'
import { BIOFIX_OPTIONS } from '../_utils/biofixOptions'
import { getMinAndMaxYear } from '../_utils/getMinAndMaxYear'
import type { BiofixConfigMenuPropType, BiofixOptionType } from '../types'

export const BiofixConfigMenu: FC<BiofixConfigMenuPropType> = ({
  biofixConfigOption,
  setBiofixConfigOption,
  biofixDate,
  setBiofixDate,
  deleteBiofixByBlockId,
  editBiofixDateByBlockId,
  handleAddBiofix,
  handleDeleteBiofix,
  handleEditBiofix,
  loading,
  selectedBlocks,
  setSelectedBlocks,
  timezone,
  year,
  getCropsBlocksMultiSelectData,
  handleClearTentativeEdit,
}) => {
  const { isWideScreen } = useScreenSize()

  const actionTitleForBiofixOption = {
    [BIOFIX_OPTIONS.ADD]: translate.phrases.banyanApp('Add New Biofix Date'),
    [BIOFIX_OPTIONS.DELETE]: translate.phrases.banyanApp('Select Biofix Dates Below To Delete'),
    [BIOFIX_OPTIONS.EDIT]: translate.phrases.banyanApp('Change Selected Biofix Dates To'),
  }

  const { minYear } = getMinAndMaxYear(timezone)

  const handleCancelAction = () => {
    setBiofixConfigOption(null)

    setBiofixDate(moment.tz(timezone).year(year))
  }

  return (
    <div>
      <div
        css={{
          display: 'flex',
          justifyContent: isWideScreen ? 'flex-end' : 'space-around',
          flexWrap: 'wrap',
        }}
      >
        <Button
          variant="link"
          leftIcon={<IconPlusCircle />}
          onClick={() => setBiofixConfigOption(BIOFIX_OPTIONS.ADD as BiofixOptionType)}
        >
          {translate.phrases.banyanApp('Add New')}
        </Button>
        <Button
          variant="link"
          leftIcon={<IconEdit />}
          onClick={() => setBiofixConfigOption(BIOFIX_OPTIONS.EDIT as BiofixOptionType)}
        >
          {translate.phrases.banyanApp('Edit')}
        </Button>
        <Button
          variant="link"
          leftIcon={<IconTrashCan />}
          onClick={() => setBiofixConfigOption(BIOFIX_OPTIONS.DELETE as BiofixOptionType)}
        >
          {translate.phrases.banyanApp('Delete')}
        </Button>
      </div>
      {biofixConfigOption && (
        <div
          css={{
            backgroundColor: colors.grey50,
            borderRadius: 3,
            marginBottom: '10px',
            padding: '10px',
            width: '100%',
          }}
        >
          <div
            css={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <span css={{ fontWeight: 700, fontSize: 16 }}>
              {actionTitleForBiofixOption[biofixConfigOption]}
            </span>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <CloseButton onClick={handleCancelAction} />
          </div>
          <div
            css={{
              alignItems: !isWideScreen ? 'flex-start' : 'center',
              display: 'flex',
              flexDirection: !isWideScreen ? 'column' : 'row',
              justifyContent: 'flex-start',
            }}
          >
            {biofixConfigOption && biofixConfigOption === BIOFIX_OPTIONS.ADD && (
              <>
                <div
                  css={{
                    alignItems: !isWideScreen ? 'flex-start' : 'center',
                    display: 'flex',
                    flexDirection: !isWideScreen ? 'column' : 'row',
                    marginBottom: !isWideScreen ? '10px' : '0',
                  }}
                >
                  <div
                    css={{
                      alignItems: !isWideScreen ? 'flex-start' : 'center',
                      display: 'flex',
                      flexDirection: !isWideScreen ? 'column' : 'row',
                      marginBottom: !isWideScreen ? '5px' : '0',
                      marginRight: !isWideScreen ? 0 : '5px',
                    }}
                  >
                    <DatePicker
                      minDate={moment.tz('2016-01-01', timezone).startOf('day').toDate()}
                      maxDate={moment.tz(timezone).toDate()}
                      onChange={(newDate) => {
                        if (newDate) setBiofixDate(moment.tz(newDate, timezone))
                      }}
                      value={biofixDate.toDate()}
                      valueFormat={translate.dates.getMomentFormat('MMM D, YYYY')}
                    />
                  </div>
                  <div
                    css={{
                      'alignItems': !isWideScreen ? 'flex-start' : 'center',
                      'display': 'flex',
                      'flexDirection': !isWideScreen ? 'column' : 'row',
                      'marginBottom': !isWideScreen ? '10px' : '0',
                      '> *': {
                        margin: isWideScreen ? '0 5px' : '5px 0',
                      },
                    }}
                  >
                    <div>{translate.phrases.banyanApp('to')}</div>

                    <MultiSelect
                      containerCss={{ width: isWideScreen ? 400 : '80vw' }}
                      data={getCropsBlocksMultiSelectData()}
                      groupOptions={true}
                      sortGroups={true}
                      selectedData={selectedBlocks}
                      onChange={(blocks) => setSelectedBlocks(blocks)}
                      placeholder={translate.phrases.banyanApp('Select blocks')}
                      noOfValuesToDisplay={2}
                      selectedValueDecorator={(value, index) => {
                        return index && index >= 2 ? `+ ${value}` : `${value}`
                      }}
                    />
                  </div>
                </div>
                <Button
                  variant="primary"
                  loading={loading}
                  disabled={isEmpty(selectedBlocks)}
                  onClick={handleAddBiofix}
                >
                  {translate.phrases.banyanApp('Add Biofix')}
                </Button>
              </>
            )}
            {biofixConfigOption && biofixConfigOption === BIOFIX_OPTIONS.DELETE && (
              <>
                <Button
                  variant="primary"
                  loading={loading}
                  disabled={isEmpty(deleteBiofixByBlockId)}
                  onClick={handleDeleteBiofix}
                >
                  {translate.phrases.banyanApp('Delete Selected Dates')}
                </Button>
              </>
            )}
            {biofixConfigOption && biofixConfigOption === BIOFIX_OPTIONS.EDIT && (
              <>
                <div
                  css={{
                    alignItems: !isWideScreen ? 'flex-start' : 'center',
                    display: 'flex',
                    flexDirection: !isWideScreen ? 'column' : 'row',
                    marginBottom: !isWideScreen ? '10px' : '0',
                  }}
                >
                  <DatePicker
                    excludeDate={(current) => {
                      if (
                        moment
                          .tz(current, timezone)
                          .isBefore(moment.tz(timezone).set('year', year).startOf('year'))
                      )
                        return true

                      if (moment.tz(timezone) < moment.tz(`${minYear}-01-01`, timezone).startOf('day')) {
                        return true
                      }

                      return moment.tz(current, timezone) > moment.tz(timezone)
                    }}
                    onChange={(newDate) => {
                      if (newDate) setBiofixDate(moment.tz(newDate, timezone))
                    }}
                    value={biofixDate.toDate()}
                    valueFormat={translate.dates.getMomentFormat('MMM D, YYYY')}
                  />
                </div>
                <div>
                  <Button
                    css={{ marginRight: 15 }}
                    disabled={isEmpty(editBiofixDateByBlockId)}
                    onClick={handleClearTentativeEdit}
                    variant="link"
                  >
                    {translate.phrases.banyanApp('Clear')}
                  </Button>
                  <Button
                    variant="primary"
                    loading={loading}
                    disabled={isEmpty(editBiofixDateByBlockId)}
                    onClick={handleEditBiofix}
                  >
                    {translate.phrases.banyanApp('Change Selected Dates')}
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  )
}
