import { translate } from 'i18n/i18n'
import { isNil } from 'lodash'
import type { TUnitConverterOptionsWithoutUnits, TUnitConverterTypicalReturn } from '../types'

const DEFAULT_DECIMAL_PLACES_HRS = 1

export const leafWetness = {
  leafWetnessHours: (
    value: number | null = null,
    { decimalPlaces = DEFAULT_DECIMAL_PLACES_HRS }: TUnitConverterOptionsWithoutUnits = {},
  ): TUnitConverterTypicalReturn => {
    return {
      categoryTitle: () => translate.phrases.banyanApp('Leaf Wetness'),
      categoryTitleWithoutUnit: () => translate.phrases.banyanApp('Leaf Wetness'),
      defaultNumberOfDecimalPlaces: () => DEFAULT_DECIMAL_PLACES_HRS,
      suffix: () => translate.measurements.hours.unit(),
      shortTitle: () => translate.phrases.banyanApp('Wet Hours'),
      title: () => translate.phrases.banyanApp('Leaf Wetness Hours'),
      titleWithoutUnit: () => translate.phrases.banyanApp('Leaf Wetness'),
      value: () => {
        if (isNil(value)) return null

        return +value?.toFixed(decimalPlaces)
      },
      valueAsString: () => translate.measurements.hours.value(value, decimalPlaces),
      valueWithSuffix: () => translate.measurements.hours.valueWithUnit(value, decimalPlaces),
      valueWithNoRounding: () => value,
    }
  },
}
