import { ButtonLinkSmall } from 'App/Map/UserSettingsMenu/Shared/MyDetails/ButtonLinkSmall'
import { translate } from 'i18n/i18n'
import type { FC } from 'react'
import { useState } from 'react'
import { ChangePassword } from './updateUserDetailsRelatedForms/ChangePassword'

export const ChangePasswordSection: FC = () => {
  const [showChangePassword, setShowChangePassword] = useState(false)

  return (
    <div css={{ margin: '20px 0', paddingBottom: 20, zIndex: 1 }}>
      <div css={{ fontWeight: 700, marginBottom: 16 }}>{translate.phrases.banyanApp('Change Password')}</div>
      {showChangePassword ? (
        <ChangePassword />
      ) : (
        <ButtonLinkSmall onClick={() => setShowChangePassword(true)}>
          {translate.phrases.banyanApp('Update Password')}
        </ButtonLinkSmall>
      )}
    </div>
  )
}
