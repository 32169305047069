import { Loader } from '@mantine/core'
import type { SharedTypes } from '@semios/app-platform-banyan-route-definitions'
import { NoDataProperty } from 'App/Map/UserSettingsMenu/components/NoDataProperty'
import { SettingsSearchNotFound } from 'App/Map/UserSettingsMenu/components/SettingsSearchNotFound/SettingsSearchNotFound'
import { translate } from 'i18n/i18n'
import { searchStringFromStringWithSpaces } from 'utils/searchStringFromStringWithSpaces'
import type { TFertigationSettingsData } from '../../types'
import { PropertySection } from './PropertySection'

export const InjectorsTab = ({
  data,
  filterPropertiesText,
  loading,
  properties,
  propertiesWithEditPermission,
}: {
  data: TFertigationSettingsData[]
  filterPropertiesText: string
  loading: boolean
  properties: SharedTypes.TProperty[]
  propertiesWithEditPermission: number[]
}) => {
  const fertigationPropertyIds = data.map((d) => d.propertyId) ?? []

  const mappedInjectorsData = data.flatMap((d) => {
    const foundProperty = properties.find((p) => p.propertyId === d.propertyId)

    if (!foundProperty) {
      return []
    }

    return {
      propertyName: foundProperty.propertyName,
      propertyId: d.propertyId,
      farmId: d.farmId,
      injectors: d.injectorSettings.filter((injector) => {
        return searchStringFromStringWithSpaces(filterPropertiesText, injector.tankName)
      }),
    }
  })

  const propertiesWithoutFertigationSettings = properties.filter(
    (p) => !fertigationPropertyIds.includes(p.propertyId),
  )

  const hasSearchResults =
    filterPropertiesText &&
    mappedInjectorsData.every((property) => {
      return property.injectors.length === 0
    })

  return (
    <div css={{ display: 'flex', flexDirection: 'column' }}>
      {loading ? (
        <div css={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
          <Loader />
        </div>
      ) : (
        <div>
          {!hasSearchResults ? (
            mappedInjectorsData.map((property) =>
              property.injectors.length === 0 ? null : (
                <PropertySection
                  key={property.farmId}
                  property={property}
                  hasEditPermission={propertiesWithEditPermission.includes(property.propertyId)}
                />
              ),
            )
          ) : (
            <SettingsSearchNotFound />
          )}
          {!filterPropertiesText &&
            propertiesWithoutFertigationSettings.map(
              (property) =>
                property && (
                  <NoDataProperty
                    key={property.propertyId}
                    title={property.propertyName}
                    noDataText={translate.phrases.banyanApp(
                      'There are no injectors configured for your property. Please contact Semios if you wish to add injectors.',
                    )}
                  />
                ),
            )}
        </div>
      )}
    </div>
  )
}
