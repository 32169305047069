import { IconCurrentLocationMarker } from 'components/icons/IconCurrentLocationMarker'
import { useContext, useEffect, useRef } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import { colors } from 'settings/colors'
import svg64 from 'svg64'
import { mItemZIndexes } from 'utils/useSetMapOverlayZIndex'
import { MapContext } from '../../MapContext/MapContext'

export const useLocationMarker = ({
  position,
  accuracy,
}: {
  position: google.maps.LatLngLiteral | null
  accuracy: number | null
}) => {
  const { map } = useContext(MapContext)
  const positionMarker = useRef<google.maps.Marker | null>(null)
  const accuracyCircle = useRef<google.maps.Circle | null>(null)

  useEffect(() => {
    if (!map) return

    if (accuracyCircle.current) {
      if (!position || !accuracy) {
        // Hide the circle if position or accuracy are missing
        accuracyCircle.current.setMap(null)
      } else {
        accuracyCircle.current.setMap(map)

        // Update the circle with new position & accuracy
        accuracyCircle.current.setCenter(position)

        accuracyCircle.current.setRadius(accuracy)
      }
    } else if (position && accuracy) {
      accuracyCircle.current = new google.maps.Circle({
        strokeWeight: 0,
        fillColor: colors.primaryLight,
        fillOpacity: 0.35,
        map,
        center: position,
        radius: accuracy,
        zIndex: mItemZIndexes.M_CurrentLocation,
      })
    }

    return () => {
      if (accuracyCircle.current) {
        accuracyCircle.current.setMap(null)
      }
    }
  }, [map, position, accuracy])

  useEffect(() => {
    if (!map) return

    if (positionMarker.current) {
      if (!position) {
        // Hide the marker if no position
        positionMarker.current.setMap(null)
      } else {
        positionMarker.current.setMap(map)

        // Update the marker with new position
        positionMarker.current.setPosition(position)
      }
    } else if (position) {
      const iconSvgElement = IconCurrentLocationMarker().props.children
      const iconSvgContent = renderToStaticMarkup(iconSvgElement)
      const iconWidth = 60
      const iconHeight = 60

      const svgString = `
      <svg width="${iconWidth}" height="${iconHeight}" viewBox="0 0 ${iconWidth} ${iconHeight}" xmlns="http://www.w3.org/2000/svg">
        ${iconSvgContent}
      </svg>`

      // Transform SVG to base64 url
      const base64Svg = svg64(svgString)
      const anchor = new google.maps.Point(iconWidth / 2, iconHeight / 2)

      const image: google.maps.Icon = {
        url: base64Svg,
        size: new google.maps.Size(iconWidth, iconHeight),
        anchor,
      }

      positionMarker.current = new google.maps.Marker({
        position,
        icon: image,
        map,
        zIndex: mItemZIndexes.M_CurrentLocation,
      })
    }

    return () => {
      if (positionMarker.current) {
        positionMarker.current.setMap(null)
      }
    }
  }, [map, position])

  return null
}
