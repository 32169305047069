import type { routes } from '@semios/app-platform-banyan-route-definitions'
import type { VV } from '@semios/app-platform-value-type-definitions'
import { PrecipitationTitleChildren } from 'App/Map/PanelDetails/SectionTitleBars/PrecipitationTitleChildren/PrecipitationTitleChildren'
import { getTimezoneForSelectedPropertyOrRegion } from 'App/Map/PanelDetails/_utils/getTimezoneForSelectedPropertyOrRegion'
import { propertyLacksPermissionSectionMaker } from 'App/Map/PanelDetails/_utils/propertyLacksPermissionSectionMaker'
import { selectedPropertyHasPermission } from 'App/Map/PanelDetails/_utils/selectedPropertyHasPermission'
import { selectedRegionHasPermission } from 'App/Map/PanelDetails/_utils/selectedRegionHasPermission'
import { EAggregationInterval } from 'App/Map/types'
import type {
  StackedChartSection,
  TChartSeries,
  TChartSeriesWithTRGBAColorWith1AtTheEndColor,
} from 'components/StackedChart/types'
import { isEmpty } from 'lodash'
import { default as moment } from 'moment-timezone'
import { colors } from 'settings/colors'
import { detailsPanelStore } from 'stores/detailsPanelStore'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import type { TPointCategory, TSelectedFieldAssetsStoreState } from 'stores/selectedFieldAssetsStore'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import type { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { doesSelectedPointHaveValueTypes } from 'utils/doesSelectedFieldAssetHaveValueTypes'
import { isUserOnlyAFreeRegionalUser } from 'utils/isUserOnlyAFreeRegionalUser'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import { isRegionDataEnabledForCharts } from 'utils/useIsRegionDataEnabled'
import { chooseAmongstUnAggHourlyAndDaily } from '../../chooseAmongstUnAggHourlyAndDaily'
import { getColorRulesForCanopySeries } from '../../createContent/_utils/getColorRulesForSeries'
import { getXDateFormat } from '../_utils/getXDateFormat'
import { makeCompareSeasonsSeriesFromRegularSeries } from '../_utils/makeCompareSeasonsSeriesFromRegularSeries'
import {
  makeRegionalSeries,
  makeRegionalSeriesFromRegularSeries,
} from '../_utils/makeRegionalSeriesFromRegularSeries'
import { renderCumulativeCaption } from './renderCumulativeCaption'

const pointCategory: TPointCategory = 'outOfBlockPoint'
const permissionRequired = 'VIEW_WEATHER_DETAILS'

const checkPermission = () =>
  selectedPropertyHasPermission({ permission: permissionRequired }) ||
  (isUserOnlyAFreeRegionalUser() && selectedRegionHasPermission({ permission: permissionRequired }))

const possibleValueTypesToRequest: VV.DomainTypes.Weather.TTimeseriesValueTypeKeysMerged[] = ['precipitation']

const getRegionalValuesRequested = ({
  selectedFieldAssets,
  preferredAggregationInterval,
}: {
  selectedFieldAssets: TSelectedFieldAssetsStoreState
  preferredAggregationInterval: {
    preferredAggregationInterval: ReturnType<typeof chooseAmongstUnAggHourlyAndDaily>
  }
}): Partial<routes.Values.Request> => {
  const selectedRegion = selectedFieldAssets.region

  if (!selectedRegion) return {}

  const valuesForRegion = fieldAssetStore.getState().regions?.[selectedRegion]?.valuesTimeseries || []
  const valuesToRequestForRegion = possibleValueTypesToRequest.filter((v) => valuesForRegion.includes(v))

  const valuesRequested = valuesToRequestForRegion.reduce((request, valueType) => {
    request[valueType] = preferredAggregationInterval

    return request
  }, {} as Partial<Record<typeof possibleValueTypesToRequest[number], typeof preferredAggregationInterval>>)

  if (isEmpty(valuesRequested)) return {}

  return {
    regions: {
      regionIds: [selectedRegion],
      valuesRequested,
    },
  }
}

const getPointsValuesRequested = ({
  selectedFieldAssets,
  preferredAggregationInterval,
}: {
  selectedFieldAssets: TSelectedFieldAssetsStoreState
  preferredAggregationInterval: {
    preferredAggregationInterval: ReturnType<typeof chooseAmongstUnAggHourlyAndDaily>
  }
}): Partial<routes.Values.Request> => {
  if (!selectedFieldAssets[pointCategory] || !selectedFieldAssets.property) return {}

  const valuesForPoint =
    fieldAssetStore.getState().properties?.[selectedFieldAssets.property]?.points?.[
      selectedFieldAssets[pointCategory]
    ]?.valuesTimeseries || []

  const valuesToRequest = possibleValueTypesToRequest.filter((v) => valuesForPoint.includes(v))

  const valuesRequested = valuesToRequest.reduce((request, valueType) => {
    request[valueType] = preferredAggregationInterval

    return request
  }, {} as Partial<Record<typeof possibleValueTypesToRequest[number], typeof preferredAggregationInterval>>)

  if (
    !doesSelectedPointHaveValueTypes({
      valuesTimeseries: Object.keys(valuesRequested),
      pointCategory,
    })
  )
    return {}

  return {
    points: {
      lngLats: [selectedFieldAssets[pointCategory]],
      valuesRequested,
    },
  }
}

export const apiArgs = ({
  selectedValueGroups,
  selectedFieldAssets,
}: {
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}): Partial<routes.Values.Request> => {
  if (!checkPermission()) return {}

  if (!selectedValueGroups.precipitation) return {}

  const interval = detailsPanelStore.getState().precipitationAggregation

  const preferredAggregationInterval = {
    preferredAggregationInterval: interval as EAggregationInterval.DAILY | EAggregationInterval.HOURLY,
  }

  const pointsToRequest = getPointsValuesRequested({
    selectedFieldAssets,
    preferredAggregationInterval,
  })

  const showRegionalData = isRegionDataEnabledForCharts()

  const regionsToRequest = showRegionalData
    ? getRegionalValuesRequested({
        selectedFieldAssets,
        preferredAggregationInterval,
      })
    : null

  return { ...pointsToRequest, ...regionsToRequest }
}

export const content = ({
  data,
  compareSeasonsData,
}: {
  data: routes.Values.Response
  compareSeasonsData: routes.Values.Response
}): StackedChartSection => {
  const commonReturnItems = {
    title: unitConverter.precipitation().categoryTitleWithoutUnit(),
    titleChildren: (
      <PrecipitationTitleChildren
        showAggregationToggle={false}
        valuesTimeseriesToFilterOn={possibleValueTypesToRequest}
      />
    ),
    id: 'stackem-rain',
  }

  if (!checkPermission()) return propertyLacksPermissionSectionMaker(commonReturnItems)

  const showRegionalData = isRegionDataEnabledForCharts()
  const selectedFieldAssets = selectedFieldAssetsStore.getState()
  const stationLngLat = selectedFieldAssets[pointCategory]
  const selectedRegion = selectedFieldAssets.region
  const { compareSeasonsInterval } = detailsPanelStore.getState()
  const precipitationSeriesId = 'precipitation'
  const series: TChartSeries[] = []

  const colorRules = getColorRulesForCanopySeries({
    hasRegion: showRegionalData && !!selectedRegion,
    hasInCanopySensor: !!selectedFieldAssets[pointCategory],
    hasAboveCanopySensor: false,
    hasBelowCanopySensor: false,
  })

  if (stationLngLat) {
    const precipitationSeries: TChartSeriesWithTRGBAColorWith1AtTheEndColor = {
      name: unitConverter.precipitation().shortTitle(),
      id: precipitationSeriesId,
      tooltip: {
        valueDecimals: unitConverter.precipitation().defaultNumberOfDecimalPlaces(),
        valueSuffix: ` ${unitConverter.precipitation().suffix()}`,
      },
      yAxis: 0,
      data: (data?.points?.[stationLngLat]?.values?.precipitation?.[0]?.timeseries ?? []).map((d) => [
        +new Date(d.timestamp),
        unitConverter.precipitation(d.value.sum).value(),
      ]),
      type: 'area',
      step: 'center',
      showInLegend: true,
    }

    series.push(precipitationSeries)

    if (compareSeasonsInterval) {
      series.push(
        makeCompareSeasonsSeriesFromRegularSeries(precipitationSeries, {
          data: (
            compareSeasonsData?.points?.[stationLngLat]?.values?.precipitation?.[0]?.timeseries ?? []
          ).map((d) => [+new Date(d.timestamp), unitConverter.precipitation(d.value.sum).value()]),
        }),
      )
    }
  }

  if (showRegionalData && selectedRegion) {
    const regionData = (data?.regions?.[selectedRegion]?.values?.precipitation?.[0]?.timeseries ?? []).map(
      (d) => [+new Date(d.timestamp), unitConverter.precipitation(d.value.sum).value()],
    )

    const regionName = fieldAssetStore.getState().regions?.[selectedRegion]?.name

    if (series.length) {
      const baseSeries = series[0]

      series.push(
        makeRegionalSeriesFromRegularSeries(baseSeries, {
          name: regionName,
          data: regionData,
          color: colorRules.region,
        }),
      )
    } else {
      const seriesName = selectedFieldAssets.property // TODO: this should really be a permissions check
        ? // and not show regional data if property doesn't have wind permission
          regionName
        : unitConverter.precipitation().shortTitle()

      const regionalSeries: TChartSeriesWithTRGBAColorWith1AtTheEndColor = {
        name: seriesName,
        id: precipitationSeriesId,
        tooltip: {
          valueDecimals: unitConverter.precipitation().defaultNumberOfDecimalPlaces(),
          valueSuffix: ` ${unitConverter.precipitation().suffix()}`,
        },
        yAxis: 0,
        data: regionData,
        type: 'area',
        step: 'center',
        color: colorRules.region,
      }

      series.push(makeRegionalSeries(regionalSeries))
    }
  }

  const timezone = getTimezoneForSelectedPropertyOrRegion()

  const allForecastMoments = [
    data?.points?.[String(stationLngLat)]?.values?.precipitation?.[0]?.metadata?.forecastStartsAt,
    data?.regions?.[String(selectedRegion)]?.values?.precipitation?.[0]?.metadata?.forecastStartsAt,
  ]
    .filter(Boolean)
    .map((d) => moment.tz(d, timezone))

  const firstForecastTimestamp = allForecastMoments.length ? +moment.min(allForecastMoments) : +new Date()
  const isHourlyAllowed = chooseAmongstUnAggHourlyAndDaily() !== EAggregationInterval.DAILY

  const aggregationOfData = stationLngLat
    ? data?.points?.[stationLngLat]?.values?.precipitation?.[0]?.metadata.aggregationInterval
    : data?.regions?.[String(selectedRegion)]?.values?.precipitation?.[0]?.metadata.aggregationInterval ??
      EAggregationInterval.HOURLY

  const checkPrecipitationDataExists = series[0] && 'data' in series[0] && Boolean(series[0].data?.length)

  return {
    ...commonReturnItems,
    items: [
      {
        chartConfig: {
          semiosHighchartsAdditions: {
            id: commonReturnItems.id,
            firstForecastTimestamp,
          },
          caption: {
            align: 'left',
            verticalAlign: 'top',
            useHTML: true,
            margin: 0,
            style: {
              fontSize: '14px',
              color: colors.midnight,
            },
          },
          chart: {
            type: 'line',
            events: {
              render: function (this) {
                const rainSeries = this.series.find((s) => s.userOptions.id === precipitationSeriesId)
                const rainVisible = rainSeries && rainSeries.visible
                const currentMarginTop = this.options.chart?.marginTop
                const desiredMarginTop = checkPrecipitationDataExists && rainVisible ? 100 : 20

                if (currentMarginTop !== desiredMarginTop) {
                  this.update(
                    {
                      chart: {
                        marginTop: desiredMarginTop,
                      },
                    },
                    false,
                  )
                }

                if (rainVisible) {
                  renderCumulativeCaption(this, firstForecastTimestamp)
                } else {
                  this.setCaption({ text: '' })
                }
              },
            },
            marginTop: checkPrecipitationDataExists === true ? 100 : 20,
          },
          tooltip: { xDateFormat: getXDateFormat(aggregationOfData === EAggregationInterval.DAILY) },
          series: checkPrecipitationDataExists ? series : [],
        },
      },
    ],
    titleChildren: (
      <PrecipitationTitleChildren
        showAggregationToggle={isHourlyAllowed}
        valuesTimeseriesToFilterOn={possibleValueTypesToRequest}
      />
    ),
  }
}
