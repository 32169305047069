import { numberToUUID } from '@capacitor-community/bluetooth-le'
import { SemiosBleServiceUUID } from '../../constants'
import type { Decoder, Encoder } from '../../types'
import { emptyPayload, numberToSemiosUUID } from '../../util/utility'
import { CurrentTimestamp, FirmwareVersion, RequestDeviceReboot, ToggleBuzzer } from './commands'

const DeviceInfoServiceUUID = numberToUUID(0x180a)
const FirmwareVersionUUID = numberToUUID(0x2a26)
const BuzzerUUID = numberToSemiosUUID(0x0003)
const TimestampUUID = numberToSemiosUUID(0x000b)
const RebootDeviceUUID = numberToSemiosUUID(0x0002)

const ToggleBuzzerEncoder: Encoder<ToggleBuzzer> = {
  type: ToggleBuzzer,
  uuid: {
    service: SemiosBleServiceUUID,
    characteristic: BuzzerUUID,
  },
  encode: function (data: ToggleBuzzer): DataView {
    const buffer = new ArrayBuffer(3)
    const view = new DataView(buffer)

    view.setUint8(0, ((data.activate ? 1 : 0) << 7) | data.numberOfBeeps)

    view.setUint8(1, data.beepOnDuration)

    view.setUint8(2, data.beepOffDuration)

    return view
  },
}

const CurrentTimestampDecoder: Decoder<CurrentTimestamp> & Encoder<CurrentTimestamp> = {
  type: CurrentTimestamp,
  uuid: {
    service: SemiosBleServiceUUID,
    characteristic: TimestampUUID,
  },
  decode: function (data: DataView): CurrentTimestamp {
    const date = new Date()

    date.setTime(Number(data.getBigUint64(0)) * 1000)

    return new CurrentTimestamp(date)
  },
  encode: function (data: CurrentTimestamp): DataView {
    const buffer = new ArrayBuffer(8)
    const view = new DataView(buffer)

    view.setBigUint64(0, BigInt(data.timestampInSecond))

    return view
  },
}

const FirmwareDecoder: Decoder<FirmwareVersion> = {
  type: FirmwareVersion,
  uuid: {
    service: DeviceInfoServiceUUID,
    characteristic: FirmwareVersionUUID,
  },
  decode: function (data: DataView): FirmwareVersion | null {
    const decoder = new TextDecoder()
    const version = decoder.decode(data)

    return { version }
  },
}

const RequestDeviceRebootEncoder: Encoder<RequestDeviceReboot> = {
  type: RequestDeviceReboot,
  uuid: {
    service: SemiosBleServiceUUID,
    characteristic: RebootDeviceUUID,
  },
  encode: function (): DataView {
    return emptyPayload()
  },
}

export { CurrentTimestampDecoder, FirmwareDecoder, RequestDeviceRebootEncoder, ToggleBuzzerEncoder }
