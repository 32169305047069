import { SharedTypes } from '@semios/app-platform-banyan-route-definitions'
import type { TFieldAssetKeyTypes } from '@semios/app-platform-banyan-route-definitions/src/routes/userAppStartup'
import { getEmitterTypeToEnum } from '@semios/app-platform-common'
import type { TIrrigationDeviceStatus } from '@semios/app-platform-value-type-definitions/src/valuesCurrentTypes/irrigation'
import * as Sentry from '@sentry/react'
import { translate } from 'i18n/i18n'
import type { TValuesCurrentPointsValueTypes } from 'stores/mapControlsStore/types'
import { showNotification } from 'utils/showNotification'
import { irrigationSchedulerCurrentStatus } from '../../api/irrigationSchedulerCurrentStatus'
import { irrigationSchedulerCurrentValues } from '../../api/irrigationSchedulerCurrentValues'
import { irrigationSchedulerEventsGet } from '../../api/irrigationSchedulerEventsGet'
import type { fieldAssetAndIrrigationSchedulerSelector } from '../../useFetchEventsAndCurrentStatusListener'
import type { ISOString } from '../irrigationSchedulerStore'
import { getVisibleIrrigationZoneEmitters } from '../selectors/getVisibleIrrigationZoneEmitters'
import { actions } from './actions'

export const fetchAllIrrigationSchedulerEventsAndCurrentValuesData = async (
  selection: ReturnType<typeof fieldAssetAndIrrigationSchedulerSelector>,
  setLoadingEventsAndCurrentStatus: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const visibleIrrigationZoneEmitters = getVisibleIrrigationZoneEmitters()

  if (visibleIrrigationZoneEmitters.length) {
    setLoadingEventsAndCurrentStatus(true)

    const irrigationZoneIds = Array.from(
      new Set(
        visibleIrrigationZoneEmitters.map((irrigationZoneEmitter) =>
          Number(irrigationZoneEmitter.irrigationZoneId),
        ),
      ),
    )

    const results = await Promise.allSettled([
      irrigationSchedulerEventsGet({
        dateFrom: selection.dateRange.mDateFrom.toISOString(),
        dateTo: selection.dateRange.mDateTo.toISOString(),
        irrigationZoneIds,
      }),
      irrigationSchedulerCurrentStatus({ irrigationZoneIds }),
      irrigationSchedulerCurrentValues(selection.selectedPropertyId),
    ])

    const [eventsResult, currentStatusResult, currentValuesResult] = results

    const stoppedIrrigationZoneEmitters: Array<{
      irrigationZoneEmitterId: TFieldAssetKeyTypes.TIrrigationZoneEmitterId
      timestamp: ISOString
    }> = []

    const currentEmitterStatus: Array<{
      irrigationZoneEmitterId: TFieldAssetKeyTypes.TIrrigationZoneEmitterId
      currentEmitterStatus: TIrrigationDeviceStatus
    }> = []

    if (eventsResult.status === 'fulfilled') {
      const events = eventsResult.value

      events.forEach((event) => {
        let currentValueStatus: TIrrigationDeviceStatus | null = null

        if (currentValuesResult.status === 'fulfilled') {
          const currentValuesForSensor = currentValuesResult.value.irrigation_zones?.[event.irrigationZoneId]

          if (currentValuesForSensor) {
            const emitterType = getEmitterTypeToEnum(event.emitterType)
            const currentValueKey = `irrigationIsOn_${emitterType}_status` as TValuesCurrentPointsValueTypes

            currentValueStatus =
              //@ts-ignore
              currentValuesForSensor[currentValueKey]?.value ?? null
          }
        }

        currentEmitterStatus.push({
          irrigationZoneEmitterId: event.irrigationZoneEmitterId,
          currentEmitterStatus: currentValueStatus,
        })
      })

      actions.api.eventsGet(events)

      actions.main.setEmitterStatus(currentEmitterStatus)
    } else {
      Sentry.captureException(eventsResult.reason)
    }

    if (currentStatusResult.status === 'fulfilled') {
      currentStatusResult.value.forEach((item) => {
        if (
          item.stopStatus &&
          (item.stopStatus.status === SharedTypes.zoneStopStatusSchema.Enum.STOP_PENDING ||
            item.stopStatus.status === SharedTypes.zoneStopStatusSchema.Enum.STOPPED)
        ) {
          stoppedIrrigationZoneEmitters.push({
            irrigationZoneEmitterId: item.irrigationZoneEmitterId,
            timestamp: item.stopStatus.setAt,
          })
        }
      })

      actions.main.setStoppedIrrigationZoneEmitters(stoppedIrrigationZoneEmitters)
    } else {
      Sentry.captureException(currentStatusResult.reason)
    }

    if (currentValuesResult.status === 'rejected') {
      Sentry.captureException(currentValuesResult.reason)
    }

    if (results.some((result) => result.status === 'rejected')) {
      showNotification({ type: 'error', message: translate.phrases.banyanApp('There was an error') })
    }

    setLoadingEventsAndCurrentStatus(false)
  }
}
