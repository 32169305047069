import type { AdapterStatus } from './types'

class BleDeviceNotFoundError extends Error {
  constructor(nodeIdentifier?: string) {
    super(`Ble device with node identifier ${nodeIdentifier} not found`)
  }
}

class BleDeviceInvalidError extends Error {
  constructor(deviceId: string, deviceName?: string) {
    super(`Ble device with id ${deviceId} and name ${deviceName} is invalid`)
  }
}

class BleUnsupportedDeviceError extends Error {
  constructor(nodeType: string) {
    super(`Ble device with node type ${nodeType} is unsupported`)
  }
}

class BleDeviceNotConnectedError extends Error {
  constructor(deviceId: string) {
    super(`Ble device with id ${deviceId} is not connected`)
  }
}

class BleDecoderNotFoundError extends Error {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(payload: unknown) {
    super(`Ble decoder/encoder for ${JSON.stringify(payload)} not found`)
  }
}

class BleDecoderInvalidPayloadError extends Error {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(payload: unknown) {
    super(`Payload ${JSON.stringify(payload)} must be an instance of DTO`)
  }
}

class BleAdapterStatusInvalidError extends Error {
  constructor(status: AdapterStatus) {
    super(`Ble adapter status ${status} is invalid`)
  }
}

class BleUnknownError extends Error {
  constructor(error: unknown) {
    super(error instanceof Error ? error.message : JSON.stringify(error))
  }
}

export {
  BleAdapterStatusInvalidError,
  BleDecoderInvalidPayloadError,
  BleDecoderNotFoundError,
  BleDeviceInvalidError,
  BleDeviceNotConnectedError,
  BleDeviceNotFoundError,
  BleUnknownError,
  BleUnsupportedDeviceError,
}
