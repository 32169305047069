import { translate } from 'i18n/i18n'

const FITS_WSU = 'FITS_WSU'
const NO_FIT = 'NO_FIT'
const STARTS_THE_SAME_BUT_LONGER_WINDOW = 'STARTS_THE_SAME_BUT_LONGER_WINDOW'
const STARTS_THE_SAME_BUT_LONGER_WINDOW_BIMODAL = 'STARTS_THE_SAME_BUT_LONGER_WINDOW_BIMODAL'

const FIRST_FLIGHT_ONLY_AND_STARTS_THE_SAME_BUT_LONGER_WINDOW_BIMODAL =
  'FIRST_FLIGHT_ONLY_AND_STARTS_THE_SAME_BUT_LONGER_WINDOW_BIMODAL'

const FIRST_FLIGHT_ONLY_AND_STARTS_THE_SAME_BUT_LONGER_WINDOW =
  'FIRST_FLIGHT_ONLY_AND_STARTS_THE_SAME_BUT_LONGER_WINDOW'

const FIRST_FLIGHT_ONLY_AND_FITS_WSU = 'FIRST_FLIGHT_ONLY_AND_FITS_WSU'

export const DescriptionByModelImplication = ({
  modelImplication,
  insectId,
}: {
  modelImplication: string | undefined
  insectId: number
}) => {
  let description = null

  if (modelImplication === undefined || modelImplication === NO_FIT)
    description = translate.phrases.banyanApp(
      'Currently available data does not meet requirements for modelling. Contact us for more information.',
    )

  if (modelImplication === FITS_WSU)
    description = translate.phrases.banyanApp(
      'On farm data suggests that the codling moth flight phenology conforms with the regional model.',
    )

  if (modelImplication === STARTS_THE_SAME_BUT_LONGER_WINDOW)
    description = translate.phrases.banyanApp(
      'On farm data suggests that codling moth flight occurs over a longer period of time than the regional model predicts. This means that eggs, larvae are likely to be present for a longer period of time than the regional model predicts.',
    )

  if (modelImplication === STARTS_THE_SAME_BUT_LONGER_WINDOW_BIMODAL)
    description = translate.phrases.banyanApp(
      'On farm data suggests that codling moth flight occurs over a longer period of time than the regional model predicts. The first-generation is bimodal, meaning that two periods of high trap activity may be present within the first flight. This means that eggs and larvae are likely to be present for a longer period of time than the regional model predicts.',
    )

  if (modelImplication === FIRST_FLIGHT_ONLY_AND_STARTS_THE_SAME_BUT_LONGER_WINDOW) {
    description = (
      <>
        {translate.phrases.banyanApp(
          'On farm data suggests that codling moth flight occurs over a longer period of time than the regional model predicts. This means that eggs, larvae are likely to be present for a longer period of time than the regional model predicts.',
        )}
        <br />
        <br />
        {translate.phrases.banyanApp(
          'Only the 1st flight has been modelled, because in past years very few codling moth were caught during the 2nd and 3rd flight.',
        )}
      </>
    )
  }

  if (modelImplication === FIRST_FLIGHT_ONLY_AND_FITS_WSU) {
    description = (
      <>
        {translate.phrases.banyanApp(
          'On farm data suggests that the codling moth flight phenology conforms with the regional model.',
        )}
        <br />
        <br />
        {translate.phrases.banyanApp(
          'Only the 1st flight has been modelled, because in past years very few codling moth were caught during the 2nd and 3rd flight.',
        )}
      </>
    )
  }

  if (modelImplication === FIRST_FLIGHT_ONLY_AND_STARTS_THE_SAME_BUT_LONGER_WINDOW_BIMODAL) {
    description = (
      <>
        {translate.phrases.banyanApp(
          'On farm data suggests that codling moth flight occurs over a longer period of time than the regional model predicts. The first-generation is bimodal, meaning that two periods of high trap activity may be present within the first flight. This means that eggs and larvae are likely to be present for a longer period of time than the regional model predicts.',
        )}
        <br />
        <br />
        {translate.phrases.banyanApp(
          'Only the 1st flight has been modelled, because in past years very few codling moth were caught during the 2nd and 3rd flight.',
        )}
      </>
    )
  }

  if (!modelImplication) {
    if (insectId === 11)
      description = translate.phrases.banyanApp(
        'This model shows a generalized representation of adult obliquebanded leafroller (OBLR) phenology. It has been built using OBLR trap capture data from multiple sources and years. This model is intended for use in pome fruit and cherries.',
      )
    else if (insectId === 7) {
      description = translate.phrases.banyanApp(
        'This model provides estimates of relative flight for the 1st and 2nd generation only. OFM may have 5 or more generations per year, however modeling later flights becomes progressively more difficult due to the potential for overlapping generations alongside various pest management practices. Any management required for OFM after the 2nd flight should be based on in-field observations or trap count data.',
      )
    }
  }

  return <div>{description}</div>
}
