import { Text, UnstyledButton, useMantineTheme } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { IconArrowDown } from 'components/icons/IconArrowDown'
import { IconArrowUp } from 'components/icons/IconArrowUp'

export const DeviceGroup = ({
  groupName,
  children,
  expanded,
}: {
  groupName: string
  expanded: boolean
  children: React.ReactNode
}) => {
  const theme = useMantineTheme()
  const [isOpen, { toggle }] = useDisclosure(expanded)

  return (
    <div css={{ background: theme.colors.gray[2], padding: '2px', margin: '10px', borderRadius: '4px' }}>
      <div css={{ position: 'relative', display: 'flex' }} onClick={toggle}>
        <Text
          css={{
            flex: '1',
            padding: '10px',
            fontSize: '1.5em',
            fontWeight: 'bold',
          }}
        >
          {groupName}
        </Text>
        <span
          css={{
            paddingInline: '20px',
            alignContent: 'center',
          }}
        >
          <UnstyledButton onClick={toggle}>
            <span css={{ color: theme.colors.gray[6], fontSize: '1.5em' }}>
              {isOpen ? <IconArrowUp /> : <IconArrowDown />}
            </span>
          </UnstyledButton>
        </span>
      </div>

      {isOpen && <div>{children}</div>}
    </div>
  )
}
