import type { routes } from '@semios/app-platform-banyan-route-definitions'
import { getInsectIdFromValueGroup } from '@semios/app-platform-common'
import type { VV } from '@semios/app-platform-value-type-definitions'
import { VIEW_PESTS_LARVAL_TREND_ID_insectId } from '@semios/app-platform-value-type-definitions'
import { blockLacksTrapsSectionMaker } from 'App/Map/PanelDetails/_utils/blockLacksTrapsSectionMaker'
import { getTimezoneForSelectedPropertyOrRegion } from 'App/Map/PanelDetails/_utils/getTimezoneForSelectedPropertyOrRegion'
import { propertyLacksPermissionSectionMaker } from 'App/Map/PanelDetails/_utils/propertyLacksPermissionSectionMaker'
import { selectedPropertyHasPermission } from 'App/Map/PanelDetails/_utils/selectedPropertyHasPermission'
import type { TPestSectionCategory } from 'App/Map/PanelDetails/_utils/sortPestSections'
import { EAggregationInterval } from 'App/Map/types'
import { lineChartTooltipFormatter } from 'components/StackedChart/_utils/lineChartTooltipFormatter/lineChartTooltipFormatter'
import { updateTooltipContents } from 'components/StackedChart/_utils/lineChartTooltipFormatter/updateTooltipContents'
import type {
  StackedChartPestSection,
  TChartSeries,
  TChartSeriesWithTRGBAColorWith1AtTheEndColor,
} from 'components/StackedChart/types'
import moment from 'moment-timezone'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import type { TSelectedFieldAssetsStoreState } from 'stores/selectedFieldAssetsStore'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import type {
  selectedValueGroupsStore,
  TValueGroup,
} from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { doesSelectedBlockHaveValueTypes } from 'utils/doesSelectedFieldAssetHaveValueTypes'
import { INSECT_LARVAL_TREND_VALUE_KEY_PREFIX } from 'utils/insectRequestValueKeyPrefix'
import { sortByKey } from 'utils/sortByKey'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import { detailsPanelStore } from '../../../../../../../stores/detailsPanelStore'
import { getXDateFormat } from '../_utils/getXDateFormat'
import { makeCompareSeasonsSeriesFromRegularSeries } from '../_utils/makeCompareSeasonsSeriesFromRegularSeries'
import { aggregateBlockDataFromTraps } from './aggregateBlockDataFromTraps'
import { LarvalTrendTitleChildren } from './LarvalTrendTitleChildren/LarvalTrendTitleChildren'

const preferredAggregationInterval = { preferredAggregationInterval: EAggregationInterval.DAILY } as const

const getValueTypeIdForInsectId = (insectId: number) => {
  return `${INSECT_LARVAL_TREND_VALUE_KEY_PREFIX}${insectId}` as keyof VV.DomainTypes.TrapCatchesInsect.TValuesReturnWithMetaIgnoringKeying['blocks']
}

export const apiArgs = ({
  selectedValueGroups,
  selectedFieldAssets,
}: {
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}): Partial<routes.Values.Request> => {
  if (!selectedFieldAssets.block) return {}

  const valuesRequested: Partial<
    Record<
      VV.DomainTypes.TrapCatchesInsect.TTimeseriesValueTypeKeysMerged,
      typeof preferredAggregationInterval
    >
  > = {}

  Object.entries(selectedValueGroups).forEach(([valueGroup, isActive]) => {
    if (!!isActive && valueGroup.includes('larval_trend_insect_id_')) {
      const insectId = getInsectIdFromValueGroup(valueGroup)

      if (
        !insectId ||
        !selectedPropertyHasPermission({ permission: VIEW_PESTS_LARVAL_TREND_ID_insectId(insectId) })
      )
        return

      valuesRequested[getValueTypeIdForInsectId(insectId)] = preferredAggregationInterval
    }
  })

  if (Object.keys(valuesRequested).length === 0) return {}

  const valuesToBeRequested: Partial<routes.Values.Request> = {}

  if (
    Object.keys(valuesRequested).length &&
    doesSelectedBlockHaveValueTypes({ valuesTimeseries: Object.keys(valuesRequested) })
  ) {
    valuesToBeRequested.blocks = {
      blockIds: [selectedFieldAssets.block],
      valuesRequested: valuesRequested,
    }
  }

  return valuesToBeRequested
}

export const content = ({
  data,
  selectedValueGroups,
  compareSeasonsData,
}: {
  data: routes.Values.Response
  compareSeasonsData: routes.Values.Response
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
}): StackedChartPestSection[] => {
  const insects = fieldAssetStore.getState()?.insects
  const { compareSeasonsInterval, larvalTrendShowValues } = detailsPanelStore.getState()

  if (!insects) return []

  const blockId = String(selectedFieldAssetsStore.getState().block)

  const larvalTrendContent: StackedChartPestSection[] = Object.values(insects)
    .filter((insect) => selectedValueGroups[`larval_trend_insect_id_${insect.insectId}` as TValueGroup])
    .map(({ insectId }): StackedChartPestSection => {
      const hasPermission = selectedPropertyHasPermission({
        permission: VIEW_PESTS_LARVAL_TREND_ID_insectId(insectId),
      })

      const valueTypeKey = getValueTypeIdForInsectId(insectId)
      const pestSectionCategory: TPestSectionCategory = 'larval-trend'

      const commonReturnItems = {
        title: unitConverter.insectLarvalTrend(null, { insectId }).titleWithoutUnit(),
        titleChildren: (
          <LarvalTrendTitleChildren showToggle={false} valuesTimeseriesToFilterOn={[valueTypeKey]} />
        ),
        id: `insect-larval-trend-insect-id-${insectId}-${blockId}`,
        pestSectionCategory,
        insectId,
      }

      if (!hasPermission) {
        return { ...propertyLacksPermissionSectionMaker(commonReturnItems), pestSectionCategory, insectId }
      }

      if (!doesSelectedBlockHaveValueTypes({ valuesTimeseries: [valueTypeKey] })) {
        return { ...blockLacksTrapsSectionMaker(commonReturnItems), pestSectionCategory, insectId }
      }

      const series: TChartSeries[] = []

      const forecastStartsAt =
        data?.blocks?.[blockId]?.values?.[valueTypeKey]?.[0]?.metadata?.forecastStartsAt

      let firstForecastTimestamp: number = +new Date()

      if (forecastStartsAt) {
        const timezone = getTimezoneForSelectedPropertyOrRegion()

        firstForecastTimestamp = +moment.tz(forecastStartsAt, timezone).startOf('day')
      }

      const larvalTrendSeries: TChartSeriesWithTRGBAColorWith1AtTheEndColor = {
        name: unitConverter.insectLarvalTrend().categoryTitleWithoutUnit(),
        tooltip: {
          valueDecimals: unitConverter.insectLarvalTrend().defaultNumberOfDecimalPlaces(),
          valueSuffix: ` ${unitConverter.insectLarvalTrend().suffix()}`,
        },
        yAxis: 0,
        data: aggregateBlockDataFromTraps({
          valueType: data?.blocks?.[blockId]?.values?.[valueTypeKey] || [],
        }),
        type: 'line',
        connectNulls: false,
        marker: {
          enabled: false,
        },
        id: `insect-larval-trend-insect-id-${insectId}-series`,
      }

      series.push(larvalTrendSeries)

      if (compareSeasonsInterval) {
        series.push(
          makeCompareSeasonsSeriesFromRegularSeries(larvalTrendSeries, {
            data: aggregateBlockDataFromTraps({
              valueType: compareSeasonsData?.blocks?.[blockId]?.values?.[valueTypeKey] || [],
            }),
          }),
        )
      }

      return {
        ...commonReturnItems,
        items: [
          {
            chartConfig: {
              semiosHighchartsAdditions: {
                id: unitConverter.insectLarvalTrend(null, { insectId }).titleWithoutUnit(),
                firstForecastTimestamp,
              },
              yAxis: {
                min: 0,
                labels: {
                  enabled: larvalTrendShowValues,
                },
              },
              chart: {
                type: 'line',
              },
              tooltip: {
                formatter: function (tooltip) {
                  let tooltipContents = lineChartTooltipFormatter(this, tooltip, firstForecastTimestamp)

                  if (larvalTrendShowValues) return tooltipContents

                  return updateTooltipContents({
                    seriesId: larvalTrendSeries.id,
                    tooltipContents,
                    fieldsToChange: {
                      value: () => '',
                      compareSeasonsValue: () => '',
                    },
                  })
                },
                xDateFormat: getXDateFormat(),
              },
              series,
            },
          },
        ],
        titleChildren: <LarvalTrendTitleChildren showToggle valuesTimeseriesToFilterOn={[valueTypeKey]} />,
      }
    })
    .sort(sortByKey('title'))

  return larvalTrendContent
}
