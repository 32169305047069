import type { TRGBAColorWith1AtTheEnd } from 'settings/colors'
import { alphaColorReplacer, colors, type TRGBAColorWithOpacityAtTheEnd } from 'settings/colors'

export const getColorRulesForCanopySeries = (params: {
  hasAboveCanopySensor: boolean
  hasBelowCanopySensor: boolean
  hasInCanopySensor: boolean
  hasInversion?: boolean
  hasRegion: boolean
}): {
  above: [TRGBAColorWith1AtTheEnd, TRGBAColorWith1AtTheEnd | TRGBAColorWithOpacityAtTheEnd]
  below: [TRGBAColorWith1AtTheEnd, TRGBAColorWith1AtTheEnd | TRGBAColorWithOpacityAtTheEnd]
  in: [TRGBAColorWith1AtTheEnd, TRGBAColorWith1AtTheEnd | TRGBAColorWithOpacityAtTheEnd]
  inversion: [TRGBAColorWith1AtTheEnd, TRGBAColorWith1AtTheEnd | TRGBAColorWithOpacityAtTheEnd] | null
  region: TRGBAColorWith1AtTheEnd
} => {
  const countOfNonRegionalSeries = Object.entries(params).filter(
    ([key, value]) => key !== 'hasRegion' && Boolean(value),
  ).length

  if (countOfNonRegionalSeries <= 1) {
    const colorRule: [TRGBAColorWith1AtTheEnd, TRGBAColorWith1AtTheEnd] = [colors.midnight, colors.green]

    return {
      above: colorRule,
      below: colorRule,
      in: colorRule,
      inversion: null,
      region: countOfNonRegionalSeries === 0 ? colors.midnight : colors.regionsCompare,
    }
  } else if (!params.hasInversion) {
    return {
      above: [colors.blue, colors.blue800],
      below: [colors.orange, colors.orange800],
      in: [colors.green, colors.green800],
      inversion: null,
      region: colors.midnight,
    }
  } else {
    return {
      above: [colors.blue, alphaColorReplacer(colors.blue, 0.4)],
      below: [colors.orange, alphaColorReplacer(colors.orange, 0.4)],
      in: [colors.green, alphaColorReplacer(colors.green, 0.4)],
      inversion: [colors.pink, alphaColorReplacer(colors.pink, 0.4)],
      region: colors.midnight,
    }
  }
}

export const getColorRulesForPestSeries = (params: {
  hasTrapSeries: boolean
  hasDegreeDaySeries: boolean
  hasRegionTrapSeries: boolean
  hasRegionDegreeDaySeries: boolean
}): {
  trap: [TRGBAColorWith1AtTheEnd, TRGBAColorWith1AtTheEnd]
  degreeDay: [TRGBAColorWith1AtTheEnd, TRGBAColorWith1AtTheEnd]
  regionDegreeDay: TRGBAColorWith1AtTheEnd
  regionTrap: TRGBAColorWith1AtTheEnd | TRGBAColorWithOpacityAtTheEnd
} => {
  return {
    trap: [colors.green, colors.green800],
    degreeDay: [colors.orange, colors.orange800],
    regionDegreeDay: colors.midnight,
    regionTrap: params.hasRegionDegreeDaySeries ? alphaColorReplacer(colors.midnight, 0.4) : colors.midnight,
  }
}
