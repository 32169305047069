import type { TAntennaType } from '@semios/app-platform-internal-api-sdk'
import { OfflineRequestError } from 'utils/errorCodes'
import { stringify as stringifyWKT } from 'wellknown'
import type { NodeDroppingActivationPayload } from '../NodeInstallation/types'
import { serviceCenterStore } from '../store/serviceCenterStore'
import type { TActiveGateway, TActiveNode } from '../types'
import { ApiRequestQueue, ApiRequestStatus, ApiRequestType } from './api/queue'
import type { GatewayDropInstallMeta } from './api/serviceCenterGatewayDropInstall'
import { serviceCenterGatewayDropInstall } from './api/serviceCenterGatewayDropInstall'
import type { NodeDropInstallMeta } from './api/serviceCenterNodeDropInstall'
import { serviceCenterNodeDropInstall } from './api/serviceCenterNodeDropInstall'
import { getIdentifier } from './getIdentifier'
import { isSemiosGatewayType } from './getNodeNetworkType'

export async function dropInstall(
  payload: NodeDroppingActivationPayload,
): Promise<TActiveNode | TActiveGateway> {
  let unSynced = true
  let updatedPayload: GatewayDropInstallMeta | NodeDropInstallMeta

  if (isSemiosGatewayType(payload.nodeType)) {
    updatedPayload = {
      node: {
        location: stringifyWKT(payload.location),
        propertyId: payload.propertyId,
        gatewayIdentifier: payload.nodeIdentifier,
        name: payload.name,
        managementType: payload.managementType,
        maintenanceOwnerId: payload.maintenanceOwnerId,
      },
      antennaType: 'DEFAULT' as TAntennaType,
    }
  } else {
    updatedPayload = {
      ...payload,
      location: stringifyWKT(payload.location),
    }
  }

  try {
    const { status } = await ApiRequestQueue.createRequest(
      isSemiosGatewayType(payload.nodeType)
        ? ApiRequestType.DROP_INSTALL_GATEWAY
        : ApiRequestType.DROP_INSTALL_NODE,
      updatedPayload,
    )

    if (status === ApiRequestStatus.COMPLETED) unSynced = false
  } catch (error) {
    // If the request failed because the app is offline, don't throw an error
    if (!(error instanceof OfflineRequestError)) throw error
  }

  if (unSynced) {
    isSemiosGatewayType(payload.nodeType)
      ? serviceCenterGatewayDropInstall.callback(updatedPayload as GatewayDropInstallMeta)
      : serviceCenterNodeDropInstall.callback(updatedPayload as NodeDropInstallMeta)
  }

  const activeNodes = serviceCenterStore.selectors.getActiveNodes(serviceCenterStore.getState())
  const [newActiveNode] = activeNodes.filter((node) => getIdentifier(node) === payload.nodeIdentifier)

  // Select newly installed active node
  serviceCenterStore.actions.selectEquipment(payload.nodeIdentifier, 'active')

  return newActiveNode
}
