import type { routes } from '@semios/app-platform-banyan-route-definitions'
import { serviceCenterStore } from 'App/ServiceCenter/store/serviceCenterStore'
import { apiFetch } from 'utils/apiFetch'

export type PlannedGatewayRepositionMeta = routes.ServiceCenterPlannedGatewayReposition.Request

const request = async (meta: PlannedGatewayRepositionMeta): Promise<PlannedGatewayRepositionMeta> => {
  await apiFetch({ url: '/service-center-planned-gateway-reposition', body: meta, params: { method: 'PUT' } })

  return meta
}

const callback = (meta: PlannedGatewayRepositionMeta) => {
  serviceCenterStore.actions.repositionPseudoNodes({ [meta.pseudogatewayId]: meta.location })
}

export const serviceCenterPlannedGatewayReposition = {
  request,
  callback,
}
