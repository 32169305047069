import { Group, Stack, Text, UnstyledButton, useMantineTheme } from '@mantine/core'
import { Notifications } from '@mantine/notifications'
import * as Sentry from '@sentry/react'
import { useBleManager } from 'App/ServiceCenter/BluetoothLowEnergy/BleManager'
import { AdapterStatus } from 'App/ServiceCenter/BluetoothLowEnergy/types'
import { AboveAllModal } from 'components/AboveAllModalOverlay/AboveAllModalOverlay'
import { Button } from 'components/Button/Button'
import { IconBluetooth } from 'components/icons/IconBluetooth'
import { IconBluetoothCircle } from 'components/icons/IconBluetoothCircle'
import { MiniStatusbar } from 'components/MiniStatusbarOverlay/MiniStatusbarOverlay'
import { translate } from 'i18n/i18n'
import { memo, useEffect, useRef, useState } from 'react'
import { userDetailsStore } from 'stores/userDetailsStore'
import { isSemiosEmployeeOrTester } from 'utils/isSemiosEmployeeOrTester'
import {
  getBleConnectionStatusColor,
  getBleConnectionStatusDescription,
} from '../NodeInstallation/Overview/NodeSummary/NodeSummary'
import { defaultNotificationProps } from './defaultNotificationProps'

export const BleStatusButton = (props: { isBleRequired: boolean; nodeIdentifier?: string }) => {
  const isEmployeeOrTester = userDetailsStore.useSelector((s) => isSemiosEmployeeOrTester(s))
  const bleManager = useBleManager()
  const color = getBleConnectionStatusColor(bleManager.adapterStatus)
  const theme = useMantineTheme()

  if (!props.isBleRequired) {
    return null
  }

  const handleClick = () => {
    if (isEmployeeOrTester && bleManager.adapterStatus === AdapterStatus.CONNECTED) {
      AboveAllModal.open({
        modalId: 'ble-connection-keeper',
        title: translate.phrases.placeholder('BLE Debug window'),
        centered: true,
        withCloseButton: true,
        children: <BleDeviceConnectionDebugInfo nodeId={props.nodeIdentifier} />,
      })
    } else if (bleManager.adapterStatus === AdapterStatus.IDLE) {
      Notifications.show({
        ...defaultNotificationProps,
        id: 'ble-disconnected',
        icon: (
          <span css={{ fontSize: 20, color: theme.colors.gray[5] }}>
            <IconBluetooth />
          </span>
        ),
        title: <Text>{translate.phrases.placeholder('Bluetoothe Disconnected')}</Text>,
        autoClose: false,
        message: (
          <Stack>
            <Text>
              {translate.phrases.placeholder(
                'You have lost Bluetooth connection please reconnect to complete the install.',
              )}
            </Text>
            <Button
              variant="tertiary"
              onClick={() => {
                props.nodeIdentifier && bleManager.connectDeviceByNodeId(props.nodeIdentifier)

                Notifications.hide('ble-disconnected')
              }}
            >
              {translate.phrases.placeholder('Reconnect Bluetooth')}
            </Button>
          </Stack>
        ),
      })
    } else if (bleManager.adapterStatus === AdapterStatus.DISABLED) {
      Notifications.show({
        ...defaultNotificationProps,
        id: 'ble-disconnected',
        icon: (
          <span css={{ fontSize: 20, color: theme.colors.gray[5] }}>
            <IconBluetooth />
          </span>
        ),
        title: <Text>{translate.phrases.placeholder('Bluetooth Disabled')}</Text>,
        autoClose: false,
        color: 'white',
        message: (
          <Stack>
            <Text>
              {translate.phrases.placeholder('Please go to your device settings and enable Bluetooth')}
            </Text>
            <Button variant="tertiary" onClick={() => Notifications.hide('ble-disconnected')}>
              {translate.phrases.placeholder('Okay')}
            </Button>
          </Stack>
        ),
      })
    }
  }

  return (
    <UnstyledButton onClick={handleClick}>
      <span
        css={{
          display: 'flex',
          alignContent: 'center',
          fontSize: 30,
          color: color,
        }}
      >
        <Group>
          <IconBluetoothCircle />
        </Group>
      </span>
    </UnstyledButton>
  )
}

const BleDeviceConnectionDebugInfo = ({ nodeId }: { nodeId?: string }) => {
  const bleManager = useBleManager()

  return (
    <>
      <Stack>
        <Text>{'node ID: ' + nodeId}</Text>
        <Text>{'device ID: ' + bleManager.connectedDevice?.deviceId}</Text>
        <Text>{'adapter status:' + AdapterStatus[bleManager.adapterStatus]}</Text>
      </Stack>
    </>
  )
}

const handleReconnectModal = (callback: (shouldReconnect: boolean) => void) =>
  AboveAllModal.open({
    modalId: 'reconnect',
    title: translate.phrases.placeholder('Bluetooth Connection Failed'),
    centered: true,
    withCloseButton: false,
    children: (
      <>
        <Text size="sm">
          {translate.phrases.placeholder(
            'Bluetooth Failed to connect. Please make sure that you have bluetooth turned on in your mobile device. ',
          )}
        </Text>
        <div css={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Button
            variant="tertiary"
            onClick={() => {
              callback(false)
            }}
            css={{ width: '100%', margin: 10 }}
          >
            {translate.phrases.placeholder('Cancel')}
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              callback(true)
            }}
            css={{ width: '100%', margin: 10 }}
          >
            {translate.phrases.placeholder('Try Again')}
          </Button>
        </div>
      </>
    ),
  })

export interface BleDeviceConnectionPluginOptions {
  onShowReconnectModal: (callback: (shouldReconnect: boolean) => void) => void
  onHideReconnectModal: () => void
}

const BleDeviceConnectionPlugin = ({
  nodeIdentifier: nodeId,
  options,
}: {
  nodeIdentifier?: string
  options?: BleDeviceConnectionPluginOptions
}) => {
  const bleManager = useBleManager()

  const opt = options
    ? options
    : {
        onShowReconnectModal: handleReconnectModal,
        onHideReconnectModal: () => {
          AboveAllModal.close('reconnect')
        },
      }

  const bleStatus = useRef<AdapterStatus>(bleManager.adapterStatus)
  const bleDeviceId = useRef<string | undefined>(bleManager.connectedDevice?.deviceId)
  const [shouldConnected, setShouldConnected] = useState<boolean>(false) //BLE connection keep alive

  bleStatus.current = bleManager.adapterStatus

  bleDeviceId.current = bleManager.connectedDevice?.deviceId

  const cleanup = async (status: AdapterStatus, deviceId?: string) => {
    try {
      MiniStatusbar.hide()

      if (status === AdapterStatus.SCANNING) {
        await bleManager.stopScan()
      } else if (status === AdapterStatus.CONNECTING) {
        await bleManager.deferDisconnect()
      } else if (status === AdapterStatus.CONNECTED && deviceId) {
        await bleManager.disconnectDevice(deviceId)
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('error: ', e)

      Sentry.captureException(e)
    }
  }

  useEffect(() => {
    if (nodeId) {
      if (shouldConnected) {
        if (bleManager.adapterStatus === AdapterStatus.IDLE && !bleManager.isBusy.current) {
          opt.onShowReconnectModal((reconnect) => {
            if (reconnect) {
              bleManager.connectDeviceByNodeId(nodeId)
            } else {
              setShouldConnected(false)

              opt.onHideReconnectModal()
            }
          })
        } else {
          opt.onHideReconnectModal()
        }
      } else {
        opt.onHideReconnectModal()
      }
    }
  }, [shouldConnected, nodeId, bleManager.adapterStatus])

  useEffect(() => {
    if (nodeId) {
      bleManager.connectDeviceByNodeId(nodeId)
    }
  }, [nodeId])

  useEffect(() => {
    const status = bleManager.adapterStatus
    const color = getBleConnectionStatusColor(status)
    const description = getBleConnectionStatusDescription(status)

    switch (status) {
      case AdapterStatus.CONNECTED:
        MiniStatusbar.show({
          period: 2000,
          children: createBleStatusbar(color, description),
        })

        break

      case AdapterStatus.CONNECTING:
        MiniStatusbar.show({
          children: createBleStatusbar(color, description),
        })

        break

      case AdapterStatus.SCANNING:
        MiniStatusbar.show({
          children: createBleStatusbar(color, description),
        })

        break

      case AdapterStatus.DISABLED:
        MiniStatusbar.hide()

        break

      case AdapterStatus.IDLE:
        MiniStatusbar.hide()

        break

      default:
        break
    }
  }, [bleManager.adapterStatus])

  useEffect(() => {
    //if adapter is trying to connect, we should keep trying to connect
    if (bleManager.adapterStatus > AdapterStatus.IDLE) {
      setShouldConnected(true)
    } else if (bleManager.adapterStatus === AdapterStatus.DISABLED) {
      AboveAllModal.open({
        modalId: 'ble-disabled',
        title: translate.phrases.placeholder('Enable Bluetooth'),
        centered: true,
        withCloseButton: false,
        children: (
          <>
            <Text size="sm">
              {translate.phrases.placeholder(
                'This device requires a bluetooth connection in order to proceed. Please be sure to enable bluetooth in your mobile device settings.',
              )}
            </Text>
            <div css={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Button
                variant="primary"
                onClick={() => {
                  AboveAllModal.close('ble-disabled')
                }}
                css={{ width: '100%', margin: 10, height: 40 }}
              >
                {translate.phrases.placeholder('Okay')}
              </Button>
            </div>
          </>
        ),
      })
    }
  }, [bleManager.adapterStatus])

  useEffect(() => {
    return () => {
      const status = bleStatus.current
      const deviceId = bleDeviceId.current

      //We want to cleanup the connection based on current status, that's why we use ref
      cleanup(status, deviceId)
    }
  }, [nodeId])

  return <></>
}

const createBleStatusbar = (color: string, status: string) => (
  <Group
    css={{
      backgroundColor: color,
      fontSize: 11,
      paddingLeft: 12,
      paddingRight: 12,
      marginTop: 'env(safe-area-inset-top)',
    }}
  >
    <IconBluetooth />
    <Text style={{ fontWeight: 'bold' }}>{status}</Text>
  </Group>
)

export const BleDeviceConnectionKeeper = memo(BleDeviceConnectionPlugin)
