import type { routes } from '@semios/app-platform-banyan-route-definitions'
import type { VV } from '@semios/app-platform-value-type-definitions'
import { VIEW_PESTS_PHENOLOGY_ID_insectId } from '@semios/app-platform-value-type-definitions'
import { DropdownSelectorProperty } from 'App/Map/PanelDetails/SectionTitleBars/DropdownSelectorProperty/DropdownSelectorProperty'
import { propertyLacksDataSectionMaker } from 'App/Map/PanelDetails/_utils/propertyLacksDataSectionMaker'
import { propertyLacksPermissionSectionMaker } from 'App/Map/PanelDetails/_utils/propertyLacksPermissionSectionMaker'
import { selectedPropertyHasPermission } from 'App/Map/PanelDetails/_utils/selectedPropertyHasPermission'
import type { TPestSectionCategory } from 'App/Map/PanelDetails/_utils/sortPestSections'
import { EAggregationInterval } from 'App/Map/types'
import type { StackedChartPestSection, TChartSeries } from 'components/StackedChart/types'
import type { AxisPlotBandsLabelOptions, SeriesOptionsType } from 'highcharts'
import type { Options } from 'highcharts/highstock'
import { translate } from 'i18n/i18n'
import { fromPairs } from 'lodash'
import moment from 'moment-timezone'
import { colors } from 'settings/colors'
import { detailsPanelStore } from 'stores/detailsPanelStore'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import type { TSelectedFieldAssetsStoreState } from 'stores/selectedFieldAssetsStore'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import type {
  selectedValueGroupsStore,
  TValueGroup,
} from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { doesSelectedPropertyHaveValueTypes } from 'utils/doesSelectedFieldAssetHaveValueTypes'
import { INSECT_PHENOLOGY_VALUE_KEY_PREFIX } from 'utils/insectRequestValueKeyPrefix'
import { sortByKey } from 'utils/sortByKey'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import { baseChartOptions } from '../../../../../../../components/StackedChart/_utils/baseChartOptions'
import { insectPhenologyTooltipFormatter } from './_utils/insectPhenologyTooltipFormatter'
import InsectPhenologyHighcharts from './components/InsectPhenologyHighcharts'

type TSprayWindowSeries = {
  from: number | undefined
  to: number | undefined
  label?: AxisPlotBandsLabelOptions
  id: string
  color: string
}

const preferredAggregationInterval = {
  preferredAggregationInterval: EAggregationInterval.UNAGGREGATED,
} as const

const getValueTypeIdForInsectId = (insectId: number) => {
  return `${INSECT_PHENOLOGY_VALUE_KEY_PREFIX}${insectId}` as VV.DomainTypes.PhenologyInsect.TTimeseriesValueTypeKeysMerged
}

export const apiArgs = ({
  selectedValueGroups,
  selectedFieldAssets,
}: {
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}) => {
  const targetScope = selectedFieldAssets.property

  if (!targetScope) return {}

  const valuesRequested: Partial<
    Record<VV.DomainTypes.PhenologyInsect.TTimeseriesValueTypeKeysMerged, typeof preferredAggregationInterval>
  > = {}

  Object.entries(selectedValueGroups).forEach(([valueGroup, isActive]) => {
    if (!!isActive && valueGroup.includes('phenology_insect_id_')) {
      const insectId = Number(valueGroup.split('_').slice(-1)[0])

      if (!selectedPropertyHasPermission({ permission: VIEW_PESTS_PHENOLOGY_ID_insectId(insectId) })) return

      valuesRequested[getValueTypeIdForInsectId(insectId)] = preferredAggregationInterval
    }
  })

  if (Object.keys(valuesRequested).length === 0) return {}

  const valuesToBeRequested: Partial<routes.Values.Request> = {}

  if (
    Object.keys(valuesRequested).length &&
    doesSelectedPropertyHaveValueTypes({ valuesTimeseries: Object.keys(valuesRequested) })
  )
    valuesToBeRequested.properties = {
      propertyIds: [targetScope],
      valuesRequested,
    }

  return valuesToBeRequested
}

export const content = ({
  data,
  selectedValueGroups,
}: {
  data: routes.Values.Response
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
}): StackedChartPestSection[] => {
  const insects = fieldAssetStore.getState()?.insects
  const propertyIdInNumber = selectedFieldAssetsStore.getState().property

  if (!insects || !propertyIdInNumber) return []

  const propertyIdInString = String(propertyIdInNumber)

  const tempSelectedValueGroups = {
    ...selectedValueGroups,
    phenology_insect_id_1: selectedValueGroups.degree_days_insect_id_1,
    phenology_insect_id_7: selectedValueGroups.degree_days_insect_id_7,
    phenology_insect_id_11: selectedValueGroups.degree_days_insect_id_11,
  }

  const phenologyContent: StackedChartPestSection[] = Object.values(insects)
    .filter((insect) => tempSelectedValueGroups[`phenology_insect_id_${insect.insectId}` as TValueGroup])
    .sort(sortByKey('name'))
    .map(({ insectId }) => {
      const hasPermission = selectedPropertyHasPermission({
        permission: VIEW_PESTS_PHENOLOGY_ID_insectId(insectId),
      })

      const valueTypeKey = getValueTypeIdForInsectId(insectId)
      const pestSectionCategory: TPestSectionCategory = 'insect-phenology'

      const commonReturnItems = {
        title: unitConverter.insectPhenology(null, { insectId }).titleWithoutUnit(),
        titleChildren: <DropdownSelectorProperty valuesTimeseriesToFilterOn={[valueTypeKey]} />,
        id: `insect-phenology-insect-id-${insectId}-${propertyIdInString}`,
        pestSectionCategory,
        insectId,
      }

      if (!hasPermission) {
        return { ...propertyLacksPermissionSectionMaker(commonReturnItems), pestSectionCategory, insectId }
      }

      if (!doesSelectedPropertyHaveValueTypes({ valuesTimeseries: [valueTypeKey] })) {
        return { ...propertyLacksDataSectionMaker(commonReturnItems), pestSectionCategory, insectId }
      }

      const metadata = data?.properties?.[propertyIdInString]?.values?.[valueTypeKey]?.[0]?.metadata
      const propertyShouldIncludeSemiosModel = metadata && metadata.propertyShouldIncludeSemiosModel
      const seriesData = data?.properties?.[propertyIdInString]?.values?.[valueTypeKey]?.[0]?.series
      const modeledFlightPredictions = seriesData?.modeledFlightPredictions || []
      const dateToday = new Date().toISOString().split('T')[0]

      const timezone =
        fieldAssetStore.getState()?.properties?.[propertyIdInNumber].timezone || 'America/Los_Angeles'

      const cumulativeDegreeDaysOfToday = unitConverter
        .insectDegreeDaysCumulative(
          modeledFlightPredictions.find((d) => d.date === dateToday)?.cumulativeDegreeDays,
        )
        .value()

      const xAxisMax = unitConverter
        .insectDegreeDaysCumulative(
          (modeledFlightPredictions[modeledFlightPredictions.length - 1]?.cumulativeDegreeDays || 0) + 100,
        )
        .value()

      const dateTo = detailsPanelStore.getState().dateTo
      const yearFrom = moment.tz(dateTo, timezone).format('YYYY')

      const seriesTrapCatchData = modeledFlightPredictions
        .filter((d) => (insectId === 1 ? d.modelType === 'wsu' : d.modelType === 'semios'))
        .map((d) => [
          unitConverter.insectDegreeDaysCumulative(d.cumulativeDegreeDays).value(),
          d.trapCatchValue,
        ])

      const degreeDaysDatePairs: Record<string, string> = fromPairs(
        modeledFlightPredictions
          .filter((d) => (insectId === 1 ? d.modelType === 'wsu' : d.modelType === 'semios') && d.date)
          .map((d) => [
            String(unitConverter.insectDegreeDaysCumulative(d.cumulativeDegreeDays).value()),
            String(d.date),
          ]),
      )

      const trapCatchSeries: SeriesOptionsType = {
        id: 'TrapCatches',
        name: translate.phrases.banyanApp('Trap Catches'),
        type: 'column',
        data: seriesTrapCatchData,
        yAxis: 1,
        color: colors.black,
      }

      const seriesModelSemiosData = modeledFlightPredictions
        .filter((d) => d.modelType === 'semios')
        .map((d) => [
          unitConverter.insectDegreeDaysCumulative(d.cumulativeDegreeDays).value(),
          d.percentageInsectFlights,
        ])

      const modelSemiosSeries: SeriesOptionsType = {
        id: 'RelativeFlightSemios',
        name: translate.phrases.banyanApp('Semios'),
        type: 'line',
        data: seriesModelSemiosData,
        yAxis: 0,
        color: colors.green,
        visible: propertyShouldIncludeSemiosModel,
      }

      const seriesModelWSUData = modeledFlightPredictions
        .filter((d) => d.modelType === 'wsu')
        .map((d) => [
          unitConverter.insectDegreeDaysCumulative(d.cumulativeDegreeDays).value(),
          d.percentageInsectFlights,
        ])

      const modelWSUSeries: SeriesOptionsType = {
        id: 'RelativeFlightWSU',
        name: translate.phrases.banyanApp('Regional Model'),
        type: 'line',
        data: seriesModelWSUData,
        yAxis: 0,
        color: colors.orange,
      }

      const optimalSprayWindows = seriesData?.optimalSprayWindows || []

      let seriesSprayWindowsSemios: TSprayWindowSeries[] = []

      if (insectId === 1) {
        seriesSprayWindowsSemios = optimalSprayWindows
          .filter((d) => d.modelType === 'semios')
          .map((d) => ({
            id: 'sprayWindowSemios',
            color: 'rgba(80,134,94,.25)',
            from: unitConverter.insectDegreeDaysCumulative(d.startCumulativeDegreeDays).value() || undefined,
            to: unitConverter.insectDegreeDaysCumulative(d.endCumulativeDegreeDays).value() || undefined,
          }))
      } else if (insectId === 11) {
        const oblrPlotBandMaker = (
          from: number | undefined,
          to: number | undefined,
          text: string,
        ): {
          from: number | undefined
          to: number | undefined
          label: AxisPlotBandsLabelOptions
        } => ({
          from: from,
          to: to,
          label: {
            text: text,
            rotation: 90,
            align: 'right',
            style: {
              textAnchor: 'start',
              color: '#a3a3a3',
              fontFamily: 'Roboto, Helvetica Neue, Helvetica, Arial, sans-serif',
            },
          },
        })

        seriesSprayWindowsSemios = [
          {
            id: 'sprayWindowSemios',
            color: 'rgba(80,134,94,.25)',
            ...oblrPlotBandMaker(
              unitConverter.insectDegreeDaysCumulative(50, { decimalPlaces: 0 }).value() || undefined,
              unitConverter.insectDegreeDaysCumulative(106, { decimalPlaces: 0 }).value() || undefined,
              'Larvae (overwintering)',
            ),
          },
          {
            id: 'sprayWindowSemios',
            color: 'rgba(80,134,94,.25)',
            ...oblrPlotBandMaker(
              unitConverter.insectDegreeDaysCumulative(400, { decimalPlaces: 0 }).value() || undefined,
              unitConverter.insectDegreeDaysCumulative(459, { decimalPlaces: 0 }).value() || undefined,
              'Eggs',
            ),
          },
          {
            id: 'sprayWindowSemios',
            color: 'rgba(80,134,94,.25)',
            ...oblrPlotBandMaker(
              unitConverter.insectDegreeDaysCumulative(500, { decimalPlaces: 0 }).value() || undefined,
              unitConverter.insectDegreeDaysCumulative(556, { decimalPlaces: 0 }).value() || undefined,
              'Larvae',
            ),
          },
          {
            id: 'sprayWindowSemios',
            color: 'rgba(80,134,94,.25)',
            ...oblrPlotBandMaker(
              unitConverter.insectDegreeDaysCumulative(1000, { decimalPlaces: 0 }).value() || undefined,
              unitConverter.insectDegreeDaysCumulative(1111, { decimalPlaces: 0 }).value() || undefined,
              'Eggs + larvae',
            ),
          },
        ]
      }

      const seriesSprayWindowsWSU = optimalSprayWindows
        .filter((d) => d.modelType === 'wsu')
        .map((d) => ({
          id: 'sprayWindowWSU',
          color: 'rgba(255,137,29,.25)',
          from: unitConverter.insectDegreeDaysCumulative(d.startCumulativeDegreeDays).value() || undefined,
          to: unitConverter.insectDegreeDaysCumulative(d.endCumulativeDegreeDays).value() || undefined,
        }))

      const sprayWindowsSemiosLegendSeries: TChartSeries = {
        id: 'sprayWindowSemios',
        color: 'rgba(80,134,94,.25)',
        data: [],
        name: translate.phrases.banyanApp('Semios'),
        type: 'area',
        hideFromTooltip: true,
        visible: propertyShouldIncludeSemiosModel,
      }

      const sprayWindowWSULegendSeries: TChartSeries = {
        id: 'sprayWindowWSU',
        color: 'rgba(255,137,29,.25)',
        data: [],
        name: translate.phrases.banyanApp('Regional Model'),
        type: 'area',
        hideFromTooltip: true,
      }

      const series: SeriesOptionsType[] = [
        modelSemiosSeries,
        modelWSUSeries,
        sprayWindowsSemiosLegendSeries,
        sprayWindowWSULegendSeries,
        trapCatchSeries,
      ]

      const options: Options = {
        accessibility: { enabled: false },
        credits: {
          enabled: false,
        },
        title: {
          text: '',
        },
        lang: {
          noData: translate.phrases.banyanApp('No data found.'),
        },
        legend: {
          enabled: false,
        },
        noData: {
          useHTML: true,
        },
        xAxis: {
          type: 'linear',
          title: {
            text: translate.phrases.banyanApp('Degree Days'),
          },
          min: 0,
          max: xAxisMax,
          tickInterval: 200,
          plotLines: [
            {
              id: 'plotLine-today',
              color: colors.red,
              value: cumulativeDegreeDaysOfToday || undefined,
              label: {
                text: translate.phrases.banyanApp('Today'),
                style: {
                  color: colors.red,
                },
                rotation: 0,
                x: -15,
                y: -5,
              },
              zIndex: 2,
            },
          ],
          plotBands: [
            ...seriesSprayWindowsSemios,
            ...seriesSprayWindowsWSU,
            {
              id: 'plotBand-forecast',
              color: 'rgba(215,215,215,.25)',
              from: cumulativeDegreeDaysOfToday || undefined,
              to: 999999999999999,
              zIndex: 1,
              label: {
                y: -6,
                x: -10,
                align: 'right',
                style: {
                  fontSize: '14px',
                  zIndex: 99999999,
                },
                useHTML: true,
              },
            },
          ],
        },
        yAxis: [
          {
            title: {
              text: translate.phrases.banyanApp('Relative Flight'),
            },
            max: 100,
            maxPadding: 0,
            minPadding: 0,
            endOnTick: false,
          },
          {
            title: {
              text: translate.phrases.banyanApp('Catches'),
            },
            opposite: true,
          },
        ],
        series,
        tooltip: {
          ...baseChartOptions(timezone).tooltip,
          shared: true,
          formatter: function (tooltip): string {
            return insectPhenologyTooltipFormatter(this, tooltip, degreeDaysDatePairs)
          },
          style: {
            zIndex: 1,
            fontFamily: 'Roboto, Helvetica Neue, Helvetica, Arial, sans-serif',
          },
          enabled: true,
        },
      }

      return {
        ...commonReturnItems,
        items: [],
        fullWidthItems: [
          {
            content: (
              <InsectPhenologyHighcharts
                options={options}
                year={yearFrom}
                modelImplication={metadata && metadata.modelImplication}
                insectId={insectId}
                propertyShouldIncludeSemiosModel={propertyShouldIncludeSemiosModel}
                seriesSprayWindowsSemios={seriesSprayWindowsSemios}
                seriesSprayWindowsWSU={seriesSprayWindowsWSU}
              />
            ),
          },
        ],
      }
    })

  return phenologyContent
}
