import { SemiosClassicSection } from 'App/Map/UserSettingsMenu/Shared/MyDetails/SemiosClassicSection'
import { useStateWithTimeout } from 'components/Select/useStateWithTimeout'
import { translate } from 'i18n/i18n'
import type { FC } from 'react'
import { useEffect, useState } from 'react'
import { isSemiosClassicMigrationEnabled } from 'utils/semiosClassicMigration/isSemiosClassicMigrationEnabled'
import type { TMyDetailsProps } from '../../types'
import { ErrorMessage } from '../ErrorMessage/ErrorMessage'
import { ChangePasswordSection } from './ChangePasswordSection'
import { Header } from './Header'
import { LanguageSection } from './LanguageSection'
import { PersonalInfoSection } from './PersonalInfoSection.tsx/PersonalInfoSection'

export const MyDetails: FC<TMyDetailsProps> = ({ durationMillisecond }) => {
  const [hideErrorMessage, setHideErrorMessage] = useState(false)
  const { stateWithTimeout, setStateWithTimeout } = useStateWithTimeout(durationMillisecond)
  const onCloseButtonPress = () => setHideErrorMessage(true)

  useEffect(() => {
    const timer = setTimeout(() => {
      if (hideErrorMessage) setHideErrorMessage(false)
    }, durationMillisecond)

    return () => clearTimeout(timer)
  }, [hideErrorMessage])

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'center',
        padding: 30,
        overflowY: 'auto',
      }}
    >
      <ErrorMessage
        showErrorMessage={stateWithTimeout === 'error' && !hideErrorMessage}
        onCloseButtonPress={onCloseButtonPress}
        message={translate.phrases.banyanApp('Your preferences could not be saved. Please try again.')}
      />
      <Header />
      <PersonalInfoSection />
      <LanguageSection stateWithTimeout={stateWithTimeout} setStateWithTimeout={setStateWithTimeout} />
      <ChangePasswordSection />
      {isSemiosClassicMigrationEnabled && <SemiosClassicSection />}
    </div>
  )
}
