import { Group, Stack, Text, useMantineTheme } from '@mantine/core' // Import the Text component from the appropriate library
import { useBleManager } from 'App/ServiceCenter/BluetoothLowEnergy/BleManager'
import { AdapterStatus } from 'App/ServiceCenter/BluetoothLowEnergy/types'
import { SignalStrength } from 'App/ServiceCenter/SelectedEquipmentCard/ActiveNodeDetail/log/SignalStrength'
import { ToggleSymbolValue } from 'App/ServiceCenter/ToggleSymbolValue/ToggleSymbolValue'
import { Button } from 'components/Button/Button'
import { IconBluetooth } from 'components/icons/IconBluetooth'
import { IconCellular } from 'components/icons/IconCellular'
import { translate } from 'i18n/i18n'
import React from 'react'

export const getBleConnectionStatusColor = (status: AdapterStatus) => {
  switch (status) {
    case AdapterStatus.IDLE:
      return '#B4B3BB'

    case AdapterStatus.DISABLED:
      return '#B4B3BB'

    case AdapterStatus.SCANNING:
      return '#FFAB00'

    case AdapterStatus.CONNECTING:
      return '#FFAB00'

    case AdapterStatus.CONNECTED:
      return '#13A44D'

    default:
      return '#B4B3BB'
  }
}

export const getBleConnectionStatusDescription = (status: AdapterStatus) => {
  switch (status) {
    case AdapterStatus.IDLE:
      return translate.phrases.placeholder('Disconnected')

    case AdapterStatus.DISABLED:
      return translate.phrases.placeholder('Disabled')

    case AdapterStatus.SCANNING:
      return translate.phrases.placeholder('Scanning...')

    case AdapterStatus.CONNECTING:
      return translate.phrases.placeholder('Connecting...')

    case AdapterStatus.CONNECTED:
      return translate.phrases.placeholder('Connected')

    default:
      return ''
  }
}

export const NodeSummary: React.FC<{
  nodeId?: string
  connectionStatus?: {
    bleStatus: AdapterStatus
    loraNetworkStatus?: number | 'disconnected'
  }
  children?: React.ReactNode
}> = ({ nodeId, connectionStatus, children }) => {
  const theme = useMantineTheme()
  const bleManager = useBleManager()
  const nodeIdentifier = nodeId

  const getCellularSignalLevel = (rssi: number | 'disconnected') => {
    if (rssi === 'disconnected') return 0 // No signal

    if (rssi < -80) {
      return 1
    } else if (rssi < -70) {
      return 2
    } else if (rssi < -60) {
      return 3
    } else if (rssi < -50) {
      return 4
    } else {
      return 5
    }
  }

  return (
    <>
      <Stack spacing={'xs'}>
        {connectionStatus && (
          <Stack spacing={'xs'}>
            <Group position="left" spacing={'xs'}>
              <Text>{translate.phrases.placeholder('Bluetooth:')}</Text>
              <span
                css={{
                  alignItems: 'center',
                  display: 'flex',
                  color: getBleConnectionStatusColor(connectionStatus.bleStatus),
                }}
              >
                <IconBluetooth />
                <Text css={{ marginLeft: '4px' }}>
                  {getBleConnectionStatusDescription(connectionStatus.bleStatus)}
                </Text>
              </span>
            </Group>
            {connectionStatus.loraNetworkStatus !== undefined && (
              <Group position="left" spacing={'xs'}>
                <Text>{translate.phrases.placeholder('Signal Strength:')}</Text>
                {connectionStatus.loraNetworkStatus === 'disconnected' && (
                  <span
                    css={{
                      alignItems: 'center',
                      display: 'flex',
                      color: theme.colors.gray[6],
                    }}
                  >
                    <IconCellular />
                    <Text css={{ marginLeft: '4px' }}>{translate.phrases.placeholder('No Signal')}</Text>
                  </span>
                )}
                {typeof connectionStatus.loraNetworkStatus === 'number' && (
                  <div>
                    <ToggleSymbolValue value={`${connectionStatus.loraNetworkStatus} dBm`}>
                      <SignalStrength
                        signalRSSI={connectionStatus.loraNetworkStatus}
                        signalLevel={getCellularSignalLevel(connectionStatus.loraNetworkStatus)}
                      />
                    </ToggleSymbolValue>
                  </div>
                )}
              </Group>
            )}
          </Stack>
        )}
        {connectionStatus && nodeId && connectionStatus.bleStatus === AdapterStatus.IDLE && (
          <Button
            variant="tertiary"
            css={{ width: '100%', marginTop: 10, marginBottom: 10 }}
            onClick={() => nodeIdentifier && bleManager.connectDeviceByNodeId(nodeIdentifier)}
          >
            <Text>{translate.phrases.placeholder('Connect with Bluetooth')}</Text>
          </Button>
        )}
      </Stack>
      {children}
    </>
  )
}
