import type { routes, SharedTypes } from '@semios/app-platform-banyan-route-definitions'
import { apiFetch } from 'utils/apiFetch'
import { parse as parseWKT } from 'wellknown'
import { serviceCenterStore } from '../../store/serviceCenterStore'
import type { TActiveNode } from '../../types'
import { getIdentifier } from '../getIdentifier'

export type PlannedNodeInstallMeta = routes.ServiceCenterPlannedNodeInstall.Request

export type PlannedNodeInstallResult = SharedTypes.TActiveNode

const request = async (meta: PlannedNodeInstallMeta): Promise<PlannedNodeInstallResult> => {
  const installedNode = await apiFetch({ url: '/service-center-planned-node-install', body: meta })

  return installedNode
}

const callback = (response: PlannedNodeInstallResult, meta: PlannedNodeInstallMeta) => {
  const activeNodes = serviceCenterStore.selectors.getActiveNodes(serviceCenterStore.getState())

  const updatedActiveNodes = [...activeNodes].filter(
    (node) => getIdentifier(node) !== response.nodeIdentifier,
  )

  const activeNode: TActiveNode = {
    ...response,
    name: meta.name || null,
    maintenanceOwnerId: meta.maintenanceOwnerId,
    devices: response.devices ? response.devices : meta.devices,
    nodeIdentifier: meta.nodeIdentifier,
    nodeType: meta.nodeType.toLowerCase() as SharedTypes.TNodeType,
    propertyId: meta.propertyId,
    // Transform string WKT to serialized GeoJSON
    location: JSON.stringify(parseWKT(meta.location)),
  }

  updatedActiveNodes.push(activeNode)

  serviceCenterStore.actions.setActiveNodes(updatedActiveNodes)

  // Remove installed planned node from list
  serviceCenterStore.actions.removePlannedNodes([`${meta.pseudonodeId}`])
}

export const serviceCenterPlannedNodeInstall = {
  request,
  callback,
}
