import { Tabs } from '@mantine/core'
import { mapPropertiesForPropertySelect } from 'App/irrigation-scheduler/utils/mapPropertiesForPropertySelect'
import { PropertySelect } from 'components/PropertySelect/PropertySelect'
import { SearchTextInput } from 'components/SearchTextInput/SearchTextInput'
import { translate } from 'i18n/i18n'
import { useState } from 'react'
import { colors } from 'settings/colors'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { sortByKey } from 'utils/sortByKey'
import type { TFertigationSettingsData } from '../types'
import { FertilizersTab } from './FertilizersTab/FertilizersTab'
import { RefreshWiseconnDataButton } from './FertilizersTab/RefreshWiseconnDataButton'
import { InjectorsTab } from './InjectorsTab/InjectorsTab'

type TActiveTab = 'FERTILIZERS' | 'INJECTORS'

export const FertigationConfigurationTabs = ({
  fertigationSettings,
  loading,
  propertiesWithEditPermission,
  propertiesWithViewPermission,
  selectedPropertyId,
  setFertigationSettings,
  setLoading,
  setSelectedPropertyId,
}: {
  fertigationSettings: TFertigationSettingsData[]
  loading: boolean
  propertiesWithEditPermission: number[]
  propertiesWithViewPermission: number[]
  selectedPropertyId: number | null
  setFertigationSettings: React.Dispatch<React.SetStateAction<TFertigationSettingsData[]>>
  setLoading: (arg: boolean) => void
  setSelectedPropertyId: (arg: number | null) => void
}) => {
  const [activeTab, setActiveTab] = useState<TActiveTab>('FERTILIZERS')
  const [filterPropertiesText, setFilterPropertiesText] = useState('')
  const propertyValues = Object.values(fieldAssetStore.useSelector((s) => s.properties) ?? {})

  const filteredProperties = selectedPropertyId
    ? propertyValues.filter((p) => p.propertyId === selectedPropertyId)
    : propertyValues.sort(sortByKey('propertyName'))

  return (
    <Tabs
      css={{ marginTop: 24 }}
      styles={{
        tabsList: {
          button: {
            'fontSize': 15,
            'padding': 10,
            'color': colors.primary,
            '&:hover': { background: 'transparent', fontWeight: 600 },
            '&[data-active]': {
              'fontWeight': 700,
              'borderBottom': `4px solid ${colors.midnight}`,
              '&:hover': { fontWeight: 700, borderColor: colors.midnight },
            },
            '&:not(:first-of-type)': { marginLeft: 20 },
          },
        },
      }}
      value={activeTab}
      onTabChange={(val: TActiveTab) => setActiveTab(val)}
    >
      <Tabs.List>
        <Tabs.Tab value="FERTILIZERS">{translate.phrases.banyanApp('Fertilizers and Chemicals')}</Tabs.Tab>
        <Tabs.Tab value="INJECTORS">{translate.phrases.banyanApp('Injector Configuration')}</Tabs.Tab>
      </Tabs.List>
      <Tabs.Panel value="FERTILIZERS">
        <div css={{ display: 'flex', gap: '20px', padding: '10px 20px', alignItems: 'center' }}>
          <div css={{ flexGrow: 2 }}>
            <SearchTextInput
              clearable={true}
              placeholder={translate.phrases.banyanApp('Filter fertilizers and injectors')}
              searchText={filterPropertiesText}
              setSearchText={setFilterPropertiesText}
            />
          </div>
          <div css={{ flexGrow: 1 }}>
            <PropertySelect
              clearable={true}
              data={mapPropertiesForPropertySelect(propertyValues)}
              onChange={setSelectedPropertyId}
              selectedPropertyId={selectedPropertyId}
            />
          </div>
          <RefreshWiseconnDataButton
            loading={loading}
            propertyIds={propertiesWithViewPermission}
            setFertigationSettings={setFertigationSettings}
            setLoading={setLoading}
          />
        </div>
        <FertilizersTab
          data={fertigationSettings}
          filterPropertiesText={filterPropertiesText}
          loading={loading}
          properties={filteredProperties}
          propertiesWithEditPermission={propertiesWithEditPermission}
        />
      </Tabs.Panel>
      <Tabs.Panel value="INJECTORS">
        <div css={{ display: 'flex', gap: '20px', padding: '10px 20px', alignItems: 'center' }}>
          <div css={{ flexGrow: 2 }}>
            <SearchTextInput
              clearable={true}
              placeholder={translate.phrases.banyanApp('Filter injectors')}
              searchText={filterPropertiesText}
              setSearchText={setFilterPropertiesText}
            />
          </div>
          <div css={{ flexGrow: 1 }}>
            <PropertySelect
              clearable={true}
              data={mapPropertiesForPropertySelect(propertyValues)}
              onChange={setSelectedPropertyId}
              selectedPropertyId={selectedPropertyId}
            />
          </div>
          <RefreshWiseconnDataButton
            loading={loading}
            propertyIds={propertiesWithViewPermission}
            setFertigationSettings={setFertigationSettings}
            setLoading={setLoading}
          />
        </div>
        <InjectorsTab
          data={fertigationSettings}
          filterPropertiesText={filterPropertiesText}
          loading={loading}
          properties={filteredProperties}
          propertiesWithEditPermission={propertiesWithEditPermission}
        />
      </Tabs.Panel>
    </Tabs>
  )
}
