import type { routes } from '@semios/app-platform-banyan-route-definitions'
import type { TFieldAssetKeyTypes } from 'App/Map/types'
import { AlmaSummary } from 'components/AlmaSummary/AlmaSummary'
import { getTranslatableTemplateForValueTypeGroup } from 'components/AlmaSummary/getTranslatableTemplateForValueTypeGroup'
import { translate } from 'i18n/i18n'
import { unitConverter } from 'utils/unitConverter/unitConverter'

export const Summary = ({
  regionId,
  stationLngLat,
}: {
  stationLngLat?: TFieldAssetKeyTypes.TLngLat | null
  regionId?: TFieldAssetKeyTypes.TRegionId | null
}) => {
  if (!regionId && !stationLngLat) return null

  let params: routes.ValuesCurrent.Request = {}

  if (stationLngLat) {
    params = {
      points: {
        lngLats: [stationLngLat],
        values: { air_temperature_sidekick: true },
      },
    }
  } else if (regionId) {
    params = {
      regions: {
        regionIds: [regionId],
        values: { air_temperature_sidekick: true },
      },
    }
  }

  const summaryProps = {
    queryParams: params,
    getStatementId: (summaryTemplate: routes.ValuesCurrent.Response) => {
      if (stationLngLat) {
        return summaryTemplate.points?.[stationLngLat]?.air_temperature_sidekick?.value?.uuid
      }

      if (regionId) {
        return summaryTemplate.regions?.[regionId]?.air_temperature_sidekick?.value?.uuid
      }
    },
    renderFunction: (summaryTemplate: routes.ValuesCurrent.Response) => {
      let dataObject = null

      if (stationLngLat) {
        dataObject = summaryTemplate.points?.[stationLngLat]?.air_temperature_sidekick
      } else if (regionId) {
        dataObject = summaryTemplate.regions?.[regionId]?.air_temperature_sidekick
      }

      const values = dataObject?.value?.statements?.values

      if (values) {
        const {
          lastYearDiff,
          lastYearIsWarmer,
          lastYearMean,
          nextWeekMax,
          nextWeekMean,
          nextWeekMin,
          nextWeekThreshold,
          thisWeekMax,
          thisWeekMin,
          thisWeekMean,
          thisWeekThreshold,
        } = values

        const templateToUse = getTranslatableTemplateForValueTypeGroup('air_temperature_sidekick')

        if (!templateToUse) return null

        const roundingOptions = { decimalPlaces: 0 }

        const translatedValue = translate.phrases.sidekick(templateToUse, {
          lastYearDiff: unitConverter.temperatureInversion(lastYearDiff, roundingOptions).valueWithSuffix(),
          lastYearIsWarmer: lastYearIsWarmer
            ? translate.phrases.sidekick('warmer')
            : translate.phrases.sidekick('cooler'),
          lastYearMean: unitConverter.temperatureInCanopy(lastYearMean, roundingOptions).valueWithSuffix(),
          nextWeekMax: unitConverter.temperatureInCanopy(nextWeekMax, roundingOptions).valueWithSuffix(),
          nextWeekMin: unitConverter.temperatureInCanopy(nextWeekMin, roundingOptions).valueWithSuffix(),
          nextWeekMean: unitConverter.temperatureInCanopy(nextWeekMean, roundingOptions).valueWithSuffix(),
          nextWeekThreshold: translate.phrases.sidekick(nextWeekThreshold),
          thisWeekMax: unitConverter.temperatureInCanopy(thisWeekMax, roundingOptions).valueWithSuffix(),
          thisWeekMin: unitConverter.temperatureInCanopy(thisWeekMin, roundingOptions).valueWithSuffix(),
          thisWeekMean: unitConverter.temperatureInCanopy(thisWeekMean, roundingOptions).valueWithSuffix(),
          thisWeekThreshold: thisWeekThreshold,
        })

        const templateParts = translatedValue.split('. ')

        let translatedValueWithNullsExcluded = translatedValue

        try {
          const filteredParts: string[] = []

          templateParts.forEach((part) => {
            let hasValues

            if (part.includes('Last week')) {
              hasValues = thisWeekMax !== null && thisWeekMin !== null && thisWeekMean !== null
            } else if (part.includes('Next week')) {
              hasValues = nextWeekMax !== null && nextWeekMin !== null && nextWeekMean !== null
            } else if (part.includes('Last year')) {
              hasValues = lastYearDiff !== null && lastYearMean !== null
            }

            if (hasValues) {
              filteredParts.push(part)
            }

            translatedValueWithNullsExcluded = filteredParts.join('. ')
          })
        } catch {
          translatedValueWithNullsExcluded = translatedValue
        }

        return <div>{translatedValueWithNullsExcluded}</div>
      }
    },
  }

  return (
    <AlmaSummary
      context="details-panel"
      summaries={{
        temperature: summaryProps,
      }}
    />
  )
}
