import { AdapterStatus } from 'App/ServiceCenter/BluetoothLowEnergy/types'
import type { TActiveNode, TNodeDevice, TNodeDevicePort } from 'App/ServiceCenter/types'
import { Button } from 'components/Button/Button'
import { IconRefresh } from 'components/icons/IconRefresh'
import { translate } from 'i18n/i18n'
import { useLnrNodeStatus } from '../useLnrNodeStatus'
import { Device } from './Device/Device'
import { DeviceGroup } from './DeviceGroup/DeviceGroup'

interface DevicesListProps {
  node: TActiveNode
  sdiChannels: string[]
  stereoChannels: string[]
  rj11Channels: string[]
  //sensor status here
  onAddDevice: (port: TNodeDevicePort, channel: number) => void
  onRemoveDevice: (port: TNodeDevicePort, channel: number) => void
  isBleRequired: boolean
}

export const DevicesList: React.FC<DevicesListProps> = ({
  node,
  sdiChannels,
  stereoChannels,
  rj11Channels,
  onAddDevice,
  onRemoveDevice,
  isBleRequired,
}: DevicesListProps) => {
  const { requestAllDevicesStatusReading, isReadingAllDevicesStatus, adapterStatus, getDeviceStatus } =
    useLnrNodeStatus(isBleRequired, node.nodeIdentifier)

  const sdiDevices = sdiChannels.reduce(
    (
      acc: {
        [address: string]: TNodeDevice | null
      },
      address,
    ) => {
      acc[address] = node.devices?.sdi?.[parseInt(address)] || null

      return acc
    },
    {},
  )

  const stereoDevices = stereoChannels.reduce(
    (
      acc: {
        [address: string]: TNodeDevice | null
      },
      address,
    ) => {
      acc[address] = node.devices?.stereo?.[parseInt(address)] || null

      return acc
    },
    {},
  )

  const rj11Devices = rj11Channels.reduce(
    (
      acc: {
        [address: string]: TNodeDevice | null
      },
      address,
    ) => {
      acc[address] = node.devices?.rj11?.[parseInt(address)] || null

      return acc
    },
    {},
  )

  type DeviceWrapper = {
    key: TNodeDevicePort
    title: string
    channels: { [address: string]: TNodeDevice | null }
  }

  const wrapper: DeviceWrapper[] = [
    {
      key: 'sdi',
      title: translate.phrases.placeholder('SDI'),
      channels: sdiDevices,
    },
    {
      key: 'stereo',
      title: translate.phrases.placeholder('Stereo'),
      channels: stereoDevices,
    },
    {
      key: 'rj11',
      title: translate.phrases.placeholder('RJ11'),
      channels: rj11Devices,
    },
  ]

  return (
    <>
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          alignContent: 'space-between',
        }}
      >
        <h2 css={{ flex: '1 1 auto', paddingLeft: 10 }}>{translate.phrases.placeholder('Inputs')}</h2>
        {isBleRequired && adapterStatus === AdapterStatus.CONNECTED && (
          <Button
            variant="link"
            onClick={requestAllDevicesStatusReading}
            rightIcon={<IconRefresh />}
            loading={isReadingAllDevicesStatus}
          >
            {translate.phrases.placeholder('Refresh')}
          </Button>
        )}
      </div>
      <div>
        {wrapper
          .filter(({ channels }) => Object.keys(channels).length !== 0)
          .map(({ key, title, channels }) => {
            return (
              <DeviceGroup
                key={key}
                expanded={Object.values(channels).some((dev) => dev !== null)}
                groupName={title}
              >
                {Object.entries(channels).map(([channel, device]) => (
                  <Device
                    channel={parseInt(channel)}
                    key={channel}
                    style={{
                      ':last-child': {
                        marginBottom: '0px',
                        borderBottomLeftRadius: '2px',
                        borderBottomRightRadius: '2px',
                      },
                    }}
                    device={device}
                    deviceStatus={getDeviceStatus(key, parseInt(channel), device)}
                    onAdd={() => onAddDevice(key, parseInt(channel))}
                    onRemove={() => {
                      onRemoveDevice(key, parseInt(channel))
                    }}
                    isBusy={isReadingAllDevicesStatus}
                  />
                ))}
              </DeviceGroup>
            )
          })}
      </div>
    </>
  )
}
