import type { routes } from '@semios/app-platform-banyan-route-definitions'
import { getEarliestDateFromAndLatestDateToFromPulseIrrigationEvents } from 'App/irrigation-scheduler/components/right-panels/utils/getEarliestDateFromAndLatestDateToFromPulseIrrigationEvents'
import type { TInjectorSettings } from 'App/Map/UserSettingsMenu/Shared/FertigationConfiguration/types'
import { translate } from 'i18n/i18n'
import { groupBy } from 'lodash'
import moment from 'moment-timezone'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { mapApiScheduledEventStatusToTScheduledEventEnum } from '../../mapApiScheduledEventStatusToTScheduledEventEnum'
import type {
  APPLIED_EVENT,
  PUBLISHED_EVENT,
  SCHEDULED_CONTINUOUS_EVENT,
  SCHEDULED_PULSE_EVENT,
} from '../irrigationSchedulerStore'
import { irrigationSchedulerStore } from '../irrigationSchedulerStore'
import { getSelectedPropertyTimezoneForIrrigationScheduler } from '../selectors/getSelectedPropertyTimezoneForIrrigationScheduler'

export const eventsGet = (apiEvents: routes.IrrigationSchedulerEventsGet.Response) => {
  irrigationSchedulerStore.setState((isss) => {
    const appliedEvents: APPLIED_EVENT[] = []
    const debugPublishedEvents: PUBLISHED_EVENT[] = []
    const debugPublishedPulseEvents: PUBLISHED_EVENT[] = []
    const scheduledEvents: SCHEDULED_CONTINUOUS_EVENT[] = []
    const scheduledPulseEvents: SCHEDULED_PULSE_EVENT[] = []
    const timezone = getSelectedPropertyTimezoneForIrrigationScheduler(fieldAssetStore.getState(), isss)
    const hasPendingDeletionEvents = apiEvents.some((apiEvent) => apiEvent.hasPendingDeletionEvents)

    const fertigationSettingsForProperty = (isss.fertigationSettings ?? []).find(
      (f) => f.propertyId === isss.selectedPropertyId,
    )

    const injectorSettingsByTankId =
      fertigationSettingsForProperty?.injectorSettings.reduce<Record<number, TInjectorSettings>>(
        (acc, injector) => {
          acc[injector.tankId] = injector

          return acc
        },
        {},
      ) ?? {}

    const injectorSettingsByExternalTankId =
      fertigationSettingsForProperty?.injectorSettings.reduce<Record<string, TInjectorSettings>>(
        (acc, injector) => {
          acc[injector.externalTankId] = injector

          return acc
        },
        {},
      ) ?? {}

    const fertilizerNamesByFertilizerId =
      fertigationSettingsForProperty?.fertilizerSettings.reduce<Record<number, string>>((acc, fertilizer) => {
        acc[fertilizer.fertilizerId] = fertilizer.fertilizerName

        return acc
      }, {}) ?? {}

    apiEvents.forEach((apiEvent) => {
      const { irrigationZoneEmitterId } = apiEvent

      apiEvent.actualIrrigationEvents.forEach((appliedEvent) => {
        const isRunning = appliedEvent.dateTo === null && appliedEvent.status === 'on'

        const appliedFertigationEvents = (appliedEvent.appliedFertigationEvents ?? []).map((fertigation) => {
          return {
            ...fertigation,
            startTime: moment.tz(fertigation.startTime, timezone),
            // endTime can be null if the fertigation is still running
            endTime: fertigation.endTime
              ? moment.tz(fertigation.endTime, timezone)
              : moment.tz(new Date(), timezone),
            fertilizerName:
              fertilizerNamesByFertilizerId[fertigation.fertilizerId] ??
              translate.phrases.banyanApp('Unknown Fertilizer'), // it's possible fertilizerId can become undefined in property settings if removed in DropControl
            injectorName:
              injectorSettingsByExternalTankId[fertigation.tankId]?.tankName ??
              translate.phrases.banyanApp('Unknown Injector'), // it's possible tankId can become undefined in property settings if removed in DropControl
          }
        })

        appliedEvents.push({
          dateFrom: moment.tz(appliedEvent.dateFrom, timezone),
          dateTo: isRunning ? moment.tz(new Date(), timezone) : moment.tz(appliedEvent.dateTo, timezone),
          irrigationZoneId: apiEvent.irrigationZoneId.toString(),
          irrigationZoneEmitterId,
          status: appliedEvent.status,
          emitterType: apiEvent.emitterType,
          appliedFertigationEvents,
        })
      })

      apiEvent.publishedIrrigationEvents.forEach((publishedEvent) => {
        if (publishedEvent.pulseEventId) {
          publishedEvent.pulseIrrigationEvents?.forEach((pulseEvent) => {
            debugPublishedPulseEvents.push({
              ...pulseEvent,
              appliedFertigationEvents: [],
              dateFrom: moment.tz(pulseEvent.dateFrom, timezone),
              dateTo: moment.tz(pulseEvent.dateTo, timezone),
              emitterType: apiEvent.emitterType,
              eventId: pulseEvent.pulseEventId?.toString() ?? '',
              externalEventId: publishedEvent.externalEventId?.toString() ?? '',
              irrigationZoneEmitterId,
              irrigationZoneId: apiEvent.irrigationZoneId.toString(),
              status: pulseEvent.status,
            })
          })
        } else if (publishedEvent.externalEventId) {
          debugPublishedEvents.push({
            ...publishedEvent,
            appliedFertigationEvents: [],
            dateFrom: moment.tz(publishedEvent.dateFrom, timezone),
            dateTo: moment.tz(publishedEvent.dateTo, timezone),
            emitterType: apiEvent.emitterType,
            eventId: publishedEvent.externalEventId?.toString() ?? '',
            externalEventId: publishedEvent.externalEventId?.toString() ?? '',
            irrigationZoneEmitterId,
            irrigationZoneId: apiEvent.irrigationZoneId.toString(),
            status: publishedEvent.status,
          })
        }
      })

      apiEvent.scheduledIrrigationEvents.forEach((scheduledEvent) => {
        if (scheduledEvent.eventId) {
          scheduledEvents.push({
            dateFrom: moment.tz(scheduledEvent.dateFrom, timezone),
            dateTo: moment.tz(scheduledEvent.dateTo, timezone),
            emitterType: apiEvent.emitterType,
            irrigationZoneEmitterId,
            eventId: scheduledEvent.eventId.toString(),
            type: mapApiScheduledEventStatusToTScheduledEventEnum(scheduledEvent.status),
            irrigationZoneId: apiEvent.irrigationZoneId.toString(),
            fertigationSchedules: (scheduledEvent.fertigationSchedules ?? []).map((fertigationEvent) => {
              const { fertigationStartTime, fertigationEndTime, injectorId, fertilizerId } = fertigationEvent
              const injector = injectorSettingsByTankId[injectorId]
              const flowRate = injector?.flowRate
              const flowRateUnit = injector?.flowRateUnit
              const tankName = injector?.tankName ?? translate.phrases.banyanApp('Unknown Injector') // it's possible tankId can become undefined in property settings if removed in DropControl

              const fertilizerName =
                fertilizerNamesByFertilizerId[fertilizerId] ??
                translate.phrases.banyanApp('Unknown Fertilizer') // it's possible fertilizerId can become undefined in property settings if removed in DropControl

              const duration = moment
                .tz(fertigationEndTime, timezone)
                .diff(moment.tz(fertigationStartTime, timezone), 'minute')

              return {
                ...fertigationEvent,
                duration,
                fertigationEndTime: moment.tz(fertigationEndTime, timezone),
                fertigationStartTime: moment.tz(fertigationStartTime, timezone),
                fertilizerName,
                flowRate,
                flowRateUnit,
                injectorName: tankName,
              }
            }),
          })
        } else if (
          scheduledEvent.pulseEventId &&
          scheduledEvent.pulseIrrigationEvents &&
          scheduledEvent.pulseIrrigationEvents.length > 0 &&
          scheduledEvent.pulseIrrigationConfig
        ) {
          const { earliestDateFrom, latestDateTo } =
            getEarliestDateFromAndLatestDateToFromPulseIrrigationEvents(
              scheduledEvent.pulseIrrigationEvents,
              timezone,
            )

          scheduledPulseEvents.push({
            dateFrom: moment.tz(earliestDateFrom, timezone),
            dateTo: moment.tz(latestDateTo, timezone),
            emitterType: apiEvent.emitterType,
            irrigationZoneEmitterId,
            pulseEventId: scheduledEvent.pulseEventId?.toString(),
            pulseIrrigationConfig: scheduledEvent.pulseIrrigationConfig,
            pulseIrrigationEvents: scheduledEvent.pulseIrrigationEvents.map((event) => {
              return {
                dateFrom: moment.tz(event.dateFrom, timezone),
                dateTo: moment.tz(event.dateTo, timezone),
                eventId: event.eventId.toString(),
                externalEventId: event.externalEventId?.toString() ?? '',
                status: mapApiScheduledEventStatusToTScheduledEventEnum(event.status),
              }
            }),
            type: mapApiScheduledEventStatusToTScheduledEventEnum(scheduledEvent.status),
            irrigationZoneId: apiEvent.irrigationZoneId.toString(),
            externalEventId: scheduledEvent.externalEventId?.toString() ?? '',
          })
        }
      })
    })

    return {
      ...isss,
      hasPendingDeletionEvents,
      appliedEventsByIrrigationZoneEmitterId: groupBy(
        appliedEvents,
        (event) => event.irrigationZoneEmitterId,
      ),
      debugPublishedEventsByIrrigationZoneEmitterId: groupBy(
        debugPublishedEvents,
        (event) => event.irrigationZoneEmitterId,
      ),
      debugPublishedPulseIrrigationEventsByIrrigationZoneEmitterId: groupBy(
        debugPublishedPulseEvents,
        (event) => event.irrigationZoneEmitterId,
      ),
      scheduledEvents: scheduledEvents.reduce<{ [key: string]: SCHEDULED_CONTINUOUS_EVENT }>((a, b) => {
        a[b.eventId] = { ...b }

        return a
      }, {}),
      scheduledPulseIrrigationEvents: scheduledPulseEvents.reduce<{
        [key: string]: SCHEDULED_PULSE_EVENT
      }>((a, b) => {
        a[b.pulseEventId] = { ...b }

        return a
      }, {}),
    }
  })
}
