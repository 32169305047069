import type { VV } from '@semios/app-platform-value-type-definitions'
import { INSECT_TRAP_CATCHES } from '@semios/app-platform-value-type-definitions/src/valuesTimeseriesTypes/helperTypes/_types'
import {
  INSECT_DEGREE_DAY_VALUE_KEY_PREFIX,
  INSECT_TRAP_CATCHES_PERCENTAGE_VALUE_KEY_PREFIX,
  INSECT_TRAP_CATCHES_VALUE_KEY_PREFIX,
} from 'utils/insectRequestValueKeyPrefix'

export const getValueTypesForInsectId = (insectId: number) => {
  return [
    `${INSECT_DEGREE_DAY_VALUE_KEY_PREFIX}${insectId}`,
    `${INSECT_TRAP_CATCHES}${insectId}`,
    `${INSECT_TRAP_CATCHES_PERCENTAGE_VALUE_KEY_PREFIX}${insectId}`,
  ] as (
    | VV.DomainTypes.DegreeDaysInsect.TTimeseriesValueTypeKeysMerged
    | VV.DomainTypes.TrapCatchesInsect.TTimeseriesValueTypeKeysMerged
  )[]
}

export const getTrapValueTypeIdForInsectId = (insectId: number) => {
  return `${INSECT_TRAP_CATCHES_VALUE_KEY_PREFIX}${insectId}` as keyof VV.DomainTypes.TrapCatchesInsect.TValuesReturnWithMetaIgnoringKeying['properties']
}

export const getTrapPercentageValueTypeIdForInsectId = (insectId: number) => {
  return `${INSECT_TRAP_CATCHES_PERCENTAGE_VALUE_KEY_PREFIX}${insectId}` as keyof VV.DomainTypes.TrapCatchesInsect.TValuesReturnWithMetaIgnoringKeying['regions']
}

export const getDegreeDayValueTypeIdForInsectId = (insectId: number) => {
  return `${INSECT_DEGREE_DAY_VALUE_KEY_PREFIX}${insectId}` as keyof VV.DomainTypes.DegreeDaysInsect.TValuesReturnWithMetaIgnoringKeying['properties']
}
