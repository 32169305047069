import { makeBaseSvgIconProps } from './_utils/makeBaseSvgIconProps'

export const IconDot = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 36 36')}>
      <path
        d="M0,18 C0,27.9411255 8.0588745,36 18,36 C27.9411255,36 36,27.9411255 36,18 C36,8.0588745 27.9411255,0 18,0 C8.0588745,0 0,8.0588745 0,18"
        fill="currentColor"
      />
    </svg>
  )
}
