import { translate } from 'i18n/i18n'
import { isNil } from 'lodash'
import type { TUnitConverterOptionsWithoutUnits, TUnitConverterTypicalReturn } from '../types'

const DEFAULT_DECIMAL_PLACES_DAILY_TRV = 0

const fireBlightMaker =
  (isDaily: boolean) =>
  (
    trvValue: number | null = null,
    { decimalPlaces = DEFAULT_DECIMAL_PLACES_DAILY_TRV }: TUnitConverterOptionsWithoutUnits = {},
  ): TUnitConverterTypicalReturn => {
    let convertedValue: number | null

    if (isNil(trvValue)) {
      convertedValue = null
    } else {
      convertedValue = trvValue
    }

    const label = translate.phrases.banyanApp('Fire Blight')
    const unitSymbol = isDaily ? translate.phrases.banyanApp('Max TRV') : translate.phrases.banyanApp('TRV')

    const labelAndUnit = translate.phrases.templates('{{labelA}} {{labelB}}', {
      labelA: label,
      labelB: unitSymbol,
    })

    return {
      categoryTitle: () => labelAndUnit,
      categoryTitleWithoutUnit: () => label,
      defaultNumberOfDecimalPlaces: () => DEFAULT_DECIMAL_PLACES_DAILY_TRV,
      shortTitle: () => unitSymbol,
      suffix: () => '',
      title: () =>
        translate.phrases.templates('{{labelA}} ({{labelB}})', {
          labelA: label,
          labelB: isDaily
            ? translate.phrases.banyanApp('Max Daily Temperature Risk Value')
            : translate.phrases.banyanApp('Temperature Risk Value'),
        }),
      titleWithoutUnit: () => label,
      value: () => {
        if (isNil(convertedValue)) return null

        return +convertedValue?.toFixed(decimalPlaces)
      },
      valueAsString: () => {
        return translate.measurements.diseaseSeverityValue.value(convertedValue, decimalPlaces)
      },
      valueWithSuffix: () => {
        return translate.measurements.diseaseSeverityValue.value(convertedValue, decimalPlaces)
      },
      valueWithNoRounding: () => convertedValue,
    }
  }

export const fireBlight = {
  fireBlight: fireBlightMaker(false),
  fireBlightDaily: fireBlightMaker(true),
}
