import type { routes } from '@semios/app-platform-banyan-route-definitions'
import { apiFetch } from 'utils/apiFetch'
import { parse as parseWKT } from 'wellknown'
import { serviceCenterStore } from '../../store/serviceCenterStore'
import type { TActiveGateway, TNodeType } from '../../types'
import { getIdentifier } from '../getIdentifier'

export type PlannedGatewayInstallMeta = routes.ServiceCenterPlannedGatewayInstall.Request

const request = async (meta: PlannedGatewayInstallMeta): Promise<PlannedGatewayInstallMeta> => {
  await apiFetch({
    url: '/service-center-planned-gateway-install',
    body: meta,
  })

  return meta
}

const callback = (meta: PlannedGatewayInstallMeta) => {
  const activeNodes = serviceCenterStore.selectors.getActiveNodes(serviceCenterStore.getState())

  const updatedActiveNodes = [...activeNodes].filter(
    (node) => getIdentifier(node) !== meta.node.gatewayIdentifier,
  )

  const activeGateway: TActiveGateway = {
    ...meta,
    name: meta.node.name || null,
    maintenanceOwnerId: meta.node.maintenanceOwnerId,
    gatewayIdentifier: meta.node.gatewayIdentifier,
    gatewayType: 'ln_gtwy' as TNodeType,
    propertyIds: [meta.node.propertyId],
    // Transform string WKT to serialized GeoJSON
    location: JSON.stringify(parseWKT(meta.node.location as string)),
  }

  updatedActiveNodes.push(activeGateway)

  serviceCenterStore.actions.setActiveNodes(updatedActiveNodes)

  // Remove installed planned node
  serviceCenterStore.actions.removePlannedNodes([`${meta.pseudogatewayId}`])
}

export const serviceCenterPlannedGatewayInstall = {
  request,
  callback,
}
