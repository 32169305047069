import { useMantineTheme } from '@mantine/core'
import { notifications } from '@mantine/notifications'
import { IconNetworkOffline } from 'components/icons/IconNetworkOffline'
import { IconSuccess } from 'components/icons/IconSuccess'
import { IconWarning } from 'components/icons/IconWarning'
import { translate } from 'i18n/i18n'
import { NetworkStatusNotification, networkStore } from '../store/networkStore'
import { apiRequestQueueStore } from '../utils/api/queue/apiRequestQueueStore'

export const useNetworkStatusBanner = () => {
  const theme = useMantineTheme()

  const networkStatusNotification = networkStore.useSelector(
    networkStore.selectors.getNetworkStatusNotification,
  )

  const uncompletedRequests = apiRequestQueueStore.useSelector(
    apiRequestQueueStore.selectors.getUncompletedRequests,
  )

  const failedRequests = apiRequestQueueStore.useSelector(apiRequestQueueStore.selectors.getFailedRequests)

  if (!networkStatusNotification) {
    notifications.hide('network-status')

    return
  }

  let icon
  let iconColor
  let title
  let message

  if (networkStatusNotification === NetworkStatusNotification.OFFLINE) {
    icon = <IconNetworkOffline />

    iconColor = theme.colors.red[0]

    title = translate.phrases.placeholder('Low Connectivity')

    message = uncompletedRequests.length
      ? translate.phrases.placeholder(
          'Requests will be submitted once reconnected ({{requestCount}} pending requests)',
          {
            requestCount: uncompletedRequests.length.toString(),
          },
        )
      : translate.phrases.placeholder('You may have limited capabilities.')
  } else if (networkStatusNotification === NetworkStatusNotification.UPLOADING) {
    message = translate.phrases.placeholder('Uploading offline activity to the server')
  } else if (networkStatusNotification === NetworkStatusNotification.SYNC_SUCCESS) {
    icon = <IconSuccess />

    iconColor = theme.colors.green[0]

    message = translate.phrases.placeholder('Offline synchronization completed')
  } else if (networkStatusNotification === NetworkStatusNotification.SYNC_ERROR) {
    icon = <IconWarning />

    iconColor = theme.colors.red[0]

    title = translate.phrases.placeholder('Synchronization Error')

    message = translate.phrases.placeholder('{{requestCount}} requests could not be uploaded', {
      requestCount: failedRequests.length.toString(),
    })
  }

  notifications.show({
    id: 'network-status',
    title: title,
    message: message,
    loading: networkStatusNotification === NetworkStatusNotification.UPLOADING,
    icon: !!icon && (
      <span
        css={{
          display: 'flex',
          alignContent: 'center',
          alignItems: 'center',
          fontSize: 28,
          color: iconColor,
          backgroundColor: theme.colors.white[0],
        }}
      >
        {icon}
      </span>
    ),
    autoClose: false,
    withCloseButton: networkStatusNotification !== NetworkStatusNotification.UPLOADING,
    onClose: () => networkStore.actions.hideNetworkStatusNotification(),
  })
}
