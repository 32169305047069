import { isEmpty } from '@semios/app-platform-common'
import { Button } from 'components/Button/Button'
import { Checkbox } from 'components/Checkbox/Checkbox'
import { SearchTextInput } from 'components/SearchTextInput/SearchTextInput'
import { translate } from 'i18n/i18n'
import React, { useEffect, useState } from 'react'
import { colors } from 'settings/colors'
import { getLabel } from '../historicalRisk'
import type { BlockWithRiskHistory } from './fireBlightSettings'

export const useBlocksFilter = ({
  blocks,
  loading,
}: {
  blocks: BlockWithRiskHistory[]
  loading: boolean
}) => {
  const [searchText, setSearchText] = useState('')
  const [filteredBlocks, setFilteredBlocks] = useState<BlockWithRiskHistory[]>(blocks)
  const [selectedBlocks, setSelectedBlocks] = useState<BlockWithRiskHistory[]>([])

  useEffect(() => {
    setFilteredBlocks(() => blocks)
  }, [blocks.length, loading])

  useEffect(() => {
    if (isEmpty(searchText)) {
      setFilteredBlocks(() => blocks)
    } else {
      setFilteredBlocks(() =>
        blocks.filter((block) => block.name?.toLowerCase().includes(searchText.toLowerCase())),
      )
    }
  }, [searchText])

  const handleSelectAll = () => {
    setSelectedBlocks(() => [...filteredBlocks])
  }

  const handleClearAll = () => {
    setSelectedBlocks(() => [])
  }

  const reset = () => {
    setSearchText('')

    setSelectedBlocks([])
  }

  const FilterComponent = (
    <div css={{ display: 'flex', gap: '20px', flexWrap: 'wrap', flexDirection: 'column' }}>
      <SearchTextInput
        placeholder={translate.phrases.banyanApp('Search Block Name')}
        searchText={searchText}
        setSearchText={setSearchText}
        clearable
      />
      <div css={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button variant="link" onClick={handleSelectAll}>
          {translate.phrases.banyanApp('Select All')}
        </Button>
        <div css={{ display: 'flex', alignItems: 'center' }}>
          <div
            css={{
              borderLeft: '1px solid black',
              height: 22,
            }}
          />
        </div>
        <Button variant="link" onClick={handleClearAll}>
          {translate.phrases.banyanApp('Clear All')}
        </Button>
      </div>
      <div
        css={{
          display: 'grid',
          gridTemplateRows: '1fr',
          gridTemplateColumns: 'fit-content(50%) 1fr', // fit the content up to a max of 50%
          gap: '5px 15px',
          alignItems: 'center',
        }}
      >
        {filteredBlocks.map((block) => (
          <React.Fragment key={block.blockId}>
            <Checkbox
              label={block.name}
              checked={selectedBlocks.some((selectedBlock) => selectedBlock.blockId === block.blockId)}
              onChange={(e) => {
                if (e.target.checked) {
                  setSelectedBlocks([...selectedBlocks, block])
                } else {
                  setSelectedBlocks(
                    selectedBlocks.filter((selectedBlock) => selectedBlock.blockId !== block.blockId),
                  )
                }
              }}
            />
            <span css={{ color: colors.grey500, fontSize: 'small', alignSelf: 'center' }}>
              {getLabel(block.riskId)}
            </span>
          </React.Fragment>
        ))}
      </div>
    </div>
  )

  return {
    FilterComponent,
    selectedBlocks,
    reset,
  }
}
