import { BleClient } from '@capacitor-community/bluetooth-le'
import { DecoderMap, EncoderMap, ReadTimeout, writeAndNotifyTimeout, WriteTimeout } from '../constants'
import { BleDecoderInvalidPayloadError, BleDecoderNotFoundError } from '../errors'
import type { DtoConstructor } from '../types'
import { DTO } from '../types'
import { withTimeout } from '../util/utility'

export abstract class SemiosBleNode {
  readonly deviceId: string
  readonly nodeId: string
  readonly nodeType: string
  readonly notfications: Map<DtoConstructor, DTO>
  readonly listeners: Map<DtoConstructor, { timestamp: number; callback: () => void }>

  getNotification<T extends DTO>(t: new () => T): T | null {
    return this.notfications.get(t) as T | null
  }

  async read<T extends DTO>(t: new () => T): Promise<T> {
    const decoder = DecoderMap.get(t)

    if (!decoder) {
      throw new BleDecoderNotFoundError(t)
    }

    const data = await BleClient.read(this.deviceId, decoder.uuid.service, decoder.uuid.characteristic, {
      timeout: ReadTimeout,
    })

    return decoder.decode(data)
  }

  registerListener<T extends DTO>(t: new () => T, listener: () => void) {
    this.listeners.set(t, { timestamp: Date.now(), callback: listener })
  }

  unregisterListener<T extends DTO>(t: new () => T) {
    this.listeners.delete(t)
  }

  async writeAndNotify<T extends DTO, R extends DTO>(t: T, r: new () => R): Promise<R> {
    const res = new Promise<R>((resolve) => {
      this.registerListener(r, () => {
        this.unregisterListener(r)

        const notification = this.getNotification(r)

        if (notification) resolve(notification)
      })
    })

    await this.write(t)

    return await withTimeout(res, writeAndNotifyTimeout)
  }

  async write<T extends DTO>(t: T): Promise<void> {
    if (!(t instanceof DTO)) {
      throw new BleDecoderInvalidPayloadError(t)
    }

    const encoder = EncoderMap.get(t.constructor as new () => T)

    if (!encoder) {
      throw new BleDecoderNotFoundError(t)
    }

    await BleClient.write(
      this.deviceId,
      encoder.uuid.service,
      encoder.uuid.characteristic,
      encoder.encode(t),
      {
        timeout: WriteTimeout,
      },
    )
  }

  async initialize(): Promise<void> {
    return Promise.resolve()
  }

  constructor(
    deviceId: string,
    nodeId: string,
    nodeType: string,
    notfications: Map<DtoConstructor, DTO>,
    listeners: Map<DtoConstructor, { timestamp: number; callback: () => void }>,
  ) {
    this.deviceId = deviceId

    this.nodeId = nodeId

    this.nodeType = nodeType

    this.notfications = notfications

    this.listeners = listeners
  }
}
