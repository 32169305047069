import { Loader } from '@mantine/core'
import type { SharedTypes } from '@semios/app-platform-banyan-route-definitions'
import { NoDataProperty } from 'App/Map/UserSettingsMenu/components/NoDataProperty'
import { SettingsSearchNotFound } from 'App/Map/UserSettingsMenu/components/SettingsSearchNotFound/SettingsSearchNotFound'
import { translate } from 'i18n/i18n'
import { searchStringFromStringWithSpaces } from 'utils/searchStringFromStringWithSpaces'
import type { TFertigationSettingsData, TMappedFertilizers } from '../../types'
import { PropertySection } from './PropertySection'

export const FertilizersTab = ({
  data,
  filterPropertiesText,
  loading,
  properties,
  propertiesWithEditPermission,
}: {
  data: TFertigationSettingsData[]
  filterPropertiesText: string
  loading: boolean
  properties: SharedTypes.TProperty[]
  propertiesWithEditPermission: number[]
}) => {
  const fertigationPropertyIds = data.map((d) => d.propertyId) ?? []

  const fertilizerDictionary = data.reduce<Record<string, TFertigationSettingsData['fertilizerSettings'][0]>>(
    (acc, curr) => {
      curr.fertilizerSettings.forEach((f) => {
        acc[f.fertilizerId] = f
      })

      return acc
    },
    {},
  )

  const mappedFertilizerData: TMappedFertilizers[] = data.flatMap((d) => {
    const foundProperty = properties.find((p) => p.propertyId === d.propertyId)

    if (!foundProperty) {
      return []
    }

    return {
      propertyId: d.propertyId,
      propertyName: foundProperty?.propertyName ?? '',
      farmId: d.farmId,
      injectors: d.injectorSettings.map((injector) => {
        return {
          externalTankId: injector.externalTankId,
          tankName: injector.tankName,
          tankId: injector.tankId,
          fertilizers: injector.fertilizerIds
            .map((f) => {
              const fertilizer = fertilizerDictionary[f]

              return {
                fertilizerName:
                  fertilizer.fertilizerName ?? translate.phrases.banyanApp('Unknown Fertilizer'), // it's possible fertilizerId can become undefined in property settings if removed in DropControl
                tankName: injector.tankName,
                injectorId: injector.tankId,
              }
            })
            .filter((fertilizer) => {
              return (
                searchStringFromStringWithSpaces(filterPropertiesText, fertilizer.fertilizerName) ||
                searchStringFromStringWithSpaces(filterPropertiesText, injector.tankName)
              )
            }),
        }
      }),
    }
  })

  const propertiesWithoutFertigationSettings = properties.filter(
    (p) => !fertigationPropertyIds.includes(p.propertyId),
  )

  const hasSearchResults =
    filterPropertiesText &&
    mappedFertilizerData.every((property) =>
      property.injectors.every((injector) => injector.fertilizers.length === 0),
    )

  return (
    <div css={{ display: 'flex', flexDirection: 'column' }}>
      {loading ? (
        <div css={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
          <Loader />
        </div>
      ) : (
        <div>
          {!hasSearchResults ? (
            mappedFertilizerData.map((property) => {
              return property.injectors.every((injector) => injector.fertilizers.length === 0) ? null : (
                <PropertySection
                  key={property.propertyId}
                  property={property}
                  hasEditPermission={propertiesWithEditPermission.includes(property.propertyId)}
                />
              )
            })
          ) : (
            <SettingsSearchNotFound />
          )}
          {!filterPropertiesText &&
            propertiesWithoutFertigationSettings.map(
              (property) =>
                property && (
                  <NoDataProperty
                    key={property.propertyId}
                    title={property.propertyName}
                    noDataText={translate.phrases.banyanApp(
                      'There are no fertilizers or chemicals configured for your property. Please contact Semios if you wish to add any.',
                    )}
                  />
                ),
            )}
        </div>
      )}
    </div>
  )
}
