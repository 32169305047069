import { Accordion } from '@mantine/core'
import { serviceCenterStore } from 'App/ServiceCenter/store/serviceCenterStore'
import { getNodeTypeLabel } from 'App/ServiceCenter/utils/getNodeTypeLabel'
import { translate } from 'i18n/i18n'
import React from 'react'
import { selectedFieldAssetsStore } from '../../../../../stores/selectedFieldAssetsStore'
import { equipmentStatusPanelStore } from '../../../store/equipmentStatusPanelStore'
import type { TNodeType } from '../../../types'

const ServiceIssueSummary = () => {
  const serviceIssuesByEquipmentStatusPanel = equipmentStatusPanelStore.useSelector(
    equipmentStatusPanelStore.selectors.getServiceIssues,
  )

  const serviceIssuesByMapPropertyId = serviceCenterStore.useSelector((s) => s.serviceIssues)

  const selectedPropertyId = equipmentStatusPanelStore.useSelector(
    equipmentStatusPanelStore.selectors.getPropertyId,
  )

  const selectedMapPropertyId = selectedFieldAssetsStore.useSelector((s) => s.property)

  const dataSource =
    selectedPropertyId === selectedMapPropertyId
      ? serviceIssuesByMapPropertyId
      : serviceIssuesByEquipmentStatusPanel

  const shapedServiceIssues = React.useMemo(() => {
    const results: { [nodeType: string]: { [issueTitle: string]: number } } = {}

    Object.values(dataSource).forEach((serviceIssue) => {
      serviceIssue.forEach((issue) => {
        const { nodeType, nodeServiceIssueTypeTitle } = issue

        // Group by node type and issue title
        if (!results[nodeType]) {
          results[nodeType] = { [nodeServiceIssueTypeTitle]: 1 }
        } else if (!results[nodeType][nodeServiceIssueTypeTitle]) {
          results[nodeType][nodeServiceIssueTypeTitle] = 1
        } else {
          results[nodeType][nodeServiceIssueTypeTitle]++
        }
      })
    })

    return results
  }, [dataSource])

  if (Object.keys(shapedServiceIssues).length === 0) {
    return <div style={{ padding: 8 }}>{translate.phrases.placeholder('No service issues found')}</div>
  }

  return (
    <Accordion
      multiple={true}
      variant="default"
      chevronPosition="right"
      chevronSize={20}
      styles={{
        chevron: {
          scale: '180%',
        },
        content: { paddingTop: 0, paddingBottom: 0 },
      }}
    >
      {Object.entries(shapedServiceIssues).map(([nodeType, issues]) => {
        const issueTotalCountByNodeType = Object.values(issues).reduce((acc, count) => acc + count, 0)

        return (
          <Accordion.Item key={nodeType} value={nodeType}>
            <Accordion.Control
              style={{
                backgroundColor: '#E2E2E2',
                color: 'black',
                transform: 'translateX(-1px)',
                fontWeight: 'bold',
                height: '30px',
                fontSize: '14px',
              }}
            >
              <span style={{ width: 70, display: 'inline-block' }}>{`${getNodeTypeLabel(
                nodeType as TNodeType,
              )}`}</span>
              <span>{`(${issueTotalCountByNodeType})`}</span>
            </Accordion.Control>
            <Accordion.Panel>
              {Object.entries(issues).map(([issueTitle, count]) => (
                <div style={{ marginTop: 8, marginBottom: 8 }} key={issueTitle}>
                  {issueTitle} <span>{`(${count})`}</span>
                </div>
              ))}
            </Accordion.Panel>
          </Accordion.Item>
        )
      })}
    </Accordion>
  )
}

export default ServiceIssueSummary
