import type { TMonthIndexNumber } from '@semios/app-platform-i18n-core'
import { translate } from 'i18n/i18n'
import { groupBy } from 'lodash'
import moment from 'moment-timezone'
import type { FC } from 'react'
import { getScreenSize } from 'utils/getScreenSize'
import type { BiofixYearBarPropType } from '../types'
import { Month } from './Month'

export const BiofixYearBar: FC<BiofixYearBarPropType> = ({
  biofixConfigOption,
  biofixDate,
  biofixDates,
  deleteBiofixes,
  editBiofixDate,
  isBlockSelected,
  loading,
  year,
  timezone,
}) => {
  const monthIndexes: TMonthIndexNumber[] = Array.from({ length: 12 }, (_, i) => i as TMonthIndexNumber)

  const biofixDatesGroupedByMonthIndex = groupBy(biofixDates || [], (biofixDate) => {
    return moment.tz(biofixDate, timezone).month()
  })

  const { isWideScreen } = getScreenSize()

  return (
    <div css={{ height: '50px', position: 'relative' }}>
      <div
        className={loading ? 'summary-table-cell-loading' : ''}
        css={{
          display: 'flex',
          justifyContent: 'space-evenly',
          height: '30px',
          padding: '0 3px',
          position: 'absolute',
          minWidth: '100%',
          zIndex: `${loading ? 10 : 0}`,
          paddingRight: isWideScreen ? '3px' : '10px',
        }}
      >
        {monthIndexes.map((monthIndex) => (
          <Month
            biofixConfigOption={biofixConfigOption}
            biofixes={biofixDatesGroupedByMonthIndex[monthIndex] || []}
            deleteBiofixes={deleteBiofixes}
            editBiofixDate={editBiofixDate}
            isBlockSelected={isBlockSelected}
            key={monthIndex}
            isLast={monthIndexes.length - 1 === monthIndex}
            loading={loading}
            monthIndex={monthIndex}
            selectedBiofixDate={biofixDate}
            text={translate.dates.monthShortLabel(monthIndex)}
            timezone={timezone}
            year={year}
          />
        ))}
      </div>
    </div>
  )
}
