import { translate } from 'i18n/i18n'
import { userDetailsStore } from 'stores/userDetailsStore'

export const useEmitterConfigurationUnit = (): {
  unit: string
  isImperial: boolean
  dbUnitPerHour: 'INCH' | 'MILLIMETER'
  emitterFlowRateUnit: string
  rowSpacingUnit: string
  min: number
  precision: number
} => {
  const rainUnit = userDetailsStore.useSelector((s) => s.rain)

  if (rainUnit === 'IMPERIAL') {
    return {
      unit: translate.measurements.inchesPerHour.unit(),
      emitterFlowRateUnit: translate.measurements.gallonsPerHour.unit(),
      rowSpacingUnit: translate.measurements.feet.unit(),
      dbUnitPerHour: 'INCH',
      isImperial: true,
      min: 0.001,
      precision: 3,
    }
  }

  return {
    unit: translate.measurements.millimetersPerHour.unit(),
    emitterFlowRateUnit: translate.measurements.litersPerHour.unit(),
    rowSpacingUnit: translate.measurements.meters.unit(),
    dbUnitPerHour: 'MILLIMETER',
    isImperial: false,
    min: 0.01,
    precision: 2,
  }
}
