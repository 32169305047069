import type { routes } from '@semios/app-platform-banyan-route-definitions'
import { isEmpty } from '@semios/app-platform-common'
import type { VV } from '@semios/app-platform-value-type-definitions'
import { VIEW_PESTS_TRAP_CATCHES_ID_insectId } from '@semios/app-platform-value-type-definitions'
import { DropdownSelectorProperty } from 'App/Map/PanelDetails/SectionTitleBars/DropdownSelectorProperty/DropdownSelectorProperty'
import { propertyLacksPermissionSectionMaker } from 'App/Map/PanelDetails/_utils/propertyLacksPermissionSectionMaker'
import { regionLacksPermissionSectionMaker } from 'App/Map/PanelDetails/_utils/regionLacksPermissionSectionMaker'
import { selectedPropertyHasPermission } from 'App/Map/PanelDetails/_utils/selectedPropertyHasPermission'
import { selectedRegionHasPermission } from 'App/Map/PanelDetails/_utils/selectedRegionHasPermission'
import type { TPestSectionCategory } from 'App/Map/PanelDetails/_utils/sortPestSections'
import type { TFieldAssetValueTypes } from 'App/Map/types'
import { EAggregationInterval } from 'App/Map/types'
import type { GridTableContentPestSection, GridTableContentSectionItem } from 'components/GridTable/types'
import { translate } from 'i18n/i18n'
import { filter, intersection } from 'lodash'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import type { TSelectedFieldAssetsStoreState } from 'stores/selectedFieldAssetsStore'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import type {
  selectedValueGroupsStore,
  TValueGroup,
} from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import {
  doesSelectedPropertyHaveValueTypes,
  doesSelectedRegionHaveValueTypes,
} from 'utils/doesSelectedFieldAssetHaveValueTypes'
import { filterFieldAssetsByValueTypes } from 'utils/filterFieldAssetsByValueTypes'
import {
  INSECT_TRAP_CATCHES_PERCENTAGE_VALUE_KEY_PREFIX,
  INSECT_TRAP_CATCHES_VALUE_KEY_PREFIX,
} from 'utils/insectRequestValueKeyPrefix'
import { isUserOnlyAFreeRegionalUser } from 'utils/isUserOnlyAFreeRegionalUser'
import { sortByKey } from 'utils/sortByKey'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import { isRegionDataEnabled } from 'utils/useIsRegionDataEnabled'
import {
  calTotalSectionHeight,
  SummaryTableHierarchicalContent,
  SummaryTableHierarchicalLabel,
} from '../../../_utils/by-domain/_utils/hierarchicalUtils'

const checkPermission = (insectId: number) =>
  selectedPropertyHasPermission({ permission: VIEW_PESTS_TRAP_CATCHES_ID_insectId(insectId) }) ||
  (isUserOnlyAFreeRegionalUser() &&
    selectedRegionHasPermission({ permission: VIEW_PESTS_TRAP_CATCHES_ID_insectId(insectId) }))

type TPoint = TFieldAssetValueTypes.TPoint

type TBlock = TFieldAssetValueTypes.TBlock

const preferredAggregationInterval = { preferredAggregationInterval: EAggregationInterval.DAILY } as const

const getValueTypeIdForInsectId = (insectId: number) => {
  return `${INSECT_TRAP_CATCHES_VALUE_KEY_PREFIX}${insectId}` as keyof VV.DomainTypes.TrapCatchesInsect.TValuesReturnWithMetaIgnoringKeying['points']
}

const getRegionalValueTypeIdForInsectId = (insectId: number) => {
  return `${INSECT_TRAP_CATCHES_PERCENTAGE_VALUE_KEY_PREFIX}${insectId}` as keyof VV.DomainTypes.TrapCatchesInsect.TValuesReturnWithMetaIgnoringKeying['regions']
}

const getValuesRequestedForRegions = ({
  selectedValueGroups,
  selectedFieldAssets,
}: {
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}) => {
  const selectedRegion = selectedFieldAssets.region

  if (!selectedRegion) return {}

  const { valuesRequested } = getValuesRequestedAndInsectIds(
    selectedValueGroups,
    getRegionalValueTypeIdForInsectId,
  )

  if (Object.keys(valuesRequested).length === 0) return {}

  if (
    isEmpty(valuesRequested) ||
    !doesSelectedRegionHaveValueTypes({ valuesTimeseries: Object.keys(valuesRequested) })
  ) {
    return {}
  }

  return {
    regions: {
      regionIds: [selectedRegion],
      valuesRequested,
    },
  }
}

const getValuesRequestedForPoints = ({
  selectedValueGroups,
  selectedFieldAssets,
}: {
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}) => {
  if (!selectedFieldAssets.property) return {}

  const { valuesRequested, insectIds } = getValuesRequestedAndInsectIds(
    selectedValueGroups,
    getValueTypeIdForInsectId,
  )

  const { property: selectedProperty } = selectedFieldAssets

  const pointsOfProperty = getAssociatedPoints({
    properties: fieldAssetStore.getState().properties,
    propertyId: selectedProperty,
    insectIds,
  })

  if (Object.keys(valuesRequested).length === 0) return {}

  const valuesToBeRequested: Partial<routes.Values.Request> = {}

  if (
    Object.keys(valuesRequested).length &&
    doesSelectedPropertyHaveValueTypes({ valuesTimeseries: Object.keys(valuesRequested) })
  )
    valuesToBeRequested.points = {
      lngLats: pointsOfProperty.map(({ lngLat }) => {
        return lngLat
      }),
      valuesRequested,
    }

  return valuesToBeRequested
}

export const apiArgs = ({
  selectedValueGroups,
  selectedFieldAssets,
}: {
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}): Partial<routes.Values.Request> => {
  const { insectIds } = getValuesRequestedAndInsectIds(selectedValueGroups)
  const showRegionalData = isRegionDataEnabled()

  const hasPermission = insectIds.reduce((result, currentValue) => {
    return checkPermission(currentValue) ? result + 1 : result
  }, 0)

  if (!hasPermission) return {}

  const pointsToRequest = getValuesRequestedForPoints({ selectedValueGroups, selectedFieldAssets })

  const regionsToRequest = showRegionalData
    ? getValuesRequestedForRegions({
        selectedValueGroups,
        selectedFieldAssets,
      })
    : {}

  return { ...pointsToRequest, ...regionsToRequest }
}

const transpose = (matrix: { timestamp: string; value: number | null }[][]) => {
  return matrix[0]?.map((_, colIndex) => matrix?.map((row) => row[colIndex]))
}

export const content = ({
  data,
  selectedFieldAssets,
  selectedValueGroups,
}: {
  data: routes.Values.Response
  selectedFieldAssets: TSelectedFieldAssetsStoreState
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
}): GridTableContentPestSection[] => {
  const { insects, properties, regions } = fieldAssetStore.getState()
  const selectedProperty = selectedFieldAssetsStore.getState().property
  const selectedRegion = selectedFieldAssets.region
  const pestSectionCategory: TPestSectionCategory = 'trap-catches-property-level'
  const showRegionalData = isRegionDataEnabled()
  const isUserFreeUser = isUserOnlyAFreeRegionalUser()

  if (!insects || (!selectedProperty && !selectedRegion)) return []

  const { insectIds } = getValuesRequestedAndInsectIds(selectedValueGroups)

  const pointsOfProperty = getAssociatedPoints({
    properties,
    propertyId: selectedProperty,
    insectIds,
  })

  const blocksOfProperty = getAssociatedBlocks({
    properties,
    propertyId: selectedProperty,
    insectIds,
  })

  const trapCatchesContent: GridTableContentPestSection[] = Object.values(insects)
    .filter((insect) => selectedValueGroups[`trap_catches_insect_id_${insect.insectId}` as TValueGroup])
    .map(({ insectId }) => {
      const valueTypeKey = getValueTypeIdForInsectId(insectId)

      const commonReturnItems = {
        title: unitConverter.insectTrapCatches(null, { insectId }).titleWithoutUnit(),
        titleChildren: <DropdownSelectorProperty valuesTimeseriesToFilterOn={[valueTypeKey]} />,
        id: `summary-grid-insect-trap-catches-insect-id-${insectId}-${selectedProperty ?? selectedRegion}`,
        pestSectionCategory,
        insectId,
      }

      const hasInsectPermission = checkPermission(insectId)

      if (!hasInsectPermission && !isUserFreeUser) {
        return { ...propertyLacksPermissionSectionMaker(commonReturnItems), pestSectionCategory, insectId }
      }

      if (!hasInsectPermission && isUserFreeUser) {
        return { ...regionLacksPermissionSectionMaker(commonReturnItems), pestSectionCategory, insectId }
      }

      const trapCatchRows = data?.points ? data.points : {}

      const associatedBlocks = blocksOfProperty.filter((block) =>
        block.valuesTimeseries.includes(`insectTrapCatches_${insectId}`),
      )

      const targetWidth = 220

      // get all points that are traps for current insect
      // group by block (or out of block)

      const associatedPoints = filterFieldAssetsByValueTypes({
        fieldAssets: pointsOfProperty,
        valuesTimeseries: [`insectTrapCatches_${insectId}`],
      })

      const pointsByBlock = associatedPoints.reduce((result, point) => {
        const blockId = point.blockIds?.[0]

        result[blockId ?? -1] = result[blockId ?? -1] || []

        result[blockId ?? -1].push(point)

        return result
      }, {} as Record<number, TPoint[]>)

      const items = Object.keys(pointsByBlock)
        .map((blockIdStr) => {
          const blockId = +blockIdStr
          const points = pointsByBlock[blockId]

          const associatedValues = points.sort(sortByKey('name')).map((point) => {
            const value = trapCatchRows[point.lngLat]?.values[valueTypeKey]

            if (!value) {
              return []
            }

            const { timeseries } = value[0]

            return timeseries.map((dataPoint) => {
              return {
                timestamp: dataPoint.timestamp,
                value: dataPoint.value,
                name: point.name,
              }
            })
          })

          const transposedValues = transpose(associatedValues)

          const finalValues = transposedValues?.reduce((result, currentValue) => {
            return {
              ...result,
              [currentValue[0].timestamp]: currentValue,
            }
          }, {})

          const block = blockId > 0 ? associatedBlocks.find((block) => block.blockId === blockId) : null
          const blockName = block?.name
          const mainTitle = blockName ?? ''

          const indentedTitles = points.map((point) => ({
            key: point.lngLat,
            name: point.name,
          }))

          const { height, labelMinWidth, valueMinWidth } = calTotalSectionHeight(
            mainTitle,
            indentedTitles,
            targetWidth,
          )

          return {
            id: `${blockName} ${blockId}`,
            label: <SummaryTableHierarchicalLabel mainTitle={mainTitle} indentedTitles={indentedTitles} />,
            height,
            labelMinWidth,
            valueMinWidth,
            render: (dataPoints: { timestamp: string; value: number | null; name: string }[] | undefined) => {
              if (!dataPoints) {
                return <></>
              }

              return <SummaryTableHierarchicalContent mainTitle={mainTitle} indentedDataPoints={dataPoints} />
            },
            values: finalValues,
          }
        })
        .sort(sortByKey('id')) as GridTableContentSectionItem[]

      const trapCatchRegionalRow = data?.regions ? data.regions : {}

      let regionalFinalValues = {}

      const regionalValueTypeKey = getRegionalValueTypeIdForInsectId(insectId)

      if (Object.keys(trapCatchRegionalRow).length) {
        const regionalData = Object.values(trapCatchRegionalRow)[0]
        const regionalTimeseries = regionalData.values[regionalValueTypeKey]

        if (regionalTimeseries) {
          regionalTimeseries[0].timeseries.forEach((timeseries) => {
            const timeseriesValue = unitConverter.insectTrapCatches(timeseries.value).value()

            regionalFinalValues = {
              ...regionalFinalValues,
              [timeseries.timestamp]: [
                {
                  ...timeseries,
                  value: timeseriesValue !== null ? `${timeseriesValue} %` : '-',
                  name: translate.phrases.banyanApp('Trap Catches Percentage'),
                },
              ],
            }
          })
        }
      }

      const mainTitle =
        regions && selectedRegion && regions[selectedRegion] ? regions[selectedRegion].name : ''

      const indentedTitles = [
        {
          key: 'insectTrapCatches_regional_percentage',
          name: translate.phrases.banyanApp('Trap Catches Percentage'),
        },
      ]

      const { height, labelMinWidth, valueMinWidth } = calTotalSectionHeight(
        mainTitle,
        indentedTitles,
        targetWidth,
      )

      const regionalItem = {
        id:
          regions && selectedRegion && regions[selectedRegion]
            ? `${regions[selectedRegion].name} ${selectedRegion}`
            : 'unknown region',
        label: <SummaryTableHierarchicalLabel mainTitle={mainTitle} indentedTitles={indentedTitles} />,
        height,
        labelMinWidth,
        valueMinWidth,
        render: (dataPoints: { timestamp: string; value: number | null; name: string }[] | undefined) => {
          if (!dataPoints) {
            return <></>
          }

          return <SummaryTableHierarchicalContent mainTitle={mainTitle} indentedDataPoints={dataPoints} />
        },
        values: regionalFinalValues,
      }

      return {
        ...commonReturnItems,
        items:
          showRegionalData &&
          doesSelectedRegionHaveValueTypes({
            valuesTimeseries: [regionalValueTypeKey],
          })
            ? [...items, regionalItem]
            : items,
      }
    })
    .sort(sortByKey('title'))

  return trapCatchesContent
}

const getValuesRequestedAndInsectIds = (
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups'],
  getValueTypeId?: (
    insectId: number,
  ) =>
    | keyof VV.DomainTypes.TrapCatchesInsect.TValuesReturnWithMetaIgnoringKeying['regions']
    | keyof VV.DomainTypes.TrapCatchesInsect.TValuesReturnWithMetaIgnoringKeying['points'],
): {
  valuesRequested: Partial<
    Record<
      VV.DomainTypes.TrapCatchesInsect.TTimeseriesValueTypeKeysMerged,
      typeof preferredAggregationInterval
    >
  >
  insectIds: number[]
} => {
  const valuesRequested: Partial<
    Record<
      VV.DomainTypes.TrapCatchesInsect.TTimeseriesValueTypeKeysMerged,
      typeof preferredAggregationInterval
    >
  > = {}

  let insectIds: number[] = []

  Object.entries(selectedValueGroups).forEach(([valueGroup, isActive]) => {
    if (!!isActive && valueGroup.includes('trap_catches_insect_id_')) {
      const insectId = Number(valueGroup.split('_').slice(-1)[0])

      if (!checkPermission(insectId)) return

      insectIds.push(insectId)

      if (getValueTypeId) {
        valuesRequested[getValueTypeId(insectId)] = preferredAggregationInterval
      }
    }
  })

  return { valuesRequested, insectIds }
}

const getAssociatedPoints = ({
  properties,
  propertyId,
  insectIds,
}: {
  properties: Record<number, routes.UserAppStartup.TFieldAssetValueTypes.TProperty> | undefined
  propertyId: number | null
  insectIds: number[]
}): TPoint[] => {
  const property = propertyId && properties?.[propertyId]
  const points = property ? Object.values(property['points'] || {}) : []

  return filter<TPoint>(
    points,
    (point) => intersection(point.configuration.trapInsectIds, insectIds)?.length > 0,
  )
}

const getAssociatedBlocks = ({
  properties,
  propertyId,
  insectIds,
}: {
  properties: Record<number, routes.UserAppStartup.TFieldAssetValueTypes.TProperty> | undefined
  propertyId: number | null
  insectIds: number[]
}): TBlock[] => {
  const property = propertyId && properties?.[propertyId]
  const blocks = property ? Object.values(property['blocks'] || {}) : []

  return filter<TBlock>(
    blocks,
    (block) =>
      intersection(
        block.valuesTimeseries,
        insectIds.map((insectId) => `insectTrapCatches_${insectId}`),
      )?.length > 0,
  )
}
