import type { routes } from '@semios/app-platform-banyan-route-definitions'
import type { FireBlightHistoricalRiskDatabaseId } from '@semios/app-platform-common'
import { FIRE_BLIGHT_DEFAULT } from '@semios/app-platform-common'
import type { VV } from '@semios/app-platform-value-type-definitions'
import { EAggregationInterval } from '@semios/app-platform-value-type-definitions'
import { blockLacksCropSectionMaker } from 'App/Map/PanelDetails/_utils/blockLacksCropSectionMaker'
import { FireBlightSettings } from 'App/Map/PanelDetails/_utils/by-domain/fireBlight/fireBlightSettings/fireBlightSettings'
import { riskPlotBands } from 'App/Map/PanelDetails/_utils/by-domain/fireBlight/historicalRisk'
import { propertyLacksPermissionSectionMaker } from 'App/Map/PanelDetails/_utils/propertyLacksPermissionSectionMaker'
import { refreshMapData } from 'App/Map/PanelDetails/_utils/refreshMapData'
import { selectedPropertyHasPermission } from 'App/Map/PanelDetails/_utils/selectedPropertyHasPermission'
import { DropdownSelectorBlock } from 'App/Map/PanelDetails/SectionTitleBars/DropdownSelectorBlock/DropdownSelectorBlock'
import { chooseAmongstUnAggHourlyAndDaily } from 'App/Map/PanelDetails/StackemCharts/_utils/chooseAmongstUnAggHourlyAndDaily'
import { QuestionToolTip } from 'components/QuestionToolTip/QuestionToolTip'
import type { StackedChartSection, TChartSeries } from 'components/StackedChart/types'
import type { SeriesOptionsType } from 'highcharts'
import { translate } from 'i18n/i18n'
import type { TRGBAColorWith1AtTheEnd } from 'settings/colors'
import { colors } from 'settings/colors'
import { detailsPanelStore } from 'stores/detailsPanelStore'
import {
  selectedFieldAssetsStore,
  type TSelectedFieldAssetsStoreState,
} from 'stores/selectedFieldAssetsStore'
import type { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { doesSelectedBlockHaveValueTypes } from 'utils/doesSelectedFieldAssetHaveValueTypes'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import { getXDateFormat } from '../_utils/getXDateFormat'
import { makeCompareSeasonsSeriesFromRegularSeries } from '../_utils/makeCompareSeasonsSeriesFromRegularSeries'

const permission = 'VIEW_FIRE_BLIGHT_DETAILS'
const checkPermission = () => selectedPropertyHasPermission({ permission })

export const defaultValuesRequested: Partial<
  Record<
    VV.DomainTypes.FireBlight.TTimeseriesValueTypeKeysMerged,
    { preferredAggregationInterval: ReturnType<typeof chooseAmongstUnAggHourlyAndDaily> }
  >
> = {
  fireBlightTRV: { preferredAggregationInterval: EAggregationInterval.DAILY },
}

export const apiArgs = ({
  selectedValueGroups,
  selectedFieldAssets,
}: {
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}): Partial<routes.Values.Request> => {
  if (!checkPermission()) return {}

  if (!selectedValueGroups.fire_blight) return {}

  if (!selectedFieldAssets.block) return {}

  const valuesRequested = {
    ...defaultValuesRequested,
    fireBlightTRV: { preferredAggregationInterval: chooseAmongstUnAggHourlyAndDaily() },
  }

  if (!doesSelectedBlockHaveValueTypes({ valuesTimeseries: Object.keys(valuesRequested) })) return {}

  return {
    blocks: {
      blockIds: [selectedFieldAssets.block],
      valuesRequested,
    },
  }
}

export const content = ({
  data,
  compareSeasonsData,
  updateData,
}: {
  data: routes.Values.Response
  compareSeasonsData: routes.Values.Response
  updateData: (pathToSet: string, dataToSet: unknown) => void
}): StackedChartSection => {
  const blockId = selectedFieldAssetsStore.getState().block
  const fireBlightTemperatureRiskValue = data.blocks?.[Number(blockId)]?.values?.fireBlightTRV?.[0]
  const blockMeta = fireBlightTemperatureRiskValue?.metadata
  const riskId = String(blockMeta?.riskId ?? FIRE_BLIGHT_DEFAULT) as FireBlightHistoricalRiskDatabaseId
  const { compareSeasonsInterval } = detailsPanelStore.getState()

  const onApply = (riskId: FireBlightHistoricalRiskDatabaseId, blockIds: string[]) => {
    for (const bid of blockIds) {
      updateLocalRiskHistory(bid, riskId)
    }

    refreshMapData('fire_blight')
  }

  const commonReturnItems = {
    title: unitConverter.fireBlight().categoryTitleWithoutUnit(),
    titleChildren: (
      <>
        <QuestionToolTip>{translate.phrases.banyanApp('Temperature Risk Value')}</QuestionToolTip>
        <DropdownSelectorBlock
          valuesTimeseriesToFilterOn={Object.keys(defaultValuesRequested)}
          showAssociatedWeatherStationName
          propertyPermissionsToCheck={[permission]}
        />
        <FireBlightSettings
          onApply={onApply}
          valuesTimeseries={Object.keys(defaultValuesRequested)}
          riskId={riskId}
        />
      </>
    ),
    titleCss: { width: '100%' },
    id: 'fireBlight',
  }

  if (!checkPermission()) return propertyLacksPermissionSectionMaker(commonReturnItems)

  if (!doesSelectedBlockHaveValueTypes({ valuesTimeseries: Object.keys(defaultValuesRequested) }))
    return blockLacksCropSectionMaker({
      ...commonReturnItems,
      translatedCropName: translate.phrases.banyanApp('Fire Blight model data'),
    })

  const updateLocalRiskHistory = (blockId: string, riskId: string) =>
    updateData(`blocks.${blockId}.values.fireBlightTRV.0.metadata.riskId`, riskId)

  const isDaily = blockMeta?.aggregationInterval === 'DAILY'

  const blockData = (fireBlightTemperatureRiskValue?.timeseries ?? []).map((d) => [
    +new Date(d.timestamp),
    unitConverter.fireBlight(d.value).value(),
  ])

  const mainSeries: TChartSeries & { color?: TRGBAColorWith1AtTheEnd } = {
    id: 'fireBlight',
    type: 'line',
    name: isDaily ? unitConverter.fireBlightDaily().shortTitle() : unitConverter.fireBlight().shortTitle(),
    data: blockData,
    color: colors.midnight,
  }

  const series: SeriesOptionsType[] = [mainSeries]

  if (compareSeasonsInterval) {
    const compareData =
      compareSeasonsData.blocks?.[Number(blockId)]?.values?.fireBlightTRV?.[0]?.timeseries ?? []

    const data = compareData.map((d) => [+new Date(d.timestamp), unitConverter.fireBlight(d.value).value()])

    series.push(makeCompareSeasonsSeriesFromRegularSeries(mainSeries, { data }))
  }

  const plotBands = riskPlotBands(riskId) ?? []

  const plotLines = plotBands.map((band) => ({
    id: band.id,
    color: colors.zoneBorderLine,
    width: 0.25,
    value: band.to,
  }))

  const emptySeriesForPlotBandsInLegend: TChartSeries[] = plotBands.reverse().map((band) => ({
    type: 'column',
    id: band.id,
    name: band.legendLabel,
    color: band.color,
    data: [],
    showInLegend: true,
    hideFromTooltip: true,
  }))

  series.push(...emptySeriesForPlotBandsInLegend)

  const firstForecastTimestamp = blockMeta?.forecastStartsAt
    ? +new Date(blockMeta.forecastStartsAt)
    : +new Date()

  return {
    ...commonReturnItems,
    items: [
      {
        chartConfig: {
          semiosHighchartsAdditions: {
            id: 'fireBlight',
            firstForecastTimestamp: firstForecastTimestamp,
          },
          tooltip: {
            xDateFormat: getXDateFormat(isDaily),
          },
          chart: {
            type: 'line',
          },
          legend: {
            symbolRadius: 2, // rounded-square legends for risks
          },
          yAxis: {
            plotBands,
            plotLines,
            gridLineColor: 'transparent',
            min: 0,
          },
          xAxis: {
            plotBands: [
              {
                id: 'plotBand-forecast',
                color: 'rgba(215,215,215,.4)',
                from: +new Date(),
                to: 999999999999999,
                zIndex: 1,
                label: {
                  text: translate.phrases.banyanApp('Forecast'),
                  y: -4,
                  x: -10,
                  align: 'right',
                  style: {
                    fontSize: '14px',
                    zIndex: 99999999,
                  },
                  useHTML: true,
                },
              },
            ],
          },
          series,
        },
      },
    ],
  }
}
