import { getInsectIdFromValueGroup } from '@semios/app-platform-common'
import type { TPermission, VV } from '@semios/app-platform-value-type-definitions'
import {
  EAggregationInterval,
  VIEW_PESTS_DEGREE_DAYS_ID_insectId,
} from '@semios/app-platform-value-type-definitions'
import type { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import type { TPossibleValuesToCheck } from 'utils/doesSelectedFieldAssetHaveValueTypes'
import { getDegreeDayValueTypeIdForInsectId } from './getValueTypesForInsectId'

const preferredAggregationInterval = { preferredAggregationInterval: EAggregationInterval.DAILY } as const

export const getDegreeDayValuesRequested = ({
  selectedValueGroups,
  permissionCheck,
  doesTargetHaveValueTypes,
}: {
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
  doesTargetHaveValueTypes: ({ valuesCurrent, valuesTimeseries }: TPossibleValuesToCheck) => boolean
  permissionCheck: (permissionId: TPermission) => boolean
}) => {
  const valuesRequested: Partial<
    Record<
      VV.DomainTypes.DegreeDaysInsect.TTimeseriesValueTypeKeysMerged,
      typeof preferredAggregationInterval
    >
  > = {}

  Object.entries(selectedValueGroups).forEach(([valueGroup, isActive]) => {
    if (!!isActive && valueGroup.includes('degree_days_insect_id_')) {
      const insectId = getInsectIdFromValueGroup(valueGroup)

      if (!insectId || !permissionCheck(VIEW_PESTS_DEGREE_DAYS_ID_insectId(insectId))) return

      const valueType = getDegreeDayValueTypeIdForInsectId(insectId)

      if (!doesTargetHaveValueTypes({ valuesTimeseries: [valueType] })) return

      valuesRequested[valueType] = preferredAggregationInterval
    }
  })

  return valuesRequested
}
