import { routes } from '@semios/app-platform-banyan-route-definitions'
import type { FireBlightHistoricalRiskDatabaseId } from '@semios/app-platform-common'
import { FIRE_BLIGHT_DEFAULT, isEmpty, isNil, isRiskIdValid } from '@semios/app-platform-common'
import type { VC } from '@semios/app-platform-value-type-definitions'
import { getFireblightHeatmapColor } from 'App/Map/_utils/getFireblightHeatmapColor'
import type { TValueType } from 'App/Map/CurrentValuesMap/caches/BlockScdsValuesCache/BlockScdsValuesCache'
import type { TFieldAssetKeyTypes } from 'App/Map/types'
import { colors } from 'settings/colors'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import type {
  TValuesCurrentBlocksValueTypes,
  TValuesCurrentHeatmapPointsValueTypes,
  TValuesCurrentPointsValueTypes,
  TValuesCurrentPropertiesValueTypes,
} from 'stores/mapControlsStore/types'
import { MAP_VISUAL } from 'stores/mapControlsStore/types'
import { apiFetch } from 'utils/apiFetch'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import type { TGetCacheUpdatesFromResponseParameters, TGetCacheUpdatesFromResponseReturn } from './_types'
import { generateUsualStyleAPIArgs } from './_utils/generateUsualStyleAPIArgs'
import { populateSCDsForBlocks } from './_utils/generateUsualStyleGetCacheUpdatesFromResponse'
import { getHeatmapCSS } from './_utils/getHeatmapCSS'
import { getValueType } from './_utils/getValueType'

const valueGroup = 'fire_blight'
const heatmapExtremesForAllPropertiesInVisibleRegions = false

type TValueObject =
  VC.DomainTypes.FireBlight.TCurrentValuesReturnIgnoringKeying['blocks']['fireBlight_today_trv']

type TValueObjectForProperty =
  VC.DomainTypes.FireBlight.TCurrentValuesReturnIgnoringKeying['properties']['fireBlight_today_trv']

const getHeatmapCSSForRisk = (
  value: number | null,
  riskId: FireBlightHistoricalRiskDatabaseId,
): ReturnType<typeof getHeatmapCSS> => {
  const color = colors.midnight

  let backgroundColor: ReturnType<typeof getHeatmapCSS>['backgroundColor']

  if (isNil(value)) {
    return getHeatmapCSS({ value })
  } else {
    backgroundColor = getFireblightHeatmapColor(value, riskId)
  }

  return {
    color,
    backgroundColor,
  }
}

const makeApiArgs = (
  processedCaches: TGetCacheUpdatesFromResponseParameters['processedCaches'],
): routes.ValuesCurrent.Request => {
  return generateUsualStyleAPIArgs({
    heatmapExtremesForAllPropertiesInVisibleRegions,
    processedCaches,
    block: {
      valueType: getValueType(MAP_VISUAL.BLOCK, valueGroup),
    },
    inBlockPoint: {
      valueType: getValueType(MAP_VISUAL.SCD, valueGroup),
    },
    property: {
      valueType: getValueType(MAP_VISUAL.PROPERTY, valueGroup),
    },
  })
}

const getRiskCategory = async (
  processedCaches: TGetCacheUpdatesFromResponseParameters['processedCaches'],
) => {
  let blockIds = processedCaches.blockValues.itemsWithinView.map((block) => Number(block.id))

  if (isEmpty(blockIds)) {
    const propertyIds = processedCaches.propertyValues.itemsWithinView.map((property) => Number(property.id))
    const properties = fieldAssetStore.getState().properties

    blockIds = propertyIds.reduce<number[]>((acc, pid) => {
      const propertyBlocksObj = properties?.[pid]?.blocks || {}

      acc = acc.concat(Object.keys(propertyBlocksObj).map(Number))

      return acc
    }, [])
  }

  const response = await apiFetch({
    url: routes.FieldAssetSettingsGet.path,
    body: {
      fireBlight: { blockIds },
    },
  })

  const { fireBlight } = response

  if (!fireBlight || 'error' in fireBlight) return {}

  const riskByBlockId = fireBlight.reduce<Record<string, FireBlightHistoricalRiskDatabaseId>>(
    (acc, { blockId, riskId }) => {
      if (isRiskIdValid(riskId)) {
        acc[blockId] = riskId
      } else {
        acc[blockId] = FIRE_BLIGHT_DEFAULT
      }

      return acc
    },
    {},
  )

  return riskByBlockId
}

export const getResponseAndShapeForCacheUpdate = async ({
  cacheKeys,
  processedCaches: processedCachesWithoutSCDs,
}: TGetCacheUpdatesFromResponseParameters): Promise<TGetCacheUpdatesFromResponseReturn> => {
  const args = makeApiArgs(processedCachesWithoutSCDs)

  if (isEmpty(args)) return {}

  const currentValuesPromise = apiFetch({
    url: routes.ValuesCurrent.path,
    body: args,
  })

  const riskPromise = getRiskCategory(processedCachesWithoutSCDs)
  const [response, riskByBlockId] = await Promise.all([currentValuesPromise, riskPromise])
  const returner: TGetCacheUpdatesFromResponseReturn = {}
  const processedCaches = populateSCDsForBlocks(response, processedCachesWithoutSCDs)

  if (!!processedCaches.blockValues.itemsWithinView.length) {
    const valueTypeForBlock = getValueType(MAP_VISUAL.BLOCK, valueGroup) as TValuesCurrentBlocksValueTypes
    const itemIdsWithinView: string[] = []

    const itemsWithinViewThatNowHaveValues = processedCaches.blockValues.itemsWithinView.flatMap((block) => {
      const valueObject = response?.blocks?.[block.id]?.[valueTypeForBlock] as TValueObject

      let value: number | null = null

      const riskId = riskByBlockId[block.id]

      if (valueObject && 'value' in valueObject) {
        value = valueObject.value as number | null
      }

      if (isNil(value)) {
        return []
      }

      itemIdsWithinView.push(block.id)

      return {
        id: String(block.id),
        value: {
          [String(valueTypeForBlock)]: {
            children: unitConverter.fireBlight(value, { decimalPlaces: 0 }).valueAsString(),
            onHover: true,
            getContainerCSS: () =>
              isNil(riskId)
                ? getHeatmapCSSForRisk(value, FIRE_BLIGHT_DEFAULT) // is the worst-case scenario, default for risk
                : getHeatmapCSSForRisk(value, riskId),
          },
        },
      }
    })

    returner.blocks = {
      itemIdsWithinView,
      itemsWithinViewThatNowHaveValues,
      cacheKey: cacheKeys.blockValuesCacheKey,
    }
  }

  if (!!processedCaches.stationValues.itemsWithinView.length) {
    const valueTypeForStation = getValueType(MAP_VISUAL.POINT, valueGroup) as TValuesCurrentPointsValueTypes
    const itemIdsWithinView: string[] = []

    const itemsWithinViewThatNowHaveValues = processedCaches.stationValues.itemsWithinView.flatMap(
      (station) => {
        const valueObject = response?.points?.[station.meta.lngLat]?.[valueTypeForStation] as TValueObject
        const riskId = riskByBlockId[Number(station.meta.blockId)] ?? FIRE_BLIGHT_DEFAULT

        let value: number | null = null

        if (valueObject && 'value' in valueObject) {
          value = valueObject.value as number | null
        }

        if (isNil(value)) {
          return []
        }

        itemIdsWithinView.push(station.id)

        const children = unitConverter.fireBlight(value, { decimalPlaces: 0 }).valueAsString()

        return {
          id: station.id,
          value: {
            [valueTypeForStation]: {
              children,
              onHover: true,
              getContainerCSS: () => getHeatmapCSSForRisk(value, riskId),
              isWeatherStation: true,
              getSize: () => {
                let size = 40

                if (children.length > 3) size = size + (children.length - 3) * 6

                return size
              },
            },
          },
        }
      },
    )

    returner.stations = {
      itemIdsWithinView,
      itemsWithinViewThatNowHaveValues,
      cacheKey: cacheKeys.stationCacheKey,
    }
  }

  if (!!processedCaches.blockScdsValues.itemsWithinView.length) {
    const valueTypeForBlockSCD = getValueType(
      MAP_VISUAL.SCD,
      valueGroup,
    ) as TValuesCurrentHeatmapPointsValueTypes

    const itemIdsWithinView: string[] = []

    const itemsWithinViewThatNowHaveValues = processedCaches.blockScdsValues.itemsWithinView.flatMap(
      (block) => {
        const riskId = riskByBlockId[block.id] ?? FIRE_BLIGHT_DEFAULT

        itemIdsWithinView.push(block.id)

        return {
          id: block.meta.blockId,
          value: block.meta.scds.reduce((acc: TValueType, { googleLatLng, lngLat }) => {
            const { value } = (response?.heatmap_points?.[lngLat]?.[valueTypeForBlockSCD] as {
              value: number | null
            }) ?? { value: null }

            itemIdsWithinView.push(block.id)

            acc[googleLatLng.toUrlValue()] = {
              [valueTypeForBlockSCD]: {
                children: unitConverter.fireBlight(value as number, { decimalPlaces: 0 }).valueAsString(),
                getContainerCSS: () => getHeatmapCSSForRisk(value as number, riskId),
              },
            }

            return acc
          }, {}),
        }
      },
    )

    returner.heatmapPoints = {
      itemIdsWithinView,
      itemsWithinViewThatNowHaveValues,
      cacheKey: cacheKeys.scdCacheKey,
    }
  }

  const willShowStations = !!returner.stations?.itemIdsWithinView.length
  const willShowBlocks = !!returner.blocks?.itemIdsWithinView.length
  const willShowHeatmapPoints = !!returner.heatmapPoints?.itemIdsWithinView.length
  const propertyIdsToValues: Record<TFieldAssetKeyTypes.TPropertyId, number | null> = {}

  const propertyIdsToRiskIds: Record<
    TFieldAssetKeyTypes.TPropertyId,
    FireBlightHistoricalRiskDatabaseId | null
  > = {}

  const propertyIds = processedCaches.propertyValues.itemsWithinView.map((s) => Number(s.id))

  const valueTypeForProperty = getValueType(
    MAP_VISUAL.PROPERTY,
    valueGroup,
  ) as TValuesCurrentPropertiesValueTypes

  if (valueTypeForProperty) {
    propertyIds.forEach((propertyId) => {
      const valueObjectForProperty = response?.properties?.[propertyId]?.[
        valueTypeForProperty
      ] as TValueObjectForProperty

      let value = null

      if (valueObjectForProperty && 'value' in valueObjectForProperty) {
        value = valueObjectForProperty.value
      }

      if (typeof value !== 'string' && !Array.isArray(value)) {
        propertyIdsToValues[propertyId] = value
      }

      if (valueObjectForProperty && 'meta' in valueObjectForProperty) {
        const blockId = valueObjectForProperty.meta

        if (blockId) propertyIdsToRiskIds[propertyId] = riskByBlockId[blockId]
      } else {
        propertyIdsToRiskIds[propertyId] = FIRE_BLIGHT_DEFAULT
      }
    })
  }

  const showValuesForPropertyRatherThanName =
    !isEmpty(propertyIdsToValues) && !willShowStations && !willShowBlocks && !willShowHeatmapPoints

  const itemsWithinViewThatNowHaveValues = processedCaches.propertyValues.itemsWithinView.flatMap(
    (property) => {
      if (!showValuesForPropertyRatherThanName) {
        return {
          id: String(property.id),
          value: {
            [String(valueTypeForProperty)]: {
              children: property.meta.propertyName,
              onHover: false,
              getContainerCSS: () => ({ backgroundColor: colors.midnight, color: 'white' }),
              baseZIndex: undefined,
            },
          },
        }
      }

      const value = propertyIdsToValues[Number(property.id)]
      const riskId = propertyIdsToRiskIds[Number(property.id)] ?? FIRE_BLIGHT_DEFAULT

      return {
        id: String(property.id),
        value: {
          [String(valueTypeForProperty)]: {
            children: unitConverter.fireBlight(value, { decimalPlaces: 0 }).valueAsString(),
            onHover: true,
            getContainerCSS: () => getHeatmapCSSForRisk(value, riskId),
            baseZIndex: isNil(value) ? -10 : undefined,
          },
        },
      }
    },
  )

  returner.properties = {
    itemsWithinViewThatNowHaveValues,
    cacheKey: cacheKeys.propertyCacheKey,
    itemIdsWithinView: processedCaches.propertyValues.itemIdsWithinView,
  }

  return returner
}
