import { isBleSupported } from 'App/ServiceCenter/BluetoothLowEnergy/util/utility'
import { AboveAllModal } from 'components/AboveAllModalOverlay/AboveAllModalOverlay'
import { useEffect, type FC } from 'react'
import { serviceCenterStore } from '../store/serviceCenterStore'
import type { TActiveNode } from '../types'
import { BleDeviceConnectionKeeper } from '../utils/BleConnectionKeeper'
import { isBleNode } from '../utils/constants/nodeType'
import { DevicesManagement } from './DevicesManagement'

export function openNodeDeviceModal(nodeIdentifier: string) {
  AboveAllModal.open({
    modalId: 'node-device-manager',
    fullScreen: true,
    withCloseButton: false,
    padding: 0,
    children: <NodeDeviceModal nodeIdentifier={nodeIdentifier} />,
    styles: {
      content: {
        marginLeft: 'env(safe-area-inset-left)',
        marginRight: 'env(safe-area-inset-right)',
        boxShadow: 'none',
        transform: 'none !important',
      },
    },
  })
}

const NodeDeviceModal: FC<{ nodeIdentifier: string }> = ({ nodeIdentifier }) => {
  const activeNodes = serviceCenterStore.useSelector(serviceCenterStore.selectors.getActiveNodes)

  const node = activeNodes.find(
    (node) => (node as TActiveNode).nodeIdentifier === nodeIdentifier,
  ) as TActiveNode

  useEffect(() => {
    if (!node) {
      // this would happen if someone removes the node from Noc2 or Splash and refresh got triggered. The node would be removed from the activeNodes list
      // eslint-disable-next-line no-console
      console.error('No selected node found for node device modal')

      AboveAllModal.close('node-device-manager')
    }
  }, [node])

  if (!node) {
    return null
  }

  const isBleRequired = isBleSupported() && isBleNode(node.nodeType)

  return (
    <>
      {isBleRequired && <BleDeviceConnectionKeeper nodeIdentifier={nodeIdentifier} />}
      <DevicesManagement
        node={node}
        isBleRequired={isBleRequired}
        onClose={() => {
          AboveAllModal.close('node-device-manager')
        }}
      />
    </>
  )
}
