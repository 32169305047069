import { useMantineTheme } from '@mantine/core'
import type { Ref } from 'react'
import { forwardRef, useEffect, useState } from 'react'
import { useMultiSelectContext } from './MultiSelect.context'

export const MultiSelectSearchInput = forwardRef((_, ref: Ref<HTMLInputElement>) => {
  const {
    disabled,
    optionsLabel,
    inputValue,
    loading,
    onArrowKeyNavigation,
    placeholder,
    placeholderStyle,
    selectedValues,
    setInputValue,
  } = useMultiSelectContext()

  const [showPlaceholder, setShowPlaceholder] = useState(selectedValues.length === 0)

  useEffect(() => {
    if (selectedValues.length === 0) {
      const timeoutId = setTimeout(() => setShowPlaceholder(true), 2)

      return () => clearTimeout(timeoutId)
    } else {
      setShowPlaceholder(false)
    }
  }, [selectedValues])

  const theme = useMantineTheme()

  return (
    <input
      css={{
        'border': optionsLabel ? '1px solid #464B53' : 'none',
        'width': '100%',
        'minHeight': 30,
        'background': 'transparent',
        ':focus': {
          outline: 'none',
        },
        'fontSize': 14,
        'paddingLeft': 10,
        'flex': '0 1 auto',
        'marginRight': '5px',
        '::placeholder': { ...(placeholderStyle ?? { color: theme.colors.grey[2] }) },
      }}
      type="text"
      ref={ref}
      onChange={(e) => setInputValue(e.target.value)}
      value={inputValue}
      placeholder={showPlaceholder ? placeholder : ''}
      onKeyDown={onArrowKeyNavigation}
      autoComplete="off"
      disabled={loading || disabled}
    />
  )
})

MultiSelectSearchInput.displayName = 'MultiSelectSearchInput'
