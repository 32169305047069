import { Stack } from '@mantine/core'
import type { TNodeDeviceChannels, TNodeDevices } from 'App/ServiceCenter/types'
import { translate } from 'i18n/i18n'
import { fieldAssetStore } from 'stores/fieldAssetStore'

export const DevicesSummary = ({ nodeDevices }: { nodeDevices?: TNodeDevices }) => {
  const allDeviceModels = fieldAssetStore.useSelector((s) => s.devices)

  const allDeviceSources = Object.entries(nodeDevices || {}).flatMap(
    ([connector, value]: [string, TNodeDeviceChannels]) => {
      return Object.entries(value).map(([chan, device]) => {
        return {
          connector,
          channel: chan,
          source: device.source,
        }
      })
    },
  )

  return (
    <>
      {nodeDevices && allDeviceSources && (
        <>
          <h4 css={{ marginBottom: 10 }}>{translate.phrases.placeholder('Device Configuration:')}</h4>
          <Stack>
            {allDeviceSources.map((device) => (
              <div key={`${device.connector}-${device.channel}`}>
                {allDeviceModels[device.source]?.name || device.source}
              </div>
            ))}
          </Stack>
          <p>{allDeviceSources.length === 0 && translate.phrases.placeholder('No devices')}</p>
        </>
      )}
    </>
  )
}
