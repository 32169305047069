import type { GalleryPhoto, Photo } from '@capacitor/camera'
import { Text } from '@mantine/core'
import { ImageUpload } from 'App/ServiceCenter/ImageUpload/ImageUpload'
import { createErrorModal } from 'App/ServiceCenter/utils/createCommonModals'
import { isNodeDropping } from 'App/ServiceCenter/utils/getInstallationSteps'
import { uploadInstallationImage } from 'App/ServiceCenter/utils/uploadInstallationImage'
import { useNavigator } from 'App/ServiceCenter/utils/useNavigator'
import { AboveAllModal } from 'components/AboveAllModalOverlay/AboveAllModalOverlay'
import { translate } from 'i18n/i18n'
import React, { useState } from 'react'
import { checkAuthorization } from 'utils/checkAuthorization'
import { Footer } from '../Footer/Footer'
import type { InstallationStepProp } from '../types'
import { ConnectionStatus } from './ConnectionStatus/ConnectionStatus'
import { FinalReview } from './FinalReview/FinalReview'

enum PageKey {
  BLE_CONNECTION = 'BLE_CONNECTION',
  FINAL_REVIEW = 'FINAL_REVIEW',
}

export const InstallationReview: React.FC<InstallationStepProp<{ isReviewImageUploaded: boolean }>> = ({
  payload,
  isBleRequired,
  onSubmit,
  onClose,
}: InstallationStepProp<{ isReviewImageUploaded: boolean }>) => {
  const { page: currentPage, navigateTo } = useNavigator<PageKey, undefined>(
    isBleRequired ? PageKey.BLE_CONNECTION : PageKey.FINAL_REVIEW,
  )

  const [finalImage, setFinalImage] = useState<Photo | GalleryPhoto | undefined>()
  const [isSaving, setIsSaving] = useState(false)

  const canUploadImage = checkAuthorization({
    permission: 'CREATE_SSC_NODE_IMAGE',
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    entity: payload.propertyId!,
  })

  const handleFinishInstallation = async () => {
    try {
      if (canUploadImage) {
        // Upload image
        const identifier = payload.nodeIdentifier
        const action = isNodeDropping(payload) ? 'EQUIP_INSTALL_DROP' : 'EQUIP_INSTALL_PSEUDO'

        if (!identifier) {
          throw new Error(translate.phrases.placeholder('Node identifier is missing'))
        }

        if (!finalImage) {
          throw new Error(translate.phrases.placeholder('Please upload a photo of the installed equipment.'))
        }

        setIsSaving(true)

        await uploadInstallationImage(identifier, finalImage, action)

        onSubmit({ isReviewImageUploaded: true })
      } else {
        onSubmit({ isReviewImageUploaded: false })
      }

      onClose(true)
    } catch (error) {
      setIsSaving(false)

      AboveAllModal.open(
        createErrorModal(translate.phrases.placeholder('Installation Error'), (error as Error).message),
      )
    }
  }

  return (
    <>
      <div>
        {currentPage === PageKey.BLE_CONNECTION && (
          <ConnectionStatus
            isBleRequired={isBleRequired}
            payload={payload}
            footer={
              <Footer
                onPrevious={() => onClose(false)}
                onNext={() => navigateTo(PageKey.FINAL_REVIEW)}
                nextButtonLabel={translate.phrases.placeholder('Next')}
              />
            }
          />
        )}
        {currentPage === PageKey.FINAL_REVIEW && (
          <FinalReview
            footer={
              <Footer
                onPrevious={() => (isBleRequired ? navigateTo(PageKey.BLE_CONNECTION) : onClose(false))}
                onNext={() => handleFinishInstallation()}
                disableNextButton={canUploadImage && !finalImage}
                nextButtonLabel={translate.phrases.placeholder('Finish')}
                loading={isSaving}
              />
            }
          >
            {canUploadImage ? (
              <>
                <Text>{translate.phrases.placeholder('Submit a photo and finalize the install.')}</Text>
                <ImageUpload onlyCamera image={finalImage} setImage={setFinalImage} />
              </>
            ) : (
              <></>
            )}
          </FinalReview>
        )}
      </div>
    </>
  )
}
