import { Capacitor } from '@capacitor/core'
import { Stack, Text, TextInput } from '@mantine/core'
import { useForm } from '@mantine/form'
import { notifications } from '@mantine/notifications'
import { sortByKey } from '@semios/app-platform-common'
import type { TFieldAssetValueTypes } from 'App/Map/types'
import { useBleManager } from 'App/ServiceCenter/BluetoothLowEnergy/BleManager'
import { AdapterStatus } from 'App/ServiceCenter/BluetoothLowEnergy/types'
import { ManagementTypes } from 'App/ServiceCenter/types'
import { isBleNode } from 'App/ServiceCenter/utils/constants/nodeType'
import { createErrorModal } from 'App/ServiceCenter/utils/createCommonModals'
import { isNodeDropping } from 'App/ServiceCenter/utils/getInstallationSteps'
import { useScannerSupportCheck } from 'App/ServiceCenter/utils/hooks/useScannerSupportCheck'
import { AboveAllModal } from 'components/AboveAllModalOverlay/AboveAllModalOverlay'
import { IconBluetooth } from 'components/icons/IconBluetooth'
import { translate } from 'i18n/i18n'
import React, { useEffect, useState } from 'react'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import { checkAuthorization } from 'utils/checkAuthorization'
import { isValidNodeIdentifier } from '../../utils/validateNodeIdentifier'
import { Footer } from '../Footer/Footer'
import type { EquipmentActivationPayload, InstallationStepProp } from '../types'
import { NodeIdentifierField } from './NodeIdentifierField/NodeIdentifierField'
import { NodeTypeField } from './NodeTypeField/NodeTypeField'
import { PropertyField } from './PropertyField/PropertyField'

export type NodeActivationPayload = Pick<
  EquipmentActivationPayload,
  'nodeIdentifier' | 'propertyId' | 'nodeType' | 'name' | 'managementType' | 'maintenanceOwnerId'
>

export const NodeActivation: React.FC<InstallationStepProp<NodeActivationPayload>> = ({
  payload,
  onClose,
  onSubmit,
  isBleRequired,
}) => {
  const [isSaving, setIsSaving] = useState(false)
  const bleManager = useBleManager()
  const scannerSupport = useScannerSupportCheck()
  const nodeDropping = isNodeDropping(payload)

  const properties: {
    [propertyId: number]: TFieldAssetValueTypes.TProperty
  } = fieldAssetStore.useSelector((s) => s?.properties ?? {})

  const selectedPropertyId = selectedFieldAssetsStore.getState().property

  const propertiesWithPerm: (TFieldAssetValueTypes.TProperty & { installable: boolean })[] = Object.values(
    properties,
  ).map((p) => {
    const installable = checkAuthorization({
      permission: 'EDIT_SSC_INSTALL_NODE',
      entity: p.propertyId,
    })

    return { ...p, installable }
  })

  const propertiesSelectOptions = propertiesWithPerm
    .map((p) => {
      return {
        label: p.propertyName,
        value: p.propertyId.toString(),
        disabled: !p.installable,
      }
    })
    .sort(sortByKey('label'))

  const defaultProperty = propertiesWithPerm.find((p) => p.propertyId === selectedPropertyId)
  const defaultSelectedPropertyId = defaultProperty?.installable ? defaultProperty.propertyId : undefined

  const defaultSelectMaintenanceOwnerId = defaultProperty?.installable
    ? defaultProperty.maintenanceOwnerId
    : propertiesWithPerm.find((p) => p.installable)?.maintenanceOwnerId

  const form = useForm({
    initialValues: {
      nodeIdentifier: payload.nodeIdentifier || '',
      nodeType: payload.nodeType || undefined,
      propertyId: payload.propertyId ? payload.propertyId : defaultSelectedPropertyId,
      name: payload.name || payload.nodeIdentifier || '',
      managementType: payload.managementType ? payload.managementType : ManagementTypes.COMMERCIAL,
      maintenanceOwnerId: payload.maintenanceOwnerId
        ? payload.maintenanceOwnerId
        : defaultSelectMaintenanceOwnerId,
    },
    validate: {
      propertyId: (value) => (value ? null : translate.phrases.placeholder('Please select a property')),
      nodeIdentifier: (value) => {
        if (!value) {
          return translate.phrases.placeholder('Please enter a node identifier')
        } else if (nodeDropping && !isValidNodeIdentifier(value)) {
          return translate.phrases.placeholder(
            'The node identifier that you have entered is invalid. Please try again.',
          )
        } else if (!isValidNodeIdentifier(value, form.values.nodeType)) {
          //For planned node installation, we need to validate the node identifier against the selected equipment type
          return translate.phrases.placeholder(
            'The node identifier that you have entered does not match the selected equipment type. Please try again.',
          )
        } else {
          return null
        }
      },
      nodeType: (value) => (value ? null : translate.phrases.placeholder('Please select equipment type')),
      name: (value) =>
        value && value.length < 6
          ? translate.phrases.placeholder('Name is too short (min 6 characters)')
          : null,
    },
    transformValues: (values) => ({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      propertyId: values.propertyId!,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      nodeIdentifier: values.nodeIdentifier!,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      nodeType: values.nodeType!,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      managementType: values.managementType!,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      maintenanceOwnerId: values.maintenanceOwnerId!,
      name: values.name,
    }),
  })

  const handleSubmit = async () => {
    const validation = form.validate()

    if (validation.hasErrors) {
      return
    }

    try {
      setIsSaving(true)

      const selectedPropertyMaintenanceOwnerId =
        properties[form.values.propertyId as number].maintenanceOwnerId

      await onSubmit({
        ...form.getTransformedValues(),
        maintenanceOwnerId: selectedPropertyMaintenanceOwnerId,
      })

      onClose(true)
    } catch (error) {
      setIsSaving(false)

      // eslint-disable-next-line no-console
      console.error(error)

      AboveAllModal.open(
        createErrorModal(translate.phrases.placeholder('Activation Error'), (error as Error).message),
      )
    }
  }

  useEffect(() => {
    if (isBleRequired && !!form.values.nodeType && isBleNode(form.values.nodeType)) {
      if (bleManager.adapterStatus === AdapterStatus.DISABLED) {
        notifications.show({
          id: 'enable-bluetooth',
          icon: <IconBluetooth />,
          title: <Text>{translate.phrases.placeholder('Enable Bluetooth')}</Text>,
          autoClose: false,
          message: (
            <Text>
              {translate.phrases.placeholder(
                'This device requires a bluetooth connection in order to proceed. Please be sure to enable bluetooth in your mobile device settings.',
              )}
            </Text>
          ),
        })
      } else {
        notifications.hide('enable-bluetooth')
      }
    }
  }, [form.values.nodeIdentifier, form.values.nodeType, bleManager.adapterStatus])

  let description: string

  if (Capacitor.getPlatform() === 'web') {
    description = translate.phrases.placeholder('Manually enter the serial number located on the device.')
  } else if (scannerSupport.NFC.supported && scannerSupport.QR.supported) {
    description = translate.phrases.placeholder(
      'Scan the NFC tag located on the device or scan the QR code or manually enter the serial number below.',
    )
  } else if (scannerSupport.NFC.supported) {
    description = translate.phrases.placeholder(
      'Scan the NFC tag located on the device or manually enter the serial number below.',
    )
  } else if (scannerSupport.QR.supported) {
    description = translate.phrases.placeholder(
      'Scan the QR code located on the device or manually enter the serial number below.',
    )
  } else {
    description = translate.phrases.placeholder('Manually enter the serial number located on the device.')
  }

  return (
    <>
      <div css={{ padding: 10 }}>
        <Stack>
          {translate.phrases.placeholder(description)}

          <PropertyField
            {...form.getInputProps('propertyId')}
            // disable property select if node is being installed from pseudonode
            disabled={!nodeDropping || propertiesSelectOptions.length === 1}
            required
            label={translate.phrases.placeholder('Property')}
            placeholder={translate.phrases.placeholder('Select Property')}
            data={propertiesSelectOptions}
            onChange={(value) => {
              form.setValues((prev) => ({ ...prev, propertyId: value ? parseInt(value) : undefined }))
            }}
          />
          <TextInput
            {...form.getInputProps('name')}
            label={translate.phrases.placeholder('Equipment Name')}
            placeholder={translate.phrases.placeholder('Insert New Equipment Name')}
            onChange={(e) => {
              form.setValues((prev) => ({
                ...prev,
                name: e.target.value.length > 0 ? e.target.value : undefined,
              }))
            }}
          />

          <NodeIdentifierField
            required
            {...form.getInputProps('nodeIdentifier')}
            label={translate.phrases.placeholder('Node Identifier')}
            placeholder={translate.phrases.placeholder('Type or Scan ID')}
            onChange={(e) => {
              //Override default onChanged in getInputProps to handle uppercase
              form.setValues((prev) => ({
                ...prev,
                nodeIdentifier: e.target.value.toUpperCase(),
              }))
            }}
          />
          {nodeDropping && (
            <NodeTypeField
              propertyId={form.values.propertyId}
              required
              {...form.getInputProps('nodeType')}
              label={translate.phrases.placeholder('Equipment Type')}
              placeholder={translate.phrases.placeholder('Please Select Equipment Type')}
              nodeIdentifier={form.values.nodeIdentifier}
            />
          )}
        </Stack>
      </div>
      <Footer
        onNext={handleSubmit}
        loading={isSaving}
        onPrevious={() => onClose(false)}
        nextButtonLabel={translate.phrases.placeholder('Submit')}
      />
    </>
  )
}
