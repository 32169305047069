import { irrigationSchedulerStore } from 'App/irrigation-scheduler/utils/store/irrigationSchedulerStore'
import { translate } from 'i18n/i18n'
import { useEffect, useState } from 'react'
import { userDetailsStore } from 'stores/userDetailsStore'
import { ItemHeader } from '../../WideScreen/ItemHeader/ItemHeader'
import { fetchFertigationSettings } from './fetchFertigationSettings'
import { FertigationConfigurationTabs } from './Tabs/FertigationConfigurationTabs'
import type { TFertigationSettingsData } from './types'

export const FertigationConfiguration = () => {
  const issSelectedPropertyId = irrigationSchedulerStore.useSelector((s) => s.selectedPropertyId)
  const [selectedPropertyId, setSelectedPropertyId] = useState<null | number>(issSelectedPropertyId)
  const [fertigationSettings, setFertigationSettings] = useState<TFertigationSettingsData[]>([])
  const [loading, setLoading] = useState(false)

  const { permissions } = userDetailsStore.useSelector((s) => ({
    permissions: s.permissions,
  }))

  const propertiesWithEditPermission = Object.keys(permissions['EDIT_FERTIGATION'] ?? {}).map(Number)

  const propertiesWithViewPermission = [
    ...new Set([
      ...propertiesWithEditPermission,
      ...Object.keys(permissions['VIEW_FERTIGATION'] ?? {}).map(Number),
    ]),
  ]

  useEffect(() => {
    fetchFertigationSettings({
      setLoading,
      setFertigationSettings,
      propertyIds: propertiesWithViewPermission,
    })
  }, [])

  return (
    <div>
      <ItemHeader>{translate.phrases.banyanApp('Fertigation Configuration')}</ItemHeader>
      <FertigationConfigurationTabs
        fertigationSettings={fertigationSettings}
        loading={loading}
        propertiesWithViewPermission={propertiesWithViewPermission}
        propertiesWithEditPermission={propertiesWithEditPermission}
        selectedPropertyId={selectedPropertyId}
        setFertigationSettings={setFertigationSettings}
        setLoading={setLoading}
        setSelectedPropertyId={setSelectedPropertyId}
      />
    </div>
  )
}
