import type { routes } from '@semios/app-platform-banyan-route-definitions'
import { EAggregationInterval, type VV } from '@semios/app-platform-value-type-definitions'
import { DropdownSelectorPoint } from 'App/Map/PanelDetails/SectionTitleBars/DropdownSelectorPoint/DropdownSelectorPoint'
import { getXDateFormat } from 'App/Map/PanelDetails/StackemCharts/_utils/by-domain/_utils/getXDateFormat'
import { makeCompareSeasonsSeriesFromRegularSeries } from 'App/Map/PanelDetails/StackemCharts/_utils/by-domain/_utils/makeCompareSeasonsSeriesFromRegularSeries'
import { propertyLacksPermissionSectionMaker } from 'App/Map/PanelDetails/_utils/propertyLacksPermissionSectionMaker'
import { regionLacksPermissionSectionMaker } from 'App/Map/PanelDetails/_utils/regionLacksPermissionSectionMaker'
import { selectedPropertyHasPermission } from 'App/Map/PanelDetails/_utils/selectedPropertyHasPermission'
import { selectedRegionHasPermission } from 'App/Map/PanelDetails/_utils/selectedRegionHasPermission'
import type { StackedChartSection, TChartSeries } from 'components/StackedChart/types'
import type { TRGBAColorWith1AtTheEnd } from 'settings/colors'
import { colors } from 'settings/colors'
import { detailsPanelStore } from 'stores/detailsPanelStore'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import type { TSelectedFieldAssetsStoreState } from 'stores/selectedFieldAssetsStore'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import type { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import {
  doesSelectedPointHaveValueTypes,
  doesSelectedRegionHaveValueTypes,
} from 'utils/doesSelectedFieldAssetHaveValueTypes'
import { isUserOnlyAFreeRegionalUser } from 'utils/isUserOnlyAFreeRegionalUser'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import { isRegionDataEnabled, isRegionDataEnabledForCharts } from 'utils/useIsRegionDataEnabled'
import { chooseAmongstUnAggHourlyAndDaily } from '../../chooseAmongstUnAggHourlyAndDaily'
import { makeRegionalSeriesFromRegularSeries } from '../_utils/makeRegionalSeriesFromRegularSeries'

const permissionRequired = 'VIEW_WEATHER_DETAILS'

const checkPermission = () =>
  selectedPropertyHasPermission({ permission: permissionRequired }) ||
  (isUserOnlyAFreeRegionalUser() && selectedRegionHasPermission({ permission: permissionRequired }))

const valueType = 'leafWetnessHours' as VV.DomainTypes.LeafWetness.TTimeseriesValueTypeKeysMerged
const pointCategory = 'weatherPoint'

export const apiArgs = ({
  selectedValueGroups,
  selectedFieldAssets,
}: {
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}): Partial<routes.Values.Request> => {
  if (!checkPermission()) return {}

  if (!selectedValueGroups.leaf_wetness || !selectedFieldAssets.weatherPoint || !selectedFieldAssets.region)
    return {}

  const returner: Partial<routes.Values.Request> = {}

  const pointHasValueType = doesSelectedPointHaveValueTypes({
    valuesTimeseries: [valueType],
    pointCategory,
  })

  const preferredAggregationInterval = chooseAmongstUnAggHourlyAndDaily()

  if (pointHasValueType) {
    returner.points = {
      lngLats: [selectedFieldAssets.weatherPoint],
      valuesRequested: {
        [valueType]: { preferredAggregationInterval: preferredAggregationInterval },
      },
    }
  }

  const regionHasValueType =
    isRegionDataEnabled() && doesSelectedRegionHaveValueTypes({ valuesTimeseries: [valueType] })

  if (regionHasValueType) {
    returner.regions = {
      regionIds: [selectedFieldAssets.region],
      valuesRequested: {
        [valueType]: { preferredAggregationInterval: preferredAggregationInterval },
      },
    }
  }

  return returner
}

export const content = ({
  data,
  compareSeasonsData,
}: {
  data: routes.Values.Response
  compareSeasonsData: routes.Values.Response
}): StackedChartSection => {
  const commonReturnItems = {
    title: unitConverter.leafWetnessHours().title(),
    titleChildren: (
      <DropdownSelectorPoint valuesTimeseriesToFilterOn={[valueType]} pointCategory={pointCategory} />
    ),
    id: 'stackem-leaf-wetness',
  }

  const selectedFieldAssets = selectedFieldAssetsStore.getState()
  const selectedProperty = selectedFieldAssets.property
  const selectedRegion = selectedFieldAssets.region
  const hasPermission = checkPermission()

  if (!hasPermission && selectedProperty) return propertyLacksPermissionSectionMaker(commonReturnItems)

  if (!hasPermission && !selectedProperty) return regionLacksPermissionSectionMaker(commonReturnItems)

  const selectedPoint = selectedFieldAssets[pointCategory]
  const lngLat = String(selectedPoint)
  const { compareSeasonsInterval } = detailsPanelStore.getState()
  const leafWetnessHoursData = data.points?.[lngLat]?.values?.[valueType]?.[0]

  let isDaily = leafWetnessHoursData?.metadata.aggregationInterval === EAggregationInterval.DAILY

  let latestNonForecastDate = leafWetnessHoursData?.metadata.forecastStartsAt
    ? +new Date(leafWetnessHoursData?.metadata.forecastStartsAt)
    : +new Date()

  const series: TChartSeries[] = []

  if (leafWetnessHoursData) {
    const values = leafWetnessHoursData?.timeseries?.map((t) => {
      return {
        x: +new Date(t.timestamp),
        y: t.value as number | null, // TODO: if fully remove isWet then can clean this up
      }
    })

    const leafWetnessHoursSeries: TChartSeries & { color: TRGBAColorWith1AtTheEnd } = {
      id: 'leaf-wetness-hours',
      color: colors.midnight,
      name: unitConverter.leafWetnessHours().titleWithoutUnit(),
      tooltip: {
        valueDecimals: unitConverter.leafWetnessHours().defaultNumberOfDecimalPlaces(),
        valueSuffix: unitConverter.leafWetnessHours().suffix(),
      },
      yAxis: 0,
      type: 'area',
      step: 'center',
      data: values,
    }

    series.push(leafWetnessHoursSeries)

    if (compareSeasonsInterval) {
      const leafWetnessHoursCompareSeasonsData =
        compareSeasonsData?.points?.[lngLat]?.values?.leafWetnessHours?.[0]

      series.push(
        makeCompareSeasonsSeriesFromRegularSeries(leafWetnessHoursSeries, {
          data: leafWetnessHoursCompareSeasonsData?.timeseries.map((t) => [+new Date(t.timestamp), t.value]),
          color: colors.compareSeasonsGreen,
        }),
      )
    }
  }

  if (
    isRegionDataEnabledForCharts() &&
    selectedRegion &&
    doesSelectedRegionHaveValueTypes({ valuesTimeseries: [valueType] })
  ) {
    const regionName = fieldAssetStore.getState()?.regions?.[selectedRegion]?.name
    const regionData = data.regions?.[selectedRegion]?.values?.[valueType]?.[0]

    latestNonForecastDate = latestNonForecastDate ?? regionData?.metadata.forecastStartsAt

    isDaily = regionData?.metadata.aggregationInterval === EAggregationInterval.DAILY

    const values = regionData?.timeseries?.map((t) => {
      return {
        x: +new Date(t.timestamp),
        y: t.value as number | null, // TODO: if fully remove isWet then can clean this up
      }
    })

    if (series.length) {
      const regionalSeries = makeRegionalSeriesFromRegularSeries(series[0], {
        name: regionName,
        data: values,
        color: colors.regionsCompare,
      })

      series.push(regionalSeries)
    } else {
      const seriesName = selectedPoint ? regionName : unitConverter.leafWetnessHours().titleWithoutUnit()

      series.push({
        id: 'leaf-wetness-hours',
        color: colors.midnight,
        name: seriesName,
        tooltip: {
          valueDecimals: unitConverter.leafWetnessHours().defaultNumberOfDecimalPlaces(),
          valueSuffix: unitConverter.leafWetnessHours().suffix(),
        },
        yAxis: 0,
        type: 'area',
        step: 'center',
        data: values,
      })
    }
  }

  return {
    ...commonReturnItems,
    items: [
      {
        chartConfig: {
          semiosHighchartsAdditions: {
            id: commonReturnItems.id,
            firstForecastTimestamp: latestNonForecastDate,
          },
          chart: {
            type: 'line',
          },
          yAxis: {
            min: 0,
            max: isDaily ? 24 : 1,
          },
          series: series,
          tooltip: {
            xDateFormat: getXDateFormat(isDaily),
          },
        },
      },
    ],
  }
}
