import type { routes } from '@semios/app-platform-banyan-route-definitions'
import type { VV } from '@semios/app-platform-value-type-definitions'
import type { getColorRulesForPestSeries } from 'App/Map/PanelDetails/StackemCharts/_utils/createContent/_utils/getColorRulesForSeries'
import type { TXAxisPlotLinesOptionsWithTooltipDisplay } from 'components/StackedChart/_utils/lineChartTooltipFormatter/buildTooltipPlotLinesIfAny'
import type { TChartSeries } from 'components/StackedChart/types'
import { translate } from 'i18n/i18n'
import type { TRGBAColorWith1AtTheEnd } from 'settings/colors'
import { INSECT_DEGREE_DAY_VALUE_KEY_PREFIX } from 'utils/insectRequestValueKeyPrefix'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import { isRegionDataEnabledForCharts } from 'utils/useIsRegionDataEnabled'
import { makeCompareSeasonsSeriesFromRegularSeries } from '../../../../_utils/makeCompareSeasonsSeriesFromRegularSeries'
import {
  makeRegionalSeries,
  makeRegionalSeriesFromRegularSeries,
} from '../../../../_utils/makeRegionalSeriesFromRegularSeries'
import { generateBiofixPlotLinesAndSeries } from '../generateBiofixPlotLinesAndSeries'

export const buildDegreeDaySeries = ({
  targetScope,
  targetScopeHasDDValueTypes,
  insectId,
  targetScopeData,
  targetScopeId,
  DDvalueTypeKey,
  regionsData,
  regionName,
  showRegionalData,
  compareSeasonsInterval,
  compareSeasonsTargetScopeData,
  dateFrom,
  dateTo,
  timezone,
  colorRules,
}: {
  targetScope: 'PROPERTY' | 'BLOCK' | 'REGION'
  targetScopeHasDDValueTypes: boolean
  insectId: number
  targetScopeData:
    | routes.Values.Response['blocks']
    | routes.Values.Response['properties']
    | routes.Values.Response['regions']
  targetScopeId: string
  DDvalueTypeKey: string
  regionsData?: NonNullable<routes.Values.Response['regions']>['string']
  regionName?: string | null
  showRegionalData: boolean
  compareSeasonsInterval: number
  compareSeasonsTargetScopeData: routes.Values.Response['blocks'] | routes.Values.Response['properties']
  dateFrom: string
  dateTo: string
  timezone: string
  colorRules: ReturnType<typeof getColorRulesForPestSeries>
}) => {
  const DDSeries: TChartSeries[] = []

  let biofixPlotLines: TXAxisPlotLinesOptionsWithTooltipDisplay[] = []

  if (targetScopeHasDDValueTypes) {
    const insectDegreeDaysCumulativeSeries: TChartSeries & { color?: TRGBAColorWith1AtTheEnd } = {
      name: unitConverter.insectDegreeDaysCumulative().categoryTitleWithoutUnit(),
      id: `${INSECT_DEGREE_DAY_VALUE_KEY_PREFIX}${insectId}`,
      tooltip: {
        valueDecimals: unitConverter.insectDegreeDaysCumulative().defaultNumberOfDecimalPlaces(),
        valueSuffix: ` ${unitConverter.insectDegreeDaysCumulative().suffix()}`,
      },
      yAxis: 0,
      data: (
        targetScopeData?.[targetScopeId]?.values?.[
          DDvalueTypeKey as VV.DomainTypes.DegreeDaysInsect.TTimeseriesValueTypeKeysMerged
        ]?.[0]?.timeseries ?? []
      ).map((d: { timestamp: string; value: number | null }) => [
        +new Date(d.timestamp),
        unitConverter.insectDegreeDaysCumulative(d.value).value(),
      ]),
      type: 'line',
      marker: {
        enabled: false,
      },
      color: targetScope === 'REGION' ? colorRules.regionDegreeDay : colorRules.degreeDay[0],
    }

    DDSeries.push(insectDegreeDaysCumulativeSeries)

    if (compareSeasonsInterval) {
      DDSeries.push(
        makeCompareSeasonsSeriesFromRegularSeries(insectDegreeDaysCumulativeSeries, {
          data: (
            compareSeasonsTargetScopeData?.[targetScopeId]?.values?.[
              DDvalueTypeKey as VV.DomainTypes.DegreeDaysInsect.TTimeseriesValueTypeKeysMerged
            ]?.[0]?.timeseries ?? []
          ).map((d: { timestamp: string; value: number | null }) => [
            +new Date(d.timestamp),
            unitConverter.insectDegreeDaysCumulative(d.value).value(),
          ]),
          color: colorRules.degreeDay[1],
        }),
      )
    }

    const { biofixSeriesForAddingToggle, biofixPlotLines: blockBiofixPlotLines } =
      generateBiofixPlotLinesAndSeries({
        biofixDates: {
          baseSeason:
            targetScopeData?.[targetScopeId]?.values?.[
              DDvalueTypeKey as VV.DomainTypes.DegreeDaysInsect.TTimeseriesValueTypeKeysMerged
            ]?.[0]?.metadata?.biofixDates ?? [],
          comparisonSeason: !compareSeasonsInterval
            ? []
            : compareSeasonsTargetScopeData?.[targetScopeId]?.values?.[
                DDvalueTypeKey as VV.DomainTypes.DegreeDaysInsect.TTimeseriesValueTypeKeysMerged
              ]?.[0]?.metadata?.biofixDates ?? [],
        },
        dateFrom,
        dateTo,
        intervalForCompareSeasonsOffset: compareSeasonsInterval,
        timezone,
      })

    if (biofixSeriesForAddingToggle) DDSeries.push(biofixSeriesForAddingToggle)

    biofixPlotLines = blockBiofixPlotLines ?? []
  }

  const showRegions = isRegionDataEnabledForCharts() && showRegionalData && regionName

  let forecastMoments = [
    targetScopeData?.[targetScopeId]?.values?.[
      DDvalueTypeKey as VV.DomainTypes.DegreeDaysInsect.TTimeseriesValueTypeKeysMerged
    ]?.[0]?.metadata?.forecastStartsAt,
  ]

  if (targetScope === 'PROPERTY') {
    forecastMoments.push(
      regionsData?.values?.[
        DDvalueTypeKey as VV.DomainTypes.DegreeDaysInsect.TTimeseriesValueTypeKeysMerged
      ]?.[0]?.metadata?.forecastStartsAt,
    )

    if (showRegions) {
      if (DDSeries.length) {
        const baseSeries = DDSeries[0] as TChartSeries & { color?: TRGBAColorWith1AtTheEnd }

        const regionalSeries = makeRegionalSeriesFromRegularSeries(baseSeries, {
          name: `${regionName} (${translate.phrases.banyanApp('Degree Days')})`,
          data: (
            regionsData?.values?.[
              DDvalueTypeKey as VV.DomainTypes.DegreeDaysInsect.TTimeseriesValueTypeKeysMerged
            ]?.[0]?.timeseries ?? []
          ).map((d: { timestamp: string; value: number | null }) => [
            +new Date(d.timestamp),
            unitConverter.insectDegreeDaysCumulative(d.value).value(),
          ]),
          color: colorRules.regionDegreeDay,
        })

        DDSeries.push(regionalSeries)
      } else {
        const seriesName = targetScopeHasDDValueTypes
          ? `${regionName} (${translate.phrases.banyanApp('Degree Days')})`
          : unitConverter.insectDegreeDaysCumulative().categoryTitleWithoutUnit()

        const insectDegreeDaysCumulativeSeriesForRegion: TChartSeries & {
          color?: TRGBAColorWith1AtTheEnd
        } = {
          name: seriesName,
          id: `${INSECT_DEGREE_DAY_VALUE_KEY_PREFIX}${insectId}-region`,
          tooltip: {
            valueDecimals: unitConverter.insectDegreeDaysCumulative().defaultNumberOfDecimalPlaces(),
            valueSuffix: ` ${unitConverter.insectDegreeDaysCumulative().suffix()}`,
          },
          yAxis: 0,
          data: (
            regionsData?.values?.[
              DDvalueTypeKey as VV.DomainTypes.DegreeDaysInsect.TTimeseriesValueTypeKeysMerged
            ]?.[0]?.timeseries ?? []
          ).map((d: { timestamp: string; value: number | null }) => [
            +new Date(d.timestamp),
            unitConverter.insectDegreeDaysCumulative(d.value).value(),
          ]),
          type: 'line',
          marker: {
            enabled: false,
          },
          color: colorRules.regionDegreeDay,
        }

        DDSeries.push(makeRegionalSeries(insectDegreeDaysCumulativeSeriesForRegion))
      }
    }
  }

  return {
    biofixPlotLines,
    forecastMoments,
    DDSeries,
  }
}
