import { Tooltip } from '@mantine/core'
import { translate } from 'i18n/i18n'
import { isEmpty } from 'lodash'
import moment from 'moment-timezone'
import type { FC } from 'react'
import { BIOFIX_OPTIONS } from '../_utils/biofixOptions'
import type { BiofixDayType, MonthPropType } from '../types'

const colors = {
  BIOFIX_TIMELINE: {
    biofixLine: 'rgba(230, 16, 183, 1)',
    default: '#EEEEEE',
    divider: '#BFBFBF',
    green: 'rgb(0, 140, 57, 1)',
    orange: 'rgba(232, 116, 59, 1)',
    secondary: 'rgba(151, 151, 151, 1)',
    secondaryFaded: 'rgba(151, 151, 151, 0.31)',
  },
}

export const Month: FC<MonthPropType> = ({
  biofixConfigOption,
  biofixes,
  deleteBiofixes = [],
  editBiofixDate,
  isBlockSelected,
  isLast,
  loading,
  monthIndex,
  selectedBiofixDate,
  text,
  timezone,
  year,
}) => {
  const monthArgument = moment.tz(timezone).month(monthIndex).month()
  const currentMonth = moment.tz(timezone).month()

  let isValidDate = monthArgument <= currentMonth

  const currentYear = moment.tz(timezone).year()

  if (currentYear !== year && year < currentYear) isValidDate = true

  const monthStart = moment.tz(timezone).year(year).month(monthIndex).startOf('month')
  const monthEnd = moment.tz(timezone).year(year).month(monthIndex).endOf('month').startOf('day')
  const totalDays = monthEnd.diff(monthStart, 'days')

  let isSameMonth = moment.tz(selectedBiofixDate, timezone).month() === monthArgument

  if (biofixConfigOption === BIOFIX_OPTIONS.EDIT) {
    isSameMonth = moment.tz(editBiofixDate, timezone).month() === monthArgument
  }

  let addBiofix: BiofixDayType = null

  if (biofixConfigOption === BIOFIX_OPTIONS.ADD && isSameMonth && isBlockSelected) {
    addBiofix = {
      date: moment.tz(selectedBiofixDate, timezone),
      isNewDate: true,
      percent: moment.tz(selectedBiofixDate, timezone).diff(monthStart, 'days') / totalDays,
      isEditDate: false,
      isDeleteDate: false,
    }
  }

  const isEditSameMonthSelectedBlock =
    biofixConfigOption === BIOFIX_OPTIONS.EDIT && isSameMonth && isBlockSelected

  const biofixDays = biofixes.map((b) => {
    const sameEditAsEditDate = editBiofixDate
      ? moment.tz(b, timezone).isSame(moment.tz(editBiofixDate, timezone))
      : false

    const biofix: BiofixDayType = {
      date: moment.tz(b, timezone),
      isEditDate: sameEditAsEditDate && isEditSameMonthSelectedBlock,
      isDeleteDate: !!deleteBiofixes.find((db) => moment.tz(db, timezone).isSame(moment.tz(b, timezone))),
      percent: moment.tz(b, timezone).diff(monthStart, 'days') / totalDays,
      isNewDate: false,
    }

    return biofix
  })

  if (addBiofix) {
    biofixDays.push(addBiofix)
  }

  biofixDays.filter((item) => !isEmpty(item))

  let backgroundColor = colors.BIOFIX_TIMELINE.secondaryFaded

  if (isValidDate) {
    backgroundColor = colors.BIOFIX_TIMELINE.default
  }

  if (loading) {
    backgroundColor = 'transparent'
  }

  return (
    <div css={{ zIndex: 0 }}>
      <div
        css={{
          backgroundColor: backgroundColor,
          height: '30px',
          position: 'relative',
          width: `${960 / 12}px`,
          borderRight: isLast ? 'none' : `1px solid ${colors.BIOFIX_TIMELINE.divider}`,
        }}
      >
        {!isValidDate
          ? null
          : biofixDays.map(({ date, percent, isNewDate, isEditDate, isDeleteDate }) => {
              let lineColor = colors.BIOFIX_TIMELINE.biofixLine

              if (isNewDate) {
                lineColor = colors.BIOFIX_TIMELINE.green
              }

              if (isEditDate || isDeleteDate) {
                lineColor = colors.BIOFIX_TIMELINE.orange
              }

              const isHighlighted = isNewDate || isEditDate || isDeleteDate

              return (
                <div key={`${date?.toISOString()} ${isHighlighted}`} css={{ display: 'inline' }}>
                  <Tooltip label={date ? translate.dates.format(date, 'MMM D, YYYY') : null}>
                    <div
                      css={{
                        'borderRight': `${isHighlighted ? 2 : 1}px ${
                          isHighlighted ? 'solid' : 'dashed'
                        } ${lineColor}`,
                        'content': '""',
                        'height': '30px',
                        'position': 'absolute',
                        'transform': `translate(${percent * (950 / 12)}px, 0px)`,
                        'z-index': 1,
                        '&:after': {
                          position: 'absolute',
                          content: '""',
                          backgroundColor: lineColor,
                          borderRadius: '50%',
                          display: 'inline-block',
                          height: `${isHighlighted ? 6 : 5}px`,
                          transform: `translate(-2px, ${isHighlighted ? -6 : -4}px)`,
                          width: `${isHighlighted ? 6 : 5}px`,
                        },
                      }}
                    />
                  </Tooltip>
                </div>
              )
            })}
      </div>
      <div css={{ textAlign: 'center', color: colors.BIOFIX_TIMELINE.secondary }}>{text}</div>
    </div>
  )
}
