import type { TFieldAssetKeyTypes } from 'App/Map/types'
import { AlmaSummary } from 'components/AlmaSummary/AlmaSummary'
import { getSummaryParams as getDegreeDaysSummaryParams } from './insectDegreeDaySummary'
import { getSummaryParams as getTrapSummaryParams } from './insectTrapSummary'

export const AlmaSummaries = ({
  insectId,
  propertyId,
  regionId,
}: {
  insectId: TFieldAssetKeyTypes.TInsectId
  propertyId?: TFieldAssetKeyTypes.TPropertyId | null
  regionId?: TFieldAssetKeyTypes.TRegionId | null
}) => {
  return (
    <AlmaSummary
      context="details-panel"
      summaries={{
        [`insectTrapsSummary_${insectId}`]: getTrapSummaryParams({ insectId, propertyId, regionId }),
        [`insectDegreeDaysSummary_${insectId}`]: getDegreeDaysSummaryParams({
          insectId,
          propertyId,
          regionId,
        }),
      }}
    />
  )
}
