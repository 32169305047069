import { makeBaseSvgIconProps } from 'components/icons/_utils/makeBaseSvgIconProps'

export const IconPlusCircle = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 20 20')}>
      <path
        d="M16.148 10.7906L16.148 9.48143L10.9576 9.48143V4.29098H9.64837V9.48143L4.45792 9.48143L4.45792 10.7906L9.64837 10.7906V15.9811H10.9576V10.7906L16.148 10.7906Z"
        fill="currentColor"
      />
      <circle cx="10" cy="10" r="9.5" stroke="currentColor" />
    </svg>
  )
}
