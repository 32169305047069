import { LoadingOverlay } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { EDIT_PESTS_BIOFIX_ID_insectId } from '@semios/app-platform-value-type-definitions'
import { useVirtualizer } from '@tanstack/react-virtual'
import { Authorization } from 'components/Authorization/Authorization'
import { Button } from 'components/Button/Button'
import { ConfirmationSettingsModal } from 'components/ConfirmationSettingsModal/ConfirmationSettingsModal'
import { ModalDrawer } from 'components/ModalDrawer/ModalDrawer'
import { translate } from 'i18n/i18n'
import { isEmpty } from 'lodash'
import moment from 'moment-timezone'
import type { FC } from 'react'
import { useEffect, useRef, useState } from 'react'
import { SharedSettings } from 'settings/SharedSettings'
import { detailsPanelStore } from 'stores/detailsPanelStore'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import { getInsectNameFromId } from 'utils/getInsectNameFromId'
import { useScreenSize } from 'utils/useScreenSize'
import { BiofixConfigMenu } from './BiofixConfigMenu/BiofixConfigMenu'
import BiofixTabTitle from './BiofixTabTitle/BiofixTabTitle'
import { BiofixYearBar } from './BiofixYearBar/BiofixYearBar'
import { BlockHeader } from './BlockHeader/BlockHeader'
import { BIOFIX_OPTIONS } from './_utils/biofixOptions'
import useBlockMultipleBiofix from './_utils/useBlockMultipleBiofix'

const refreshTheDetailsPanelData = () =>
  detailsPanelStore.setState((s) => ({ ...s, keyForRefreshing: new Date().toISOString() }))

export const BiofixModal: FC<{ insectId: number; propertyId: number }> = ({ insectId, propertyId }) => {
  const [opened, { open, close }] = useDisclosure(false)
  const [showConfirmCancelWithoutSaving, setShowConfirmCancelWithoutSaving] = useState(false)
  const fieldAsset = fieldAssetStore.getState()
  const selectedProperty = fieldAsset.properties?.[Number(propertyId)]
  const timezone = selectedProperty?.timezone || moment.tz.guess()

  const {
    biofixBlocks: blocks,
    biofixConfigOption,
    biofixDate,
    deleteBiofixByBlockId,
    editBiofixDateByBlockId,
    handleAddBiofix,
    handleDeleteBiofix,
    handleEditBiofix,
    loading,
    selectedBlocks,
    setBiofixConfigOption,
    setBiofixDate,
    setDeleteBiofixByBlockId,
    setEditBiofixDateByBlockId,
    setSelectedBlocks,
    year,
    setYear,
    propertyName,
    getCropsBlocksMultiSelectData,
    handleClearTentativeEdit,
    resetState,
    blockIdsWithChanges,
  } = useBlockMultipleBiofix({ opened, insectId, propertyId })

  const rowVirtualizerParentRef = useRef<HTMLDivElement>(null)

  const rowVirtualizer = useVirtualizer({
    count: blocks.length,
    getScrollElement: () => rowVirtualizerParentRef.current,
    estimateSize: () => 75,
    overscan: 2,
    getItemKey: (index) => blocks[index].id,
  })

  const handleClickToClose = () => {
    const mightHaveUnsavedChanges = biofixConfigOption && !loading

    if (mightHaveUnsavedChanges) {
      setShowConfirmCancelWithoutSaving(true)
    } else {
      handleClose()
    }
  }

  const handleSaveAndClose = async () => {
    if (biofixConfigOption === BIOFIX_OPTIONS.EDIT) {
      await handleEditBiofix()
    } else if (biofixConfigOption === BIOFIX_OPTIONS.DELETE) {
      await handleDeleteBiofix()
    } else if (biofixConfigOption === BIOFIX_OPTIONS.ADD) {
      await handleAddBiofix()
    }

    handleClose()
  }

  const handleResetAndClose = () => {
    resetState()

    handleClose()
  }

  const handleClose = () => {
    const selectedBlockId = selectedFieldAssetsStore.getState().block

    if (selectedBlockId && blockIdsWithChanges.has(selectedBlockId)) {
      // trigger refresh of charts if the block user is viewing has been edited
      refreshTheDetailsPanelData()
    }

    resetState()

    close()
  }

  useEffect(() => {
    // as the user changes biofix config option, we should scroll back to the top
    rowVirtualizer.scrollToOffset(0)
  }, [biofixConfigOption])

  const isScrolledToTop = !rowVirtualizer.scrollOffset
  const { isWideScreen } = useScreenSize()
  const width = isWideScreen ? '1025px' : '100%'

  return (
    <Authorization requires={{ entity: propertyId, permission: EDIT_PESTS_BIOFIX_ID_insectId(insectId) }}>
      <>
        <ModalDrawer
          opened={opened}
          onClose={handleClickToClose}
          title={translate.phrases.templates('{{labelA}} - {{labelB}} - {{labelC}}', {
            labelA: translate.phrases.banyanApp('Manage Biofix'),
            labelB: propertyName,
            labelC: getInsectNameFromId(insectId),
          })}
          size={width}
          zIndex={SharedSettings.DEFAULT_MODAL_DRAWER_Z_INDEX}
        >
          <LoadingOverlay visible={loading} />

          <div
            css={{
              display: 'grid',
              gridTemplateRows: 'auto 1fr',
              alignItems: 'center',
              width: width,
              height: '100%',
            }}
          >
            <div
              css={{
                boxShadow: isScrolledToTop ? 'unset' : '0px -10px 20px 15px rgba(0,0,0,0.3)',
                transition: '200ms ease all',
                zIndex: 2,
                width: width,
              }}
            >
              <div css={{ padding: '5px 10px' }}></div>
              <BiofixConfigMenu
                biofixConfigOption={biofixConfigOption}
                setBiofixConfigOption={setBiofixConfigOption}
                biofixDate={biofixDate}
                setBiofixDate={setBiofixDate}
                deleteBiofixByBlockId={deleteBiofixByBlockId}
                editBiofixDateByBlockId={editBiofixDateByBlockId}
                handleAddBiofix={handleAddBiofix}
                handleDeleteBiofix={handleDeleteBiofix}
                handleEditBiofix={handleEditBiofix}
                loading={loading}
                timezone={timezone}
                selectedBlocks={selectedBlocks}
                setSelectedBlocks={setSelectedBlocks}
                year={year}
                getCropsBlocksMultiSelectData={getCropsBlocksMultiSelectData}
                handleClearTentativeEdit={handleClearTentativeEdit}
              />
              <BiofixTabTitle setYear={setYear} timezone={timezone} year={year} />
            </div>

            {blocks.length > 0 && (
              <div
                css={{
                  height: '100%',
                  maxHeight: '100%',
                  width: '100%',
                  maxWidth: width,
                  overflow: 'auto',
                  padding: '0px 24px 24px 24px',
                }}
                ref={rowVirtualizerParentRef}
              >
                <div>
                  {!isEmpty(blocks) &&
                    blocks.map((b) => {
                      let isSelected = !!selectedBlocks.find((block) => block.id === String(b.id))

                      if (biofixConfigOption === BIOFIX_OPTIONS.EDIT) {
                        isSelected = !!Object.keys(editBiofixDateByBlockId).find((bId) => +bId === +b.id)
                      }

                      const startOfYear = moment.tz(timezone).set('year', year).startOf('year')
                      const endOfYear = moment.tz(timezone).set('year', year).endOf('year')

                      const biofixes =
                        b.biofix &&
                        b.biofix.filter((b) => {
                          const biofix = +moment.tz(b, timezone)

                          return +startOfYear <= biofix && biofix <= +endOfYear
                        })

                      const biofixesData = (biofixes || [])
                        .sort((a, b) => moment.tz(a, timezone).diff(moment.tz(b, timezone)))
                        .map((biofixDate) => ({
                          value: moment.tz(biofixDate, timezone).toISOString(),
                          label: translate.dates.format(moment.tz(biofixDate, timezone), 'MMM D, YYYY'),
                        }))

                      const deletedBiofixes = (deleteBiofixByBlockId[b.id] || []).map((deletedBiofix) => ({
                        value: moment.tz(deletedBiofix, timezone).toISOString(),
                        label: translate.dates.format(moment.tz(deletedBiofix, timezone), 'MMM D, YYYY'),
                      }))

                      const biofixToEdit = editBiofixDateByBlockId[b.id]

                      return (
                        <div key={b.id} css={{ marginBottom: '15px' }}>
                          <BlockHeader
                            block={b}
                            timezone={timezone}
                            isSelected={isSelected}
                            biofixConfigOption={biofixConfigOption}
                            biofixesForBlock={biofixesData}
                            deletedBiofixes={deletedBiofixes}
                            setDeleteBiofixByBlockId={setDeleteBiofixByBlockId}
                            setEditBiofixDateByBlockId={setEditBiofixDateByBlockId}
                            biofixToEdit={biofixToEdit}
                            onBlockSelect={(e) => {
                              if (!e.target.checked) {
                                setSelectedBlocks((prevState) =>
                                  [...prevState].filter((block) => block.id !== String(b.id)),
                                )
                              } else {
                                setSelectedBlocks((prevState) => [
                                  ...prevState,
                                  {
                                    id: String(b.id),
                                    label: b.name,
                                    value: String(b.id),
                                    group: b.crop.name,
                                  },
                                ])
                              }
                            }}
                          />
                          <BiofixYearBar
                            biofixConfigOption={biofixConfigOption}
                            biofixDate={biofixDate}
                            biofixDates={biofixes || []}
                            deleteBiofixes={deleteBiofixByBlockId[b.id]}
                            editBiofixDate={editBiofixDateByBlockId[b.id]}
                            isBlockSelected={isSelected}
                            timezone={timezone}
                            loading={loading}
                            year={year}
                          />
                        </div>
                      )
                    })}
                </div>
              </div>
            )}
          </div>
          <ConfirmationSettingsModal
            confirmModalOpened={showConfirmCancelWithoutSaving}
            setConfirmModalOpened={setShowConfirmCancelWithoutSaving}
            handleResetAndClose={handleResetAndClose}
            handleUpdate={handleSaveAndClose}
          />
        </ModalDrawer>

        <Button onClick={open} variant="link">
          {translate.phrases.banyanApp('Manage Biofix')}
        </Button>
      </>
    </Authorization>
  )
}
