import type { TCurrentValuesMapCacheKeys, TCurrentValuesMapProcessedCaches } from './by-domain/_types'
import { generateUsualStyleGetCacheUpdatesFromResponse } from './by-domain/_utils/generateUsualStyleGetCacheUpdatesFromResponse'

export const updateMapWhenNoCurrentValuesToRender = ({
  processedCaches,
  cacheKeys,
}: {
  processedCaches: TCurrentValuesMapProcessedCaches
  cacheKeys: TCurrentValuesMapCacheKeys
}) => {
  return generateUsualStyleGetCacheUpdatesFromResponse({
    cacheKeys,
    processedCaches,
    response: {},
    heatmapExtremesForAllPropertiesInVisibleRegions: false,
  })
}
