import type { routes } from '@semios/app-platform-banyan-route-definitions'
import { sortByKey } from '@semios/app-platform-common'
import { DropdownSelectorProperty } from 'App/Map/PanelDetails/SectionTitleBars/DropdownSelectorProperty/DropdownSelectorProperty'
import { AlternariaSettings } from 'App/Map/PanelDetails/_utils/by-domain/alternaria/AlternariaSettings/AlternariaSettings'
import { propertyLacksPermissionSectionMaker } from 'App/Map/PanelDetails/_utils/propertyLacksPermissionSectionMaker'
import { selectedPropertyHasPermission } from 'App/Map/PanelDetails/_utils/selectedPropertyHasPermission'
import type { TCachedValuesFieldAssetData } from 'App/Map/PanelDetails/_utils/useApiValuesTimeseries/_types'
import { EAggregationInterval } from 'App/Map/types'
import type { GridTableContentSection, GridTableContentSectionItem } from 'components/GridTable/types'
import { QuestionToolTip } from 'components/QuestionToolTip/QuestionToolTip'
import { translate } from 'i18n/i18n'
import { cloneDeep, isEmpty } from 'lodash'
import type { Dispatch, SetStateAction } from 'react'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import type { TSelectedFieldAssetsStoreState } from 'stores/selectedFieldAssetsStore'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import type { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { filterFieldAssetsByValueTypes } from 'utils/filterFieldAssetsByValueTypes'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import { BadgeCell } from '../../../BadgeCell/BadgeCell'
import { BlockWithWeatherStationLabel } from '../../../BlockWithWeatherStationLabel/BlockWithWeatherStationLabel'
import { blockTimestampToValuesDictionaryMaker, defaultValuesRowHeight } from '../_utils'
import { getAlternariaStatus } from './getAlternariaStatus'

const checkPermission = () => selectedPropertyHasPermission({ permission: 'VIEW_ALTERNARIA_DETAILS' })
const preferredAggregationInterval = { preferredAggregationInterval: EAggregationInterval.UNAGGREGATED }

const valuesRequested: Record<string, typeof preferredAggregationInterval> = {
  alternariaCumulativeRolling7Days: preferredAggregationInterval,
}

export const apiArgs = ({
  selectedValueGroups,
  selectedFieldAssets,
}: {
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}): Partial<routes.Values.Request> => {
  if (!checkPermission()) return {}

  if (!selectedValueGroups.alternaria || !selectedFieldAssets.property) return {}

  const blocks = fieldAssetStore.getState()?.properties?.[Number(selectedFieldAssets.property)]?.blocks

  if (!blocks) return {}

  const relevantBlocksForProperty = filterFieldAssetsByValueTypes({
    fieldAssets: Object.values(blocks),
    valuesTimeseries: Object.keys(valuesRequested),
  })

  if (isEmpty(relevantBlocksForProperty)) return {}

  const blockIds = relevantBlocksForProperty.map((block) => block.blockId)

  return {
    blocks: {
      blockIds,
      valuesRequested,
    },
  }
}

const dsvValueType = 'alternaria'
const dsv7DaysValueType = 'alternariaCumulativeRolling7Days'

const generateItem = ({
  id,
  values,
  valueType,
  sprayThreshold,
  label,
  weatherStationLabel,
}: {
  id: string
  values: ReturnType<typeof blockTimestampToValuesDictionaryMaker>
  valueType: keyof typeof unitConverter
  sprayThreshold?: number
  label: string
  weatherStationLabel: string
  setLocallyCachedValuesData?: Dispatch<SetStateAction<TCachedValuesFieldAssetData>>
}): GridTableContentSectionItem & { blockIdLabel: string } => ({
  id,
  label: <BlockWithWeatherStationLabel label={label} weatherStationLabel={weatherStationLabel} />,
  blockIdLabel: label,
  height: defaultValuesRowHeight,
  labelMinWidth: 160,
  valueMinWidth: 120,
  render: (dataPoint: { timestamp: string; value: number | null } | undefined) => {
    if (dataPoint === undefined || dataPoint === null || dataPoint.value === null)
      return translate.phrases.templates('-')

    const valueToUse = unitConverter[valueType](dataPoint.value).value()

    if (valueToUse === null || valueToUse === undefined) return translate.phrases.templates('-')

    if (valueType === dsvValueType) return valueToUse

    const backgroundColor = getAlternariaStatus(valueToUse, sprayThreshold)

    return (
      <BadgeCell backgroundColor={backgroundColor}>
        {unitConverter[valueType](dataPoint.value).value()}
      </BadgeCell>
    )
  },
  values,
})

export const content = ({
  data,
  updateData,
}: {
  data: routes.Values.Response
  updateData: (pathToSet: string, dataToSet: unknown) => void
}): GridTableContentSection => {
  const { property } = selectedFieldAssetsStore.getState()
  const blocks = fieldAssetStore.getState()?.properties?.[Number(property)]?.blocks ?? {}
  const points = fieldAssetStore.getState()?.properties?.[Number(property)]?.points ?? {}

  const commonReturnItems = {
    title: unitConverter.alternariaCumulativeRolling7Days().categoryTitleWithoutUnit(),
    titleCss: { width: '100%' },
    titleChildren: (
      <>
        <QuestionToolTip>{translate.phrases.banyanApp('7 Day Disease Severity Value')}</QuestionToolTip>
        <DropdownSelectorProperty valuesTimeseriesToFilterOn={Object.keys(valuesRequested)} />
        <AlternariaSettings
          handleAfterSave={(blockIdToNewThreshold) => {
            const blocksToUpdate = Object.keys(blocks).filter(
              (blockId) => blockIdToNewThreshold[Number(blockId)],
            )

            if (blocksToUpdate.length === 0) return

            const oldData = cloneDeep(data.blocks) ?? {}

            blocksToUpdate.forEach((blockId) => {
              const valuesForBlock = data.blocks?.[blockId]?.values?.alternariaCumulativeRolling7Days?.[0]

              if (!valuesForBlock) return

              const updated = {
                ...valuesForBlock,
                metadata: {
                  ...valuesForBlock.metadata,
                  blockDSVSprayThreshold: blockIdToNewThreshold[Number(blockId)],
                },
              }

              oldData[Number(blockId)] = {
                ...oldData[Number(blockId)],
                values: {
                  ...(oldData[Number(blockId)]?.values ?? {}),
                  alternariaCumulativeRolling7Days: [updated],
                },
              }
            })

            updateData('blocks', oldData)
          }}
        />
      </>
    ),
    id: 'summary-grid-alternaria',
  }

  if (!checkPermission()) return propertyLacksPermissionSectionMaker(commonReturnItems)

  const relevantBlocksForProperty = filterFieldAssetsByValueTypes({
    fieldAssets: Object.values(blocks),
    valuesTimeseries: Object.keys(valuesRequested),
  })

  const items = relevantBlocksForProperty
    .map((block) => {
      const sprayThreshold = data?.blocks?.[block.blockId]?.values?.[dsv7DaysValueType]?.[0].metadata
        .blockDSVSprayThreshold as number

      const weatherStationLabel =
        !!block.associatedWeatherStation && points[block.associatedWeatherStation]?.name
          ? points[block.associatedWeatherStation].name
          : translate.phrases.banyanApp('Unknown Weather Station')

      const label = block.name || translate.phrases.banyanApp('Unnamed Block')

      return generateItem({
        id: `alternaria-cumulative-dsv-${block.blockId}`,
        valueType: dsv7DaysValueType,
        values: blockTimestampToValuesDictionaryMaker(data, dsv7DaysValueType, block.blockId),
        sprayThreshold,
        label,
        weatherStationLabel,
      }) as GridTableContentSectionItem
    })
    .sort(sortByKey('blockIdLabel'))

  return {
    ...commonReturnItems,
    items,
  }
}
