import type { TNodeDevice } from '../types'

export enum EDeviceStatus {
  PLANNED,
  INSTALLED,
  DISCONNECTED,
  CONNECTED,
  MISMATCHED,
}

export type TDeviceConfiguration = Required<Omit<TNodeDevice, 'imageUrls' | 'installationStatus'>>
