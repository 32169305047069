import { apiFetch } from 'utils/apiFetch'

export const irrigationSchedulerFertigationSettingsGet = async ({
  propertyIds,
}: {
  propertyIds: number[]
}) => {
  return await apiFetch({
    url: '/irrigation-fertigation-settings-get',
    body: { propertyIds },
    params: { timeout: 180000 },
  })
}
