import { Button } from 'components/Button/Button'
import { IconLeftArrowCircle } from 'components/icons/IconLeftArrowCircle'
import { IconRightArrowCircle } from 'components/icons/IconRightArrowCircle'
import moment from 'moment-timezone'
import type { FC } from 'react'
import { useScreenSize } from 'utils/useScreenSize'
import { getMinAndMaxYear } from '../_utils/getMinAndMaxYear'
import type { BiofixTabTitlePropType } from '../types'

const cssForArrows = {
  'fontSize': '28px',
  'padding': 0,
  ':disabled': {
    svg: {
      fill: 'transparent',
    },
  },
}

const BiofixTabTitle: FC<BiofixTabTitlePropType> = ({ setYear, timezone, year }) => {
  const { maxYear, minYear } = getMinAndMaxYear(timezone)
  const { isWideScreen } = useScreenSize()

  return (
    <div
      css={{
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '20px',
        marginTop: '20px',
        width: '100%',
      }}
    >
      <Button
        variant="link"
        leftIcon={<IconLeftArrowCircle />}
        onClick={() => {
          if (year > minYear) {
            setYear(year - 1)
          }
        }}
        css={cssForArrows}
        disabled={+year === minYear}
      />

      <h3
        css={{
          margin: '0 50px',
          fontStyle: 'normal',
          fontWeight: 'bold',
          fontSize: '18px',
          lineHeight: '22px',
        }}
      >
        {`${moment // TODO: use translate.dates.format and use correct months based on hemisphere
          .tz(timezone)
          .month(0)
          .format(`${isWideScreen ? 'MMMM' : 'MMM'}`)} - ${moment
          .tz(timezone)
          .month(11)
          .format(`${isWideScreen ? 'MMMM' : 'MMM'}`)} ${year}`}
      </h3>
      <Button
        variant="link"
        leftIcon={<IconRightArrowCircle />}
        onClick={() => {
          if (maxYear > year) {
            setYear(year + 1)
          }
        }}
        css={cssForArrows}
        disabled={+year === maxYear}
      />
    </div>
  )
}

export default BiofixTabTitle
