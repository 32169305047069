import { Portal } from '@mantine/core'
import React, { useEffect, useState } from 'react'
import { SharedSettings } from 'settings/SharedSettings'
import { init } from 'stores/_utils/simple-store'

export type StatusbarProps = {
  show: boolean
  period?: number
  children: React.ReactNode
}

const StatusbarStore = init<StatusbarProps>({
  show: false,
  children: <></>,
})

const show = (props: Omit<StatusbarProps, 'show'>) => {
  StatusbarStore.setState((prev) => {
    return { ...prev, ...props, period: props.period, show: true }
  })
}

const hide = () => {
  StatusbarStore.setState((prev) => {
    return { ...prev, show: false }
  })
}

const Overlay: React.FC = () => {
  const { show: isShow, period, children } = StatusbarStore.useSelector((props) => props)
  const [currentPeriod, setCurrentPeriod] = useState<NodeJS.Timeout>()

  useEffect(() => {
    if (currentPeriod) {
      clearTimeout(currentPeriod)

      setCurrentPeriod(undefined)
    }

    if (period) {
      setCurrentPeriod(
        setTimeout(() => {
          hide()
        }, period),
      )
    }

    return () => {
      if (currentPeriod) {
        clearTimeout(currentPeriod)
      }
    }
  }, [period, children])

  if (!isShow) {
    return null
  }

  return (
    <Portal
      css={{
        zIndex: SharedSettings.DEFAULT_MINI_STATS_BAR_Z_INDEX,
        position: 'absolute',
        width: '100%',
      }}
    >
      {children}
    </Portal>
  )
}

export const MiniStatusbar = {
  show,
  hide,
  Overlay,
}
