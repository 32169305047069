import { IconPin } from 'components/icons/IconPin'
import type React from 'react'
import { useContext, useEffect, useRef } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import svg64 from 'svg64'
import { MapContext } from '../../../Map/MapContext/MapContext'

const ICON_WIDTH = 28
const ICON_HEIGHT = 40

export const InitialLocationMarker: React.FC<{
  coordinates: { lat: number; lng: number }
}> = ({ coordinates }) => {
  const { map } = useContext(MapContext)
  const markerRef = useRef<null | google.maps.Marker>(null)
  const { viewBox, width, height, children: svgElement } = IconPin().props

  const SVGIconString = `
    <svg width="${width}" height="${height}" viewBox="${viewBox}" xmlns="http://www.w3.org/2000/svg" color="grey">
        ${renderToStaticMarkup(svgElement)}
    </svg>`

  useEffect(() => {
    if (!map) return

    const marker = new window.google.maps.Marker({
      map,
      position: coordinates,
      icon: {
        url: svg64(SVGIconString),
        scaledSize: new window.google.maps.Size(ICON_WIDTH, ICON_HEIGHT),
      },
    })

    markerRef.current = marker

    return () => {
      // Remove the marker when the component unmounts
      marker.setMap(null)
    }
  }, [map])

  return null
}
