import { OfflineRequestError } from 'utils/errorCodes'
import type { TDeviceConfiguration } from '../DevicesManagement/types'
import { ApiRequestQueue, ApiRequestStatus, ApiRequestType } from './api/queue'
import type { NodeDeviceInstallMeta } from './api/serviceCenterNodeDeviceInstall'
import { serviceCenterNodeDeviceInstall } from './api/serviceCenterNodeDeviceInstall'

export async function installNodeDevice(
  nodeIdentifier: string,
  config: TDeviceConfiguration,
  force?: boolean,
) {
  let unsynced = true

  const request: NodeDeviceInstallMeta = {
    ...config,
    nodeIdentifier,
    force,
  }

  try {
    const { status } = await ApiRequestQueue.createRequest(ApiRequestType.INSTALL_NODE_DEVICE, request)

    if (status === ApiRequestStatus.COMPLETED) unsynced = false
  } catch (error) {
    // If the request failed because the app is offline, don't throw an error
    if (!(error instanceof OfflineRequestError)) {
      // TODO: Return a user-friendly error message
      throw error
    }
  }

  if (unsynced) {
    serviceCenterNodeDeviceInstall.callback(undefined, request)
  }
}
