import { arrayOfObjectsSearch } from '@semios/app-platform-common'
import type { TFieldAssetValueTypes } from 'App/Map/types'
import { NoDataProperty } from 'App/Map/UserSettingsMenu/components/NoDataProperty'
import { SettingsSearchNotFound } from 'App/Map/UserSettingsMenu/components/SettingsSearchNotFound/SettingsSearchNotFound'
import { translate } from 'i18n/i18n'
import { useState } from 'react'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { sortByKey } from 'utils/sortByKey'
import { DeleteEmitterGroupModal } from './DeleteEmitterGroupModal'
import { EditEmitterGroupModal } from './EditEmitterGroupModal'
import { PropertySection } from './PropertySection'

export const GroupsTab = ({
  filterPropertiesText,
  selectedPropertyId,
}: {
  filterPropertiesText: string
  selectedPropertyId: number | null
}) => {
  const properties = Object.values(fieldAssetStore.useSelector((s) => s.properties) ?? {})

  const [selectedZoneGroupEdit, setSelectedZoneGroupEdit] = useState<
    (TFieldAssetValueTypes.TIrrigationZoneEmitterGroup & { propertyId: number }) | null
  >(null)

  const [selectedZoneGroupDelete, setSelectedZoneGroupDelete] = useState<
    (TFieldAssetValueTypes.TIrrigationZoneEmitterGroup & { propertyId: number }) | null
  >(null)

  const filteredProperties = selectedPropertyId
    ? [properties.find((p) => p.propertyId === selectedPropertyId)]
    : arrayOfObjectsSearch(properties, filterPropertiesText, ['propertyName']).sort(sortByKey('propertyName'))

  const propertiesWithEmitterGroups = filteredProperties.filter(
    (property) => Object.keys(property?.irrigationZoneEmitterGroups ?? {}).length,
  )

  const propertiesWithoutEmitterGroups = filteredProperties.filter(
    (property) => !Object.keys(property?.irrigationZoneEmitterGroups ?? {}).length,
  )

  const noSearchResults =
    filterPropertiesText && !propertiesWithEmitterGroups.length && !propertiesWithoutEmitterGroups.length

  return (
    <>
      {noSearchResults && <SettingsSearchNotFound />}
      {propertiesWithEmitterGroups.map((property) => {
        if (!property) return null

        return (
          <PropertySection
            key={property.propertyId}
            propertyId={property.propertyId}
            propertyName={property.propertyName}
            zoneGroups={Object.values(property.irrigationZoneEmitterGroups ?? {}).sort(
              sortByKey('groupName'),
            )}
            filterPropertiesText={filterPropertiesText}
            setSelectedZoneGroupEdit={setSelectedZoneGroupEdit}
            setSelectedZoneGroupDelete={setSelectedZoneGroupDelete}
          />
        )
      })}
      {propertiesWithoutEmitterGroups.map((property) => {
        if (!property) return null

        return (
          <NoDataProperty
            key={property.propertyId}
            title={property.propertyName}
            noDataText={translate.phrases.banyanApp('No groups have been created for this property yet.')}
          />
        )
      })}
      <EditEmitterGroupModal
        selectedZoneGroupEdit={selectedZoneGroupEdit}
        setSelectedZoneGroupEdit={setSelectedZoneGroupEdit}
      />
      <DeleteEmitterGroupModal
        selectedZoneGroupDelete={selectedZoneGroupDelete}
        setSelectedZoneGroupDelete={setSelectedZoneGroupDelete}
      />
    </>
  )
}
