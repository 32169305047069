import type { routes } from '@semios/app-platform-banyan-route-definitions'
import { BiofixModal } from 'App/Map/PanelDetails/_utils/by-domain/insectDegreeDays/BiofixModal/BiofixModal'
import { getTimezoneForSelectedPropertyOrRegion } from 'App/Map/PanelDetails/_utils/getTimezoneForSelectedPropertyOrRegion'
import { selectedPropertyHasPermission } from 'App/Map/PanelDetails/_utils/selectedPropertyHasPermission'
import { DropdownSelectorBlock } from 'App/Map/PanelDetails/SectionTitleBars/DropdownSelectorBlock/DropdownSelectorBlock'
import type { StackedChartPestSection } from 'components/StackedChart/types'
import { translate } from 'i18n/i18n'
import { detailsPanelStore } from 'stores/detailsPanelStore'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import type { TSelectedFieldAssetsStoreState } from 'stores/selectedFieldAssetsStore'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import type { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { doesSelectedBlockHaveValueTypes } from 'utils/doesSelectedFieldAssetHaveValueTypes'
import { getDegreeDayValuesRequested } from './_utils/getDegreeDayValuesRequested'
import { getInsectTrapCatchesValuesRequested } from './_utils/getTrapCatchValuesRequested'
import { insectTrapAndDegreeContent } from './_utils/insectTrapAndDegree'

export const apiArgs = ({
  selectedFieldAssets,
  selectedValueGroups,
}: {
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}): Partial<routes.Values.Request> => {
  if (!selectedFieldAssets.block) return {}

  const degreeDayValuesRequested = getDegreeDayValuesRequested({
    selectedValueGroups,
    doesTargetHaveValueTypes: doesSelectedBlockHaveValueTypes,
    permissionCheck: (permissionId) => selectedPropertyHasPermission({ permission: permissionId }),
  })

  const trapCatchValuesRequested = getInsectTrapCatchesValuesRequested({
    selectedValueGroups,
    doesTargetHaveValueTypes: doesSelectedBlockHaveValueTypes,
    permissionCheck: (permissionId) => selectedPropertyHasPermission({ permission: permissionId }),
  })

  const valuesToBeRequested: Partial<routes.Values.Request> = {}

  valuesToBeRequested.blocks = {
    blockIds: [selectedFieldAssets.block],
    valuesRequested: { ...degreeDayValuesRequested, ...trapCatchValuesRequested },
  }

  return valuesToBeRequested
}

export const getTrapCatchValuesRequestedForBlocks = ({
  selectedValueGroups,
  selectedFieldAssets,
}: {
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}): Partial<routes.Values.Request> => {
  const targetScope = selectedFieldAssets.block

  if (!targetScope) return {}

  const valuesRequested = getInsectTrapCatchesValuesRequested({
    selectedValueGroups,
    doesTargetHaveValueTypes: doesSelectedBlockHaveValueTypes,
    permissionCheck: (permissionId) => selectedPropertyHasPermission({ permission: permissionId }),
  })

  if (Object.keys(valuesRequested).length === 0) return {}

  const valuesToBeRequested: Partial<routes.Values.Request> = {}

  valuesToBeRequested.blocks = {
    blockIds: [targetScope],
    valuesRequested: valuesRequested,
  }

  return valuesToBeRequested
}

export const buildBlockSection = ({
  data,
  selectedValueGroups,
  compareSeasonsData,
}: {
  data: routes.Values.Response
  compareSeasonsData: routes.Values.Response
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
}): StackedChartPestSection[] => {
  // builds series for DD
  // builds series for catches
  // puts into a chart
  // returns section with header etc

  const insects = fieldAssetStore.getState()?.insects

  if (!insects) return []

  const selectedFieldAssets = selectedFieldAssetsStore.getState()
  const selectedProperty = selectedFieldAssets.property
  const timezone = getTimezoneForSelectedPropertyOrRegion()
  const { dateFrom, dateTo } = detailsPanelStore.getState()
  const targetScopeId = String(selectedFieldAssets.block)

  const TitleChildren: React.FC<{ valuesTimeseriesToFilterOn: string[]; insectId?: number }> = ({
    valuesTimeseriesToFilterOn,
    insectId,
  }: {
    valuesTimeseriesToFilterOn: string[]
    insectId?: number
  }) => (
    <>
      <DropdownSelectorBlock valuesTimeseriesToFilterOn={valuesTimeseriesToFilterOn} />
      {insectId && <BiofixModal propertyId={Number(selectedProperty)} insectId={insectId} />}
    </>
  )

  return insectTrapAndDegreeContent({
    insects,
    selectedValueGroups,
    targetScope: 'BLOCK',
    targetScopeId,
    targetScopeData: data?.blocks,
    compareSeasonsTargetScopeData: compareSeasonsData?.blocks,
    doesTargetHaveValueTypes: doesSelectedBlockHaveValueTypes,
    sectionTitleSuffix: translate.phrases.banyanApp('Block'),
    TitleChildren,
    pestSectionCategory: 'combined-block-level',
    selectedProperty,
    dateFrom,
    dateTo,
    timezone,
    showRegionalData: false,
  })
}
