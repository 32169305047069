import { now } from 'moment-timezone'
import { useEffect, useState } from 'react'
import { useBleManager } from '../BluetoothLowEnergy/BleManager'
import type { SensorTypeIds } from '../BluetoothLowEnergy/constants'
import { SensorTypeMapping } from '../BluetoothLowEnergy/constants'
import type { SdiSensorStatus } from '../BluetoothLowEnergy/models/LnrNode/commands'
import {
  AllSdiSensorStatus,
  LnrVital,
  RequestAllSdiSensorStatus,
  RequestPushToCloud,
  Rj11SensorStatus,
} from '../BluetoothLowEnergy/models/LnrNode/commands'
import type { AdapterStatus } from '../BluetoothLowEnergy/types'
import type { TNodeDevice, TNodeDevicePort } from '../types'
import { NodeDeviceInstallationStatus } from '../types'
import { EDeviceStatus } from './types'

const mergeDeviceStatus: (
  device: Pick<TNodeDevice, 'source' | 'installationStatus'> | null,
  port: TNodeDevicePort,
  channel: number,
  availablePorts: Set<TNodeDevicePort>,
  devicesStatuses: Map<string, { type: SensorTypeIds; status: number }>,
) => EDeviceStatus | null = (device, port, channel, availablePorts, devicesStatuses) => {
  const installationStatus = device?.installationStatus
  const deviceSource = device?.source

  if (installationStatus === undefined) {
    return null
  }

  //For the port that does not suppor health check, or the ble is not ready
  if (!availablePorts.has(port)) {
    if (installationStatus === NodeDeviceInstallationStatus.PLANNED) {
      return EDeviceStatus.PLANNED
    } else if (installationStatus === NodeDeviceInstallationStatus.INSTALLED) {
      return EDeviceStatus.INSTALLED
    } else {
      return null
    }
  }

  const devStatus = devicesStatuses.get(getKey(port, channel))

  if (installationStatus === NodeDeviceInstallationStatus.PLANNED) {
    return EDeviceStatus.PLANNED
  } else if (devStatus && devStatus.status === 0 && SensorTypeMapping.get(devStatus.type) === deviceSource) {
    return EDeviceStatus.CONNECTED
  } else if (devStatus && devStatus.status === 1) {
    return EDeviceStatus.MISMATCHED
  } else if (devStatus && SensorTypeMapping.get(devStatus.type) !== deviceSource) {
    return EDeviceStatus.MISMATCHED
  } else {
    return EDeviceStatus.DISCONNECTED
  }
}

const getKey = (port: TNodeDevicePort, channel: number) => {
  return `${port}-${channel}`
}

const getRssi = (rssi?: number) => {
  //0x8000（-32768), disconnected
  if (!rssi) {
    return undefined
  }

  return rssi === -32768 ? 'disconnected' : rssi
}

interface LnrNodeStatus {
  adapterStatus: AdapterStatus
  loraNetworkStatus?: number | 'disconnected'
  requestAllDevicesStatusReading(): void
  isReadingAllDevicesStatus: boolean
  getDeviceStatus(
    port: TNodeDevicePort,
    channel: number,
    device: Pick<TNodeDevice, 'source' | 'installationStatus'> | null,
  ): EDeviceStatus | null
}

const useLnrNodeStatus: (bleSupport: boolean, nodeIdentifier: string) => LnrNodeStatus = (
  bleSupport: boolean,
  nodeIdentifier: string,
  scanPeriod = 60000,
) => {
  const bleManager = useBleManager()
  const [isChecking, setIsChecking] = useState(false)
  const [lastCheck, setLastCheck] = useState<number>(now())
  const [sdiSensorStatus, setSdiSensorStatus] = useState<SdiSensorStatus[]>()
  const [rj11SensorStatus, setRj11SensorStatus] = useState<Rj11SensorStatus>()

  const [loraRssi, setLoraRssi] = useState<number | 'disconnected' | undefined>(
    getRssi(bleManager.connectedDevice?.getNotification(LnrVital)?.rssi),
  )

  useEffect(() => {
    if (!bleSupport) {
      return
    }

    let timerId: ReturnType<typeof setTimeout> | null = null
    let isCanceled = false

    const cleanup = () => {
      if (timerId) {
        clearTimeout(timerId)
      }
    }

    const sleep = (ms: number) =>
      new Promise((resolve, reject) => {
        try {
          timerId = setTimeout(resolve, ms)
        } catch (e) {
          reject(e)
        }
      })

    const init = async () => {
      do {
        const device = bleManager.connectedDevice

        if (!device) {
          setSdiSensorStatus(undefined)

          setRj11SensorStatus(undefined)

          setLoraRssi(undefined)

          return
        }

        try {
          setIsChecking(true)

          const vital = await device?.read(LnrVital)

          if (isCanceled) {
            break
          }

          if (vital) {
            setLoraRssi(getRssi(vital.rssi))
          }

          const rj11SensorStatus = await device?.read(Rj11SensorStatus)

          if (isCanceled) {
            break
          }

          if (rj11SensorStatus.commandStatus === 0) {
            setRj11SensorStatus(rj11SensorStatus)
          }

          const allSdiSensorStatus = await device?.writeAndNotify<
            RequestAllSdiSensorStatus,
            AllSdiSensorStatus
          >(new RequestAllSdiSensorStatus(), AllSdiSensorStatus)

          if (isCanceled) {
            break
          }

          try {
            await device?.write(new RequestPushToCloud())
          } catch (error) {
            // sometime the device is busy, ignore the error
            // eslint-disable-next-line no-console
            console.error('Push to cloud error', error)
          }

          if (allSdiSensorStatus.commandStatus === 0) {
            setSdiSensorStatus([...allSdiSensorStatus.sensorStatus])
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('LNR status check error', error)
        } finally {
          setIsChecking(false)
        }

        if (isCanceled) {
          break
        }

        await sleep(scanPeriod)
      } while (timerId)
    }

    init()

    return () => {
      isCanceled = true

      cleanup()
    }
  }, [lastCheck, scanPeriod, nodeIdentifier, bleManager.connectedDevice?.deviceId])

  return {
    adapterStatus: bleManager.adapterStatus,
    loraNetworkStatus: loraRssi,
    isReadingAllDevicesStatus: isChecking,
    requestAllDevicesStatusReading: () => {
      setLastCheck(now())
    },
    getDeviceStatus: (
      port: TNodeDevicePort,
      channel: number,
      device: Pick<TNodeDevice, 'source' | 'installationStatus'> | null,
    ): EDeviceStatus | null => {
      const statusesMap = new Map<string, { type: SensorTypeIds; status: number }>()
      const availablePorts = new Set<TNodeDevicePort>()

      if (sdiSensorStatus) {
        sdiSensorStatus.forEach((s) => {
          statusesMap.set(getKey('sdi', parseInt(s.address)), { type: s.type, status: s.status })
        })

        availablePorts.add('sdi')
      }

      if (rj11SensorStatus) {
        statusesMap.set(getKey('rj11', 0), {
          type: rj11SensorStatus.sensorType,
          status: rj11SensorStatus.sensorStatus,
        })

        availablePorts.add('rj11')
      }

      return mergeDeviceStatus(device, port, channel, availablePorts, statusesMap)
    },
  }
}

export { useLnrNodeStatus }
