import { getValueType } from 'App/Map/CurrentValuesMap/_utils/by-domain/_utils/getValueType'
import { FireBlightHeatmapRow } from 'App/Map/MapControls/HeatmapControlsRow/FireBlightHeatmapRow/FireBlightHeatmapRow'
import { WindMachineHeatmapRow } from 'App/Map/MapControls/HeatmapControlsRow/WindMachineHeatmapRow/WindMachineHeatmapRow'
import { isNil } from 'lodash'
import { colors } from 'settings/colors'
import { HUB_CONNECTOR_ENVIRONMENT_IS_SWAGWORLD } from 'settings/hubConnectorEnvironment'
import { MAP_VISUAL } from 'stores/mapControlsStore/types'
import type { TValueGroup } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { userDetailsStore } from 'stores/userDetailsStore'
import { usePrimaryValueGroup } from 'utils/usePrimaryValueGroup'
import { valueGroupsToUnitConverterFunctionForRendering } from './_utils/valueGroupsToUnitConverterFunctionForRendering'
import { valueGroupsToUnitConverterFunctionForSaving } from './_utils/valueGroupsToUnitConverterFunctionForSaving'
import { AlmondHullSplitHeatmapRow } from './AlmondHullSplitHeatmapRow/AlmondHullSplitHeatmapRow'
import { AlternariaHeatmapRow } from './AlternariaHeatmapRow/AlternariaHeatmapRow'
import { DefaultHeatmapRow } from './DefaultHeatmapRow/DefaultHeatmapRow'
import { IrrigationActivityHeatmapRow } from './IrrigationActivityHeatmapRow/IrrigationActivityHeatmapRow'
import { PlantStressControlRow } from './PlantStressControlRow/PlantStressControlRow'
import { SoilHeatmapRow } from './SoilHeatmapRow/SoilHeatmapRow'
import { TemperatureInversionHeatmapRow } from './TemperatureInversionHeatmapRow/TemperatureInversionHeatmapRow'

export const valueGroupsWithNoHeatmap: Partial<Record<TValueGroup, true>> = {
  spray_conditions: true,
}

const fixedExtremesForValueGroups: Partial<Record<TValueGroup, { min: number; max: number }>> = {
  leaf_wetness: { min: 0, max: 24 },
}

export const HeatmapControlsRow = () => {
  const primaryValueGroup = usePrimaryValueGroup()

  // TODO: we should probably keep this in sync via another method, but this works in a pinch
  userDetailsStore.useSelector((s) => `${s.depth}${s.rain}${s.speed}${s.temperature}`)

  if (isNil(primaryValueGroup)) return null

  if (valueGroupsWithNoHeatmap[primaryValueGroup]) return null

  return (
    <div
      css={{
        'color': HUB_CONNECTOR_ENVIRONMENT_IS_SWAGWORLD ? colors.midnight : colors.white,
        'marginTop': 12,
        'width': '100%',
        'display': 'flex',
        '> div': { display: 'flex', flex: 1 },
      }}
    >
      {(() => {
        if (primaryValueGroup === 'soil') return <SoilHeatmapRow />

        if (primaryValueGroup === 'irrigation_activity') return <IrrigationActivityHeatmapRow />

        if (primaryValueGroup === 'alternaria') return <AlternariaHeatmapRow />

        if (primaryValueGroup === 'fire_blight') return <FireBlightHeatmapRow />

        if (primaryValueGroup === 'wind_machine') {
          return <WindMachineHeatmapRow />
        }

        if (primaryValueGroup === 'plant_stress') return <PlantStressControlRow />

        if (primaryValueGroup === 'almond_hull_split') return <AlmondHullSplitHeatmapRow />

        if (primaryValueGroup === 'air_temperature') {
          const isInversion = getValueType(MAP_VISUAL.POINT, primaryValueGroup) === 'inversion'

          if (isInversion) return <TemperatureInversionHeatmapRow />
        }

        return (
          <DefaultHeatmapRow
            unitConverterFunctionForRendering={
              valueGroupsToUnitConverterFunctionForRendering[primaryValueGroup]
            }
            unitConverterFunctionForSaving={valueGroupsToUnitConverterFunctionForSaving[primaryValueGroup]}
            fixedMinMaxes={fixedExtremesForValueGroups[primaryValueGroup]}
          />
        )
      })()}
    </div>
  )
}
