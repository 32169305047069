import type { BleDeviceFactory } from '../../BleManager'
import { SemiosBleServiceUUID, SensorCommandStatus, SensorTypeIds } from '../../constants'
import type { Decoder, Encoder } from '../../types'
import { emptyPayload, numberToSemiosUUID } from '../../util/utility'
import { CurrentTimestampDecoder, FirmwareDecoder, ToggleBuzzerEncoder } from '../common/coders'
import { SemiosBleNode } from '../semiosBleNode'
import type { SdiSensorStatus } from './commands'
import {
  AllSdiSensorStatus,
  DetectSdi,
  Humidity,
  LnrVital,
  ProgramSdiSensor,
  RequestAllSdiSensorStatus,
  RequestPushToCloud,
  RequestUplinkRebootMessage,
  Rj11SensorStatus,
  Temperature,
} from './commands'

const SensorNotificationUUID = numberToSemiosUUID(0x0006)
const ProgramSdiSensorUUID = numberToSemiosUUID(0x0008)
const PushToCloudUUID = numberToSemiosUUID(0x0009)
const VitalUUID = numberToSemiosUUID(0x000a)
const SdiDetectionUUID = numberToSemiosUUID(0x0012)
const SdiSensorStatusUUID = numberToSemiosUUID(0x0013)
const UplinkRebootMessageUUID = numberToSemiosUUID(0x0014)
const Rj11DetectionUUID = numberToSemiosUUID(0x0015)

const UplinkRebootMessageEncoder: Encoder<RequestUplinkRebootMessage> = {
  type: RequestUplinkRebootMessage,
  uuid: {
    service: SemiosBleServiceUUID,
    characteristic: UplinkRebootMessageUUID,
  },
  encode: function (): DataView {
    return emptyPayload()
  },
}

const PushToCloudEncoder: Encoder<RequestPushToCloud> = {
  type: RequestPushToCloud,
  uuid: {
    service: SemiosBleServiceUUID,
    characteristic: PushToCloudUUID,
  },
  encode: function (): DataView {
    return emptyPayload()
  },
}

const HumidityDecoder: Decoder<Humidity> = {
  type: Humidity,
  notifiable: true,
  uuid: {
    service: SemiosBleServiceUUID,
    characteristic: SensorNotificationUUID,
  },
  decode: function (data: DataView): Humidity | null {
    if (data.getUint8(0) !== SensorCommandStatus.OK) {
      return null
    }

    if (data.getUint8(1) !== SensorTypeIds.ONBOARD_HUMIDITY) {
      return null
    }

    return {
      humidity: data.getUint16(4) / 100.0,
    }
  },
}

const TemperatureDecoder: Decoder<Temperature> = {
  type: Temperature,
  notifiable: true,
  uuid: {
    service: SemiosBleServiceUUID,
    characteristic: SensorNotificationUUID,
  },
  decode: function (data: DataView): Temperature | null {
    if (data.getUint8(0) !== SensorCommandStatus.OK) {
      return null
    }

    if (data.getUint8(1) !== SensorTypeIds.ONBOARD_TEMPERATURE) {
      return null
    }

    return {
      temperature: data.getUint16(4) / 100.0,
    }
  },
}

const VitalDecoder: Decoder<LnrVital> = {
  uuid: {
    service: SemiosBleServiceUUID,
    characteristic: VitalUUID,
  },
  notifiable: true,
  decode: function (data: DataView): LnrVital | null {
    return {
      lithiumBattery: data.getUint16(0) / 100.0,

      solarBattery: data.getUint16(2) / 100.0,

      alkalineBattery: data.getUint16(4) / 100.0,

      rssi: data.getInt16(6),

      rsrp: data.getInt16(8),
    }
  },
  type: LnrVital,
}

const SdiDetectionDecoder: Decoder<DetectSdi> = {
  uuid: {
    service: SemiosBleServiceUUID,
    characteristic: SdiDetectionUUID,
  },
  decode: function (data: DataView): DetectSdi | null {
    if (data.getInt8(0) !== SensorCommandStatus.OK) {
      return {
        commandStatus: data.getInt8(0),
        type: SensorTypeIds.UNKNOWN,
        address: '0',
      }
    }

    return {
      commandStatus: data.getInt8(0),

      type: data.getInt8(1),

      address: String.fromCharCode(data.getInt8(2)),
    }
  },
  type: DetectSdi,
}

const Rj11SensorStatusDecoder: Decoder<Rj11SensorStatus> = {
  type: Rj11SensorStatus,
  uuid: {
    service: SemiosBleServiceUUID,
    characteristic: Rj11DetectionUUID,
  },
  decode: function (data: DataView): Rj11SensorStatus | null {
    if (data.getInt8(0) !== SensorCommandStatus.OK) {
      return {
        commandStatus: data.getInt8(0),
        sensorType: SensorTypeIds.UNKNOWN,
        sensorStatus: SensorCommandStatus.INVALID,
      }
    }

    return {
      commandStatus: data.getInt8(0),
      sensorType: data.getInt8(1),
      sensorStatus: data.getInt8(2),
    }
  },
}

const RequestAllSdiSensorStatusEncoder: Encoder<RequestAllSdiSensorStatus> = {
  type: RequestAllSdiSensorStatus,
  uuid: {
    service: SemiosBleServiceUUID,
    characteristic: SdiSensorStatusUUID,
  },
  encode: function (): DataView {
    return emptyPayload()
  },
}

const SdiSensorStatusDecoder: Decoder<AllSdiSensorStatus> = {
  uuid: {
    service: SemiosBleServiceUUID,
    characteristic: SdiSensorStatusUUID,
  },
  notifiable: true,
  decode: function (data: DataView): AllSdiSensorStatus | null {
    let idx = 0

    const commandStatus = data.getInt8(idx++)

    if (commandStatus !== SensorCommandStatus.OK) {
      return {
        commandStatus,
        sensorCount: 0,
        sensorStatus: [],
      }
    }

    const sensorCount = data.getUint8(idx++)
    const sensorStatus: SdiSensorStatus[] = []

    for (let i = 0; i < sensorCount; i++) {
      sensorStatus.push({
        address: String.fromCharCode(data.getUint8(idx++)),
        type: data.getUint8(idx++),
        status: data.getUint8(idx++),
      })
    }

    return {
      commandStatus,
      sensorCount,
      sensorStatus,
    }
  },
  type: AllSdiSensorStatus,
}

const ProgramSdiSensorEncoder: Encoder<ProgramSdiSensor> = {
  type: ProgramSdiSensor,
  uuid: {
    service: SemiosBleServiceUUID,
    characteristic: ProgramSdiSensorUUID,
  },
  encode: function (data: ProgramSdiSensor): DataView {
    const buffer = new ArrayBuffer(2)
    const view = new DataView(buffer)

    view.setInt8(0, data.isAdd ? 1 : 0)

    view.setInt8(1, data.address.charCodeAt(0))

    return view
  },
}

class LnrBleNode extends SemiosBleNode {
  async initialize(): Promise<void> {
    await this.write(new RequestUplinkRebootMessage())
  }
}

const LnrBleNodeFactory: BleDeviceFactory = {
  nodeType: 'ln_r',
  options: {
    connectingTimeout: 600000,
  },
  creator: LnrBleNode,
  convertors: [
    FirmwareDecoder,
    HumidityDecoder,
    ToggleBuzzerEncoder,
    VitalDecoder,
    TemperatureDecoder,
    CurrentTimestampDecoder,
    SdiDetectionDecoder,
    SdiSensorStatusDecoder,
    Rj11SensorStatusDecoder,
    ProgramSdiSensorEncoder,
    UplinkRebootMessageEncoder,
    PushToCloudEncoder,
    RequestAllSdiSensorStatusEncoder,
  ],
}

export { LnrBleNode, LnrBleNodeFactory }
