import { Stack, useMantineTheme } from '@mantine/core'
import { useLnrNodeStatus } from 'App/ServiceCenter/DevicesManagement/useLnrNodeStatus'
import { getNodeTypeLabel } from 'App/ServiceCenter/utils/getNodeTypeLabel'
import { Button } from 'components/Button/Button'
import { translate } from 'i18n/i18n'
import React from 'react'
import { IconSuccess } from '../../../../components/icons/IconSuccess'
import type { InstallationStep, TEquipmentInstallationStep } from '../../types'
import type { EquipmentActivationPayload } from '../types'
import { DevicesSummary } from './DevicesSummary/DevicesSummary'
import { NodeSummary } from './NodeSummary/NodeSummary'

export const Overview: React.FC<{
  payload: Partial<EquipmentActivationPayload>
  steps: TEquipmentInstallationStep[]
  isBleRequired: boolean
  onAction: (payload: InstallationStep) => void
  onClose: () => void
}> = ({ payload, steps, isBleRequired, onAction }) => {
  const theme = useMantineTheme()
  const { nodeIdentifier: nodeId, nodeType, devices: nodeDevices } = payload
  const installationSteps = steps
  const { adapterStatus, loraNetworkStatus } = useLnrNodeStatus(isBleRequired, nodeId || '')
  const connectionStatus = isBleRequired ? { bleStatus: adapterStatus, loraNetworkStatus } : undefined

  return (
    <>
      <div css={{ padding: 10, paddingBottom: 0, marginBottom: 62, overflowY: 'auto' }}>
        <div
          css={{
            backgroundColor: theme.colors.grey[0],
            border: `1px solid ${theme.colors.grey[1]}`,
            borderRadius: 3,
            padding: 20,
          }}
        >
          <Stack spacing={'xs'}>
            <h3 css={{ margin: '0 0 10px' }}>{translate.phrases.placeholder('Overview')}</h3>

            <h4 css={{ margin: 0 }}>{translate.phrases.placeholder('Equipment Type')}</h4>
            {nodeType ? getNodeTypeLabel(nodeType) : translate.phrases.placeholder('N/A')}

            <NodeSummary nodeId={nodeId} connectionStatus={connectionStatus}>
              <DevicesSummary nodeDevices={nodeDevices} />
            </NodeSummary>
          </Stack>
        </div>

        <h3 css={{ margin: '20px 10px', display: 'flex', justifyContent: 'space-between' }}>
          {translate.phrases.placeholder('Installation Steps')}
        </h3>

        {installationSteps.map((step) => (
          <Button
            key={step.title}
            variant="tertiary"
            size="lg"
            css={{ display: 'block', width: '100%', marginBottom: 10 }}
            onClick={() => onAction(step.type)}
            disabled={!step.enabled}
            rightIcon={
              step.completed && (
                <span css={{ color: theme.colors.green[0] }}>
                  <IconSuccess />
                </span>
              )
            }
          >
            {step.title}
          </Button>
        ))}
      </div>
    </>
  )
}
