import type { routes } from '@semios/app-platform-banyan-route-definitions'
import { isNil } from '@semios/app-platform-common'
import type { VV } from '@semios/app-platform-value-type-definitions'
import type { getColorRulesForPestSeries } from 'App/Map/PanelDetails/StackemCharts/_utils/createContent/_utils/getColorRulesForSeries'
import type { TChartSeries } from 'components/StackedChart/types'
import { translate } from 'i18n/i18n'
import type { TRGBAColorWithOpacityAtTheEnd } from 'settings/colors'
import type { TValueGroup } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { doesSelectedRegionHaveValueTypes } from 'utils/doesSelectedFieldAssetHaveValueTypes'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import { makeCompareSeasonsSeriesFromRegularSeries } from '../../../../_utils/makeCompareSeasonsSeriesFromRegularSeries'
import { buildTrapPercentageSeries } from './buildTrapPercentageSeries'

export const buildTrapCatchSeries = ({
  targetScopeData,
  targetScopeId,
  trapCatchValueTypeKey,
  targetScope,
  insectId,
  isFreeRegionalUser,
  showRegionalData,
  regionName,
  regionsData,
  compareSeasonsInterval,
  compareSeasonsTargetScopeData,
  colorRules,
}: {
  targetScopeData:
    | routes.Values.Response['blocks']
    | routes.Values.Response['properties']
    | routes.Values.Response['regions']
  targetScopeId: string
  trapCatchValueTypeKey: string
  targetScope: 'PROPERTY' | 'BLOCK' | 'REGION'
  insectId: number
  isFreeRegionalUser: boolean
  showRegionalData: boolean
  regionName?: string | null
  regionsData?: NonNullable<routes.Values.Response['regions']>['string']
  compareSeasonsInterval: number
  compareSeasonsTargetScopeData: routes.Values.Response['blocks'] | routes.Values.Response['properties']
  colorRules: ReturnType<typeof getColorRulesForPestSeries>
}): TChartSeries[] => {
  const trapSeries: TChartSeries[] = []
  const timestampToCountDictionary: Record<string, number | null> = {}
  const insectTrapCatchesConverter = unitConverter.insectTrapCatches

  const traps =
    targetScopeData?.[targetScopeId]?.values?.[
      trapCatchValueTypeKey as keyof VV.DomainTypes.TrapCatchesInsect.TValuesReturnWithMetaIgnoringKeying['properties']
    ] ?? []

  const timeseries = Array.isArray(traps[0]?.timeseries) ? traps[0].timeseries : []

  timeseries.forEach((ts?: { timestamp: string; value: number | null }) => {
    const { timestamp, value } = ts ?? {}

    if (!isNil(timestamp)) {
      timestampToCountDictionary[timestamp] = timestampToCountDictionary[timestamp] ?? null

      if (!isNil(value)) {
        timestampToCountDictionary[timestamp] = value
      }
    }
  })

  const trapCatchesSeries: TChartSeries & { color?: TRGBAColorWithOpacityAtTheEnd } = {
    name: `${insectTrapCatchesConverter().categoryTitleWithoutUnit()}${
      targetScope === 'REGION' ? `(${translate.phrases.banyanApp('Trap Catches Percentage')})` : ''
    }`,
    id: `insect-trap-catches-insect-id-${insectId}-series`,
    borderWidth: 2,
    tooltip: {
      valueDecimals: insectTrapCatchesConverter().defaultNumberOfDecimalPlaces(),
      valueSuffix: ` ${insectTrapCatchesConverter().suffix()}`,
    },
    yAxis: 1,
    data: Object.entries(timestampToCountDictionary).map(([timestamp, value]) => [
      +new Date(timestamp),
      insectTrapCatchesConverter(value).value(),
    ]),
    type: 'area',
    step: 'center',
    color: colorRules.trap[0],
  }

  if (!isFreeRegionalUser) {
    trapSeries.push(trapCatchesSeries)
  }

  if (
    (targetScope === 'PROPERTY' || targetScope === 'REGION') &&
    showRegionalData &&
    regionsData &&
    doesSelectedRegionHaveValueTypes({
      valuesTimeseries: [`insectTrapCatchesPercentage_${insectId}` as TValueGroup],
    })
  ) {
    trapSeries.push(
      buildTrapPercentageSeries({
        trapCatchesSeries,
        insectId,
        isFreeRegionalUser,
        regionName,
        regionsData,
        color: colorRules.regionTrap,
      }),
    )
  }

  if (!showRegionalData && compareSeasonsInterval) {
    const timestampToCountDictionaryCS: Record<string, number | null> = {}

    const trapsCS =
      compareSeasonsTargetScopeData?.[targetScopeId]?.values?.[
        trapCatchValueTypeKey as keyof VV.DomainTypes.TrapCatchesInsect.TValuesReturnWithMetaIgnoringKeying['properties']
      ] ?? []

    trapsCS[0]?.timeseries?.forEach((ts?: { timestamp: string; value: number | null }) => {
      const { timestamp, value } = ts ?? {}

      if (!isNil(timestamp)) {
        timestampToCountDictionaryCS[timestamp] = timestampToCountDictionaryCS[timestamp] ?? null

        if (!isNil(value)) {
          timestampToCountDictionaryCS[timestamp] = value
        }
      }
    })

    trapSeries.push(
      makeCompareSeasonsSeriesFromRegularSeries(trapCatchesSeries, {
        data: Object.entries(timestampToCountDictionaryCS).map(([timestamp, value]) => [
          +new Date(timestamp),
          insectTrapCatchesConverter(value).value(),
        ]),
        color: colorRules.trap[1],
      }),
    )
  }

  return trapSeries
}
