import { Button, useMantineTheme } from '@mantine/core'
import { hoverCssKey } from 'components/Button/_utils/getButtonComponent'
import { forwardRef } from 'react'
import type { MantineButtonProps } from '../../Button'

const List = forwardRef<HTMLButtonElement, MantineButtonProps>((props, ref) => {
  const theme = useMantineTheme()

  return (
    <Button
      ref={ref}
      css={{
        'background': 'white',
        'height': 60,
        'padding': '0 15px',
        'border': 'none',
        'fontStyle': 'normal',
        'fontWeight': 600,
        'fontSize': 16,
        'borderRadius': 0,
        'width': '100%',
        'textAlign': 'left',
        'lineHeight': '21px',
        'display': 'flex',
        'justifyContent': 'space-between',
        'color': 'black',
        'borderBottom': 'solid 1px #E2E2E2',
        [`&:${hoverCssKey}`]: {
          background: 'white',
        },
        '&[disabled],&[data-loading="true"]': {
          background: theme.colors.grey[1],
          color: theme.colors.white[0],
          svg: {
            fill: theme.colors.grey[3],
          },
        },
        '&[data-loading="true"]::before': {
          background: 'unset',
        },
      }}
      {...props}
    />
  )
})

List.displayName = 'List'

export { List }
