import type { Interpolation, Theme } from '@emotion/react'
import { Menu, Text } from '@mantine/core'
import type { TNodeDevice } from 'App/ServiceCenter/types'
import { NodeDeviceInstallationStatus } from 'App/ServiceCenter/types'
import { getDeviceInstallationHeightLabel } from 'App/ServiceCenter/utils/getDeviceInstallationHeightLabel'
import { Button } from 'components/Button/Button'
import { IconRightArrowCircle } from 'components/icons/IconRightArrowCircle'
import { translate } from 'i18n/i18n'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import type { EDeviceStatus } from '../../types'
import { checkDeviceGroupPermission } from '../../utils'
import { DeviceInstallationStatus } from '../DeviceInstallationStatus/DeviceInstallationStatus'

interface DeviceProps {
  channel: number
  device: TNodeDevice | null
  deviceStatus: EDeviceStatus | null
  onAdd: () => void
  onRemove: () => void
  isBusy: boolean
  style?: Interpolation<Theme>
}

export const Device: React.FC<DeviceProps> = ({
  channel,
  device,
  deviceStatus,
  onAdd,
  onRemove,
  isBusy,
  style,
}: DeviceProps) => {
  const allDeviceModels = fieldAssetStore.useSelector((s) => s.devices)
  const isNew = !device || device.installationStatus === NodeDeviceInstallationStatus.PLANNED
  const source = device?.source
  const deviceSourceLabel = source ? allDeviceModels[source]?.name || source : ''
  const deviceGroup = source ? allDeviceModels[source]?.deviceGroupName : ''
  const hasPermission = checkDeviceGroupPermission(deviceGroup)

  return (
    <div
      css={[
        {
          marginBottom: '2px',
          display: 'flex',
          flexDirection: 'column',
          padding: '10px',
          background: 'white',
        },
        style,
      ]}
    >
      <div css={{ display: 'flex' }}>
        <div css={{ flex: '1', display: 'flex', flexDirection: 'column' }}>
          <span css={{ padding: '10px' }}>
            {translate.phrases.placeholder('Address {{channel}}', { channel: channel })}
          </span>
          {device && <span css={{ padding: '10px' }}>{deviceSourceLabel}</span>}
        </div>
        <div css={{ flex: '1', alignContent: 'center', textAlign: 'center' }}>
          {device && getDeviceInstallationHeightLabel(device.height)}
        </div>
        <div css={{ flex: '1', alignContent: 'center', textAlign: 'center' }}>
          <DeviceInstallationStatus status={deviceStatus} />
        </div>
      </div>
      <Menu width={'80%'} disabled={!device}>
        <Menu.Target>
          <Button
            variant={isNew ? 'primary' : 'tertiary'}
            rightIcon={isNew ? <IconRightArrowCircle /> : undefined}
            onClick={() => {
              if (isNew) {
                onAdd()
              }
            }}
          >
            <Text>
              {isNew
                ? translate.phrases.placeholder('Add Device')
                : translate.phrases.placeholder('Edit Device')}
            </Text>
          </Button>
        </Menu.Target>
        <Menu.Dropdown>
          {/* <Menu.Item>Edit Device</Menu.Item>
            <Menu.Divider />
            <Menu.Item>Suspend Device</Menu.Item>
            <Menu.Divider /> */}
          <Menu.Item disabled={!hasPermission || isBusy} onClick={onRemove}>
            {translate.phrases.placeholder('Remove Device')}
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </div>
  )
}
