import { SOIL_MOISTURE_COLORS_ARRAY } from 'settings/colors'

const soilMoistureHeightColorMapper: { [key: string]: string } = {
  '5': SOIL_MOISTURE_COLORS_ARRAY[0],
  '10': SOIL_MOISTURE_COLORS_ARRAY[1],
  '15': SOIL_MOISTURE_COLORS_ARRAY[1],
  '20': SOIL_MOISTURE_COLORS_ARRAY[2],
  '25': SOIL_MOISTURE_COLORS_ARRAY[2],
  '30': SOIL_MOISTURE_COLORS_ARRAY[3],
  '35': SOIL_MOISTURE_COLORS_ARRAY[3],
  '40': SOIL_MOISTURE_COLORS_ARRAY[4],
  '45': SOIL_MOISTURE_COLORS_ARRAY[4],
  '50': SOIL_MOISTURE_COLORS_ARRAY[5],
  '55': SOIL_MOISTURE_COLORS_ARRAY[5],
  '60': SOIL_MOISTURE_COLORS_ARRAY[6],
  '65': SOIL_MOISTURE_COLORS_ARRAY[6],
  '70': SOIL_MOISTURE_COLORS_ARRAY[7],
  '75': SOIL_MOISTURE_COLORS_ARRAY[7],
  '80': SOIL_MOISTURE_COLORS_ARRAY[8],
  '85': SOIL_MOISTURE_COLORS_ARRAY[8],
  '90': SOIL_MOISTURE_COLORS_ARRAY[9],
  '95': SOIL_MOISTURE_COLORS_ARRAY[9],
  '100': SOIL_MOISTURE_COLORS_ARRAY[10],
  '105': SOIL_MOISTURE_COLORS_ARRAY[10],
  '110': SOIL_MOISTURE_COLORS_ARRAY[11],
  '115': SOIL_MOISTURE_COLORS_ARRAY[11],
  '120': SOIL_MOISTURE_COLORS_ARRAY[12],
  '125': SOIL_MOISTURE_COLORS_ARRAY[12],
  '130': SOIL_MOISTURE_COLORS_ARRAY[13],
  '135': SOIL_MOISTURE_COLORS_ARRAY[13],
  '140': SOIL_MOISTURE_COLORS_ARRAY[14],
  '145': SOIL_MOISTURE_COLORS_ARRAY[14],
  '150': SOIL_MOISTURE_COLORS_ARRAY[15],
  '155': SOIL_MOISTURE_COLORS_ARRAY[15],
}

export const getSoilMoistureHeightColor = (height: number) => {
  return soilMoistureHeightColorMapper[height] || 'black'
}
