import { DTO } from '../../types'

class FirmwareVersion extends DTO {
  version = 'n/a'
}

class ToggleBuzzer extends DTO {
  activate = false // 1 - Activate buzzer, 0 - Deactivate buzzer
  numberOfBeeps = 0 // 0 indicates a constant tone
  beepOnDuration = 0 // in 10s of milliseconds
  beepOffDuration = 0 // in 10s of milliseconds
}

class CurrentTimestamp extends DTO {
  constructor(timestamp?: Date) {
    super()

    if (timestamp) {
      this.timestampInSecond = Math.floor(timestamp.getTime() / 1000)
    }
  }

  getDate() {
    return new Date(this.timestampInSecond * 1000)
  }
  timestampInSecond = 0
}

class RequestDeviceReboot extends DTO {}

export { CurrentTimestamp, FirmwareVersion, RequestDeviceReboot, ToggleBuzzer }
