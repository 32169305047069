import { makeBaseSvgIconProps } from 'components/icons/_utils/makeBaseSvgIconProps'

export const IconExternalLink = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 20 20')}>
      <path
        d="M2.22222 0C1.00771 0 0 1.00771 0 2.22222V17.7778C0 18.9923 1.00771 20 2.22222 20H17.7778C18.9923 20 20 18.9923 20 17.7778V10H17.7778V17.7778H2.22222V2.22222H10V0H2.22222ZM12.2222 0V2.22222H16.2066L5.88108 12.5477L7.45226 14.1189L17.7778 3.7934V7.77778H20V0H12.2222Z"
        fill="currentColor"
      />
    </svg>
  )
}
