import { Tabs } from '@mantine/core'
import { mapPropertiesForPropertySelect } from 'App/irrigation-scheduler/utils/mapPropertiesForPropertySelect'
import { getIrrigationProperties } from 'App/irrigation-scheduler/utils/store/selectors/getIrrigationProperties'
import { PropertySelect } from 'components/PropertySelect/PropertySelect'
import { SearchTextInput } from 'components/SearchTextInput/SearchTextInput'
import { translate } from 'i18n/i18n'
import { useState } from 'react'
import { colors } from 'settings/colors'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { GroupsTab } from './GroupsTab/GroupsTab'
import { ZonesTab } from './ZonesTab/ZonesTab'

type TActiveTab = 'GROUPS' | 'ZONES'

export const IrrigationSchedulerSettingsTabs = ({
  selectedPropertyId,
  setSelectedPropertyId,
}: {
  selectedPropertyId: number | null
  setSelectedPropertyId: (arg: number | null) => void
}) => {
  const [activeTab, setActiveTab] = useState<TActiveTab>('GROUPS')
  const [filterPropertiesText, setFilterPropertiesText] = useState('')
  const fass = fieldAssetStore.useSelector((s) => s)
  const propertiesWithEmitters = getIrrigationProperties(fass)

  return (
    <Tabs
      css={{ marginTop: 24 }}
      styles={{
        tabsList: {
          button: {
            'fontSize': 15,
            'padding': 10,
            'color': colors.primary,
            '&:hover': { background: 'transparent', fontWeight: 600 },
            '&[data-active]': {
              'fontWeight': 700,
              'borderBottom': `4px solid ${colors.midnight}`,
              '&:hover': { fontWeight: 700, borderColor: colors.midnight },
            },
            '&:not(:first-of-type)': { marginLeft: 20 },
          },
        },
      }}
      value={activeTab}
      onTabChange={(val: TActiveTab) => setActiveTab(val)}
    >
      <Tabs.List>
        <Tabs.Tab value="GROUPS">{translate.phrases.banyanApp('Groups')}</Tabs.Tab>
        <Tabs.Tab value="ZONES">{translate.phrases.banyanApp('Zones')}</Tabs.Tab>
      </Tabs.List>
      <div css={{ display: 'flex', gap: '20px', padding: '10px 20px' }}>
        <div css={{ flexGrow: 2 }}>
          <SearchTextInput
            placeholder={translate.phrases.banyanApp('Filter Properties')}
            searchText={filterPropertiesText}
            setSearchText={setFilterPropertiesText}
            clearable={true}
          />
        </div>
        <div css={{ flexGrow: 1 }}>
          <PropertySelect
            selectedPropertyId={selectedPropertyId}
            onChange={setSelectedPropertyId}
            data={mapPropertiesForPropertySelect(propertiesWithEmitters)}
            clearable={true}
          />
        </div>
      </div>
      <Tabs.Panel value="GROUPS">
        <GroupsTab selectedPropertyId={selectedPropertyId} filterPropertiesText={filterPropertiesText} />
      </Tabs.Panel>
      <Tabs.Panel value="ZONES">
        <ZonesTab filterPropertiesText={filterPropertiesText} selectedPropertyId={selectedPropertyId} />
      </Tabs.Panel>
    </Tabs>
  )
}
