import { InfoMessageBox } from 'components/InfoMessageBox/InfoMessageBox'
import { ModalDrawer } from 'components/ModalDrawer/ModalDrawer'
import { translate } from 'i18n/i18n'
import { isEqual } from 'lodash'
import { useState } from 'react'
import { SharedSettings } from 'settings/SharedSettings'
import { detailsPanelStore } from 'stores/detailsPanelStore'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import { ValueGroupDictionary } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { alphabeticalSort } from 'utils/alphabeticalSort'
import { apiFetch } from 'utils/apiFetch'
import { removeCmOrInFromSoilProbeDepths } from 'utils/removeCmOrInFromSoilProbeDepths'
import { refreshMapData } from '../../App/Map/PanelDetails/_utils/refreshMapData'
import { RootZoneDepthMultiSelect } from '../RootZoneDepthsModal/RootZoneDepthMultiSelect/RootZoneDepthMultiSelect'
import { RootZoneDepthsConfirmationModal } from './RootZoneDepthsConfirmationModal/RootZoneDepthsConfirmationModal'
import type { TStationsData } from './types'

export const RootZoneDepthsModal = () => {
  const selectedStationLngLat = selectedFieldAssetsStore.useSelector((s) => s.lngLat)
  const propertyId = selectedFieldAssetsStore.useSelector((s) => s.property)
  const properties = fieldAssetStore.useSelector((s) => s.properties)

  const originalStationsData = (
    propertyId && properties
      ? Object.values(properties[propertyId]?.points || {}).filter(
          (point) => !!point?.configuration?.soilProbeDepthsAvailable,
        )
      : []
  ).map((station) => {
    return {
      lngLat: station?.lngLat,
      name: station?.name ?? translate.phrases.banyanApp('Unnamed Station'),
      favoriteDepths: station?.configuration?.soilDefaultProbeDepths ?? [],
      availableDepths: station?.configuration?.soilProbeDepthsAvailable ?? [],
    }
  })

  const [stationsData, setStationsData] = useState<TStationsData[]>(originalStationsData)
  const [confirmModalOpened, setConfirmModalOpened] = useState(false)

  const handleExit = () => {
    if (!isEqual(stationsData, originalStationsData)) {
      setConfirmModalOpened(true)
    } else {
      setSoilSettingsOpened(false)
    }
  }

  const setSoilSettingsOpened = (value: boolean) => {
    detailsPanelStore.setState((prev) => {
      return {
        ...prev,
        soilSettingsOpened: value,
      }
    })
  }

  const resetState = () => {
    setStationsData(originalStationsData)
  }

  const updateStationsData = async () => {
    const response = await apiFetch({
      url: '/field-asset-settings-set',
      body: {
        soilFavoriteDepths: stationsData.map((station) => {
          return {
            geom: station.lngLat,
            defaultHeights: station.favoriteDepths,
          }
        }),
      },
    })

    // optimistically update the store
    if (Array.isArray(response?.soilFavoriteDepths)) {
      fieldAssetStore.setState((prev) => {
        let updatedPoints = {
          ...prev.properties?.[Number(propertyId)]?.points,
        }

        stationsData.forEach((station) => {
          updatedPoints[station.lngLat] = {
            ...updatedPoints[station.lngLat],
            configuration: {
              ...updatedPoints[station.lngLat]?.configuration,
              //@ts-ignore
              soilDefaultProbeDepths: station.favoriteDepths,
            },
          }
        })

        return {
          ...prev,
          properties: {
            ...prev.properties,
            [String(propertyId)]: {
              ...prev.properties?.[Number(propertyId)],
              points: updatedPoints,
            },
          },
        }
      })

      // below is logic to update the chart, chart legend, and summary table
      const newDateString = new Date().toISOString()

      // update the soil visibility to update series legend
      const selectedStation = stationsData.find((station) => {
        return station.lngLat === selectedStationLngLat
      })

      const favoriteDepths =
        selectedStation?.favoriteDepths?.map((item) => {
          return removeCmOrInFromSoilProbeDepths(item.replace('-', ''))
        }) ?? []

      const availableDepths =
        selectedStation?.availableDepths?.map((item) => {
          return removeCmOrInFromSoilProbeDepths(item.replace('-', ''))
        }) ?? []

      detailsPanelStore.setState((prev) => {
        const newSoilVisibility: Record<string, boolean> = {}

        availableDepths.forEach((depth) => {
          favoriteDepths.includes(depth)
            ? (newSoilVisibility[String(depth)] = true)
            : (newSoilVisibility[String(depth)] = false)
        })

        const allItemsAreVisible = Object.values(newSoilVisibility).every((v) => v)

        return {
          ...prev,
          soilVisibility: newSoilVisibility,
          allSoilDepthsAreSelected: allItemsAreVisible,
          keyForRedrawing: newDateString,
          keyForRefreshingSummaryTab: newDateString,
        }
      })

      refreshMapData(ValueGroupDictionary.soil) // update the map
    }
  }

  const savingDisabled =
    stationsData.some((station) => station.favoriteDepths.length === 0) ||
    isEqual(stationsData, originalStationsData)

  const soilSettingsOpened = detailsPanelStore.useSelector((s) => {
    return s.soilSettingsOpened
  })

  return (
    <>
      <ModalDrawer
        title={translate.phrases.banyanApp('Edit Root Zone Depths')}
        onClose={() => {
          handleExit()
        }}
        opened={soilSettingsOpened}
        zIndex={SharedSettings.DEFAULT_MODAL_DRAWER_Z_INDEX}
        primaryButtonText={translate.phrases.banyanApp('Save Changes')}
        primaryButtonOnPress={() => {
          updateStationsData()

          setSoilSettingsOpened(false)
        }}
        primaryButtonDisabled={savingDisabled}
        secondaryButtonText={translate.phrases.banyanApp('Cancel')}
        secondaryButtonOnPress={handleExit}
      >
        <div css={{ padding: '13px 18px 13px 18px' }}>
          <InfoMessageBox>
            <span>{translate.phrases.banyanApp('Root zone depths are used for average AWC')}</span>
            <br />
            <span style={{ marginTop: '150px', fontSize: '11px' }}>
              {translate.phrases.banyanApp(
                'Predicted values may take up to 24 hours to adjust after modifying root zone depths',
              )}
            </span>
          </InfoMessageBox>
        </div>
        {stationsData
          .map((station) => ({ ...station })) // make a copy of the array to avoid mutating the original
          .sort((a, b) => alphabeticalSort(a.name, b.name))
          .map((station, index) => {
            return (
              <RootZoneDepthMultiSelect
                station={station}
                setStationData={setStationsData}
                stationsDataLength={stationsData.length}
                key={index}
              />
            )
          })}
        <RootZoneDepthsConfirmationModal
          setConfirmModalOpened={setConfirmModalOpened}
          confirmModalOpened={confirmModalOpened}
          resetState={resetState}
          updateStationsData={updateStationsData}
          savingDisabled={savingDisabled}
        />
      </ModalDrawer>
    </>
  )
}
