import { Select } from '@mantine/core'
import { translate } from 'i18n/i18n'
import { SharedSettings } from 'settings/SharedSettings'
import { mapControlsStore } from 'stores/mapControlsStore/mapControlsStore'
import type {
  TValuesCurrentBlocksValueTypes,
  TValuesCurrentPointsValueTypes,
  TValuesCurrentPropertiesValueTypes,
  TValuesCurrentRegionValueTypes,
} from 'stores/mapControlsStore/types'
import { MAP_VISUAL } from 'stores/mapControlsStore/types'

const updateMapVisuals = (newValueType: TValuesCurrentPointsValueTypes) => {
  mapControlsStore.setState((s) => ({
    ...s,
    mapVisualValueGroup: {
      ...s.mapVisualValueGroup,
      [MAP_VISUAL.POINT]: {
        ...s.mapVisualValueGroup[MAP_VISUAL.POINT],
        leaf_wetness: {
          ...(s.mapVisualValueGroup[MAP_VISUAL.POINT].leaf_wetness ?? { mItem: 'M_InBlockPoint' }),
          valueType: newValueType,
        },
      },
      [MAP_VISUAL.BLOCK]: {
        ...s.mapVisualValueGroup[MAP_VISUAL.BLOCK],
        leaf_wetness: {
          ...(s.mapVisualValueGroup[MAP_VISUAL.BLOCK].leaf_wetness ?? { mItem: 'M_Block' }),
          valueType: newValueType as TValuesCurrentBlocksValueTypes,
        },
      },
      [MAP_VISUAL.PROPERTY]: {
        ...s.mapVisualValueGroup[MAP_VISUAL.PROPERTY],
        leaf_wetness: {
          ...(s.mapVisualValueGroup[MAP_VISUAL.PROPERTY].leaf_wetness ?? { mItem: 'M_Property' }),
          valueType: newValueType as TValuesCurrentPropertiesValueTypes,
        },
      },
      [MAP_VISUAL.REGION]: {
        ...s.mapVisualValueGroup[MAP_VISUAL.REGION],
        leaf_wetness: {
          ...(s.mapVisualValueGroup[MAP_VISUAL.REGION].leaf_wetness ?? { mItem: 'M_Region' }),
          valueType: newValueType as TValuesCurrentRegionValueTypes,
        },
      },
    },
  }))
}

export const LeafWetnessSecondaryDropdown = () => {
  const value = mapControlsStore.useSelector(
    (s) => s.mapVisualValueGroup?.[MAP_VISUAL.POINT]?.leaf_wetness?.valueType,
  )

  const data = [
    {
      value: 'leafWetness_last24HoursSum_wetHours',
      label: translate.phrases.templates('{{labelA}} ({{labelB}})', {
        labelA: translate.phrases.banyanApp('Wet Hours'),
        labelB: translate.phrases.banyanApp('Last {{count}} Hours', { count: 24 }),
      }),
    },
    {
      value: 'leafWetness_next24HoursSum_wetHours',
      label: translate.phrases.templates('{{labelA}} ({{labelB}})', {
        labelA: translate.phrases.banyanApp('Wet Hours'),
        labelB: translate.phrases.banyanApp('Next {{count}} Hours', { count: 24 }),
      }),
    },
  ]

  return (
    <Select
      value={value}
      data={data}
      onChange={updateMapVisuals}
      styles={SharedSettings.MANTINE_SELECT_RIGHT_ICON_CHANGER}
      withinPortal
    />
  )
}
