import type { TAntennaType } from '@semios/app-platform-internal-api-sdk'
import { OfflineRequestError } from 'utils/errorCodes'
import { stringify as stringifyWKT } from 'wellknown'
import type { PlannedNodeActivationPayload } from '../NodeInstallation/types'
import { serviceCenterStore } from '../store/serviceCenterStore'
import { ApiRequestQueue, ApiRequestStatus, ApiRequestType } from './api/queue'
import type { PlannedGatewayInstallMeta } from './api/serviceCenterPlannedGatewayInstall'
import { serviceCenterPlannedGatewayInstall } from './api/serviceCenterPlannedGatewayInstall'
import { serviceCenterPlannedNodeInstall } from './api/serviceCenterPlannedNodeInstall'
import { isSemiosGatewayType } from './getNodeNetworkType'

export async function installPlannedNode(payload: PlannedNodeActivationPayload): Promise<void> {
  let unSynced = true

  const isGateway = isSemiosGatewayType(payload.nodeType)
  const pseudoId = parseInt(isGateway ? payload.pseudonodeId.split('-')[1] : payload.pseudonodeId)

  const updatedGatewayPayload: PlannedGatewayInstallMeta = {
    pseudogatewayId: pseudoId,
    antennaType: 'DEFAULT' as TAntennaType,
    node: {
      propertyId: payload.propertyId,
      gatewayIdentifier: payload.nodeIdentifier,
      name: payload.name,
      location: stringifyWKT(payload.location),
      managementType: payload.managementType,
      maintenanceOwnerId: payload.maintenanceOwnerId,
    },
  }

  const updatedNodePayload = {
    ...payload,
    name: payload.name || '',
    pseudonodeId: pseudoId,
    location: stringifyWKT(payload.location),
  }

  try {
    const { status } = await ApiRequestQueue.createRequest(
      isGateway ? ApiRequestType.INSTALL_PLANNED_GATEWAY : ApiRequestType.INSTALL_PLANNED_NODE,
      isGateway ? updatedGatewayPayload : updatedNodePayload,
    )

    if (status === ApiRequestStatus.COMPLETED) unSynced = false
  } catch (error) {
    // If the request failed because the app is offline, don't throw an error
    if (!(error instanceof OfflineRequestError)) throw error
  }

  if (unSynced) {
    if (isGateway) {
      serviceCenterPlannedGatewayInstall.callback(updatedGatewayPayload)
    } else {
      serviceCenterPlannedNodeInstall.callback(updatedNodePayload, updatedNodePayload)
    }
  }

  // Select newly installed active node
  serviceCenterStore.actions.selectEquipment(payload.nodeIdentifier, 'active')
}
