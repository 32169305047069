import { Button } from 'components/Button/Button'

export const ButtonLinkSmall = (props: {
  children: React.ReactNode
  leftIcon?: React.ReactNode
  onClick: () => void
}) => {
  return (
    <Button
      css={{ width: 'fit-content' }}
      leftIcon={props.leftIcon}
      onClick={props.onClick}
      size="sm"
      variant="link"
    >
      {props.children}
    </Button>
  )
}
