import { Admin } from 'App/Map/Admin/Admin'
import { ModalDrawer } from 'components/ModalDrawer/ModalDrawer'
import { SearchTextInput } from 'components/SearchTextInput/SearchTextInput'
import { translate } from 'i18n/i18n'
import type { FC } from 'react'
import { lazy, Suspense } from 'react'
import { SharedSettings } from 'settings/SharedSettings'
import { SettingsCollapsedItems } from '../components/SettingsCollapsedItems/SettingsCollapsedItems'
import { AcceptancePackageLinks } from '../Shared/AcceptancePackageLinks/AcceptancePackageLinks'
import { Alerts } from '../Shared/Alerts/Alerts'
import { ContactsAndGroups } from '../Shared/ContactsAndGroups/ContactsAndGroups'
import { EmitterConfiguration } from '../Shared/EmitterConfiguration/EmitterConfiguration'
import { EvapotranspirationSettings } from '../Shared/EvapotranspirationSettings/EvapotranspirationSettings'
import { FertigationConfiguration } from '../Shared/FertigationConfiguration/FertigationConfiguration'
import { IrrigationSchedulerSettings } from '../Shared/IrrigationSchedulerSettings/IrrigationSchedulerSettings'
import { ReportsMain } from '../Shared/ManageReports/ReportsMain'
import { MyDetails } from '../Shared/MyDetails'
import { Presets } from '../Shared/Presets/Presets'
import { UnitSettings } from '../Shared/UnitSettings'
import type { TUserSettingsMenuScreenProps } from '../types'
import { SettingItem } from './SettingItem'

const SemiosAPI = lazy(() => import('../Shared/SemiosAPI/SemiosAPI').then((m) => ({ default: m.SemiosAPI })))

export const UserSettingsMenuNarrowScreen: FC<TUserSettingsMenuScreenProps> = ({
  currentTab,
  setCurrentTab,
  durationMillisecond,
  searchText,
  setSearchText,
  filteredSettingItems,
}) => {
  const getCurrentSelectionTitle = () => {
    switch (currentTab) {
      case 'myDetails':
        return translate.phrases.banyanApp('Account Details')

      case 'contactsAndGroups':
        return translate.phrases.banyanApp('Contacts')

      case 'unitSettings':
        return translate.phrases.banyanApp('Unit Settings')

      case 'presets':
        return translate.phrases.banyanApp('Views')

      case 'semiosApi':
        return translate.phrases.banyanApp('Semios API')

      case 'alerts':
        return translate.phrases.banyanApp('Alerts')

      case 'reports':
        return translate.phrases.banyanApp('Reports')

      case 'irrigationScheduler':
        return translate.phrases.banyanApp('Irrigation Scheduler')

      case 'evapotranspiration':
        return translate.phrases.banyanApp('Evapotranspiration')

      case 'emitterConfiguration':
        return translate.phrases.banyanApp('Emitter Configuration')

      case 'fertigationConfiguration':
        return translate.phrases.banyanApp('Fertigation Configuration')

      default:
        break
    }
  }

  const shouldUseCustomNarrowHeader = currentTab === 'emitterConfiguration'

  return (
    <div css={{ paddingBottom: 100 }}>
      <div
        css={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          marginTop: 20,
        }}
      >
        <div css={{ width: '85%' }}>
          <SearchTextInput searchText={searchText} setSearchText={setSearchText} />
        </div>
        <div css={{ width: '85%', marginTop: 20 }}>
          {filteredSettingItems?.map((section, index) => {
            const { items, sectionLabel } = section
            const isSubMenu = items.length > 1

            return isSubMenu ? (
              <SettingsCollapsedItems sectionLabel={sectionLabel} key={index}>
                {items.map((item) => (
                  <SettingItem
                    key={item.label}
                    tabKey={item.tabKey}
                    setCurrentTab={setCurrentTab}
                    label={item.label}
                    isSubItem={true}
                  />
                ))}
              </SettingsCollapsedItems>
            ) : (
              <SettingItem
                key={items[0].label}
                tabKey={items[0].tabKey}
                setCurrentTab={setCurrentTab}
                label={items[0].label}
              />
            )
          })}
        </div>

        <ModalDrawer
          title={getCurrentSelectionTitle()}
          opened={currentTab !== 'mainMenu'}
          onClose={() => setCurrentTab('mainMenu')}
          isSecondaryModal={true}
          zIndex={SharedSettings.DEFAULT_MODAL_DRAWER_Z_INDEX}
          customNarrowHeader={shouldUseCustomNarrowHeader}
        >
          <div
            css={{
              overflowY: 'auto',
              height: 'calc(100% - env(safe-area-inset-bottom))',
            }}
          >
            {currentTab === 'unitSettings' && <UnitSettings durationMillisecond={durationMillisecond} />}
            {currentTab === 'contactsAndGroups' && <ContactsAndGroups />}
            {currentTab === 'myDetails' && <MyDetails durationMillisecond={durationMillisecond} />}
            {currentTab === 'presets' && <Presets />}
            {currentTab === 'semiosApi' && (
              <Suspense fallback={null}>
                <SemiosAPI />
              </Suspense>
            )}
            {currentTab === 'irrigationScheduler' && <IrrigationSchedulerSettings />}
            {currentTab === 'evapotranspiration' && <EvapotranspirationSettings />}
            {currentTab === 'alerts' && <Alerts />}
            {currentTab === 'reports' && <ReportsMain />}
            {currentTab === 'emitterConfiguration' && <EmitterConfiguration />}
            {currentTab === 'fertigationConfiguration' && <FertigationConfiguration />}
            {currentTab === 'admin' && <Admin />}
          </div>
        </ModalDrawer>
      </div>
      <AcceptancePackageLinks />
    </div>
  )
}
