import { setToken } from 'utils/setToken'

const getCookieValue = (name: string) => {
  const cookies = document.cookie.split(';').map((cookie) => cookie.trim())

  const foundCookie = cookies.find((cookie) => {
    return cookie.split('=')[0] === name
  })

  if (foundCookie) {
    return foundCookie.split('=')[1]
  }
}

export const checkForSemiosClassicMigration = () => {
  const cookieName = 'semios_classic_auth_token'
  const tokenFromCookie = getCookieValue(cookieName)

  if (tokenFromCookie) {
    setToken(tokenFromCookie)

    // Expire the cookie
    document.cookie = `${cookieName}=; domain=semios.com; path=/; max-age=0; secure`
  }
}
