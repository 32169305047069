import { apiFetch } from 'utils/apiFetch'
import type { TFertigationSettingsData } from './types'

export const fetchFertigationSettings = async ({
  propertyIds,
  setLoading,
  setFertigationSettings,
}: {
  propertyIds: number[]
  setLoading: (arg: boolean) => void
  setFertigationSettings: React.Dispatch<React.SetStateAction<TFertigationSettingsData[]>>
}) => {
  setLoading(true)

  try {
    const response = await apiFetch({
      url: '/irrigation-fertigation-settings-get',
      body: {
        propertyIds,
      },
    })

    if (Array.isArray(response)) {
      setFertigationSettings(response)
    } else {
      // some api error occurred
      setFertigationSettings([])
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error fetching fertigation settings:', error)

    // handle errors
    setFertigationSettings([])
  } finally {
    setLoading(false)
  }
}
