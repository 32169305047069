import type { SharedTypes } from '@semios/app-platform-banyan-route-definitions'
import { translate } from 'i18n/i18n'
import { isNil } from 'lodash'
import { userDetailsStore } from 'stores/userDetailsStore'
import type { TUnitConverterOptionsWithUnits, TUnitConverterTypicalReturn } from '../types'

export const CONVERSION_RATE_FOR_GALLONS_AND_LITERS = 3.78541

const CONVERSION_RATE_FOR_GPM_TO_LPH = CONVERSION_RATE_FOR_GALLONS_AND_LITERS * 60

export const fertigation: Record<
  'fertigation',
  (
    fertigation?: number | null,
    options?: TUnitConverterOptionsWithUnits<SharedTypes.TFertigationFlowRateUnit>,
  ) => TUnitConverterTypicalReturn
> = {
  fertigation: (
    fertigationAmount: number | null = null,
    {
      decimalPlaces,
      inputUnit = 'gal/min',
      outputUnit = userDetailsStore.getState().fertigation === 'IMPERIAL' ? 'gal/min' : 'l/h',
    }: {
      decimalPlaces?: number
      inputUnit?: SharedTypes.TFertigationFlowRateUnit
      outputUnit?: SharedTypes.TFertigationFlowRateUnit
    } = {},
  ) => {
    const defaultNumberOfDecimalPlaces = outputUnit === 'gal/min' ? 1 : 0
    const shouldConvertFromGalToL = inputUnit === 'gal/min' && outputUnit === 'l/h'
    const shouldConvertFromLToGal = inputUnit === 'l/h' && outputUnit === 'gal/min'

    let convertedValue: number | null

    if (isNil(fertigationAmount)) {
      convertedValue = null
    } else if (shouldConvertFromGalToL) {
      convertedValue = fertigationAmount * CONVERSION_RATE_FOR_GPM_TO_LPH
    } else if (shouldConvertFromLToGal) {
      convertedValue = fertigationAmount / CONVERSION_RATE_FOR_GPM_TO_LPH
    } else {
      convertedValue = Number(fertigationAmount)
    }

    const decimalPlacesToUse = decimalPlaces ?? defaultNumberOfDecimalPlaces

    const unitSymbol =
      outputUnit === 'l/h'
        ? translate.measurements.litersPerHour.unit()
        : translate.measurements.gallonsPerMinute.unit()

    const label = translate.phrases.banyanApp('Fertigation')

    return {
      categoryTitle: () => translate.phrases.templates('{{label}} ({{unitSymbol}})', { label, unitSymbol }),
      categoryTitleWithoutUnit: () => label,
      defaultNumberOfDecimalPlaces: () => defaultNumberOfDecimalPlaces,
      suffix: () => unitSymbol,
      shortTitle: () => label,
      title: () => translate.phrases.templates('{{label}} ({{unitSymbol}})', { label, unitSymbol }),
      titleWithoutUnit: () => label,
      value: () => {
        if (isNil(convertedValue)) return null

        return +convertedValue?.toFixed(decimalPlacesToUse)
      },
      valueAsString: () => {
        return outputUnit === 'l/h'
          ? translate.measurements.litersPerHour.value(convertedValue, decimalPlacesToUse)
          : translate.measurements.gallonsPerMinute.value(convertedValue, decimalPlacesToUse)
      },
      valueWithSuffix: () => {
        return outputUnit === 'l/h'
          ? translate.measurements.litersPerHour.valueWithUnit(convertedValue, decimalPlacesToUse)
          : translate.measurements.gallonsPerMinute.valueWithUnit(convertedValue, decimalPlacesToUse)
      },
      valueWithNoRounding: () => convertedValue,
    }
  },
}
