import type { routes } from '@semios/app-platform-banyan-route-definitions'
import { isEmpty, sortByKey } from '@semios/app-platform-common'
import { DropdownSelectorProperty } from 'App/Map/PanelDetails/SectionTitleBars/DropdownSelectorProperty/DropdownSelectorProperty'
import { propertyLacksPermissionSectionMaker } from 'App/Map/PanelDetails/_utils/propertyLacksPermissionSectionMaker'
import { selectedPropertyHasPermission } from 'App/Map/PanelDetails/_utils/selectedPropertyHasPermission'
import type { TFieldAssetValueTypes } from 'App/Map/types'
import { EAggregationInterval } from 'App/Map/types'
import type { GridTableContentSection } from 'components/GridTable/types'
import { translate } from 'i18n/i18n'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import type { TSelectedFieldAssetsStoreState } from 'stores/selectedFieldAssetsStore'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import type { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { filterFieldAssetsByValueTypes } from 'utils/filterFieldAssetsByValueTypes'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import { BlockWithWeatherStationLabel } from '../../../BlockWithWeatherStationLabel/BlockWithWeatherStationLabel'
import { defaultValuesRowHeight, timestampToValuesDictionaryMaker } from '../_utils'

const checkPermission = () => selectedPropertyHasPermission({ permission: 'VIEW_BEE_HOURS_DETAILS' })
const preferredAggregationInterval = { preferredAggregationInterval: EAggregationInterval.DAILY }

const valuesRequested: Record<string, typeof preferredAggregationInterval> = {
  beeHours: preferredAggregationInterval,
}

export const apiArgs = ({
  selectedValueGroups,
  selectedFieldAssets,
}: {
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}): Partial<routes.Values.Request> => {
  if (!checkPermission()) return {}

  const blocks = fieldAssetStore.getState()?.properties?.[Number(selectedFieldAssets.property)]?.blocks

  if (!blocks) return {}

  const relevantBlocksForProperty = filterFieldAssetsByValueTypes({
    fieldAssets: Object.values(blocks),
    valuesTimeseries: Object.keys(valuesRequested),
  })

  if (!selectedValueGroups.bee_hours || isEmpty(relevantBlocksForProperty)) return {}

  const blockIds = relevantBlocksForProperty.map((block) => block.blockId)

  return {
    blocks: {
      blockIds,
      valuesRequested,
    },
  }
}

const generateItem = ({
  block,
  label,
  weatherStationLabel,
  values,
}: {
  block: string
  label: string
  weatherStationLabel: string
  values: ReturnType<typeof timestampToValuesDictionaryMaker>
}) => ({
  id: 'bee_hours' + block,
  blockIdLabel: label,
  label: <BlockWithWeatherStationLabel label={label} weatherStationLabel={weatherStationLabel} />,
  height: defaultValuesRowHeight,
  labelMinWidth: 160,
  valueMinWidth: 120,
  render: (dataPoint: { value: { current: number; cumulative: number } }) => {
    if (typeof dataPoint?.value?.current !== 'number') {
      return translate.phrases.templates('-')
    }

    return unitConverter.beeHours(dataPoint?.value?.current).valueWithSuffix()
  },
  values,
})

export const content = ({ data }: { data: routes.Values.Response }): GridTableContentSection => {
  const commonReturnItems = {
    title: unitConverter.beeHours().categoryTitleWithoutUnit(),
    titleChildren: <DropdownSelectorProperty valuesTimeseriesToFilterOn={Object.keys(valuesRequested)} />,
    id: 'summary-grid-bee-hours',
  }

  if (!checkPermission()) return propertyLacksPermissionSectionMaker(commonReturnItems)

  const { property } = selectedFieldAssetsStore.getState()
  const blocks = fieldAssetStore.getState()?.properties?.[Number(property)]?.blocks ?? {}
  const points = fieldAssetStore.getState()?.properties?.[Number(property)]?.points ?? {}

  const relevantBlocksForProperty = filterFieldAssetsByValueTypes<TFieldAssetValueTypes.TBlock[]>({
    fieldAssets: Object.values(blocks),
    valuesTimeseries: Object.keys(valuesRequested),
  })

  const items = relevantBlocksForProperty
    .map((block) => {
      const values = timestampToValuesDictionaryMaker(
        data?.blocks?.[block.blockId]?.values?.beeHours?.[0]?.timeseries ?? [],
      )

      const label = block.name || translate.phrases.banyanApp('Unnamed Block')

      const weatherStationLabel =
        !!block.associatedWeatherStation && points[block.associatedWeatherStation]?.name
          ? points[block.associatedWeatherStation].name
          : translate.phrases.banyanApp('Unknown Weather Station')

      return generateItem({ block: block.blockId.toString(), label, weatherStationLabel, values })
    })
    .filter(Boolean)
    .sort(sortByKey('blockIdLabel'))

  return {
    ...commonReturnItems,
    items,
  }
}
