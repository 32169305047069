import { Checkbox, Group, Stack, useMantineTheme } from '@mantine/core'
import { AboveAllModal } from 'components/AboveAllModalOverlay/AboveAllModalOverlay'
import { Button } from 'components/Button/Button'
import { translate } from 'i18n/i18n'
import type { FC } from 'react'
import { useState, type ReactElement } from 'react'

interface ConfirmationModalProps {
  id: string
  title: string
  description: ReactElement
  checkboxLabel: string
  confirmLabel: string
  cancelLabel?: string
  onConfirm: () => void
  onCancel?: () => void
}

const dialogPadding = 16

export function showModalWithConfirmCheckbox(param: ConfirmationModalProps) {
  const { id, title } = param
  const DIALOG_ID = id

  AboveAllModal.open({
    modalId: DIALOG_ID,
    fullScreen: false,
    withCloseButton: true,
    centered: true,
    title: title,
    padding: 0,
    styles: {
      header: {
        padding: dialogPadding,
      },
    },
    children: <Content {...param} />,
  })
}

const Content: FC<ConfirmationModalProps> = ({
  id,
  description,
  checkboxLabel,
  confirmLabel,
  cancelLabel,
  onConfirm,
  onCancel,
}: ConfirmationModalProps) => {
  const [checked, setChecked] = useState(false)
  const theme = useMantineTheme()

  return (
    <Stack>
      <Stack css={{ paddingLeft: dialogPadding, paddingRight: dialogPadding }}>
        {description}
        <Checkbox
          size={'md'}
          onChange={(event) => {
            setChecked(event.target.checked)
          }}
          css={{ fontWeight: 'bold', marginTop: '16px' }}
          label={checkboxLabel}
        />
      </Stack>
      <div
        css={{
          backgroundColor: theme.colors.gray[1],
          borderTopColor: theme.colors.gray[2],
          borderTopWidth: 2,
          borderTopStyle: 'solid',
        }}
      >
        <Group css={{ padding: dialogPadding }} position={'apart'} grow>
          <Button
            variant="secondary"
            onClick={() => {
              AboveAllModal.close(id)

              onCancel && onCancel()
            }}
          >
            {cancelLabel ? cancelLabel : translate.phrases.placeholder('Cancel')}
          </Button>
          <Button
            variant="primary"
            disabled={!checked}
            onClick={() => {
              AboveAllModal.close(id)

              onConfirm()
            }}
          >
            {confirmLabel}
          </Button>
        </Group>
      </div>
    </Stack>
  )
}
