import { Input, Select } from '@mantine/core'
import { useForm } from '@mantine/form'
import { notifications } from '@mantine/notifications'
import { ErrorBoundary } from '@sentry/react'
import { AboveAllModal } from 'components/AboveAllModalOverlay/AboveAllModalOverlay'
import { WideHeader } from 'components/ModalDrawer/WideHeader/WideHeader'
import { translate } from 'i18n/i18n'
import { cloneDeep } from 'lodash'
import { useState } from 'react'
import { SharedSettings } from '../../../settings/SharedSettings'
import { Footer } from '../NodeInstallation/Footer/Footer'
import { NodeInfoHeader } from '../Shared/NodeInfoHeader/NodeInfoHeader'
import { serviceCenterStore } from '../store/serviceCenterStore'
import type { TActiveGateway, TActiveNode } from '../types'
import { createErrorModal } from '../utils/createCommonModals'
import { removeNode } from '../utils/removeNode'

const MODAL_ID = 'node-remove-modal'

export function openNodeRemoveModal(node: TActiveNode | TActiveGateway, onClose?: () => void) {
  AboveAllModal.open({
    modalId: MODAL_ID,
    fullScreen: true,
    withCloseButton: false,
    padding: 0,
    children: (
      <ErrorBoundary>
        <NodeRemoveModal
          node={cloneDeep(node)}
          onClose={() => {
            onClose && onClose()

            AboveAllModal.close(MODAL_ID)
          }}
        />
      </ErrorBoundary>
    ),
    styles: {
      content: {
        marginLeft: 'env(safe-area-inset-left)',
        marginRight: 'env(safe-area-inset-right)',
        boxShadow: 'none',
        transform: 'none !important',
      },
    },
  })
}

const NodeRemoveModal = ({ node, onClose }: { node: TActiveNode | TActiveGateway; onClose: () => void }) => {
  const [isSaving, setIsSaving] = useState(false)
  const selectedNodePropertyId = (node as TActiveNode).propertyId

  const selectedNodeIdentifier =
    (node as TActiveNode).nodeIdentifier || (node as TActiveGateway).gatewayIdentifier

  const removeReasons = serviceCenterStore.useSelector(
    serviceCenterStore.selectors.getRemoveReasonsBySelectedNode,
  )

  const form = useForm({
    initialValues: {
      removeReason: '',
    },
    validate: {
      removeReason: (value) => {
        if (!value) return translate.phrases.placeholder('Required')
      },
    },
  })

  const handleSubmit = async () => {
    const { hasErrors } = form.validate()

    if (hasErrors) return

    setIsSaving(true)

    const { removeReason } = form.values

    try {
      const payload = {
        nodeIdentifier: selectedNodeIdentifier,
        reason: removeReason,
        propertyId: selectedNodePropertyId,
      }

      await removeNode(payload)

      notifications.show({
        title: translate.phrases.placeholder('Remove Node'),
        message: translate.phrases.placeholder('Node has been removed successfully.'),
      })

      onClose()
    } catch (e) {
      AboveAllModal.open(createErrorModal(translate.phrases.placeholder('Error'), (e as Error).message))
    }

    setIsSaving(false)
  }

  return (
    <>
      <WideHeader onClose={onClose} title={translate.phrases.placeholder('Remove Equipment')} />

      <div css={{ padding: '16px' }}>
        <NodeInfoHeader node={node} />

        <Input.Wrapper id="reason" label={translate.phrases.placeholder('Reason')} css={{ marginTop: 20 }}>
          <Select
            data={removeReasons.map((item) => ({ label: item.removeReason, value: item.removeId }))}
            {...form.getInputProps('removeReason')}
            styles={SharedSettings.MANTINE_SELECT_RIGHT_ICON_CHANGER}
            placeholder={translate.phrases.placeholder('Select reason for removal')}
          />
        </Input.Wrapper>
      </div>
      <Footer
        onPrevious={onClose}
        onNext={handleSubmit}
        nextButtonLabel={translate.phrases.placeholder('Submit')}
        disableNextButton={isSaving}
      />
    </>
  )
}

export default NodeRemoveModal
