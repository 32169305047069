import type { FireBlightHistoricalRiskDatabaseId } from '@semios/app-platform-common'
import { riskIdColorPicker } from '@semios/app-platform-common'
import type { TRGBAColorWith1AtTheEnd } from 'settings/colors'

export const getFireblightHeatmapColor = (
  value: number,
  riskId: FireBlightHistoricalRiskDatabaseId,
): TRGBAColorWith1AtTheEnd => {
  const colorFunction = riskIdColorPicker[riskId]

  return colorFunction(value) as TRGBAColorWith1AtTheEnd
}
