import { IconInfo } from 'components/icons/IconInfo'
import type { AxisPlotBandsLabelOptions, Chart, Options } from 'highcharts'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { translate } from 'i18n/i18n'
import { useEffect, useRef, useState } from 'react'
import { alphaColorReplacer, colors } from 'settings/colors'
import { DescriptionByModelImplication } from './DescriptionByModelImplication'
import { ExpandableInformation } from './ExpandableInformation'
import { InsectPhenologyLegends } from './InsectPhenologyLegends'

const InsectPhenologyHighcharts = ({
  options,
  year,
  modelImplication,
  insectId,
  propertyShouldIncludeSemiosModel,
  seriesSprayWindowsSemios,
  seriesSprayWindowsWSU,
}: {
  options: Options
  year: string | undefined
  modelImplication: string | undefined
  insectId: number
  propertyShouldIncludeSemiosModel: boolean | undefined
  seriesSprayWindowsSemios: {
    from: number | undefined
    to: number | undefined
    label?: AxisPlotBandsLabelOptions
    id: string
    color: string
  }[]
  seriesSprayWindowsWSU: { id: string; color: string; from: number | undefined; to: number | undefined }[]
}) => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1510)
  const [chart, setChart] = useState<Chart | null>(null)
  const chartRef = useRef<HighchartsReact.RefObject>(null)
  const [informationCollapsed, setInformationCollapsed] = useState(true)
  const [isInfoButtonHovered, setIsInfoButtonHovered] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 500)
    }

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    if (chart) {
      chart.reflow()
    }
  })

  const afterChartCreated = (chart: Chart) => {
    setChart(chart)
  }

  const dateTitle = year ? (
    <div
      css={{
        float: 'left',
      }}
    >
      <div
        css={{
          display: 'inline-block',
          fontSize: '18px',
        }}
      >
        <strong>{year}</strong>
      </div>
      <div
        css={{
          display: 'inline-block',
          fontSize: '14px',
          paddingLeft: '10px',
        }}
      >
        {translate.phrases.banyanApp('Degree Days from Jan 1')}
      </div>
    </div>
  ) : (
    <></>
  )

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <div
        css={{
          width: '100%',
          boxShadow: `2px 2px 8px ${alphaColorReplacer(colors.midnight, 0.3)}`,
          padding: '15px',
        }}
      >
        <div
          style={{
            display: 'inline-flex',
            fontSize: '18px',
            lineHeight: '20px',
            paddingRight: '15px',
            verticalAlign: 'middle',
            color: colors.blue,
          }}
        >
          <IconInfo />
        </div>
        <div
          style={{
            display: 'inline-flex',
            fontSize: '16px',
            lineHeight: '20px',
            fontWeight: 'bolder',
          }}
        >
          {translate.phrases.banyanApp('Selected date range does not apply')}
        </div>
      </div>
      <div
        css={{
          width: '100%',
          padding: '20px 10px 0px 10px',
        }}
      >
        {dateTitle}
      </div>
      <div
        css={{
          width: '100%',
          fontSize: '16px',
          lineHeight: '20px',
          padding: '10px',
        }}
      >
        <DescriptionByModelImplication modelImplication={modelImplication} insectId={insectId} />
      </div>
      <div
        css={{
          width: '100%',
        }}
      >
        <HighchartsReact
          ref={chartRef}
          highcharts={Highcharts}
          options={{
            ...options,
            chart: {
              marginTop: 15,
              height: isSmallScreen ? 250 : 350,
            },
          }}
          containerProps={{ style: { width: '100%' } }}
          callback={afterChartCreated}
        />
        <InsectPhenologyLegends
          chart={chartRef.current?.chart}
          series={options.series || []}
          propertyShouldIncludeSemiosModel={propertyShouldIncludeSemiosModel}
          seriesSprayWindowsSemios={seriesSprayWindowsSemios}
          seriesSprayWindowsWSU={seriesSprayWindowsWSU}
          isSmallScreen={isSmallScreen}
          informationCollapsed={informationCollapsed}
          setInformationCollapsed={setInformationCollapsed}
          isInfoButtonHovered={isInfoButtonHovered}
          setIsInfoButtonHovered={setIsInfoButtonHovered}
          insectId={insectId}
        />
        {!informationCollapsed && (
          <ExpandableInformation insectId={insectId} setInformationCollapsed={setInformationCollapsed} />
        )}
      </div>
    </div>
  )
}

export default InsectPhenologyHighcharts
