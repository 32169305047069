import { Button, useMantineTheme } from '@mantine/core'
import { hoverCssKey } from 'components/Button/_utils/getButtonComponent'
import { forwardRef } from 'react'
import type { MantineButtonProps } from '../../Button'

const LinkNegative = forwardRef<HTMLButtonElement, MantineButtonProps>((props, ref) => {
  const theme = useMantineTheme()

  return (
    <Button
      ref={ref}
      size="md"
      compact={true}
      css={{
        'backgroundColor': 'transparent',
        'border': 'none',
        'color': theme.colors.red[0],
        'textDecoration': 'underline',
        [`&:not([disabled],[data-loading="true"]):${hoverCssKey}`]: {
          textDecoration: 'none',
          backgroundColor: 'transparent',
        },
        '&[disabled],&[data-loading="true"],&[data-loading="true"]::before': {
          'color': theme.colors.grey[2],
          '&:hover': { textDecoration: 'underline' },
          'backgroundColor': 'transparent',
        },
        '&[disabled],&[data-loading="true"]': {
          svg: {
            fill: theme.colors.grey[3],
          },
        },
      }}
      {...props}
    />
  )
})

LinkNegative.displayName = 'LinkNegative'

export { LinkNegative }
