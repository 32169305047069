import { LoadingOverlay } from '@mantine/core'
import * as Sentry from '@sentry/react'
import { AboveAllModal } from 'components/AboveAllModalOverlay/AboveAllModalOverlay'
import { WideHeader } from 'components/ModalDrawer/WideHeader/WideHeader'
import { translate } from 'i18n/i18n'
import React, { useEffect, useState } from 'react'
import { ApiResponseError, OfflineRequestError } from 'utils/errorCodes'
import { showNotification } from '../../../utils/showNotification'
import { Footer } from '../NodeInstallation/Footer/Footer'
import { serviceCenterStore } from '../store/serviceCenterStore'
import type { HelpGuideGroup, THelpGuideStep } from '../types'
import { serviceCenterHelpGuidesGet } from '../utils/api/serviceCenterHelpGuidesGet'
import { getHelpGuideGroupTitle, getHelpGuideTitle } from '../utils/getHelpGuideGroupTitle'
import { HelpGuideList } from './List/List'
import { HelpGuideStep } from './Step/Step'

const MODAL_ID = 'help-guides'

type SelectedGuideAlias = {
  group: HelpGuideGroup
  alias?: string
}

export function openHelpGuideModal(guide?: SelectedGuideAlias) {
  AboveAllModal.open({
    modalId: MODAL_ID,
    fullScreen: true,
    withCloseButton: false,
    padding: 0,
    children: <HelpGuideModal guide={guide} />,
    styles: {
      content: {
        marginLeft: 'env(safe-area-inset-left)',
        marginRight: 'env(safe-area-inset-right)',
        boxShadow: 'none',
        transform: 'none !important',
      },
    },
  })
}

type SelectedGuide = {
  title: string
  steps: THelpGuideStep[]
}

interface HelpGuideModalProps {
  guide?: SelectedGuideAlias
}

export const HelpGuideModal: React.FC<HelpGuideModalProps> = ({ guide: initialGuide }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [selectedGuide, setSelectedGuide] = useState<SelectedGuide | null>(null)
  const [currentStep, setCurrentStep] = useState<number>(0)

  useEffect(() => {
    if (initialGuide?.group && initialGuide?.alias) {
      handleSelectGuide(initialGuide.group, initialGuide.alias)
    }
  }, [initialGuide])

  useEffect(() => {
    async function loadHelpGuides() {
      try {
        setIsLoading(true)

        const helpGuides = await serviceCenterHelpGuidesGet()

        serviceCenterStore.actions.setHelpGuides(helpGuides)
      } catch (error) {
        if (error instanceof ApiResponseError && error.statusCode === 403) {
          showNotification({
            type: 'error',
            message: translate.phrases.placeholder('You do not have permission to view help guides.'),
          })
        } else if (!(error instanceof OfflineRequestError)) {
          Sentry.captureException(error)

          showNotification({
            type: 'error',
            message: translate.phrases.placeholder('Error: could not load help guides!'),
          })
        }
      } finally {
        setIsLoading(false)
      }
    }

    loadHelpGuides()
  }, [])

  const handleSelectGuide = (group: HelpGuideGroup, alias: string) => {
    const guides = serviceCenterStore.selectors.getHelpGuides(serviceCenterStore.getState())
    const steps = guides?.[group][alias] || []

    // Don't select the guide if not found
    if (!steps.length) return

    let guideTitle = getHelpGuideTitle(group, alias)

    const sectionLabel = getHelpGuideGroupTitle(group)
    const title = `${sectionLabel} - ${guideTitle}`

    setSelectedGuide({ title, steps })
  }

  const handleOnClose = () => {
    if (selectedGuide) {
      setSelectedGuide(null)

      setCurrentStep(0)

      return
    }

    AboveAllModal.close(MODAL_ID)
  }

  const showPreviousStep = () => {
    if (currentStep !== 0) {
      setCurrentStep(currentStep - 1)
    } else {
      setSelectedGuide(null)
    }
  }

  const showNextStep = () => {
    if (selectedGuide?.steps && currentStep !== selectedGuide.steps.length - 1) {
      setCurrentStep(currentStep + 1)
    }
  }

  const currentGuideStep = selectedGuide?.steps[currentStep]

  return (
    <>
      <WideHeader
        title={
          selectedGuide
            ? selectedGuide.title
            : translate.phrases.placeholder('Assembly and Installation Guides')
        }
        onClose={handleOnClose}
        isSecondaryModal={!!selectedGuide}
      />

      <LoadingOverlay visible={isLoading} />

      {currentGuideStep ? (
        <HelpGuideStep
          title={currentGuideStep.title}
          content={currentGuideStep.content}
          image={currentGuideStep.image}
          stepNumber={currentStep + 1}
          totalSteps={selectedGuide.steps.length}
        />
      ) : (
        <HelpGuideList expandedCard={initialGuide?.group || undefined} onSelectGuide={handleSelectGuide} />
      )}

      {selectedGuide && (
        <Footer
          onPrevious={showPreviousStep}
          onNext={showNextStep}
          disableNextButton={currentStep === selectedGuide.steps.length - 1}
        />
      )}
    </>
  )
}
