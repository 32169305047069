import type { CSSObject } from '@emotion/react'
import { useMantineTheme } from '@mantine/core'
import { ExpandableChevronIndicator } from 'components/ExpandableChevronIndicator/ExpandableChevronIndicator'
import type { Ref } from 'react'
import React, { useEffect, useRef, useState } from 'react'
import { colors } from 'settings/colors'
import { useMultiSelectContext } from './MultiSelect.context'
import type { TOptions } from './MultiSelect.types'
import { MultiSelectSearchInput } from './MultiSelectSearchInput'
import { SelectedValuesBubble } from './SelectedValuesBubble'
import { truncateLongText } from './_utils'

const baseCSSObj: CSSObject = {
  padding: '4px 6px 4px 6px',
  background: colors.grey800,
  marginRight: 5,
  borderRadius: 16,
  display: 'flex',
  alignItems: 'center',
  fontSize: 13,
  height: 26,
  color: 'white',
  whiteSpace: 'nowrap',
  cursor: 'pointer',
}

const tagMaxWidth = '175px'

const tagsCSSObj: CSSObject = {
  maxWidth: tagMaxWidth,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  justifyContent: 'space-between',
  ...baseCSSObj,
}

const tagsNotDisplayedCSSObj: CSSObject = {
  justifyContent: 'center',
  ...baseCSSObj,
}

const iconDimensions = '18px'
const itemLabelMaxWidth = '150px'

export const SearchBox = React.forwardRef((_, ref: Ref<HTMLInputElement>) => {
  const {
    selectedValues,
    noOfValuesToDisplay,
    customCloseIcon,
    closeIcon,
    hideSelectedList,
    customArrow,
    disabled,
    isDropdownOpen,
    onFocus,
    searchable,
    searchInDropdown,
    selectedValueDecorator,
    onRemoveSelectedItem,
    error,
    optionsLabel,
  } = useMultiSelectContext()

  const theme = useMantineTheme()

  const selectedValuesList =
    noOfValuesToDisplay && selectedValues.length > noOfValuesToDisplay
      ? selectedValues.slice(0, noOfValuesToDisplay)
      : selectedValues

  const textRef = useRef<HTMLDivElement | null>(null)
  const [visibleItems, setVisibleItems] = useState<TOptions[]>([])
  const [hiddenItems, setHiddenItems] = useState<TOptions[]>([])

  const calculateVisibleAndHiddenItems = () => {
    if (textRef.current) {
      const widths: { item: TOptions; width: number }[] = []

      let parentWidth = textRef.current.parentElement?.getBoundingClientRect().width || 0

      selectedValuesList.forEach((item: TOptions, index: number) => {
        const spanContainer = document.createElement('span')

        document.body.appendChild(spanContainer)

        Object.assign(spanContainer.style, {
          padding: '4px 6px 4px 6px',
          marginRight: '5px',
          borderRadius: '16px',
          height: '26px',
          fontSize: '13px',
        })

        const itemLabelSpan = document.createElement('span')

        itemLabelSpan.textContent = String(
          selectedValueDecorator && typeof item.label === 'string'
            ? selectedValueDecorator(truncateLongText(item.label, 22), index, item)
            : item.label,
        )

        Object.assign(itemLabelSpan.style, {
          maxWidth: itemLabelMaxWidth,
        })

        spanContainer.appendChild(itemLabelSpan)

        const iconSpan = document.createElement('span')

        Object.assign(iconSpan.style, {
          lineHeight: '1',
          marginLeft: '5px',
        })

        const icon = document.createElement('img')

        Object.assign(icon.style, {
          height: iconDimensions,
          width: iconDimensions,
        })

        icon.src = `${customCloseIcon ? customCloseIcon : closeIcon}`

        iconSpan.appendChild(icon)

        spanContainer.appendChild(iconSpan)

        let width = Math.ceil(spanContainer.getBoundingClientRect().width)

        if (width > parseInt(tagMaxWidth)) width = parseInt(tagMaxWidth)

        document.body.removeChild(spanContainer)

        widths.push({ item, width })
      })

      const visibleArr: TOptions[] = []
      const overflowArr: TOptions[] = []
      const roomRemaining = 0.21
      const roomForItems = parentWidth * roomRemaining

      widths.forEach(({ item, width }) => {
        if (parentWidth - width > roomForItems) {
          parentWidth -= width

          visibleArr.push(item)
        } else {
          overflowArr.push(item)
        }
      })

      setVisibleItems(visibleArr)

      setHiddenItems(overflowArr)
    }
  }

  useEffect(() => {
    calculateVisibleAndHiddenItems()

    window.addEventListener('resize', calculateVisibleAndHiddenItems)

    return () => {
      window.removeEventListener('resize', calculateVisibleAndHiddenItems)
    }
  }, [selectedValuesList])

  const itemsNotDisplayed = noOfValuesToDisplay
    ? selectedValuesList.length - noOfValuesToDisplay
    : hiddenItems.length

  const renderSelectedList = () => {
    return (
      <div
        ref={textRef}
        css={{
          display: 'flex',
          alignItems: 'center',
          position: 'relative',
          flexShrink: 0,
        }}
      >
        {visibleItems.map((value: TOptions, index: number) => {
          return (
            <span
              css={tagsCSSObj}
              onClick={(e) => {
                e.stopPropagation()

                onRemoveSelectedItem(value)
              }}
              key={index}
            >
              <span
                css={{
                  maxWidth: itemLabelMaxWidth,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {/* TODO: fix for when value.label is a ReactNode */}
                {selectedValueDecorator && typeof value.label === 'string'
                  ? selectedValueDecorator(truncateLongText(value.label, 22), index, value)
                  : value.label}
              </span>
              {!disabled && (
                <span
                  css={{
                    cursor: 'pointer',
                    lineHeight: 1,
                    marginLeft: '5px',
                  }}
                >
                  <img
                    css={{
                      height: iconDimensions,
                      width: iconDimensions,
                    }}
                    src={customCloseIcon ? customCloseIcon : closeIcon}
                  />
                </span>
              )}
            </span>
          )
        })}
        {itemsNotDisplayed > 0 && <span css={tagsNotDisplayedCSSObj}>{`+${itemsNotDisplayed}`}</span>}
      </div>
    )
  }

  return (
    <>
      <div
        css={{
          backgroundColor: disabled ? theme.colors.gray[0] : theme.colors.white[0],
          overflow: 'hidden',
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          minHeight: 36,
          padding: 2,
          border: `1px solid ${theme.colors.grey[1]}`,
          borderRadius: 3,
          cursor: 'pointer',
          borderColor: error ? theme.colors.red[0] : theme.colors.grey[1],
          width: '100%',
        }}
        onClick={onFocus}
      >
        {(!hideSelectedList || !optionsLabel) && (
          <>
            {renderSelectedList()}
            {/* if not searchable then placeholder isnt shown either. Think about this. */}
            {searchable && !searchInDropdown && <MultiSelectSearchInput ref={ref} />}
          </>
        )}

        {optionsLabel && (
          <div
            css={{
              display: 'flex',
              gap: 15,
              alignItems: 'center',
              maxWidth: '100px',
              height: 30,
              marginLeft: 10,
            }}
          >
            <span>{optionsLabel}</span>
            <SelectedValuesBubble value={selectedValues.length} />
          </div>
        )}

        {customArrow ? (
          <span>{customArrow}</span>
        ) : (
          <div
            css={{
              position: 'absolute',
              right: 0,
              top: 0,
              bottom: 0,
              backgroundColor: disabled ? theme.colors.gray[0] : theme.colors.white[0],
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              paddingRight: 10,
              paddingLeft: 10,
              fontSize: 14,
              color: colors.midnight,
            }}
          >
            <ExpandableChevronIndicator expanded={isDropdownOpen} />
          </div>
        )}
      </div>
      {error && (
        <span
          css={{
            color: theme.colors.red[0],
            padding: '5px 0',
            fontSize: 12,
          }}
        >
          {error}
        </span>
      )}
    </>
  )
})

SearchBox.displayName = 'MultiSelect.SearchBox'
