import type { routes } from '@semios/app-platform-banyan-route-definitions'
import { isNil } from '@semios/app-platform-common'
import type { VV } from '@semios/app-platform-value-type-definitions'
import { DropdownSelectorPoint } from 'App/Map/PanelDetails/SectionTitleBars/DropdownSelectorPoint/DropdownSelectorPoint'
import { propertyLacksPermissionSectionMaker } from 'App/Map/PanelDetails/_utils/propertyLacksPermissionSectionMaker'
import { selectedPropertyHasPermission } from 'App/Map/PanelDetails/_utils/selectedPropertyHasPermission'
import { selectedRegionHasPermission } from 'App/Map/PanelDetails/_utils/selectedRegionHasPermission'
import { EAggregationInterval } from 'App/Map/types'
import type { GridTableContentSection, GridTableContentSectionItem } from 'components/GridTable/types'
import { translate } from 'i18n/i18n'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import type { TPointCategory, TSelectedFieldAssetsStoreState } from 'stores/selectedFieldAssetsStore'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import type { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import {
  doesSelectedPointHaveValueTypes,
  doesSelectedRegionHaveValueTypes,
} from 'utils/doesSelectedFieldAssetHaveValueTypes'
import { isUserOnlyAFreeRegionalUser } from 'utils/isUserOnlyAFreeRegionalUser'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import { isRegionDataEnabled } from 'utils/useIsRegionDataEnabled'
import { defaultValuesRowHeight, timestampToValuesDictionaryMaker } from '../_utils'

const permissionRequired = 'VIEW_WEATHER_DETAILS'

const checkPermission = () =>
  selectedPropertyHasPermission({ permission: permissionRequired }) ||
  (isUserOnlyAFreeRegionalUser() && selectedRegionHasPermission({ permission: permissionRequired }))

const pointCategory: TPointCategory = 'weatherPoint'
const valueType = 'leafWetnessHours' as VV.DomainTypes.LeafWetness.TTimeseriesValueTypeKeysMerged

export const apiArgs = ({
  selectedValueGroups,
  selectedFieldAssets,
}: {
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}): Partial<routes.Values.Request> => {
  if (!checkPermission()) return {}

  if (!selectedValueGroups.leaf_wetness || !selectedFieldAssets[pointCategory]) return {}

  const pointHasValueType = doesSelectedPointHaveValueTypes({ pointCategory, valuesTimeseries: [valueType] })

  const valuesRequested = {
    [valueType]: { preferredAggregationInterval: EAggregationInterval.DAILY },
  }

  const returner: Partial<routes.Values.Request> = {}

  if (pointHasValueType) {
    returner.points = {
      lngLats: [selectedFieldAssets[pointCategory]],
      valuesRequested,
    }
  }

  const showRegionalData = isRegionDataEnabled()

  const regionHasValueType =
    selectedFieldAssets.region &&
    showRegionalData &&
    doesSelectedRegionHaveValueTypes({ valuesTimeseries: [valueType] })

  if (regionHasValueType) {
    returner.regions = {
      regionIds: [String(selectedFieldAssets.region)],
      valuesRequested,
    }
  }

  return returner
}

const generateItem = ({
  id,
  values,
  label,
}: {
  id: string
  values: ReturnType<typeof timestampToValuesDictionaryMaker>
  label?: string
}) => {
  return {
    id,
    label: label ?? unitConverter.leafWetnessHours().shortTitle(),
    values,
    labelMinWidth: 190,
    valueMinWidth: 120,
    height: defaultValuesRowHeight,
    render: (dataPoint: { value: number | null }) => {
      let valueToRender: string | React.ReactNode = ''

      if (isNil(dataPoint) || isNil(dataPoint.value)) {
        valueToRender = translate.phrases.templates('-')
      } else {
        const sumValue = unitConverter.leafWetnessHours(dataPoint.value).valueWithSuffix()

        valueToRender = sumValue
      }

      return <>{valueToRender}</>
    },
  }
}

export const content = ({ data }: { data: routes.Values.Response }): GridTableContentSection => {
  const commonReturnItems = {
    title: unitConverter.leafWetnessHours().title(),
    titleChildren: (
      <DropdownSelectorPoint valuesTimeseriesToFilterOn={[valueType]} pointCategory={pointCategory} />
    ),
    id: 'summary-grid-leaf-wetness',
  }

  if (!checkPermission()) return propertyLacksPermissionSectionMaker(commonReturnItems)

  const selectedFieldAssets = selectedFieldAssetsStore.getState()
  const stationLngLat = String(selectedFieldAssets[pointCategory])
  const items: GridTableContentSectionItem[] = []

  if (doesSelectedPointHaveValueTypes({ pointCategory, valuesTimeseries: [valueType] })) {
    const pointData = data?.points?.[stationLngLat]?.values
    const leafWetnessHoursData = pointData?.[valueType]?.[0]?.timeseries ?? []
    const values = timestampToValuesDictionaryMaker(leafWetnessHoursData)

    items.push(
      generateItem({
        id: `leaf-wetness-${stationLngLat}`,
        values,
      }),
    )
  }

  const showRegionalData =
    isRegionDataEnabled() &&
    doesSelectedRegionHaveValueTypes({
      valuesTimeseries: [valueType],
    })

  if (showRegionalData && selectedFieldAssets.region) {
    const regionName = selectedFieldAssets.property
      ? fieldAssetStore.getState().regions?.[selectedFieldAssets.region]?.name
      : undefined

    const regionItem = generateItem({
      id: `leaf-wetness-${selectedFieldAssets.region}`,
      values: timestampToValuesDictionaryMaker(
        data?.regions?.[selectedFieldAssets.region]?.values?.leafWetnessHours?.[0]?.timeseries ?? [],
      ),
      label: regionName,
    })

    items.push(regionItem)
  }

  return {
    ...commonReturnItems,
    items,
  }
}
