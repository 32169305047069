import { Input, Select, UnstyledButton, useMantineTheme } from '@mantine/core'
import { useForm } from '@mantine/form'
import { notifications } from '@mantine/notifications'
import type { TCameraOrientation } from '@semios/app-platform-banyan-route-definitions/src/shared-types'
import { ErrorBoundary } from '@sentry/react'
import { openHelpGuideModal } from 'App/ServiceCenter/HelpGuideModal/HelpGuideModal'
import { LuresMultiSelect } from 'App/ServiceCenter/Shared/LuresMultiSelect'
import { NodeInfoHeader } from 'App/ServiceCenter/Shared/NodeInfoHeader/NodeInfoHeader'
import type { NodeConfigUpdateMeta } from 'App/ServiceCenter/utils/api/serviceCenterNodeConfigSet'
import { updateNodeConfig } from 'App/ServiceCenter/utils/updateNodeConfig'
import { AboveAllModal } from 'components/AboveAllModalOverlay/AboveAllModalOverlay'
import { IconHelpCircle } from 'components/icons/IconHelpCircle'
import { WideHeader } from 'components/ModalDrawer/WideHeader/WideHeader'
import { translate } from 'i18n/i18n'
import _ from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { colors } from 'settings/colors'
import { SharedSettings } from 'settings/SharedSettings'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { showNotification } from 'utils/showNotification'
import { serviceCenterStore } from '../../store/serviceCenterStore'
import type { TActiveNode } from '../../types'
import { ConsumableType, HelpGuideGroup } from '../../types'
import { Footer } from '../Footer/Footer'

const MODAL_ID = 'trap-configuration'

type FormValues = {
  lureIds: number[]
  insectId: number | null
  cameraOrientation: TCameraOrientation | null
}

type Props = {
  showHeader?: boolean
  onClose: () => void
  onSubmit?: () => void
}

export const openTrapConfigurationModal = () => {
  AboveAllModal.open({
    modalId: MODAL_ID,
    fullScreen: true,
    withCloseButton: false,
    padding: 0,
    children: (
      <ErrorBoundary>
        <TrapConfiguration showHeader={true} onClose={() => AboveAllModal.close(MODAL_ID)} />
      </ErrorBoundary>
    ),
    styles: {
      content: {
        marginLeft: 'env(safe-area-inset-left)',
        marginRight: 'env(safe-area-inset-right)',
        boxShadow: 'none',
        transform: 'none !important',
      },
    },
  })
}

export const TrapConfiguration: React.FC<Props> = ({ onClose, onSubmit, showHeader = false }) => {
  const theme = useMantineTheme()
  const selectedNode = serviceCenterStore.useSelector(serviceCenterStore.selectors.getSelectedEquipmentNode)
  const [isSaving, setIsSaving] = useState(false)
  const allInsects = fieldAssetStore.useSelector((s) => s.insects)
  const { nodeType, propertyId } = selectedNode as TActiveNode
  const luresKeyById = serviceCenterStore.useSelector(serviceCenterStore.selectors.getLures)

  const getDefaultLures = () => {
    if (!_.isEmpty((selectedNode as TActiveNode)?.trap?.lures.active)) {
      return Object.values((selectedNode as TActiveNode)?.trap?.lures.active || {}).map((lure) => lure.lureId)
    } else if (!_.isEmpty((selectedNode as TActiveNode)?.trap?.lures.planned)) {
      return Object.values((selectedNode as TActiveNode)?.trap?.lures.planned || {}).map(
        (lure) => lure.lureId,
      )
    } else {
      return []
    }
  }

  const initialValues = useMemo(
    () => ({
      lureIds: getDefaultLures(),
      insectId: (selectedNode as TActiveNode)?.trap?.targetInsectId || null,
      cameraOrientation: (selectedNode as TActiveNode)?.trap?.cameraOrientation || 'HORIZONTAL',
    }),
    [selectedNode],
  )

  const form = useForm<FormValues>({
    initialValues,
  })

  if (!allInsects || !selectedNode) return null

  const insectOptions = _.sortBy(
    Object.values(allInsects)
      .filter((insect) => insect.enabled)
      .map((insect) => ({ label: insect.name, value: insect.insectId })),
    'label',
  )

  const handleSubmit = async () => {
    if (!propertyId) return

    setIsSaving(true)

    const {
      values: { lureIds, insectId, cameraOrientation },
    } = form

    try {
      const payload = {
        nodeIdentifiers: [(selectedNode as TActiveNode).nodeIdentifier],
        propertyId,
        nodeType: (selectedNode as TActiveNode).nodeType,
        trap: {
          targetInsectId: insectId || null,
          cameraOrientation,
          lureIds,
          consumableTypes: [ConsumableType.LURE],
          consumableOperationType: lureIds.length ? 'SET' : 'REMOVE',
        },
      } as NodeConfigUpdateMeta

      await updateNodeConfig(payload)

      onSubmit && onSubmit()

      onClose()
    } catch (error) {
      setIsSaving(false)

      let errorMessage: string = error as string

      if (error instanceof Error) {
        errorMessage = error.message
      }

      notifications.show({
        title: translate.phrases.placeholder('Error'),
        message: errorMessage,
        color: 'red',
      })
    }

    setIsSaving(false)
  }

  useEffect(() => {
    handleOnValuesChange(form.values)
  }, [form.values])

  const handleOnValuesChange = (values: FormValues) => {
    const {
      lureIds: [primaryLureId],
      cameraOrientation,
    } = values

    if (primaryLureId && luresKeyById[primaryLureId].trapCameraOrientation !== cameraOrientation) {
      showNotification({
        type: 'warning',
        message: translate.phrases.placeholder(
          `The default camera orientation for ${luresKeyById[primaryLureId].lureName} is ${luresKeyById[primaryLureId].trapCameraOrientation}.`,
        ),
      })
    }
  }

  return (
    <>
      {showHeader && (
        <WideHeader
          title={translate.phrases.placeholder('Configure Trap')}
          onClose={() => onClose()}
          isSecondaryModal={true}
          rightIconButton={
            <UnstyledButton
              onClick={() =>
                openHelpGuideModal({
                  group: HelpGuideGroup.USER_GUIDES,
                  alias: nodeType,
                })
              }
            >
              <span css={{ fontSize: 20, color: theme.colors.midnight[0] }}>
                <IconHelpCircle />
              </span>
            </UnstyledButton>
          }
        />
      )}

      <div css={{ padding: 20 }}>
        {!showHeader && (
          <div css={{ marginBottom: 20 }}>
            <NodeInfoHeader node={selectedNode as TActiveNode} />
          </div>
        )}

        <Input.Wrapper id="insectId" label={translate.phrases.placeholder('Target Pest')}>
          <Select
            css={{ marginBottom: '16px' }}
            placeholder={translate.phrases.placeholder('Target Pest')}
            //@ts-ignore data prop takes only string as value but it works with number as well
            data={insectOptions}
            {...form.getInputProps('insectId')}
            onChange={(value) => {
              form.getInputProps('insectId').onChange(value)

              // when insect has changed, clear lures
              form.setFieldValue('lureIds', [])
            }}
            clearable
            searchable
            styles={
              form.values.insectId
                ? // if <Select /> has "clearable" prop, MANTINE_SELECT_RIGHT_ICON_CHANGER doesn't work when the value is selected
                  // so we need to modify the style manually here
                  {
                    root: {
                      '& [aria-expanded="true"] .mantine-Select-rightSection': {
                        transform: 'rotate(180deg)',
                      },
                    },
                    rightSection: {
                      transition: 'transform 0.3s ease',
                      transform: 'rotate(180deg)',
                      marginRight: -3,
                      marginBottom: 3,
                      svg: {
                        color: `${colors.midnight} !important`,
                        transform: 'translateY(-3px)',
                        scale: '120%',
                      },
                    },
                  }
                : SharedSettings.MANTINE_SELECT_RIGHT_ICON_CHANGER
            }
          />
        </Input.Wrapper>

        <Input.Wrapper
          id="lureIds"
          css={{ marginTop: 20 }}
          label={translate.phrases.placeholder('Lure Type')}
          error={form.errors.lureIds}
        >
          <LuresMultiSelect targetInsectId={form.values?.insectId} {...form.getInputProps('lureIds')} />
        </Input.Wrapper>

        <Input.Wrapper
          id="cameraOrientation"
          css={{ marginTop: 20 }}
          label={translate.phrases.placeholder('Camera Orientation')}
          error={form.errors.lureIds}
        >
          <Select
            css={{ marginBottom: '16px' }}
            {...form.getInputProps('cameraOrientation')}
            data={[
              {
                label: translate.phrases.placeholder('Horizontal'),
                value: 'HORIZONTAL',
              },
              {
                label: translate.phrases.placeholder('Vertical'),
                value: 'VERTICAL',
              },
            ]}
            styles={SharedSettings.MANTINE_SELECT_RIGHT_ICON_CHANGER}
          />
        </Input.Wrapper>

        <Footer
          onNext={handleSubmit}
          nextButtonLabel={translate.phrases.placeholder('Configure')}
          disableNextButton={isSaving}
          loading={isSaving}
          onPrevious={() => onClose()}
        />
      </div>
    </>
  )
}
