import { IconPin } from 'components/icons/IconPin'
import React from 'react'
import { NODE_STATUS_COLORS } from '../../Map/_utils/getActiveNodeStatus'

const ICON_WIDTH = 28
const ICON_HEIGHT = 40

export const RelocationMarker: React.FC = () => {
  // set marker to the center of the map (anchor point is the bottom center of the icon)
  return (
    <div
      css={{
        position: 'absolute',
        top: `calc(50% - ${ICON_HEIGHT}px)`,
        left: `calc(50% - ${ICON_WIDTH / 2}px)`,
        zIndex: 10000,
        color: NODE_STATUS_COLORS.planned,
        fontSize: `${ICON_HEIGHT}px`,
      }}
    >
      <IconPin />
    </div>
  )
}
