import type { TNodeType } from 'App/ServiceCenter/types'

const DEVICEABLE_NODE_TYPES: TNodeType[] = ['dn_y', 'y', 'ln_r', 'rpt']
const BLE_NODE_TYPES: TNodeType[] = ['ln_r']
const SN_NODE_TYPES: TNodeType[] = ['scd', 'trp', 'y', 'rpt']
const TRAP_NODE_TYPES: TNodeType[] = ['trp', 'dn_trp']
const NO_RELOCATION_WARNING_NODE_TYPES: TNodeType[] = ['trp', 'dn_trp', 'gtwy', 'ln_gtwy']
const isSnNode = (nodeType: TNodeType): nodeType is TNodeType => SN_NODE_TYPES.includes(nodeType)

const isDeviceableNode = (nodeType: TNodeType): nodeType is TNodeType =>
  DEVICEABLE_NODE_TYPES.includes(nodeType)

const isBleNode = (nodeType: TNodeType): nodeType is TNodeType => BLE_NODE_TYPES.includes(nodeType)
const isTrapNode = (nodeType: TNodeType): nodeType is TNodeType => TRAP_NODE_TYPES.includes(nodeType)

const isLocationSensitiveNode = (nodeType: TNodeType): nodeType is TNodeType =>
  !NO_RELOCATION_WARNING_NODE_TYPES.includes(nodeType)

export {
  DEVICEABLE_NODE_TYPES,
  isBleNode,
  isDeviceableNode,
  isLocationSensitiveNode,
  isSnNode,
  isTrapNode,
  NO_RELOCATION_WARNING_NODE_TYPES,
  SN_NODE_TYPES,
  TRAP_NODE_TYPES,
}
