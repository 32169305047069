import type { SharedTypes } from '@semios/app-platform-banyan-route-definitions'
import type { TInjectorSettings } from 'App/Map/UserSettingsMenu/Shared/FertigationConfiguration/types'
import { translate } from 'i18n/i18n'
import moment from 'moment-timezone'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { mapApiScheduledEventStatusToTScheduledEventEnum } from '../../mapApiScheduledEventStatusToTScheduledEventEnum'
import { irrigationSchedulerStore } from '../irrigationSchedulerStore'
import { getSelectedPropertyTimezoneForIrrigationScheduler } from '../selectors/getSelectedPropertyTimezoneForIrrigationScheduler'

export const eventsUpdateAndOrDelete = (
  apiEvents: Array<
    Omit<SharedTypes.IrrigationScheduledEventOutput, 'status'> & {
      status?: SharedTypes.IrrigationScheduledEventOutput['status']
    }
  >,
) => {
  irrigationSchedulerStore.setState((isss) => {
    const scheduledEvents = { ...isss.scheduledEvents }
    const timezone = getSelectedPropertyTimezoneForIrrigationScheduler(fieldAssetStore.getState(), isss)

    const fertigationSettingsForProperty = (isss.fertigationSettings ?? []).find(
      (f) => f.propertyId === isss.selectedPropertyId,
    )

    const injectorSettingsByInjectorId =
      fertigationSettingsForProperty?.injectorSettings.reduce<Record<number, TInjectorSettings>>(
        (acc, injector) => {
          acc[injector.tankId] = injector

          return acc
        },
        {},
      ) ?? {}

    const fertilizerNamesByFertilizerId =
      fertigationSettingsForProperty?.fertilizerSettings.reduce<Record<number, string>>((acc, fertilizer) => {
        acc[fertilizer.fertilizerId] = fertilizer.fertilizerName

        return acc
      }, {}) ?? {}

    apiEvents.forEach((apiEvent) => {
      if (!apiEvent.status) {
        delete scheduledEvents[apiEvent.eventId]
      } else {
        scheduledEvents[apiEvent.eventId] = {
          ...scheduledEvents[apiEvent.eventId],
          dateFrom: moment.tz(apiEvent.dateFrom, timezone),
          dateTo: moment.tz(apiEvent.dateTo, timezone),
          type: mapApiScheduledEventStatusToTScheduledEventEnum(apiEvent.status),
          fertigationSchedules: (apiEvent.fertigationSchedules ?? []).map((fertigationEvent) => {
            const { fertigationStartTime, fertigationEndTime, injectorId, fertilizerId } = fertigationEvent
            const injector = injectorSettingsByInjectorId[injectorId]
            const flowRate = injector?.flowRate ?? null
            const flowRateUnit = injector?.flowRateUnit
            const injectorName = injector?.tankName ?? translate.phrases.banyanApp('Unknown Injector') // it's possible tankId can become undefined in property settings if removed in DropControl

            const fertilizerName =
              fertilizerNamesByFertilizerId[fertilizerId] ?? translate.phrases.banyanApp('Unknown Fertilizer') // it's possible fertilizerId can become undefined in property settings if removed in DropControl

            const duration = moment
              .tz(fertigationEndTime, timezone)
              .diff(moment.tz(fertigationStartTime, timezone), 'minute')

            return {
              ...fertigationEvent,
              duration,
              fertigationEndTime: moment.tz(fertigationEndTime, timezone),
              fertigationStartTime: moment.tz(fertigationStartTime, timezone),
              fertilizerName,
              flowRate,
              flowRateUnit,
              injectorName,
            }
          }),
        }
      }
    })

    return {
      ...isss,
      scheduledEvents,
    }
  })
}
