import { translate } from 'i18n/i18n'
import { isNil } from 'lodash'
import { getInsectNameFromId } from 'utils/getInsectNameFromId'
import type { TUnitConverterTypicalReturn } from '../types'

const DEFAULT_DECIMAL_PLACES = 0

const phenology = (
  percentageInsectFlights: number | null = null,
  {
    insectId = -1,
    decimalPlaces = DEFAULT_DECIMAL_PLACES,
  }: {
    insectId?: number
    decimalPlaces?: number
  } = {},
): TUnitConverterTypicalReturn => {
  let convertedValue: number | null

  if (isNil(percentageInsectFlights)) {
    convertedValue = null
  } else {
    convertedValue = Number(percentageInsectFlights)
  }

  return {
    categoryTitle: () => translate.phrases.banyanApp('Phenology'),
    categoryTitleWithoutUnit: () => translate.phrases.banyanApp('Phenology'),
    defaultNumberOfDecimalPlaces: () => DEFAULT_DECIMAL_PLACES,
    suffix: () => '',
    shortTitle: () => {
      return translate.phrases.banyanApp('{{insectName}} Phenology', {
        insectName: getInsectNameFromId(insectId),
      })
    },
    title: () => {
      return translate.phrases.banyanApp('{{insectName}} Phenology', {
        insectName: getInsectNameFromId(insectId),
      })
    },
    titleWithoutUnit: () => {
      return translate.phrases.banyanApp('{{insectName}} Phenology', {
        insectName: getInsectNameFromId(insectId),
      })
    },
    value: () => {
      if (isNil(convertedValue)) return null

      return +convertedValue?.toFixed(decimalPlaces)
    },
    valueAsString: () => `${convertedValue?.toFixed(decimalPlaces) ?? translate.phrases.templates('-')}`,
    valueWithSuffix: () => {
      if (isNil(convertedValue)) return translate.phrases.templates('-')

      return convertedValue.toFixed(decimalPlaces)
    },
    valueWithNoRounding: () => convertedValue,
  }
}

export const insectPhenology = { insectPhenology: phenology }
