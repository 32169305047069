import type { CSSObject } from '@emotion/css'
import { css } from '@emotion/css'
import { ExpandableChevronIndicator } from 'components/ExpandableChevronIndicator/ExpandableChevronIndicator'
import { colors } from 'settings/colors'

const paddingLeftTitleWrapper = 14
const paddingRightTitle = 14
const paddingLeftAndRight = 15

export const SectionTitleBar = ({
  children,
  collapsed,
  containerWidth,
  onCollapse,
  sticky = false,
  title,
  titleCss,
  wrapperCss,
}: {
  children?: React.ReactNode
  collapsed: boolean
  containerWidth?: number
  onCollapse: (changingFromOpenToCollapsed: boolean) => void
  sticky?: boolean
  title: React.ReactNode
  titleCss?: CSSObject
  wrapperCss?: CSSObject
}) => {
  const widthToLeftOfTitle = paddingLeftTitleWrapper + paddingRightTitle + paddingLeftAndRight

  let maxWidth: CSSObject['maxWidth'] = `calc(100vw - ${widthToLeftOfTitle}px - ${paddingRightTitle}px - 1px)`
  let titleWidth: CSSObject['width'] = '100vw'

  if (containerWidth) {
    const maxWidthByWayOfContainerWidth = `calc(${containerWidth}px - ${widthToLeftOfTitle}px - ${paddingRightTitle}px - 1px)`

    maxWidth = `min(${maxWidth}, ${maxWidthByWayOfContainerWidth})`

    const titleWidthByWayOfContainerWidth = `calc(${containerWidth}px)`

    titleWidth = `min(${titleWidth}, ${titleWidthByWayOfContainerWidth})`
  }

  return (
    <div
      className={css([
        {
          backgroundColor: colors.grey200,
          borderBottom: `1px solid ${collapsed ? '#cccbcb' : 'transparent'}`,
          fontWeight: 'bold',
          touchAction: 'pan-y',
          userSelect: 'none',
          width: '100%',
          ...wrapperCss,
        },
      ])}
    >
      <div
        className={css([
          {
            backgroundColor: colors.grey200,
            boxSizing: 'border-box',
            padding: `6px ${paddingLeftAndRight}px`,
            display: 'flex',
            justifyItems: 'center',
            alignItems: 'center',
          },
          sticky && {
            position: 'sticky',
            left: 0,
            top: 0,
            width: titleWidth,
          },
        ])}
      >
        <span
          css={{
            /**
             * we can give it a bigger area to target for our onClick
             * without taking up more space via a transparent border
             */
            border: '12px solid transparent',
            boxSizing: 'border-box',
          }}
        >
          <ExpandableChevronIndicator expanded={!collapsed} onClick={() => onCollapse(!collapsed)} />
        </span>
        <div
          className={css({
            paddingLeft: paddingLeftTitleWrapper,
            paddingTop: collapsed ? 6.8 : 0,
            paddingBottom: collapsed ? 6.8 : 0,
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            overflow: 'hidden',
            maxWidth,
            gap: '4px 17px',
            ...titleCss,
          })}
        >
          <div css={{ paddingRight: paddingRightTitle }}>{title}</div>
          {!collapsed && children}
        </div>
      </div>
    </div>
  )
}
