import { Group, Select, Stack, Text } from '@mantine/core'
import { useForm } from '@mantine/form'
import { isBleSupported } from 'App/ServiceCenter/BluetoothLowEnergy/util/utility'
import { showModalWithConfirmCheckbox } from 'App/ServiceCenter/utils/showModalWithConfirmCheckbox'
import { IconInfoLight } from 'components/icons/IconInfoLight'
import { IconWarning } from 'components/icons/IconWarning'
import { translate } from 'i18n/i18n'
import _ from 'lodash'
import React, { forwardRef, useState } from 'react'
import { SharedSettings } from 'settings/SharedSettings'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { useKeyboardVisible } from 'utils/useKeyboardVisible'
import { Footer } from '../../NodeInstallation/Footer/Footer'
import type { TActiveNode, TNodeDevice, TNodeDeviceChannels, TNodeDevicePort } from '../../types'
import { ManagementTypes, NodeDeviceInstallationStatus } from '../../types'
import { getDeviceInstallationHeightLabel } from '../../utils/getDeviceInstallationHeightLabel'
import type { TDeviceConfiguration } from '../types'
import { checkDeviceGroupPermission } from '../utils'

interface DeviceConfigurationProps {
  node: TActiveNode
  port: TNodeDevicePort
  channel: number
  detectSource?: string
  onCancel: () => void
  onConfirm: (config: TDeviceConfiguration, force?: boolean) => Promise<void>
}

export const DeviceConfiguration: React.FC<DeviceConfigurationProps> = ({
  node: selectedNode,
  port: selectedPort,
  channel: selectedChannel,
  detectSource,
  onCancel,
  onConfirm,
}) => {
  const [isSaving, setIsSaving] = useState(false)
  const { isKeyboardVisible } = useKeyboardVisible()
  const allDeviceModels = fieldAssetStore.useSelector((s) => s.devices)
  const nodeDevice = selectedNode.devices?.[selectedPort]?.[selectedChannel]

  let portOptions: { label: string; value: TNodeDevicePort; disabled: boolean }[] = []
  let channelOptions: { label: string; value: string; disabled: boolean }[] = []
  let heightOptions: ({ label: string; value: string; disabled: boolean } & DeviceHeightItemProps)[] = []

  const form = useForm<TDeviceConfiguration & { overwriteSource: boolean; overwriteHeight: boolean }>({
    initialValues: {
      connector: selectedPort,
      channel: selectedChannel,
      source: detectSource ? detectSource : nodeDevice?.source || '',
      height: nodeDevice?.height || '',
      hiddenFromCustomer: nodeDevice?.hiddenFromCustomer || false,
      managementType: selectedNode.managementType || ManagementTypes.COMMERCIAL,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      maintenanceOwnerId: selectedNode.maintenanceOwnerId!,
      overwriteSource: !!detectSource && detectSource !== nodeDevice?.source,
      overwriteHeight: false,
    },
    validate: {
      source: (value: string) => {
        if (sources.length === 0) {
          return translate.phrases.placeholder('No devices available, please check your permissions.')
        }

        if (value.length === 0) {
          return translate.phrases.placeholder('Please select a device type')
        }

        return null
      },
      height: (value: string) => {
        if (value.length === 0) {
          return translate.phrases.placeholder('Please select a height')
        }

        return null
      },
      channel: (value: number) => {
        if (
          !channelOptions
            .filter((opt) => !opt.disabled)
            .map((opt) => opt.value)
            .includes(value.toString())
        ) {
          return translate.phrases.placeholder('There is no available channel for this device')
        }

        return null
      },
    },
  })

  const sources = _.sortBy(
    Object.values(allDeviceModels)
      .filter((device) => device.connector === selectedPort)
      .filter((device) => checkDeviceGroupPermission(device.deviceGroupName))
      .filter((device) => device.installationChannels.includes(selectedChannel))
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((device) => {
        return {
          label: device.name,
          value: device.source,
          group: device.connector.toUpperCase(),
        }
      }),
  )

  //Can't install the device if the height is already in use by another active device w/ the same source
  const heightInUse = Object.entries(selectedNode.devices || []).reduce(
    (acc: string[], [connector, deviceGroup]: [string, TNodeDeviceChannels]) => {
      Object.entries(deviceGroup).forEach(([channel, device]: [string, TNodeDevice]) => {
        if (connector === selectedPort && +channel === selectedChannel) return

        if (
          device.source === form.values.source &&
          device.installationStatus === NodeDeviceInstallationStatus.INSTALLED
        ) {
          acc.push(device.height)
        }
      })

      return acc
    },
    [],
  )

  const heightInPlanned: Map<string, string> = new Map(
    Object.entries(selectedNode.devices || [])
      .reduce(
        (
          acc: { height: string; channel: string }[],
          [connector, deviceGroup]: [string, TNodeDeviceChannels],
        ) => {
          Object.entries(deviceGroup).forEach(([channel, device]: [string, TNodeDevice]) => {
            if (connector === selectedPort && +channel === selectedChannel) return

            if (
              device.source === form.values.source &&
              device.installationStatus === NodeDeviceInstallationStatus.PLANNED
            ) {
              acc.push({ height: device.height, channel })
            }
          })

          return acc
        },
        [],
      )
      .map((e) => [e.height, e.channel] as const),
  )

  const channelInUse = Object.entries(selectedNode.devices || []).reduce(
    (acc: number[], [connector, deviceGroup]: [string, TNodeDeviceChannels]) => {
      Object.entries(deviceGroup).forEach(([channel]: [string, TNodeDevice]) => {
        if (connector === selectedPort && +channel === selectedChannel) return

        if (connector === selectedPort) {
          acc.push(+channel)
        }
      })

      return acc
    },
    [],
  )

  if (form.values.source && allDeviceModels[form.values.source]) {
    const model = allDeviceModels[form.values.source]

    if (model) {
      heightOptions = model.installationHeights.map((height: string) => {
        const plannedHeight = heightInPlanned.get(height)

        return {
          label: getDeviceInstallationHeightLabel(height),
          warning: plannedHeight
            ? translate.phrases.placeholder('Planned for Address {{channel}}', { channel: plannedHeight })
            : undefined,
          value: height,
          disabled: heightInUse.includes(height),
        }
      })

      channelOptions = model.installationChannels.map((channel: number) => {
        return {
          label: channel.toString(),
          value: channel.toString(),
          disabled: channelInUse.includes(channel),
        }
      })
    }
  }

  portOptions = [
    {
      label: selectedPort.toUpperCase(),
      value: selectedPort,
      disabled: false,
    },
  ]

  const handleSubmit = async () => {
    const validation = form.validate()

    if (validation.hasErrors) {
      return
    }

    try {
      setIsSaving(true)

      await onConfirm(form.values, form.values.overwriteSource || form.values.overwriteHeight)
    } catch (error) {
      setIsSaving(false)
    }
  }

  return (
    // device selection option is searchable and mobile keyboard hide some options so when keyboard is open, push up entire screen
    <div css={{ padding: 10, paddingBottom: isKeyboardVisible ? 500 : 10 }}>
      <h3 css={{ margin: 0 }}>{translate.phrases.placeholder('Configure Device')}</h3>

      <p css={{ lineHeight: '24px', marginTop: 10, marginBottom: 20 }}>
        {translate.phrases.placeholder('Select the device height & port below.')}
      </p>

      <Stack>
        <Select
          required
          {...form.getInputProps('source')}
          label={translate.phrases.placeholder('Device Type')}
          disabled={detectSource !== undefined} // if detectSource is provided, user can't change the source
          placeholder={translate.phrases.placeholder('Please select a device type')}
          data={sources}
          styles={SharedSettings.MANTINE_SELECT_RIGHT_ICON_CHANGER}
          searchable
          onChange={async (v: string) => {
            //The user is trying to change the device type, check if there is a planned device that will be overwritten
            //Detection case is handled separately
            if (nodeDevice?.source && nodeDevice?.source !== v && !detectSource) {
              if (
                !(await confirmOverwritePlannedDevice(
                  allDeviceModels[nodeDevice?.source].name,
                  allDeviceModels[v].name,
                ))
              ) {
                return
              }

              form.setValues((prev) => ({ ...prev, source: v, overwriteSource: true, height: '' }))
            } else {
              form.setValues((prev) => ({ ...prev, source: v, overwriteSource: false, height: '' }))
            }
          }}
        />
        {form.isValid('source') && (
          <>
            <Select
              {...form.getInputProps('height')}
              required
              label={translate.phrases.placeholder('Installation Height/Depth')}
              itemComponent={DeviceHeightItem}
              icon={heightInPlanned.get(form.values.height) ? <YellowWarning /> : null}
              placeholder={translate.phrases.placeholder('Select height')}
              data={heightOptions}
              styles={SharedSettings.MANTINE_SELECT_RIGHT_ICON_CHANGER}
              searchable
              onChange={async (v: string) => {
                if (heightInPlanned.get(v)) {
                  if (!(await confirmRemovePlannedDevice(allDeviceModels[form.values.source].name))) {
                    return
                  }

                  form.setValues((prev) => ({ ...prev, overwriteHeight: true, height: v }))
                } else {
                  form.setValues((prev) => ({ ...prev, overwriteHeight: false, height: v }))
                }
              }}
            />
            <Select
              {...form.getInputProps('connector')}
              disabled
              required
              label={translate.phrases.placeholder('Input')}
              placeholder={translate.phrases.placeholder('Select Input')}
              data={portOptions}
              styles={SharedSettings.MANTINE_SELECT_RIGHT_ICON_CHANGER}
              searchable
            />
            <Select
              {...form.getInputProps('channel')}
              disabled
              required
              label={translate.phrases.placeholder('Address')}
              placeholder={translate.phrases.placeholder('Select Address')}
              data={channelOptions}
              value={selectedChannel.toString()}
              styles={SharedSettings.MANTINE_SELECT_RIGHT_ICON_CHANGER}
              searchable
            />
          </>
        )}
        {selectedPort === 'sdi' && detectSource && isBleSupported() && (
          <Text>
            <span css={{ paddingRight: 4 }}>
              {translate.phrases.placeholder(
                'If this is one of multiple devices you wish to plug into the SDI port be sure to remove other devices, plug this device in, and then set a unique channel before finalizing the install.',
              )}
            </span>
            <IconInfoLight />
          </Text>
        )}
      </Stack>
      <Footer
        nextButtonLabel={translate.phrases.placeholder('Next')}
        previousButtonLabel={translate.phrases.placeholder('Previous')}
        loading={isSaving}
        onNext={() => handleSubmit()}
        onPrevious={() => onCancel()}
      />
    </div>
  )
}

interface DeviceHeightItemProps extends React.ComponentPropsWithoutRef<'div'> {
  label: string
  warning?: string
}

const YellowWarning = () => {
  return (
    <span
      css={{
        color: '#FFAB00',
        display: 'flex',
      }}
    >
      <IconWarning />
    </span>
  )
}

const confirmOverwritePlannedDevice = async (plannedDeviceType: string, overwriteDeviceType: string) => {
  return await new Promise<boolean>((resolve) => {
    showModalWithConfirmCheckbox({
      id: 'planned-device-type-overwrite',
      title: translate.phrases.placeholder('Overwrite Planned Device Type'),
      description: (
        <Text>
          <translate.Phrases.placeholder
            k="A <strong>{{plannedDeviceType}}</strong> device is planned for this address. Please make sure you are installing the new device in the correct address. If you want to proceed with the installation of <strong>{{overwriteDeviceType}}</strong>, the planned device will be overwritten."
            v={{ plannedDeviceType, overwriteDeviceType }}
          />
        </Text>
      ),
      checkboxLabel: translate.phrases.placeholder('Overwrite Planned Device'),
      confirmLabel: translate.phrases.placeholder('Continue'),
      cancelLabel: translate.phrases.placeholder('Cancel'),
      onConfirm: () => {
        resolve(true)
      },
      onCancel: () => {
        resolve(false)
      },
    })
  })
}

const confirmRemovePlannedDevice = async (plannedDeviceType: string) => {
  return await new Promise<boolean>((resolve) => {
    showModalWithConfirmCheckbox({
      id: 'height-used-planned',
      title: translate.phrases.placeholder('Conflict with Planned Device'),
      description: (
        <Text>
          <translate.Phrases.placeholder
            k={
              'Another <strong>{{plannedDeviceType}}</strong> is already planned at this height on a different address. If you want to proceed with the installation at the selected height, the planned device will be overwritten.'
            }
            v={{ plannedDeviceType }}
          />
        </Text>
      ),
      checkboxLabel: translate.phrases.placeholder('Overwrite Planned Device'),
      confirmLabel: translate.phrases.placeholder('Continue'),
      cancelLabel: translate.phrases.placeholder('Cancel'),
      onConfirm: () => {
        resolve(true)
      },
      onCancel: () => {
        resolve(false)
      },
    })
  })
}

const DeviceHeightItem = forwardRef<HTMLDivElement, DeviceHeightItemProps>(
  ({ label, warning, ...others }: DeviceHeightItemProps, ref) => (
    <div ref={ref} {...others}>
      <Stack>
        <div>
          <Text>{label}</Text>
          {warning && (
            <Group spacing={4}>
              <YellowWarning />
              <Text css={{ color: 'gray' }}>{warning}</Text>
            </Group>
          )}
        </div>
      </Stack>
    </div>
  ),
)

DeviceHeightItem.displayName = 'DeviceHeightItem'
