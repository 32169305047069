import { IconRefresh } from 'components/icons/IconRefresh'

export const SpinningRefreshIcon = ({ loading }: { loading: boolean }) => {
  return (
    <span
      css={{
        'display': 'flex',
        'alignItems': 'center',
        ...(loading && {
          animation: 'spin 1s linear infinite',
          transformOrigin: 'center',
        }),
        '@keyframes spin': {
          '0%': { transform: 'rotate(0deg)' },
          '100%': { transform: 'rotate(360deg)' },
        },
      }}
    >
      <IconRefresh />
    </span>
  )
}
