import { NodeIdPattern } from './regexes'

export const extractNodeIdentifierFromString = (testString: string) => {
  let result: string | undefined

  Object.values(NodeIdPattern).forEach((pattern) => {
    if (result) return // Break loop if result is already found

    if (pattern) {
      const match = testString.match(pattern)

      if (match && match[0]) {
        result = match[0]
      }
    }
  })

  return result
}
