import { translate } from 'i18n/i18n'
import { colors } from 'settings/colors'
import type { ImperialXorMetric } from 'stores/userDetailsStore'
import { getIrrigationActivityChartSettings } from '../_utils/irrigationActivityChartSettings'

export const IrrigationChartLegendWithPlannedActivity = ({
  pressureUnitFromUserSetting,
}: {
  pressureUnitFromUserSetting: ImperialXorMetric
}) => {
  const colorStyles = {
    margin: '0px 4px 0px 10px',
    height: 15,
    width: 15,
    display: 'inline-block',
  }

  const irrigationActivitySettings = getIrrigationActivityChartSettings({ pressureUnitFromUserSetting })
  const { on, off, noData } = irrigationActivitySettings

  return (
    <div css={{ display: 'flex', padding: '5px 10px 0', fontSize: 14, fontWeight: 'bold' }}>
      <div css={{ display: 'flex', alignItems: 'center', paddingRight: '10px' }}>
        <span
          css={{
            ...colorStyles,
            background: colors.lightGreen,
            border: colors.lightGreen,
          }}
        />
        <span css={{ fontSize: 14 }}>{translate.phrases.banyanApp('Scheduled')}</span>
      </div>
      <div css={{ display: 'flex', alignItems: 'center', paddingRight: '10px' }}>
        <span css={{ ...colorStyles, background: on.color }} />
        <span>{on.text}</span>
      </div>
      <div css={{ display: 'flex', alignItems: 'center', paddingRight: '10px' }}>
        <span css={{ ...colorStyles, background: off.color }} />
        <span>{off.text}</span>
      </div>
      <div css={{ display: 'flex', alignItems: 'center' }}>
        <span css={{ ...colorStyles, background: noData.color }} />
        <span>{noData.text}</span>
      </div>
    </div>
  )
}
