import { actions } from './actions'

export const fetchFertigationSettings = async ({
  setLoadingFertigationSettings,
  propertyIds,
}: {
  setLoadingFertigationSettings: React.Dispatch<React.SetStateAction<boolean>>
  propertyIds: number[]
}) => {
  try {
    setLoadingFertigationSettings(true)

    const response = await actions.api.irrigationSchedulerFertigationSettingsGet({
      propertyIds,
    })

    if (!Array.isArray(response)) {
      throw new Error(JSON.stringify(response))
    }

    actions.api.setFertigationSettings(response)
  } catch (error) {
    // TODO: do something cool with errors
    // eslint-disable-next-line no-console
    console.error('Error fetching fertigation settings:', error)

    actions.api.setFertigationSettings([])
  } finally {
    setLoadingFertigationSettings(false)
  }
}
