import type { TFieldAssetStoreState } from 'stores/fieldAssetStore'

/**
 * In order for cultivars to be displayed to users and make sense - they have to be:
 * 1. in this list
 * 2. a row in the `public.cultivars` table
 * 3. a row in the `public.cultivars_dates_defaults` table
 * 4. 4 rows in the `model.hullsplit_tombesi_2010` table
 *
 * example SQL:
 * INSERT INTO cultivars (id, "name",crop_id) VALUES
	 (314, 'Carina',2),
	 (313, 'Shasta',2);
 *
   -- insert for Carina (id 313)
  INSERT INTO model.hullsplit_tombesi_2010 (cultivar_id,dafb,slope,intercept,rsquared) VALUES
	 (313,'30',-0.13,172,0.34),
	 (313,'50',-0.12,191,0.58),
	 (313,'90',-0.06,192,0.54),
	 (313,'total',0.03,89,0.12);

   INSERT INTO cultivars_dates_defaults (cultivar_id,stage_id,doy) VALUES
	 (313,37,53);

   // right now - crop_id 314 only meets the first 2 requirements so will not show up to users but have added it here so that once
   the other requirements are met it will show up with no further code change
 */
const almondHullSplitCultivarIds = [72, 73, 74, 75, 76, 77, 78, 80, 82, 85, 87, 88, 313, 314]

export const almondCultivarsSelector = (s: TFieldAssetStoreState) => {
  const almondCrop = s.crops ? s.crops[2] : null

  if (almondCrop) {
    const almondCultivarsArray = Object.entries(almondCrop.cultivars).map(([id, name]) => {
      return { id, name }
    })

    return almondCultivarsArray.filter((c) => almondHullSplitCultivarIds.includes(Number(c.id)))
  }

  return []
}
