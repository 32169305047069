import { Tabs, useMantineTheme } from '@mantine/core'
import * as Sentry from '@sentry/react'
import { IconServiceCenter } from 'components/icons/IconServiceCenter'
import { IconSuccess } from 'components/icons/IconSuccess'
import { IconWarning } from 'components/icons/IconWarning'
import { translate } from 'i18n/i18n'
import React, { useState } from 'react'
import { colors } from 'settings/colors'
import type { TActiveGateway, TActiveNode, TNodeServiceIssueSummary } from '../../types'
import { TServiceIssueStatus } from '../../types'
import { getNodeTypeLabelFromNode } from '../../utils/getNodeTypeLabel'
import { IssueCard } from '../IssueCard/IssueCard'

interface ServiceIssueListProps {
  node: TActiveNode | TActiveGateway
  serviceIssues: TNodeServiceIssueSummary[]

  onSelectIssue(serviceIssueId: number): void
}

enum ISSUE_TAB {
  ACTIVE = 'ACTIVE',
  REVIEW = 'REVIEW',
  HISTORY = 'HISTORY',
}

type GroupedIssues = {
  [key in ISSUE_TAB]: TNodeServiceIssueSummary[]
}

export function getServiceIssueLabelIcon(status: TServiceIssueStatus): JSX.Element | null {
  switch (status) {
    case TServiceIssueStatus.CREATED:
      return (
        <span style={{ color: colors.yellow }}>
          <IconWarning />
        </span>
      )

    case TServiceIssueStatus.FLAGGED_FOR_SERVICE:
      return (
        <span style={{ color: colors.red }}>
          <IconWarning />
        </span>
      )

    case TServiceIssueStatus.SERVICE_COMPLETED:
      return (
        <span style={{ color: colors.green }}>
          <IconSuccess />
        </span>
      )

    case TServiceIssueStatus.CLOSED_FIXED:
      return (
        <span style={{ color: colors.grey800 }}>
          <IconServiceCenter />
        </span>
      )

    case TServiceIssueStatus.CLOSED_UNFIXED:
      return (
        <span style={{ color: colors.grey800 }}>
          <IconServiceCenter />
        </span>
      )

    default: {
      Sentry.captureException(new Error(`Service issue status: "${status}" has not been implemented`))

      return null
    }
  }
}

export const ServiceIssueList: React.FC<ServiceIssueListProps> = ({ node, serviceIssues, onSelectIssue }) => {
  const theme = useMantineTheme()
  const [activeTab, setActiveTab] = useState<ISSUE_TAB>(ISSUE_TAB.ACTIVE)
  const equipmentTypeLabel = getNodeTypeLabelFromNode(node)

  const issuesByTabs = serviceIssues.reduce(
    (acc, issue) => {
      if (
        issue.serviceIssueStatus === TServiceIssueStatus.CREATED ||
        issue.serviceIssueStatus === TServiceIssueStatus.FLAGGED_FOR_SERVICE
      ) {
        acc.ACTIVE.push(issue)
      } else if (issue.serviceIssueStatus === TServiceIssueStatus.SERVICE_COMPLETED) {
        acc.REVIEW.push(issue)
      } else {
        acc.HISTORY.push(issue)
      }

      return acc
    },
    {
      ACTIVE: [],
      REVIEW: [],
      HISTORY: [],
    } as GroupedIssues,
  )

  return (
    <div
      css={{
        padding: '10px 10px 20px',
      }}
    >
      <Tabs
        styles={{
          tabsList: {
            button: {
              'fontSize': 15,
              'padding': 10,
              'color': theme.colors.primary[0],
              '&:hover': { background: 'transparent', fontWeight: 600 },
              '&[data-active]': {
                'fontWeight': 700,
                'borderBottom': `4px solid ${theme.colors.midnight[0]}`,
                '&:hover': { fontWeight: 700, borderColor: theme.colors.midnight[0] },
              },
              '&:not(:first-of-type)': { marginLeft: 20 },
            },
          },
          panel: {
            paddingTop: 10,
          },
        }}
        value={activeTab}
        onTabChange={(val) => setActiveTab(val as ISSUE_TAB)}
      >
        <Tabs.List position="apart">
          <Tabs.Tab value={ISSUE_TAB.ACTIVE}>{translate.phrases.placeholder('Active Issues')}</Tabs.Tab>
          <Tabs.Tab value={ISSUE_TAB.REVIEW}>{translate.phrases.placeholder('Review')}</Tabs.Tab>
          <Tabs.Tab value={ISSUE_TAB.HISTORY}>{translate.phrases.placeholder('History')}</Tabs.Tab>
        </Tabs.List>

        <div css={{ paddingTop: 10 }}>
          {issuesByTabs[activeTab as ISSUE_TAB].map((serviceIssue) => (
            <IssueCard
              key={serviceIssue.serviceIssueId}
              issue={serviceIssue}
              node={node}
              onSelectIssue={() => onSelectIssue(serviceIssue.serviceIssueId)}
            />
          ))}

          {!issuesByTabs[activeTab as ISSUE_TAB].length && (
            <div
              css={{
                color: theme.colors.grey[2],
                fontSize: '24px',
                fontWeight: 300,
                textAlign: 'center',
                margin: 'auto',
                paddingLeft: 30,
                paddingRight: 30,
                lineHeight: 1.5,
                position: 'absolute',
                top: 'calc(50% - 50px)',
                width: '100%',
              }}
            >
              {activeTab === ISSUE_TAB.ACTIVE &&
                translate.phrases.placeholder('This {{equipmentType}} has no active issues.', {
                  equipmentType: equipmentTypeLabel,
                })}
              {activeTab === ISSUE_TAB.REVIEW &&
                translate.phrases.placeholder('This {{equipmentType}} has no completed issues.', {
                  equipmentType: equipmentTypeLabel,
                })}
              {activeTab === ISSUE_TAB.HISTORY &&
                translate.phrases.placeholder('This {{equipmentType}} has no previous issues.', {
                  equipmentType: equipmentTypeLabel,
                })}
            </div>
          )}
        </div>
      </Tabs>
    </div>
  )
}
