import type { routes } from '@semios/app-platform-banyan-route-definitions'
import type { FireBlightHistoricalRiskDatabaseId } from '@semios/app-platform-common'
import {
  FIRE_BLIGHT_DEFAULT,
  isEmpty,
  isNil,
  riskIdColorPicker,
  sortByKey,
} from '@semios/app-platform-common'
import type { VV } from '@semios/app-platform-value-type-definitions'
import { EAggregationInterval } from '@semios/app-platform-value-type-definitions'
import { FireBlightSettings } from 'App/Map/PanelDetails/_utils/by-domain/fireBlight/fireBlightSettings/fireBlightSettings'
import { propertyLacksPermissionSectionMaker } from 'App/Map/PanelDetails/_utils/propertyLacksPermissionSectionMaker'
import { refreshMapData } from 'App/Map/PanelDetails/_utils/refreshMapData'
import { selectedPropertyHasPermission } from 'App/Map/PanelDetails/_utils/selectedPropertyHasPermission'
import { DropdownSelectorProperty } from 'App/Map/PanelDetails/SectionTitleBars/DropdownSelectorProperty/DropdownSelectorProperty'
import { BlockWithWeatherStationLabel } from 'App/Map/PanelDetails/SummaryGridTable/BlockWithWeatherStationLabel/BlockWithWeatherStationLabel'
import type { GridTableContentSection } from 'components/GridTable/types'
import { QuestionToolTip } from 'components/QuestionToolTip/QuestionToolTip'
import { translate } from 'i18n/i18n'
import { keyBy } from 'lodash'
import { colors } from 'settings/colors'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import type { TSelectedFieldAssetsStoreState } from 'stores/selectedFieldAssetsStore'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import type { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { filterFieldAssetsByValueTypes } from 'utils/filterFieldAssetsByValueTypes'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import { BadgeCell } from '../../../BadgeCell/BadgeCell'

const permission = 'VIEW_FIRE_BLIGHT_DETAILS'
const preferredAggregationInterval = { preferredAggregationInterval: EAggregationInterval.DAILY }
const checkPermission = () => selectedPropertyHasPermission({ permission })
const valueType = 'fireBlightTRV' as VV.DomainTypes.FireBlight.TTimeseriesValueTypeKeysMerged

export const apiArgs = ({
  selectedValueGroups,
  selectedFieldAssets,
}: {
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}): Partial<routes.Values.Request> => {
  if (!checkPermission()) return {}

  if (!selectedValueGroups.fire_blight) return {}

  if (!selectedFieldAssets.property) return {}

  const blocks = fieldAssetStore.getState()?.properties?.[selectedFieldAssets.property]?.blocks ?? {}

  const relevantBlocks = filterFieldAssetsByValueTypes({
    fieldAssets: Object.values(blocks),
    valuesTimeseries: [valueType],
  })

  if (isEmpty(relevantBlocks)) return {}

  return {
    blocks: {
      blockIds: relevantBlocks.map((b) => b.blockId),
      valuesRequested: {
        [valueType]: preferredAggregationInterval,
      },
    },
  }
}

export const content = ({
  data,
  updateData,
}: {
  data: routes.Values.Response
  updateData: (pathToSet: string, dataToSet: unknown) => void
}): GridTableContentSection => {
  const propertyId = selectedFieldAssetsStore.getState()?.property

  const updateLocalRiskHistory = (blockId: string, riskId: string) =>
    updateData(`blocks.${blockId}.values.fireBlightTRV.0.metadata.riskId`, riskId)

  const onApply = (riskId: FireBlightHistoricalRiskDatabaseId, blockIds: string[]) => {
    for (const bid of blockIds) {
      updateLocalRiskHistory(bid, riskId)
    }

    refreshMapData('fire_blight')
  }

  const blocks = fieldAssetStore.getState()?.properties?.[Number(propertyId)]?.blocks ?? {}
  const points = fieldAssetStore.getState()?.properties?.[Number(propertyId)]?.points ?? {}

  const relevantBlocks = filterFieldAssetsByValueTypes({
    fieldAssets: Object.values(blocks),
    valuesTimeseries: [valueType],
  })
    .map((block) => ({ ...block, name: block.name ?? translate.phrases.banyanApp('Unnamed Block') }))
    .sort(sortByKey('name'))

  const commonReturnItems = {
    id: 'fireBlight',
    title: unitConverter.fireBlightDaily().categoryTitleWithoutUnit(),
    titleCss: { width: '100%' },
    titleChildren: (
      <>
        <QuestionToolTip>{unitConverter.fireBlightDaily().title()}</QuestionToolTip>
        <DropdownSelectorProperty
          valuesTimeseriesToFilterOn={[valueType]}
          propertyPermissionsToCheck={[permission]}
        />
        <FireBlightSettings onApply={onApply} valuesTimeseries={[valueType]} riskId={FIRE_BLIGHT_DEFAULT} />
      </>
    ),
  }

  if (!checkPermission() || isEmpty(relevantBlocks))
    return propertyLacksPermissionSectionMaker(commonReturnItems)

  type PossibleValuesRecord = { timestamp: string; value: number | null | undefined }

  const items = relevantBlocks.map((block) => {
    const blockData = data?.blocks?.[block.blockId]?.values?.fireBlightTRV?.[0]
    const blockName = block.name

    const weatherStationLabel = block.associatedWeatherStation
      ? points[block.associatedWeatherStation]?.name ?? ''
      : ''

    const values = keyBy(
      blockData?.timeseries?.map(({ timestamp, value }) => ({ timestamp, value })) ?? [],
      'timestamp',
    )

    const riskId = blockData?.metadata?.riskId ?? FIRE_BLIGHT_DEFAULT
    const riskColor = riskIdColorPicker[riskId]

    return {
      id: `fire_blight_${block.blockId}`,
      height: 70,
      label: <BlockWithWeatherStationLabel label={blockName} weatherStationLabel={weatherStationLabel} />,
      labelMinWidth: 160,
      valueMinWidth: 120,
      values,
      render: (value: PossibleValuesRecord) => {
        const riskValue = value?.value

        if (isNil(riskValue)) {
          return translate.phrases.templates('-')
        }

        return (
          <BadgeCell backgroundColor={riskColor(riskValue) ?? colors.white}>
            {unitConverter.fireBlightDaily(riskValue).value()}
          </BadgeCell>
        )
      },
    }
  })

  return {
    ...commonReturnItems,
    items,
  }
}
