import { makeBaseSvgIconProps } from 'components/icons/_utils/makeBaseSvgIconProps'
import { colors } from 'settings/colors'

export const IconCurrentLocationMarker = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 60 60')}>
      <circle cx="30" cy="30" r="12.3233" fill="#F1F1F1" />
      <g>
        <circle
          cx="30"
          cy="30"
          r="8.5"
          fill={colors.primaryLight}
          style={{ transformOrigin: 'center' }}
        ></circle>
      </g>
    </svg>
  )
}
