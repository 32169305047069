import type { TActiveGateway, TActiveNode } from 'App/ServiceCenter/types'
import { getNodeTypeLabelFromNode } from 'App/ServiceCenter/utils/getNodeTypeLabel'
import React from 'react'

export const NodeInfoHeader: React.FC<{ node: TActiveNode | TActiveGateway | null }> = ({ node }) => {
  if (!node) return null

  return (
    <>
      <strong css={{ fontSize: '20px' }}>{node?.name}</strong>
      <div css={{ marginTop: '4px', fontSize: 14 }}>
        {(node as TActiveNode).nodeIdentifier || (node as TActiveGateway).gatewayIdentifier}
      </div>
      <div css={{ marginTop: '4px', fontSize: 14 }}>{getNodeTypeLabelFromNode(node)}</div>
    </>
  )
}
