import { Geolocation } from '@capacitor/geolocation'
import { Network } from '@capacitor/network'
import type { Interpolation, Theme } from '@emotion/react'
import * as Sentry from '@sentry/react'
import { translate } from 'i18n/i18n'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { showNotification } from 'utils/showNotification'
import { MapContext } from '../../MapContext/MapContext'
import { ControlButton } from './ControlButton'
import { useLocationMarker } from './useLocationMarker'

type CurrentLocationProps = {
  enableHighAccuracy?: boolean
  enableFollowing?: boolean
  autoStart?: boolean
  style?: Interpolation<Theme>
}

export type StatusType = 'denied' | 'inactive' | 'watching' | 'following'

const DEFAULT_OPTIONS = { maximumAge: 16000, timeout: 8000, enableHighAccuracy: false }

export const CurrentLocation: React.FC<CurrentLocationProps> = ({
  enableHighAccuracy = false,
  enableFollowing = true,
  autoStart = false,
  style,
}: CurrentLocationProps) => {
  const { map } = useContext(MapContext)
  const watcherID = useRef<string | null>(null)
  const dragendListenerID = useRef<google.maps.MapsEventListener | null>(null)
  const zoomListenerID = useRef<google.maps.MapsEventListener | null>(null)
  const networkListener = useRef<ReturnType<typeof Network.addListener> | null>(null)
  const [status, setStatus] = useState<StatusType>(autoStart ? 'watching' : 'inactive')
  const [currentLocation, setCurrentLocation] = useState<google.maps.LatLngLiteral | null>(null)
  const [mapCenteredOnCurrentLocation, setMapCenteredOnCurrentLocation] = useState(false)
  const [accuracy, setAccuracy] = useState<number | null>(null)

  useLocationMarker({ position: currentLocation, accuracy })

  const askForPermission = async () => {
    const { location } = await Geolocation.checkPermissions()

    if (location === 'prompt' && window.Capacitor.getPlatform() !== 'web') {
      try {
        const permissionStatus = await Geolocation.requestPermissions()

        if (permissionStatus.location === 'denied') setStatus('denied')
      } catch (error) {
        setStatus('denied')
      }
    } else if (location === 'denied') {
      showNotification({
        id: 'GEO_LOCATION_PERMISSION_DENIED_NOTIFICATION', // [APPS-7641] Gave this a static id as a quick fix to prevent the notification being shown multiple times
        type: 'error',
        message: translate.phrases.banyanApp('Please enable location permissions in your device settings.'),
      })

      setStatus('denied')
    } else if (location === 'granted') {
      if (status === 'denied') setStatus('watching')
    }
  }

  const getInitialPosition = async () => {
    await askForPermission()

    try {
      const position = await Geolocation.getCurrentPosition(DEFAULT_OPTIONS)

      if (position) {
        const latLng = { lat: position.coords.latitude, lng: position.coords.longitude }

        setCurrentLocation(latLng)

        setAccuracy(position.coords.accuracy)
      }
    } catch (error) {
      // 1: PERMISSION_DENIED
      // 2: POSITION_UNAVAILABLE
      // 3: TIMEOUT
      if ((error as GeolocationPositionError).code === 1) {
        // User denied location permission
        setStatus('denied')
      } else {
        Sentry.captureException(error)
      }
    }
  }

  const startPositionWatcher = async () => {
    await askForPermission()

    try {
      if (watcherID.current) {
        // Clear previous watcher
        await Geolocation.clearWatch({
          id: watcherID.current,
        })

        watcherID.current = null
      }

      const watchOptions = {
        ...DEFAULT_OPTIONS,
        // If high accuracy is enabled, increase the timeout to give the device more time to get a better location
        timeout: enableHighAccuracy ? 30000 : DEFAULT_OPTIONS.timeout,
        enableHighAccuracy,
      }

      watcherID.current = await Geolocation.watchPosition(
        watchOptions,
        (position, error: GeolocationPositionError) => {
          if (error) {
            // 1: PERMISSION_DENIED
            // 2: POSITION_UNAVAILABLE
            // 3: TIMEOUT
            if (error.code === 1) {
              setStatus('denied')

              if (watcherID.current) {
                // Clear previous watcher
                Geolocation.clearWatch({
                  id: watcherID.current,
                })

                watcherID.current = null
              }
            } else {
              Sentry.captureException(error)
            }

            return
          }

          if (!position) return

          const latLng = { lat: position.coords.latitude, lng: position.coords.longitude }

          const hasLocationChanged =
            !currentLocation || currentLocation.lat !== latLng.lat || currentLocation.lng !== latLng.lng

          setCurrentLocation(latLng)

          setAccuracy(position.coords.accuracy)

          if (status === 'watching') {
            if (hasLocationChanged) {
              setMapCenteredOnCurrentLocation(false)
            }
          } else if (status === 'following') {
            if (map) {
              map.setCenter(latLng)

              setMapCenteredOnCurrentLocation(true)
            }
          }
        },
      )
    } catch (error) {
      if (watcherID.current) {
        Sentry.captureException(error)
      } else {
        showNotification({
          type: 'error',
          message: translate.phrases.placeholder('Error while retrieving location. Please try again.'),
        })

        setStatus('inactive')
      }
    }
  }

  const handleMapMove = () => {
    dragendListenerID.current?.remove()

    dragendListenerID.current = null

    zoomListenerID.current?.remove()

    zoomListenerID.current = null

    setMapCenteredOnCurrentLocation(false)

    setStatus('watching')
  }

  useEffect(() => {
    if (map) {
      getInitialPosition()

      if (autoStart) {
        startPositionWatcher()
      }
    }

    // Refresh the current location when the network status changes
    networkListener.current = Network.addListener('networkStatusChange', async (status) => {
      if (status.connected) getInitialPosition()
    })

    return () => {
      // stop listening for location changes and map events
      if (watcherID.current) Geolocation.clearWatch({ id: watcherID.current })

      dragendListenerID.current?.remove()

      zoomListenerID.current?.remove()

      networkListener.current?.remove()
    }
  }, [map])

  const toggleCurrentLocationWatch = async () => {
    switch (status) {
      case 'denied':
        await getInitialPosition()

        break

      case 'inactive': // Start watching location, ask permissions
        setStatus('watching')

        await startPositionWatcher()

        break

      case 'watching':
        // If following is enabled, switch following status
        // If not, only center the map to the current location
        if (enableFollowing) setStatus('following')

        if (currentLocation) {
          map?.setCenter(currentLocation)

          setMapCenteredOnCurrentLocation(true)

          // Start listening for map move events
          dragendListenerID.current = map?.addListener('dragend', handleMapMove) || null

          zoomListenerID.current = map?.addListener('zoom_changed', handleMapMove) || null
        }

        break

      case 'following': // If following, back to watching
        setStatus('watching')

        break

      default:
        break
    }
  }

  const loading = !['denied', 'inactive'].includes(status) && !currentLocation
  // If following is disabled, but the map is centered on the current location,
  // the button will look like as in following status (blue arrow) until the map is moved again
  const buttonStatus = !enableFollowing && mapCenteredOnCurrentLocation ? 'following' : status

  return (
    <ControlButton css={style} status={buttonStatus} loading={loading} onClick={toggleCurrentLocationWatch} />
  )
}
