import { Button } from 'components/Button/Button'
import { translate } from 'i18n/i18n'
import { smallStore } from 'stores/smallStore'

export const SemiosClassicSection = () => {
  return (
    <div>
      <Button
        onClick={() => {
          smallStore.setState((s) => ({ ...s, showSettingsModal: false }))

          window.location.href = `https://${import.meta.env.VITE_SEMIOS_CLASSIC_HOSTNAME}`
        }}
        variant="tertiary"
      >
        {translate.phrases.banyanApp('Return to {{hostname}}', {
          hostname: import.meta.env.VITE_SEMIOS_CLASSIC_HOSTNAME,
        })}
      </Button>
    </div>
  )
}
