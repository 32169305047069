import type { FireBlightHistoricalRiskDatabaseId } from '@semios/app-platform-common'
import {
  FIRE_BLIGHT_DEFAULT,
  FIRE_BLIGHT_HISTORICAL_RISK_CONFIGURATION,
  FIRE_BLIGHT_HISTORICAL_RISK_CONFIGURATION_BY_ID,
  isRiskIdValid,
  riskThreshold,
} from '@semios/app-platform-common'
import { translate } from 'i18n/i18n'
import { alphaColorReplacer, colors } from 'settings/colors'

export const riskPlotBands = (riskId?: FireBlightHistoricalRiskDatabaseId) => {
  if (!riskId) return []

  const threshold = riskThreshold(riskId)

  return [
    {
      color: alphaColorReplacer(colors.fireBlightMarginalRisk, 0.6),
      from: threshold.marginal.low,
      to: threshold.marginal.high,
      id: 'fireBlight-risk-low',
      legendLabel: translate.phrases.banyanApp('Marginal Risk'),
    },
    {
      color: alphaColorReplacer(colors.fireBlightHighRisk, 0.6),
      from: threshold.high.low,
      to: threshold.high.high,
      id: 'fireBlight-risk-med',
      legendLabel: translate.phrases.banyanApp('High Risk'),
    },
    {
      color: alphaColorReplacer(colors.fireBlightExtremeRisk, 0.6),
      from: threshold.extreme.low,
      to: threshold.extreme.high,
      id: 'fireBlight-risk-high',
      legendLabel: translate.phrases.banyanApp('Extreme Risk'),
    },
  ]
}

export const getLabel = (riskId?: string | null) => {
  let validRiskId = isRiskIdValid(riskId) ? riskId : FIRE_BLIGHT_DEFAULT

  const config = FIRE_BLIGHT_HISTORICAL_RISK_CONFIGURATION_BY_ID[validRiskId]

  return translate.phrases.banyanApp(config.translatableDescription)
}

export const makeDiseaseHistoryOptions = () =>
  FIRE_BLIGHT_HISTORICAL_RISK_CONFIGURATION.map((risk) => ({
    label: translate.phrases.banyanApp(risk.translatableDescription),
    value: risk.id,
  }))
