import * as Sentry from '@sentry/react'
import { useState } from 'react'

export const useThrowErrorAndRenderErrorBoundary = (logErrorToSentry = false) => {
  const [error, setError] = useState<unknown>(null)

  if (error) {
    if (logErrorToSentry) Sentry.captureException(error)

    throw error
  }

  return (err: unknown) => setError(err)
}
