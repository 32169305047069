import { Button, Group } from '@mantine/core'
import { IconExternalLink } from 'components/icons/IconExternalLink'
import { translate } from 'i18n/i18n'
import { colors } from 'settings/colors'

export const EditInDropControlButton = ({
  dropControlFarmId,
  externalTankId,
}: {
  dropControlFarmId: number
  externalTankId: string
}) => {
  return (
    <a
      href={`https://admin.dropcontrol.com/farms/hydraulic?farm=${dropControlFarmId}&hydraulic=${externalTankId}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Button variant="link">
        <Group spacing={5} noWrap css={{ color: colors.primary }}>
          <IconExternalLink />

          <div>{translate.phrases.banyanApp('Edit In DropControl')}</div>
        </Group>
      </Button>
    </a>
  )
}
