import { Grid } from '@mantine/core'
import { translate } from 'i18n/i18n'
import moment from 'moment-timezone'
import React from 'react'
import { getActiveNodeStatus, NODE_STATUS_COLORS } from '../../../Map/_utils/getActiveNodeStatus'
import { ToggleSymbolValue } from '../../../ToggleSymbolValue/ToggleSymbolValue'
import type { TLnNodeLog, TLnSCDLog, TNodeType } from '../../../types'
import { renderWithDefaultValue } from '../../../utils/renderers'
import { BatteryStrength } from './BatteryStrength'

interface LnNodeLogProps {
  log: TLnNodeLog
  nodeType: TNodeType
}

export const LnNodeLog: React.FC<LnNodeLogProps> = ({ log, nodeType }) => {
  const lastLogTimeAgoText = log ? (
    <ToggleSymbolValue value={moment.tz(log.stamp, moment.tz.guess()).format('lll z')}>
      {moment.tz(log.stamp, 'UTC').fromNow()}
    </ToggleSymbolValue>
  ) : (
    translate.phrases.placeholder('Never')
  )

  const nodeStatus = getActiveNodeStatus(log)
  const nodeStatusColor = NODE_STATUS_COLORS[nodeStatus]

  return (
    <Grid css={{ fontSize: '14px' }}>
      <Grid.Col span={4}>{translate.phrases.placeholder('Last Log')}</Grid.Col>
      <Grid.Col span={8}>
        <span css={{ color: nodeStatusColor, fontWeight: 500 }}>
          {renderWithDefaultValue(lastLogTimeAgoText)}
        </span>
      </Grid.Col>
      {!!log && (log as TLnSCDLog).battery && (
        <>
          <Grid.Col span={4}>{translate.phrases.placeholder('Battery')}</Grid.Col>
          <Grid.Col span={8}>
            <span css={{ fontWeight: 500 }}>
              <BatteryStrength nodeType={nodeType} voltage={(log as TLnSCDLog)?.battery} />
            </span>
          </Grid.Col>
        </>
      )}
    </Grid>
  )
}
