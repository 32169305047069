import { routes } from '@semios/app-platform-banyan-route-definitions'
import { isEmpty } from '@semios/app-platform-common'
import { colors } from 'settings/colors'
import { MAP_VISUAL } from 'stores/mapControlsStore/types'
import { apiFetch } from 'utils/apiFetch'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import type { TGetCacheUpdatesFromResponseParameters, TGetCacheUpdatesFromResponseReturn } from './_types'
import { generateUsualStyleAPIArgs } from './_utils/generateUsualStyleAPIArgs'
import { generateUsualStyleGetCacheUpdatesFromResponse } from './_utils/generateUsualStyleGetCacheUpdatesFromResponse'
import { getValueType } from './_utils/getValueType'

const heatmapExtremesForAllPropertiesInVisibleRegions = false
const valueGroup = 'leaf_wetness'

const makeApiArgs = (
  processedCaches: TGetCacheUpdatesFromResponseParameters['processedCaches'],
): routes.ValuesCurrent.Request => {
  return generateUsualStyleAPIArgs({
    heatmapExtremesForAllPropertiesInVisibleRegions,
    processedCaches,
    inBlockPoint: {
      valueType: getValueType(MAP_VISUAL.POINT, valueGroup),
    },
    block: {
      valueType: getValueType(MAP_VISUAL.BLOCK, valueGroup),
    },
    region: {
      valueType: getValueType(MAP_VISUAL.REGION, valueGroup),
    },
    property: {
      valueType: getValueType(MAP_VISUAL.PROPERTY, valueGroup),
    },
  })
}

export const getResponseAndShapeForCacheUpdate = async ({
  cacheKeys,
  processedCaches,
}: TGetCacheUpdatesFromResponseParameters): Promise<TGetCacheUpdatesFromResponseReturn> => {
  const args = makeApiArgs(processedCaches)

  if (isEmpty(args)) return {}

  const response = await apiFetch({
    url: routes.ValuesCurrent.path,
    body: args,
  })

  return generateUsualStyleGetCacheUpdatesFromResponse({
    cacheKeys,
    heatmapExtremesForAllPropertiesInVisibleRegions,
    processedCaches,
    response,
    inBlockPoint: {
      valueType: getValueType(MAP_VISUAL.POINT, valueGroup),
      unitConverterFunction: unitConverter.leafWetnessHours,
    },
    block: {
      valueType: getValueType(MAP_VISUAL.BLOCK, valueGroup),
      unitConverterFunction: unitConverter.leafWetnessHours,
      valueKey: 'value',
    },
    region: {
      valueType: getValueType(MAP_VISUAL.REGION, valueGroup),
      unitConverterFunction: unitConverter.leafWetnessHours,
    },
    property: {
      valueType: getValueType(MAP_VISUAL.PROPERTY, valueGroup),
      unitConverterFunction: unitConverter.leafWetnessHours,
    },
    heatmapColoring: [
      colors.irrigationHeatmapSoftBlue,
      colors.irrigationHeatmapMediumBlue,
      colors.irrigationHeatmapStrongBlue,
    ],
  })
}
