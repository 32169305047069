import { Button, Progress, useMantineTheme } from '@mantine/core'
import { IconSuccess } from 'components/icons/IconSuccess'
import { translate } from 'i18n/i18n'
import moment from 'moment-timezone'
import React from 'react'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { networkStore } from '../store/networkStore'
import { serviceCenterStore } from '../store/serviceCenterStore'
import { downloadOfflineContents } from '../utils/loadStartupData'

export const Contents = () => {
  const theme = useMantineTheme()
  const { lastTimeDownloaded, downloadStatus } = serviceCenterStore.useSelector((s) => s.offlineContents)
  const connectionStatus = networkStore.useSelector(networkStore.selectors.getConnectionStatus).connected

  let localLastTimeAndDateDownLoaded

  if (lastTimeDownloaded) {
    const localTimeDownloaded = new Date(lastTimeDownloaded).toString()
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const formattedTimezonee = moment.tz(timezone).format('z')

    const formattedDate =
      // eslint-disable-next-line local-rules/no-moment-call
      moment(localTimeDownloaded).format('MMM D, YYYY h:mma ') + ` (${formattedTimezonee})`

    localLastTimeAndDateDownLoaded = formattedDate
  }

  const userPropertyIds = Object.values(fieldAssetStore.useSelector((s) => s?.properties ?? {})).map(
    ({ propertyId }) => propertyId,
  )

  const isDownloading = !isNaN(parseInt(downloadStatus))

  const handleDownload = async () => {
    await downloadOfflineContents(userPropertyIds)
  }

  const DownloadButton: React.FC<{ title?: string }> = ({ title }) => {
    return (
      <Button
        onClick={handleDownload}
        style={{
          background: 'white',
          borderColor: 'black',
          color: 'black',
          width: '100%',
          borderWidth: '2px',
          marginTop: '16px',
          fontSize: '16px',
          marginBottom: '6px',
        }}
      >
        {title ? title : translate.phrases.placeholder('Download')}
      </Button>
    )
  }

  if (downloadStatus === 'error') {
    return (
      <>
        <p style={{ margin: 0, paddingLeft: 30 }}>{translate.phrases.placeholder('Download failed')}</p>

        {!connectionStatus && (
          <div>{translate.phrases.placeholder('Please check your internet connection and try again')}</div>
        )}
        <DownloadButton title={'Retry'} />
      </>
    )
  }

  if (isDownloading) {
    return (
      <>
        <p style={{ margin: 0, paddingLeft: 30 }}>
          {translate.phrases.placeholder('Downloading offline content')}
        </p>
        <Progress value={parseInt(downloadStatus)} css={{ margin: '10px auto' }} />
      </>
    )
  }

  if (downloadStatus === 'success') {
    return (
      <div style={{ display: 'flex', alignItems: 'center', paddingLeft: 30 }}>
        {translate.phrases.placeholder('Download completed')}

        <span css={{ display: 'flex', color: theme.colors.green[0], marginLeft: '4px' }}>
          <IconSuccess />
        </span>
      </div>
    )
  }

  return (
    <>
      <p style={{ margin: 0, paddingLeft: 30 }}>
        {translate.phrases.placeholder(
          'For optimal offline app functionality, pre-load your property data while you still have cellular service.',
        )}
      </p>

      <div>
        <DownloadButton />
        {lastTimeDownloaded && (
          <p style={{ margin: 0, fontSize: 'smaller' }}>
            {translate.phrases.placeholder('Last downloaded: {{localLastTimeAndDateDownLoaded}}', {
              localLastTimeAndDateDownLoaded,
            })}
          </p>
        )}
      </div>
    </>
  )
}
