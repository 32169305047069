import type { TPermission } from '@semios/app-platform-value-type-definitions'
import type { TIrrigationSchedulerStoreState } from 'App/irrigation-scheduler/utils/store/irrigationSchedulerStore'
import type { TFieldAssetStoreState } from 'stores/fieldAssetStore'
import type { TUserDetailsStoreState } from 'stores/userDetailsStore'

export const getPermissionForSelectedProperty = (
  fass: TFieldAssetStoreState,
  isss: TIrrigationSchedulerStoreState,
  udss: TUserDetailsStoreState,
) => {
  const properties = fass.properties
  const selectedPropertyId = isss.selectedPropertyId
  const permissions = udss.permissions

  if (!properties || !selectedPropertyId || !properties[Number(selectedPropertyId)]) {
    return { edit: false, editFertigation: false, viewFertigation: false, publish: false, view: false }
  }

  const hasPermission = (permission: TPermission, propertyId: number) => {
    return permissions[permission] && permissions[permission][propertyId] === true
  }

  return {
    view: hasPermission('VIEW_IRRIGATION_PLANNER', selectedPropertyId),
    edit: hasPermission('EDIT_IRRIGATION_PLANNER', selectedPropertyId),
    editFertigation: hasPermission('EDIT_FERTIGATION', selectedPropertyId),
    viewFertigation: hasPermission('VIEW_FERTIGATION', selectedPropertyId),
    publish: hasPermission(properties[Number(selectedPropertyId)].publishPermission, selectedPropertyId),
  }
}
