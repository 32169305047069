import type { TOptions } from 'components/MultiSelect/MultiSelect.types'
import { translate } from 'i18n/i18n'
import type { Moment } from 'moment-timezone'
import moment from 'moment-timezone'
import { useEffect, useState } from 'react'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { apiFetch } from 'utils/apiFetch'
import { getCropNameFromId } from 'utils/getCropNameFromId'
import { showNotification } from 'utils/showNotification'
import { sortByKey } from 'utils/sortByKey'
import { useApiREST } from 'utils/useApiREST'
import type {
  BiofixOptionType,
  CropsBlocksElementType,
  DeleteBiofixByBlockIdType,
  EditBiofixByBlockIdType,
  ReshapedBlockType,
} from '../types'

export const useBlockMultipleBiofix = ({
  opened,
  insectId,
  propertyId,
}: {
  opened: boolean
  insectId: number
  propertyId: number
}) => {
  const [biofixBlocks, setBiofixBlocks] = useState<ReshapedBlockType[]>([])
  const [selectedBlocks, setSelectedBlocks] = useState<CropsBlocksElementType[]>([])
  const [biofixConfigOption, setBiofixConfigOption] = useState<BiofixOptionType | null>(null)
  const [editBiofixDateByBlockId, setEditBiofixDateByBlockId] = useState<EditBiofixByBlockIdType>({})
  const [deleteBiofixByBlockId, setDeleteBiofixByBlockId] = useState<DeleteBiofixByBlockIdType>({})
  const [blockIdsWithChanges, setBlocksIdsWithChanges] = useState<Set<number>>(new Set())
  const fieldAsset = fieldAssetStore.getState()
  const selectedProperty = fieldAsset.properties?.[Number(propertyId)]
  const propertyName = selectedProperty?.propertyName
  const timezone = selectedProperty?.timezone || moment.tz.guess()
  const [biofixDate, setBiofixDate] = useState<Moment>(moment.tz(timezone).startOf('day'))
  const [year, setYear] = useState<number>(moment.tz(timezone).year())
  const [anythingLoading, setAnythingLoading] = useState(false)

  const resetState = () => {
    setYear(moment.tz(timezone).year())

    setBiofixBlocks([])

    setBiofixConfigOption(null)

    setSelectedBlocks([])

    setEditBiofixDateByBlockId({})

    setDeleteBiofixByBlockId({})

    setBlocksIdsWithChanges(new Set())

    setBiofixDate(moment.tz(timezone).startOf('day'))
  }

  const {
    data: fieldAssetData,
    loading,
    refreshHandle,
  } = useApiREST({
    url: '/field-asset-settings-get',
    body: {
      insectBiofix: {
        propertyId,
        insectId,
      },
    },
    preventFetch: !propertyId || !opened,
    watchers: [opened],
    shaper: (data) => data,
  })

  useEffect(() => {
    setAnythingLoading(loading)
  }, [loading])

  useEffect(() => {
    if (selectedProperty && selectedProperty.blocks && fieldAssetData && fieldAssetData.insectBiofix) {
      const data = fieldAssetData.insectBiofix || []

      if (Array.isArray(data)) {
        const blocks = data
          .map((block) => {
            const fieldAssetBlock = selectedProperty?.blocks?.[block.id]
            const cropId = fieldAssetBlock?.cropIds?.[0]
            const cropName = getCropNameFromId(cropId)

            return {
              ...block,
              crop: {
                id: cropId ?? null,
                name: cropName,
              },
            }
          })
          .sort(sortByKey('name'))

        setBiofixBlocks(blocks)
      }
    }
  }, [selectedProperty, fieldAssetData])

  useEffect(() => {
    setBiofixDate(moment.tz(timezone).startOf('day').year(year))
  }, [year])

  const getCropsBlocksMultiSelectData = () => {
    const data: TOptions[] = []

    if (biofixBlocks && biofixBlocks.length) {
      biofixBlocks.forEach((block) => {
        data.push({
          id: String(block.id),
          label: block.name,
          value: String(block.id),
          group: block.crop.name,
        })
      })
    }

    return data
  }

  const handleClearTentativeEdit = () => {
    setEditBiofixDateByBlockId({})
  }

  const handleUpdateStateAfterSuccessfullSave = (notificationMessage: string) => {
    const currentSelectedBlocks = [...selectedBlocks]
    const editingBlocks = Object.keys(editBiofixDateByBlockId)
    const deletingBlocks = Object.keys(deleteBiofixByBlockId)

    setBlocksIdsWithChanges((oldBlocks) => {
      const newBlocks = new Set(oldBlocks)

      currentSelectedBlocks.forEach((block) => newBlocks.add(Number(block.id)))

      editingBlocks.forEach((block) => newBlocks.add(Number(block)))

      deletingBlocks.forEach((block) => newBlocks.add(Number(block)))

      return newBlocks
    })

    showNotification({
      message: notificationMessage,
      type: 'success',
    })

    refreshHandle()

    setBiofixConfigOption('')

    setSelectedBlocks([])

    setBiofixDate(moment.tz(timezone).startOf('day'))

    setEditBiofixDateByBlockId({})

    setDeleteBiofixByBlockId({})
  }

  const hasDuplicates = (blockIds: number[], biofixDateISO: string) => {
    return blockIds.some((blockId) => {
      return biofixBlocks.find(
        (b) => b.id === blockId && b.biofix.find((bf) => moment.tz(biofixDateISO, timezone).isSame(bf)),
      )
    })
  }

  const handleAddBiofix = async () => {
    const biofixDateISO = moment.tz(biofixDate, timezone).startOf('day').toISOString()

    const data = selectedBlocks.map((block) => {
      return {
        blockId: Number(block.id),
        insectId,
        biofix: biofixDateISO,
      }
    })

    if (
      hasDuplicates(
        data.map((d) => d.blockId),
        biofixDateISO,
      )
    ) {
      showNotification({
        message: translate.phrases.banyanApp('Biofix date already exists for selected blocks'),
        type: 'error',
      })

      return
    }

    try {
      setAnythingLoading(true)

      const response = await apiFetch({
        url: '/field-asset-settings-set',
        body: {
          insectBiofixCreate: { data, propertyId },
        },
      })

      if (response.insectBiofixCreate === true) {
        handleUpdateStateAfterSuccessfullSave(
          translate.phrases.banyanApp('Successfully added biofix for blocks'),
        )
      } else {
        showNotification({
          message: translate.phrases.banyanApp('Failed to update biofix for blocks'),
          type: 'error',
        })
      }
    } catch (error) {
      showNotification({
        message: translate.phrases.banyanApp('Failed to update biofix for blocks'),
        type: 'error',
      })
    } finally {
      setAnythingLoading(false)
    }
  }

  const handleEditBiofix = async () => {
    const newBiofixDate = moment.tz(biofixDate, timezone).startOf('day').toISOString()

    const data = Object.keys(editBiofixDateByBlockId).map((bId) => ({
      blockId: +bId,
      insectId: insectId,
      biofix: moment.tz(editBiofixDateByBlockId[bId], timezone).startOf('day').toISOString(),
      newBiofix: moment.tz(biofixDate, timezone).startOf('day').toISOString(),
    }))

    if (
      hasDuplicates(
        data.map((d) => d.blockId),
        newBiofixDate,
      )
    ) {
      showNotification({
        message: translate.phrases.banyanApp('Biofix date already exists for selected blocks'),
        type: 'error',
      })

      return
    }

    try {
      setAnythingLoading(true)

      const response = await apiFetch({
        url: '/field-asset-settings-set',
        body: { insectBiofixUpdate: { data, propertyId } },
      })

      if (response.insectBiofixUpdate === true) {
        handleUpdateStateAfterSuccessfullSave(
          translate.phrases.banyanApp('Successfully updated biofix for blocks'),
        )
      } else {
        showNotification({
          message: translate.phrases.banyanApp('Failed to update biofix for blocks'),
          type: 'error',
        })
      }
    } catch (error) {
      showNotification({
        message: translate.phrases.banyanApp('Failed to update biofix for blocks'),
        type: 'error',
      })
    } finally {
      setAnythingLoading(false)
    }
  }

  const handleDeleteBiofix = async () => {
    const data = Object.keys(deleteBiofixByBlockId).reduce(
      (biofixes: { blockId: number; insectId: number; biofix: string }[], bId) => {
        const currentBlock = deleteBiofixByBlockId[bId]

        currentBlock.forEach((bio) =>
          biofixes.push({
            blockId: +bId,
            insectId,
            biofix: moment.tz(bio, timezone).toISOString(),
          }),
        )

        return biofixes
      },
      [],
    )

    try {
      setAnythingLoading(true)

      const response = await apiFetch({
        url: '/field-asset-settings-set',
        body: {
          insectBiofixDelete: { data, propertyId },
        },
      })

      if (response.insectBiofixDelete === true) {
        handleUpdateStateAfterSuccessfullSave(
          translate.phrases.banyanApp('Successfully deleted biofix for blocks'),
        )
      } else {
        showNotification({
          message: translate.phrases.banyanApp('Failed to delete biofix for blocks'),
          type: 'error',
        })
      }
    } catch (error) {
      showNotification({
        message: translate.phrases.banyanApp('Failed to delete biofix for blocks'),
        type: 'error',
      })
    } finally {
      setAnythingLoading(false)
    }
  }

  return {
    biofixBlocks,
    biofixConfigOption,
    biofixDate,
    deleteBiofixByBlockId,
    editBiofixDateByBlockId,
    handleAddBiofix,
    handleDeleteBiofix,
    handleEditBiofix,
    loading: anythingLoading,
    selectedBlocks,
    setBiofixBlocks,
    setBiofixConfigOption,
    setBiofixDate,
    setDeleteBiofixByBlockId,
    setEditBiofixDateByBlockId,
    setSelectedBlocks,
    setYear,
    year,
    propertyName,
    getCropsBlocksMultiSelectData,
    handleClearTentativeEdit,
    resetState,
    blockIdsWithChanges,
  }
}

export default useBlockMultipleBiofix
