import { translate } from 'i18n/i18n'
import { unitTempDisplayDD } from '../../../../../../../Map/UserSettingsMenu/Shared/Alerts/settings/alertSettings'

export const CMDisclaimerBody = () => {
  return (
    <ul
      style={{
        display: 'inline-flex',
        flexDirection: 'column',
        margin: 'unset',
        padding: '0 30px',
      }}
    >
      <li>
        {translate.phrases.banyanApp('All models are based on cumulative {{ddUnitTemp}} from January 1st.', {
          ddUnitTemp: unitTempDisplayDD(),
        })}
      </li>
      <li>
        {translate.phrases.banyanApp(
          'The regional model and associated treatment window are based on WSU extension publications and other scientific literature.',
        )}
      </li>
      <li>
        {translate.phrases.banyanApp(
          'Where available, Semios flight models are based off of 2+ seasons of site-specific historical observations.',
        )}
      </li>
      <li>
        {translate.phrases.banyanApp(
          'Treatment windows indicate a period of time when large numbers of eggs and larva codling moth are likely to be present.',
        )}
      </li>
      <li>
        {translate.phrases.banyanApp(
          'Where available, Semios windows should be interpreted as high impact management timings. They do not imply lack of risk from moth activity outside the predicted windows.',
        )}
      </li>
      <li>
        {translate.phrases.banyanApp(
          'The timing of the 2nd and 3rd generation flight can be heavily influenced by the management applied to the 1st flight.',
        )}
      </li>
    </ul>
  )
}
