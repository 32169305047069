import type { Tooltip, TooltipFormatterContextObject } from 'highcharts'
import { translate } from 'i18n/i18n'
import moment from 'moment-timezone'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import { formatTime } from '../../../../../../../../components/HighchartsChart/_utils/setCustomHighchartsDateFormats'
import { buildTooltipValueRowsForTooltip } from '../../../../../../../../components/StackedChart/_utils/lineChartTooltipFormatter/buildTooltipValueRowsForTooltip'

export function insectPhenologyTooltipFormatter(
  ctx: TooltipFormatterContextObject,
  tooltip: Tooltip,
  degreeDaysDatePairs: Record<string, string>,
): string {
  const degreeDaysValue = ctx.x
  // this isn't a particularly important notion globally, so let's just use a somewhat arbitrary breakpoint
  const tooltipIsOnASmallishScreen = window.innerWidth < 500
  const padding = `0 0 0 ${tooltipIsOnASmallishScreen ? 0 : 5}px;`
  // @ts-ignore
  const timezone = tooltip?.chart?.time?.options?.timezone ?? 'America/Los_Angeles'

  let tooltipText = ''

  if (ctx.points && degreeDaysValue) {
    tooltipText += `
              <div style="font-size: 16px"><strong>
              <table style=""><tbody>
              <tr>
              <td colspan="2">${translate.phrases.banyanApp('Degree Days')}:</td>
              <td style="text-align: right; padding: ${padding}">
              <b>${degreeDaysValue} ${unitConverter.insectDegreeDaysCumulative().suffix()}</b>
              </td>
              </tr>
            `

    if (degreeDaysDatePairs[degreeDaysValue]) {
      const date = moment.tz(degreeDaysDatePairs[degreeDaysValue], timezone)
      const formattedDate = formatTime(date.valueOf(), 'ddd, MMM D, YYYY', timezone)

      tooltipText += `
                <tr>
                <td colspan="2">${translate.phrases.banyanApp('Date')}:</td>
                <td style="text-align: right; padding: ${padding}"><b>${formattedDate}</b></td>
                </tr>
              `
    }

    // @ts-ignore
    tooltipText += buildTooltipValueRowsForTooltip(ctx.points, {
      valueJustifyContentOverride: 'flex-end;',
    })

    tooltipText += '</tbody></table></strong></div>'
  }

  return tooltipText
}
