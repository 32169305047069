import type { routes } from '@semios/app-platform-banyan-route-definitions'
import type { StackedChartPestSection } from 'components/StackedChart/types'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import type { TSelectedFieldAssetsStoreState } from 'stores/selectedFieldAssetsStore'
import type { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { isUserOnlyAFreeRegionalUser } from 'utils/isUserOnlyAFreeRegionalUser'
import * as blockSection from './by-scope/blockSection'
import * as properySection from './by-scope/properySection'
import * as regionalSection from './by-scope/regionalSection'

export const apiArgs = ({
  selectedValueGroups,
  selectedFieldAssets,
}: {
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}) => {
  const blockValues = blockSection.apiArgs({ selectedFieldAssets, selectedValueGroups })
  const propertyValues = properySection.apiArgs({ selectedFieldAssets, selectedValueGroups })
  const regionValues = regionalSection.apiArgs({ selectedFieldAssets, selectedValueGroups })

  return { ...blockValues, ...propertyValues, ...regionValues }
}

export const content = ({
  data,
  selectedValueGroups,
  compareSeasonsData,
}: {
  data: routes.Values.Response
  compareSeasonsData: routes.Values.Response
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
}): StackedChartPestSection[] => {
  // for each insect that's selected, we'll return:
  // regionalSection - will return null if user isn't regional-only user
  // propertySection - will return null if user is regional only user
  // blockSection - will return null if user is regional only user

  const insects = fieldAssetStore.getState()?.insects

  if (!insects) return []

  if (isUserOnlyAFreeRegionalUser()) {
    const regionLevelContent = regionalSection.buildRegionalSection({
      data,
      selectedValueGroups,
      compareSeasonsData,
    })

    return [...regionLevelContent]
  } else {
    const blockLevelContent = blockSection.buildBlockSection({
      data,
      selectedValueGroups,
      compareSeasonsData,
    })

    const propertyLevelContent = properySection.buildPropertySection({
      data,
      selectedValueGroups,
      compareSeasonsData,
    })

    return [...blockLevelContent, ...propertyLevelContent]
  }
}
