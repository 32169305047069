import type { ApiRequestType } from './queue'
import { serviceCenterGatewayDropInstall } from './serviceCenterGatewayDropInstall'
import { serviceCenterGatewayReposition } from './serviceCenterGatewayReposition'
import { serviceCenterImagePreUpload } from './serviceCenterImagePreUpload'
import { serviceCenterImageUpload } from './serviceCenterImageUpload'
import { serviceCenterNodeConfigSet } from './serviceCenterNodeConfigSet'
import { serviceCenterNodeDeviceInstall } from './serviceCenterNodeDeviceInstall'
import { serviceCenterNodeDeviceRemove } from './serviceCenterNodeDeviceRemove'
import { serviceCenterNodeDropInstall } from './serviceCenterNodeDropInstall'
import { serviceCenterNodeImagesAdd } from './serviceCenterNodeImagesAdd'
import { serviceCenterNodeRemove } from './serviceCenterNodeRemove'
import { serviceCenterNodeReposition } from './serviceCenterNodeReposition'
import { serviceCenterNodeServiceIssueResolutionActionsAdd } from './serviceCenterNodeServiceIssueResolutionActionsAdd'
import { serviceCenterNodeServiceIssueStatusUpdate } from './serviceCenterNodeServiceIssueStatusUpdate'
import { serviceCenterNodeSwap } from './serviceCenterNodeSwap'
import { serviceCenterPlannedGatewayInstall } from './serviceCenterPlannedGatewayInstall'
import { serviceCenterPlannedGatewayReposition } from './serviceCenterPlannedGatewayReposition'
import { serviceCenterPlannedNodeInstall } from './serviceCenterPlannedNodeInstall'
import { serviceCenterPlannedNodesReposition } from './serviceCenterPlannedNodesReposition'

type ApiRequestHandler<M, R> = {
  preRequest?: (meta: M) => boolean | Error
  request: (meta: M) => Promise<R>
  callback?: (response: R, meta: M) => Promise<void> | void
}

export const requestHandlers: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [Property in ApiRequestType]: ApiRequestHandler<any, any>
} = {
  DROP_INSTALL_NODE: serviceCenterNodeDropInstall,
  DROP_INSTALL_GATEWAY: serviceCenterGatewayDropInstall,
  REMOVE_NODE_DEVICE: serviceCenterNodeDeviceRemove,
  INSTALL_PLANNED_NODE: serviceCenterPlannedNodeInstall,
  INSTALL_PLANNED_GATEWAY: serviceCenterPlannedGatewayInstall,
  INSTALL_NODE_DEVICE: serviceCenterNodeDeviceInstall,
  UPLOAD_IMAGE: serviceCenterImageUpload,
  PRE_UPLOAD_IMAGE: serviceCenterImagePreUpload,
  ADD_NODE_IMAGES: serviceCenterNodeImagesAdd,
  SWAP_NODE: serviceCenterNodeSwap,
  REMOVE_NODE: serviceCenterNodeRemove,
  REPOSITION_PSEUDO_NODES: serviceCenterPlannedNodesReposition,
  REPOSITION_PSEUDO_GATEWAY: serviceCenterPlannedGatewayReposition,
  SET_NODE_CONFIG: serviceCenterNodeConfigSet,
  ADD_RESOLUTION_ACTIONS: serviceCenterNodeServiceIssueResolutionActionsAdd,
  UPDATE_SERVICE_ISSUE_STATUS: serviceCenterNodeServiceIssueStatusUpdate,
  REPOSITION_ACTIVE_NODE: serviceCenterNodeReposition,
  REPOSITION_ACTIVE_GATEWAY: serviceCenterGatewayReposition,
}
