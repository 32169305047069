import { Checkbox } from 'components/Checkbox/Checkbox'
import { MultiSelect } from 'components/MultiSelect/MultiSelect'
import { Select } from 'components/Select/Select'
import { translate } from 'i18n/i18n'
import moment from 'moment-timezone'
import type { ChangeEventHandler, FC } from 'react'
import { useScreenSize } from 'utils/useScreenSize'
import type useBlockMultipleBiofix from '../_utils/useBlockMultipleBiofix'
import type { BiofixOptionType, ReshapedBlockType } from '../types'

type BlockHeaderProps = {
  block: ReshapedBlockType
  timezone: string
  isSelected: boolean
  onBlockSelect: ChangeEventHandler<HTMLInputElement>
  biofixConfigOption: BiofixOptionType | null
  biofixesForBlock: { value: string; label: string }[]
  deletedBiofixes: { value: string; label: string }[]
  setDeleteBiofixByBlockId: ReturnType<typeof useBlockMultipleBiofix>['setDeleteBiofixByBlockId']
  setEditBiofixDateByBlockId: ReturnType<typeof useBlockMultipleBiofix>['setEditBiofixDateByBlockId']
  biofixToEdit?: string
}

export const BlockHeader: FC<BlockHeaderProps> = ({
  block,
  timezone,
  onBlockSelect,
  isSelected,
  biofixConfigOption,
  biofixesForBlock,
  deletedBiofixes,
  setDeleteBiofixByBlockId,
  setEditBiofixDateByBlockId,
  biofixToEdit,
}) => {
  const BlockNameCmp = <span css={{ fontWeight: 700, fontSize: 16 }}>{block.name}</span>
  const { isWideScreen } = useScreenSize()

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingBottom: 5,
      }}
    >
      {biofixConfigOption === 'ADD' && (
        <Checkbox checked={isSelected} onChange={onBlockSelect} label={BlockNameCmp} />
      )}
      {biofixConfigOption !== 'ADD' && (
        <span css={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <span css={{ paddingRight: 10 }}>{BlockNameCmp}</span>
          {biofixConfigOption === 'DELETE' && (
            <MultiSelect
              containerCss={{ width: isWideScreen ? 200 : '50vw' }}
              disabled={biofixesForBlock && biofixesForBlock.length < 2}
              data={biofixesForBlock}
              selectedData={deletedBiofixes}
              placeholder={translate.phrases.banyanApp('Select Biofix Date')}
              noOfValuesToDisplay={1}
              selectedValueDecorator={(value, index) => {
                return index && index >= 1 ? `+ ${value}` : `${value}`
              }}
              hideSelectAll
              selectionLimit={biofixesForBlock.length - 1} // user shouldn't be able to delete all biofixes
              onChange={(selectedDates) => {
                setDeleteBiofixByBlockId((prevState) => {
                  const newState = { ...prevState }

                  newState[block.id] = selectedDates.map((date) =>
                    moment.tz(date.value, timezone).toISOString(),
                  )

                  return newState
                })
              }}
            />
          )}
          {biofixConfigOption === 'EDIT' && (
            <Select
              key={biofixToEdit}
              handleOnChange={(selectedDate) => {
                if (selectedDate) {
                  setEditBiofixDateByBlockId((prevState) => {
                    const newState = { ...prevState }

                    newState[block.id] = selectedDate

                    return newState
                  })
                }
              }}
              placeholder={translate.phrases.banyanApp('Select Biofix Date')}
              data={biofixesForBlock}
              value={biofixToEdit}
            />
          )}
        </span>
      )}

      <span css={{ fontWeight: 700 }}>{block.crop.name}</span>
    </div>
  )
}
