import { SectionTitleBar } from 'components/SectionTitleBar/SectionTitleBar'
import { useState } from 'react'
import { colors } from 'settings/colors'
import { getScreenSize } from 'utils/getScreenSize'
import type { TMappedFertilizers } from '../../types'
import { EditInDropControlButton } from '../EditInDropControlButton'

export const PropertySection = ({
  property,
  hasEditPermission,
}: {
  property: TMappedFertilizers
  hasEditPermission: boolean
}) => {
  const [collapsed, setCollapsed] = useState(false)
  const maxWidth = getScreenSize().screenWidth - 40

  return (
    <div key={property.propertyName}>
      <SectionTitleBar
        collapsed={collapsed}
        onCollapse={() => setCollapsed((prev) => !prev)}
        title={property.propertyName}
        containerWidth={maxWidth}
      />
      <div
        css={{
          padding: 0,
          opacity: collapsed ? 0 : 1,
          height: collapsed ? 0 : '100%',
          overflow: collapsed ? 'hidden' : 'unset',
          boxSizing: 'border-box',
          borderCollapse: 'collapse',
          transition: 'height 80ms, opacity 80ms',
        }}
      >
        {property.injectors.map((injector) => {
          const { externalTankId, fertilizers, tankId, tankName } = injector

          if (fertilizers.length === 0) return null

          return (
            <div key={tankId}>
              <div
                css={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  backgroundColor: colors.grey50,
                  borderBottom: `0.5px solid ${colors.grey200}`,
                  padding: hasEditPermission ? '5px 10px' : 10,
                  marginBottom: 5,
                }}
              >
                <b>{tankName}</b>
                {hasEditPermission && (
                  <EditInDropControlButton
                    dropControlFarmId={property.farmId}
                    externalTankId={externalTankId}
                  />
                )}
              </div>
              {fertilizers.map((fertilizer) => {
                return (
                  <div
                    key={fertilizer.fertilizerName}
                    css={{
                      width: '100%',
                      padding: 10,
                      borderBottom: `0.5px solid ${colors.grey200}`,
                    }}
                  >
                    <div
                      css={{
                        display: 'flex',
                        alignItems: 'center',
                        borderRadius: 5,
                      }}
                    >
                      <div css={{ wordBreak: 'break-word' }}>{fertilizer.fertilizerName}</div>
                    </div>
                  </div>
                )
              })}
            </div>
          )
        })}
      </div>
    </div>
  )
}
