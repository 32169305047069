import { ModalsProvider as MantineModalsProvider } from '@mantine/modals'
import { QrCodeScanner } from 'components/QrCodeScanner/QrCodeScanner'
import { SharedSettings } from 'settings/SharedSettings'

export const ModalsProvider = (props: { children: React.ReactNode }) => {
  const isScanning = QrCodeScanner.useIsScanning()

  return (
    <MantineModalsProvider
      modalProps={{
        zIndex: SharedSettings.DEFAULT_MODAL_Z_INDEX,
        style: {
          display: isScanning ? 'none' : 'block', //Hide all IstallationModal and prevent capture click event, e.g. ActivateNode
        },
        styles: {
          body: {
            padding:
              'env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left)',
          },
        },
      }}
    >
      <div
        css={{
          width: '100%',
          height: '100%',
          display: isScanning ? 'none' : 'block', //Hide Map
        }}
      >
        {props.children}
      </div>
    </MantineModalsProvider>
  )
}
