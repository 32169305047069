import type { SharedTypes } from '@semios/app-platform-banyan-route-definitions'
import { sortByKey } from '@semios/app-platform-common'
import { SectionTitleBar } from 'components/SectionTitleBar/SectionTitleBar'
import { SummaryTable } from 'components/SummaryTable/SummaryTable'
import { translate } from 'i18n/i18n'
import { useState } from 'react'
import { getScreenSize } from 'utils/getScreenSize'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import type { TInjectorSettings } from '../../types'
import { EditInDropControlButton } from '../EditInDropControlButton'

export const PropertySection = ({
  property,
  hasEditPermission,
}: {
  property: {
    propertyName: string
    farmId: number
    injectors: {
      tankName: string
      tankId: number
      flowRate: number
      flowRateUnit: SharedTypes.TFertigationFlowRateUnit
    }[]
  }
  hasEditPermission: boolean
}) => {
  const [collapsed, setCollapsed] = useState(false)
  const maxWidth = getScreenSize().screenWidth - 40

  const injectors = property.injectors.sort(sortByKey('tankName')).map((injector) => {
    return {
      ...injector,
      flowMeterIsInstalledLabel: translate.phrases.banyanApp('No'), // TODO: implement
      flowRateWithUnit: unitConverter
        .fertigation(injector.flowRate, { inputUnit: injector.flowRateUnit })
        .valueWithSuffix(),
    }
  })

  const columns = [
    {
      title: translate.phrases.banyanApp('Injector'),
      key: 'tankName',
      dataIndex: 'tankName',
    },
    {
      title: translate.phrases.banyanApp('Flow meter installed'),
      key: 'flowMeterIsInstalledLabel',
      dataIndex: 'flowMeterIsInstalledLabel',
    },
    {
      title: translate.phrases.banyanApp('Nominal Injection Rate'),
      key: 'flowRateWithUnit',
      dataIndex: 'flowRateWithUnit',
    },
    ...(hasEditPermission
      ? [
          {
            title: translate.phrases.banyanApp('Action'),
            key: 'action',
            dataIndex: 'action',
            width: 80,
            render: (_: string, record: TInjectorSettings) => (
              <EditInDropControlButton
                dropControlFarmId={property.farmId}
                externalTankId={record.externalTankId}
              />
            ),
          },
        ]
      : []),
  ]

  return (
    <>
      <SectionTitleBar
        collapsed={collapsed}
        onCollapse={() => setCollapsed((prev) => !prev)}
        title={property.propertyName}
        containerWidth={maxWidth}
      />
      <div
        css={{
          height: collapsed ? 0 : '100%',
          opacity: collapsed ? 0 : 1,
          overflow: collapsed ? 'hidden' : 'unset',
          transition: 'height 80ms, opacity 80ms',
          paddingTop: collapsed ? 0 : 5,
        }}
      >
        <SummaryTable columns={columns} data={injectors} />
      </div>
    </>
  )
}
