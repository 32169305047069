import { isEqual } from 'lodash'
import { persistentInit } from '../_utils/persistentStore/persistentStore'

export const ValueGroupDictionary = {
  air_temperature: 'air_temperature',
  alternaria: 'alternaria',
  atmospheric_pressure: 'atmospheric_pressure',
  almond_bloom: 'almond_bloom',
  almond_hull_split: 'almond_hull_split',
  bee_hours: 'bee_hours',
  chill: 'chill',
  conditions: 'conditions',
  degree_days_insect_id_1: 'degree_days_insect_id_1',
  degree_days_insect_id_10: 'degree_days_insect_id_10',
  degree_days_insect_id_11: 'degree_days_insect_id_11',
  degree_days_insect_id_12: 'degree_days_insect_id_12',
  degree_days_insect_id_13: 'degree_days_insect_id_13',
  degree_days_insect_id_14: 'degree_days_insect_id_14',
  degree_days_insect_id_15: 'degree_days_insect_id_15',
  degree_days_insect_id_16: 'degree_days_insect_id_16',
  degree_days_insect_id_17: 'degree_days_insect_id_17',
  degree_days_insect_id_18: 'degree_days_insect_id_18',
  degree_days_insect_id_19: 'degree_days_insect_id_19',
  degree_days_insect_id_2: 'degree_days_insect_id_2',
  degree_days_insect_id_20: 'degree_days_insect_id_20',
  degree_days_insect_id_23: 'degree_days_insect_id_23',
  degree_days_insect_id_24: 'degree_days_insect_id_24',
  degree_days_insect_id_25: 'degree_days_insect_id_25',
  degree_days_insect_id_26: 'degree_days_insect_id_26',
  degree_days_insect_id_27: 'degree_days_insect_id_27',
  degree_days_insect_id_28: 'degree_days_insect_id_28',
  degree_days_insect_id_29: 'degree_days_insect_id_29',
  degree_days_insect_id_30: 'degree_days_insect_id_30',
  degree_days_insect_id_3: 'degree_days_insect_id_3',
  degree_days_insect_id_4: 'degree_days_insect_id_4',
  degree_days_insect_id_6: 'degree_days_insect_id_6',
  degree_days_insect_id_7: 'degree_days_insect_id_7',
  degree_days_insect_id_8: 'degree_days_insect_id_8',
  degree_days_insect_id_9: 'degree_days_insect_id_9',
  dew_point: 'dew_point',
  equipment_status: 'equipment_status',
  evapotranspiration: 'evapotranspiration',
  fire_blight: 'fire_blight',
  fruit_growth: 'fruit_growth',
  humidity: 'humidity',
  irrigation_activity: 'irrigation_activity',
  larval_trend_insect_id_1: 'larval_trend_insect_id_1',
  larval_trend_insect_id_11: 'larval_trend_insect_id_11',
  larval_trend_insect_id_20: 'larval_trend_insect_id_20',
  larval_trend_insect_id_3: 'larval_trend_insect_id_3',
  larval_trend_insect_id_6: 'larval_trend_insect_id_6',
  larval_trend_insect_id_7: 'larval_trend_insect_id_7',
  larval_trend_insect_id_9: 'larval_trend_insect_id_9',
  leaf_wetness: 'leaf_wetness',
  ndvi: 'ndvi',
  plant_stress: 'plant_stress',
  precipitation: 'precipitation',
  soil: 'soil',
  spray_conditions: 'spray_conditions',
  trap_catches_insect_id_1: 'trap_catches_insect_id_1',
  trap_catches_insect_id_10: 'trap_catches_insect_id_10',
  trap_catches_insect_id_11: 'trap_catches_insect_id_11',
  trap_catches_insect_id_12: 'trap_catches_insect_id_12',
  trap_catches_insect_id_15: 'trap_catches_insect_id_15',
  trap_catches_insect_id_16: 'trap_catches_insect_id_16',
  trap_catches_insect_id_17: 'trap_catches_insect_id_17',
  trap_catches_insect_id_19: 'trap_catches_insect_id_19',
  trap_catches_insect_id_2: 'trap_catches_insect_id_2',
  trap_catches_insect_id_20: 'trap_catches_insect_id_20',
  trap_catches_insect_id_25: 'trap_catches_insect_id_25',
  trap_catches_insect_id_27: 'trap_catches_insect_id_27',
  trap_catches_insect_id_28: 'trap_catches_insect_id_28',
  trap_catches_insect_id_29: 'trap_catches_insect_id_29',
  trap_catches_insect_id_30: 'trap_catches_insect_id_30',
  trap_catches_insect_id_6: 'trap_catches_insect_id_6',
  trap_catches_insect_id_7: 'trap_catches_insect_id_7',
  trap_catches_insect_id_9: 'trap_catches_insect_id_9',
  phenology_insect_id_1: 'phenology_insect_id_1',
  phenology_insect_id_7: 'phenology_insect_id_7',
  phenology_insect_id_11: 'phenology_insect_id_11',
  wet_bulb: 'wet_bulb',
  wind: 'wind',
  wind_machine: 'wind_machine',
} as const

export const SectionDictionary = {
  'crop-development': 'crop',
  'diseases': 'diseases',
  'environment': 'environment',
  'insects': 'insects',
  'water-management': 'water',
  'sprays': 'sprays',
  'equipment': 'equipment',
} as const

export type TValueGroup = keyof typeof ValueGroupDictionary

export type TLayer = TValueGroup | 'irrigation_activity' | 'wind_machine_activity' | 'evapotranspiration'

export type TSection = keyof typeof SectionDictionary

export const valueGroupToSectionDictionary: Record<TValueGroup, TSection> = {
  air_temperature: 'environment',
  atmospheric_pressure: 'environment',
  conditions: 'environment',
  dew_point: 'environment',
  humidity: 'environment',
  leaf_wetness: 'environment',
  precipitation: 'environment',
  wet_bulb: 'environment',
  wind: 'environment',
  degree_days_insect_id_1: 'insects',
  degree_days_insect_id_10: 'insects',
  degree_days_insect_id_11: 'insects',
  degree_days_insect_id_12: 'insects',
  degree_days_insect_id_13: 'insects',
  degree_days_insect_id_14: 'insects',
  degree_days_insect_id_15: 'insects',
  degree_days_insect_id_16: 'insects',
  degree_days_insect_id_17: 'insects',
  degree_days_insect_id_18: 'insects',
  degree_days_insect_id_19: 'insects',
  degree_days_insect_id_2: 'insects',
  degree_days_insect_id_20: 'insects',
  degree_days_insect_id_23: 'insects',
  degree_days_insect_id_24: 'insects',
  degree_days_insect_id_25: 'insects',
  degree_days_insect_id_26: 'insects',
  degree_days_insect_id_27: 'insects',
  degree_days_insect_id_28: 'insects',
  degree_days_insect_id_29: 'insects',
  degree_days_insect_id_30: 'insects',
  degree_days_insect_id_3: 'insects',
  degree_days_insect_id_4: 'insects',
  degree_days_insect_id_6: 'insects',
  degree_days_insect_id_7: 'insects',
  degree_days_insect_id_8: 'insects',
  degree_days_insect_id_9: 'insects',
  larval_trend_insect_id_1: 'insects',
  larval_trend_insect_id_11: 'insects',
  larval_trend_insect_id_20: 'insects',
  larval_trend_insect_id_3: 'insects',
  larval_trend_insect_id_6: 'insects',
  larval_trend_insect_id_7: 'insects',
  larval_trend_insect_id_9: 'insects',
  ndvi: 'environment',
  trap_catches_insect_id_1: 'insects',
  trap_catches_insect_id_10: 'insects',
  trap_catches_insect_id_11: 'insects',
  trap_catches_insect_id_12: 'insects',
  trap_catches_insect_id_15: 'insects',
  trap_catches_insect_id_16: 'insects',
  trap_catches_insect_id_17: 'insects',
  trap_catches_insect_id_19: 'insects',
  trap_catches_insect_id_2: 'insects',
  trap_catches_insect_id_20: 'insects',
  trap_catches_insect_id_25: 'insects',
  trap_catches_insect_id_27: 'insects',
  trap_catches_insect_id_28: 'insects',
  trap_catches_insect_id_29: 'insects',
  trap_catches_insect_id_30: 'insects',
  trap_catches_insect_id_6: 'insects',
  trap_catches_insect_id_7: 'insects',
  trap_catches_insect_id_9: 'insects',
  phenology_insect_id_1: 'insects',
  phenology_insect_id_7: 'insects',
  phenology_insect_id_11: 'insects',
  irrigation_activity: 'water-management',
  soil: 'water-management',
  evapotranspiration: 'water-management',
  fruit_growth: 'water-management',
  plant_stress: 'water-management',
  spray_conditions: 'sprays',
  alternaria: 'diseases',
  fire_blight: 'diseases',
  bee_hours: 'crop-development',
  almond_bloom: 'crop-development',
  almond_hull_split: 'crop-development',
  chill: 'crop-development',
  equipment_status: 'equipment',
  wind_machine: 'equipment',
}

export const valueGroupsWithPrimaryAllowed: Record<TValueGroup, boolean> = {
  air_temperature: true,
  almond_bloom: true,
  almond_hull_split: true,
  alternaria: true,
  atmospheric_pressure: false,
  bee_hours: true,
  chill: true,
  conditions: false,
  degree_days_insect_id_1: true,
  degree_days_insect_id_2: true,
  degree_days_insect_id_3: true,
  degree_days_insect_id_4: true,
  degree_days_insect_id_6: true,
  degree_days_insect_id_7: true,
  degree_days_insect_id_8: true,
  degree_days_insect_id_9: true,
  degree_days_insect_id_10: true,
  degree_days_insect_id_11: true,
  degree_days_insect_id_12: true,
  degree_days_insect_id_13: true,
  degree_days_insect_id_14: true,
  degree_days_insect_id_15: true,
  degree_days_insect_id_16: true,
  degree_days_insect_id_17: true,
  degree_days_insect_id_18: true,
  degree_days_insect_id_19: true,
  degree_days_insect_id_20: true,
  degree_days_insect_id_23: true,
  degree_days_insect_id_24: true,
  degree_days_insect_id_25: true,
  degree_days_insect_id_26: true,
  degree_days_insect_id_27: true,
  degree_days_insect_id_28: true,
  degree_days_insect_id_29: true,
  degree_days_insect_id_30: true,
  dew_point: true,
  equipment_status: true,
  evapotranspiration: true,
  fire_blight: true,
  fruit_growth: true,
  humidity: true,
  irrigation_activity: true,
  larval_trend_insect_id_1: false,
  larval_trend_insect_id_11: false,
  larval_trend_insect_id_20: false,
  larval_trend_insect_id_3: false,
  larval_trend_insect_id_6: false,
  larval_trend_insect_id_7: false,
  larval_trend_insect_id_9: false,
  leaf_wetness: true,
  ndvi: false,
  plant_stress: true,
  precipitation: true,
  soil: true,
  spray_conditions: true,
  trap_catches_insect_id_1: true,
  trap_catches_insect_id_10: true,
  trap_catches_insect_id_11: true,
  trap_catches_insect_id_12: true,
  trap_catches_insect_id_15: true,
  trap_catches_insect_id_16: true,
  trap_catches_insect_id_17: true,
  trap_catches_insect_id_19: true,
  trap_catches_insect_id_2: true,
  trap_catches_insect_id_20: true,
  trap_catches_insect_id_25: true,
  trap_catches_insect_id_27: true,
  trap_catches_insect_id_28: true,
  trap_catches_insect_id_29: true,
  trap_catches_insect_id_30: true,
  trap_catches_insect_id_6: true,
  trap_catches_insect_id_7: true,
  trap_catches_insect_id_9: true,
  phenology_insect_id_1: true,
  phenology_insect_id_7: true,
  phenology_insect_id_11: true,
  wet_bulb: true,
  wind: true,
  wind_machine: true,
}

export const selectedValueGroupsStore = persistentInit<{
  primaryValueGroup: TValueGroup | null
  selectedValueGroups: Partial<Record<TValueGroup, number | false>>
}>({
  initialState: {
    primaryValueGroup: null,
    selectedValueGroups: {},
  },
  keysToPutInPersistentStorage: {
    primaryValueGroup: true,
    selectedValueGroups: true,
  },
  keysToPutInURL: {
    primaryValueGroup: true,
    selectedValueGroups: true,
  },
  storeName: 'selectedValueGroupsStore',
})

selectedValueGroupsStore.subscribeToChanges(
  (s) => s,
  (newStore, previousStore) => {
    // we are only concerned with selectedValueGroups changes
    if (isEqual(newStore.selectedValueGroups, previousStore.selectedValueGroups)) return

    const weSetAPrimaryValueGroupInThisChange = newStore.primaryValueGroup !== previousStore.primaryValueGroup

    // if we went out of our way to set a primary value group in this state update, we probably knew what we wanted
    if (weSetAPrimaryValueGroupInThisChange) {
      return
    }

    // if the user didn't have a manually overridden value group anyway, no need to do anything here
    if (!newStore.primaryValueGroup) {
      return
    }

    const keysThatAreOnAndWerentBefore = Object.keys(newStore.selectedValueGroups).filter(
      (k) =>
        !!newStore.selectedValueGroups[k as TValueGroup] &&
        !previousStore.selectedValueGroups[k as TValueGroup],
    )

    if (!!keysThatAreOnAndWerentBefore.length) {
      // if we turned on a new value group, let's not keep our overridden primary value group
      selectedValueGroupsStore.setState((s) => ({ ...s, primaryValueGroup: null }))

      return
    }

    if (!newStore.selectedValueGroups[newStore.primaryValueGroup]) {
      // if we removed the overridden primary value group from the selectedValueGroups as part of this state change, we should get rid of the override
      selectedValueGroupsStore.setState((s) => ({ ...s, primaryValueGroup: null }))

      return
    }
  },
)
