import type { routes } from '@semios/app-platform-banyan-route-definitions'
import type { TPhrases } from '@semios/app-platform-i18n-core'
import { i18n, translate } from 'i18n/i18n'
import { isEmpty, isNil } from 'lodash'
import { HUB_CONNECTOR_ENVIRONMENT_IS_SWAGWORLD } from 'settings/hubConnectorEnvironment'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { smallStore } from 'stores/smallStore'
import { userDetailsStore } from 'stores/userDetailsStore'
import { apiFetch } from 'utils/apiFetch'
import { isUserOnlyAFreeRegionalUser } from 'utils/isUserOnlyAFreeRegionalUser'
import { loadAppStartupApiCustomer } from 'utils/loadAppStartupApiCustomer'
import { setSelectedPreset } from 'utils/setSelectedPreset/setSelectedPreset'
import { updateUserTrackers } from 'utils/updateUserTrackers'
import { showNotification } from '../../../utils/showNotification'

export const loadAppStartupData = async () => {
  const { fieldAssetDataMD5Hash, properties } = fieldAssetStore.getState() ?? {}

  if (!HUB_CONNECTOR_ENVIRONMENT_IS_SWAGWORLD) {
    await loadAppStartupApiCustomer()
  }

  const { user, ...restOfData } = await apiFetch({
    url: '/user-app-startup',
    body: { skipFieldAssetDataIfThisMD5Hash: fieldAssetDataMD5Hash },
  })

  // UserAppStartup API response can sometimes skip properties so we need to check if fieldAssetStore properties are empty
  if (
    ((isEmpty(properties) && isEmpty(restOfData.properties)) || isEmpty(user.permissions)) &&
    user.hasAgreedToCurrentAcceptancePackage
  ) {
    showNotification({
      type: 'error',
      message: translate.phrases.banyanApp(
        'There are no properties or feature attached to your account. Please contact your sales representative for assistance.',
      ),
    })
  }

  const {
    id,
    intercomToken,
    firstName,
    lastName,
    email,
    smsNumber,
    language,
    accountType,
    units: { speed, temperature, pressure, depth, rain, fertigation },
    permissions,
    availableSemiosDefinedPresets,
    availableUserDefinedPresets,
    showDataSource,
    showRegionalData,
  } = user

  const shouldWeTryToShowRegionalData = !HUB_CONNECTOR_ENVIRONMENT_IS_SWAGWORLD

  smallStore.setState((s) => ({
    ...s,
    showDataSource: showDataSource ?? true,
    showRegionalData: shouldWeTryToShowRegionalData ? showRegionalData ?? true : false,
  }))

  if (permissions['EDIT_SERVICE_CENTER'] && !isEmpty(permissions['EDIT_SERVICE_CENTER'])) {
    // TEMPORARY: manually add a preset for Service Center
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    availableSemiosDefinedPresets!['service-center'] = {
      idHash: 'service-center',
      name: 'Equipment Status',
      ignored: false,
      dayOffsetDateFrom: 0,
      dayOffsetDateTo: 0,
      selectedValueGroups: ['equipment_status'],
      valueGroupForMap: 'equipment_status',
    }
  }

  const localizedAvailableSemiosDefinedPresets = Object.entries(availableSemiosDefinedPresets || {}).reduce<
    Record<string, routes.UserAppStartup.TAvailablePreset>
  >((result, [idHash, semiosDefinedPreset]) => {
    const translatedName = translate.phrases.dbAppPresetsSemiosDefined(
      semiosDefinedPreset.name as TPhrases['dbAppPresetsSemiosDefined'],
    )

    return {
      ...result,
      [idHash]: { ...semiosDefinedPreset, name: translatedName },
    }
  }, {})

  userDetailsStore.setState((s) => {
    return {
      ...s,
      id,
      intercomToken,
      email,
      fertigation: fertigation ?? 'IMPERIAL', // doing this the same way as showDataSource and showRegionalData for now
      depth,
      firstName,
      language,
      accountType,
      lastName,
      pressure,
      rain,
      smsNumber,
      speed,
      temperature,
      permissions,
      availableSemiosDefinedPresets: localizedAvailableSemiosDefinedPresets,
      availableUserDefinedPresets,
      isFreeRegionalUser: user.isFreeRegionalUser,
      location: user.location,
    }
  })

  await i18n.globalLng.changeLanguage(i18n.findClosestLanguage([language]))

  if (!HUB_CONNECTOR_ENVIRONMENT_IS_SWAGWORLD) {
    updateUserTrackers({ email, userId: id })
  }

  fieldAssetStore.setState((s) => ({ ...s, ...restOfData }))

  /**
   * in the event that the user does not have any value groups
   * selected, e.g. upon first booting up the app, we can choose
   * a preset to toggle on for them. We'll go through each of
   * their presets and prefer ones with "weather" in their names
   */
  const selectedValueGroups = selectedValueGroupsStore.getState()?.selectedValueGroups

  if (!Object.values(selectedValueGroups).filter(Boolean).length) {
    let foundPreset: routes.UserAppStartup.TAvailablePreset['idHash'] | null = null

    const weatherTranslationLowerCase = translate.phrases.dbAppPresetsSemiosDefined('Weather').toLowerCase()

    const weatherAndPestsTranslationLowerCase = translate.phrases
      .dbAppPresetsSemiosDefined('Weather and Pests')
      .toLowerCase()

    const freeRegionalUserPreset = Object.values({
      ...availableSemiosDefinedPresets,
      ...availableUserDefinedPresets,
    }).find((ps) => String(ps.name).toLowerCase().includes(weatherAndPestsTranslationLowerCase))

    if (isUserOnlyAFreeRegionalUser()) {
      foundPreset =
        freeRegionalUserPreset && !freeRegionalUserPreset.ignored ? freeRegionalUserPreset.idHash : null
    } else {
      const presetsToUse = Object.values({
        ...availableSemiosDefinedPresets,
        ...availableUserDefinedPresets,
      }).filter((ps) => ps.idHash !== freeRegionalUserPreset?.idHash)

      presetsToUse
        .sort((a, b) => {
          const aHasWeather = String(a.name).toLowerCase().includes(weatherTranslationLowerCase)
          const bHasWeather = String(b.name).toLowerCase().includes(weatherTranslationLowerCase)

          if (aHasWeather && !bHasWeather) return -1

          if (bHasWeather && !aHasWeather) return 1

          return 0
        })
        .forEach((presetValues) => {
          if (!!foundPreset || !!presetValues.ignored) return

          foundPreset = presetValues.idHash
        })
    }

    if (!isNil(foundPreset)) setSelectedPreset(foundPreset)
  }
}
