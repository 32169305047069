import { Group, UnstyledButton, useMantineTheme } from '@mantine/core'
import * as Sentry from '@sentry/react'
import { useBleManager } from 'App/ServiceCenter/BluetoothLowEnergy/BleManager'
import { ProgramSdiSensor } from 'App/ServiceCenter/BluetoothLowEnergy/models/LnrNode/commands'
import { AboveAllModal } from 'components/AboveAllModalOverlay/AboveAllModalOverlay'
import { IconHelpCircle } from 'components/icons/IconHelpCircle'
import { WideHeader } from 'components/ModalDrawer/WideHeader/WideHeader'
import { translate } from 'i18n/i18n'
import type { FC } from 'react'
import { useEffect } from 'react'
import { BleDeviceNotConnectedError } from '../BluetoothLowEnergy/errors'
import { ToggleBuzzer } from '../BluetoothLowEnergy/models/common/commands'
import { openHelpGuideModal } from '../HelpGuideModal/HelpGuideModal'
import { HelpGuideGroup, type TActiveNode, type TNodeDevicePort } from '../types'
import { BleStatusButton } from '../utils/BleConnectionKeeper'
import { createErrorModal } from '../utils/createCommonModals'
import { installNodeDevice } from '../utils/installNodeDevice'
import { removeNodeDevice } from '../utils/removeNodeDevice'
import { useNavigator } from '../utils/useNavigator'
import { DeviceConfiguration } from './DeviceConfiguration/DeviceConfiguration'
import { DevicesList } from './DevicesList/DevicesList'
import { SdiDeviceDetection } from './SdiDeviceDetection/SdiDeviceDetection'
import type { TDeviceConfiguration } from './types'

export enum DevicesManagementPageKey {
  LIST = 'LIST',
  DETECTION = 'DETECTION',
  CONFIGURATION = 'CONFIGURATION',
}

type DevicesManagementPageParams = {
  isBleRequired: boolean
  port: TNodeDevicePort
  channel: number
  source?: string
}

export const DevicesManagement: FC<{
  node: TActiveNode
  isBleRequired: boolean
  onBusy?: (busy: boolean) => void
  onPageChange?: (page: DevicesManagementPageKey) => void
  onClose?: () => void
}> = ({ node, isBleRequired, onBusy, onPageChange, onClose }) => {
  const {
    page: currentPage,
    extra: pageParams,
    navigateTo,
  } = useNavigator<DevicesManagementPageKey, DevicesManagementPageParams>(DevicesManagementPageKey.LIST)

  const bleManager = useBleManager()
  const theme = useMantineTheme()
  const nodeType = node.nodeType
  const { key: prevPage, params: prevParams } = getPrevPage(currentPage, pageParams)
  const title = getTitle(currentPage)
  const sdiChannels: string[] = node.nodeType === 'rpt' ? [] : ['0', '1', '2', '3']
  const stereoChannels: string[] = node.nodeType === 'rpt' ? [] : ['0', '1', '2']
  const rj11Channels: string[] = ['0']

  const handleRemoveDevice = async (port: TNodeDevicePort, channel: number) => {
    const nodeIdentifier = node.nodeIdentifier

    try {
      if (onBusy) onBusy(true)

      if (isBleRequired) {
        if (port === 'sdi') {
          const dev = bleManager.connectedDevice

          if (dev) {
            const req = new ProgramSdiSensor()

            req.address = channel.toString()

            req.isAdd = false

            await dev.write(req)

            const beep = new ToggleBuzzer() // Beep to indicate successful configuration

            beep.activate = true

            beep.numberOfBeeps = 1

            beep.beepOnDuration = 10

            await dev.write(beep)
          } else {
            //Do nothing if device is not connected, the reomve command via BLE is optional
          }
        }
      }

      await removeNodeDevice({ nodeIdentifier, device: { connector: port, channel: channel } })
    } catch (e) {
      if (e instanceof BleDeviceNotConnectedError) {
        AboveAllModal.open(
          createErrorModal(
            translate.phrases.placeholder('Unable to remove device'),
            translate.phrases.placeholder('Device is not connected'),
          ),
        )
      } else {
        AboveAllModal.open(
          createErrorModal(translate.phrases.placeholder('Unable to remove device'), (e as Error).message),
        )

        Sentry.captureException(e)
      }
    } finally {
      if (onBusy) onBusy(false)
    }
  }

  const handleConfigureDevice = async (config: TDeviceConfiguration, force?: boolean) => {
    const nodeIdentifier = node.nodeIdentifier

    try {
      if (onBusy) onBusy(true)

      if (isBleRequired) {
        if (config.connector === 'sdi') {
          const dev = bleManager.connectedDevice

          if (!dev) {
            throw new BleDeviceNotConnectedError(nodeIdentifier)
          }

          const req = new ProgramSdiSensor()

          req.address = config.channel.toString()

          req.isAdd = true

          await dev.write(req)

          const beep = new ToggleBuzzer() // Beep to indicate successful configuration

          beep.activate = true

          beep.numberOfBeeps = 1

          beep.beepOnDuration = 10

          await dev.write(beep)
        }
      }

      await installNodeDevice(nodeIdentifier, config, force)

      navigateTo(DevicesManagementPageKey.LIST)
    } catch (e) {
      if (e instanceof BleDeviceNotConnectedError) {
        AboveAllModal.open(
          createErrorModal(
            translate.phrases.placeholder('Unable to configure device'),
            translate.phrases.placeholder('Device is not connected'),
          ),
        )
      } else {
        // eslint-disable-next-line no-console
        console.error(e)

        AboveAllModal.open(
          createErrorModal(
            translate.phrases.placeholder('Unable to configure device'),
            translate.phrases.placeholder('Please try later. Error: {{err}}', { err: (e as Error).message }),
          ),
        )

        Sentry.captureException(e)
      }

      throw e
    } finally {
      if (onBusy) onBusy(false)
    }
  }

  useEffect(() => {
    if (onPageChange) {
      onPageChange(currentPage)
    }
  }, [currentPage, onPageChange])

  const handleBack = () => {
    if (prevPage) {
      navigateTo(prevPage, prevParams)
    } else if (onClose) {
      onClose()
    }
  }

  return (
    <>
      <WideHeader
        title={title}
        rightIconButton={
          <Group>
            <BleStatusButton nodeIdentifier={node.nodeIdentifier} isBleRequired={isBleRequired} />
            {nodeType && (
              <UnstyledButton
                onClick={() =>
                  openHelpGuideModal({
                    group: HelpGuideGroup.STATION_INSTALLATION,
                  })
                }
              >
                <span
                  css={{
                    display: 'flex',
                    alignContent: 'center',
                    fontSize: 20,
                    color: theme.colors.midnight[0],
                  }}
                >
                  <IconHelpCircle />
                </span>
              </UnstyledButton>
            )}
          </Group>
        }
        onClose={handleBack}
        isSecondaryModal={true}
      />
      <div>
        {currentPage === DevicesManagementPageKey.LIST && (
          <DevicesList
            isBleRequired={isBleRequired}
            node={node}
            sdiChannels={sdiChannels}
            stereoChannels={stereoChannels}
            rj11Channels={rj11Channels}
            onAddDevice={(port, channel) => {
              if (port === 'sdi' && isBleRequired) {
                navigateTo(DevicesManagementPageKey.DETECTION, { isBleRequired, port, channel })
              } else {
                navigateTo(DevicesManagementPageKey.CONFIGURATION, { isBleRequired, port, channel })
              }
            }}
            onRemoveDevice={(port, channel) => handleRemoveDevice(port, channel)}
          />
        )}
        {currentPage === DevicesManagementPageKey.DETECTION && pageParams && (
          <SdiDeviceDetection
            node={node}
            channel={pageParams.channel}
            onConfirm={(source) => {
              navigateTo(DevicesManagementPageKey.CONFIGURATION, {
                isBleRequired,
                port: pageParams.port,
                channel: pageParams.channel,
                source,
              })
            }}
            onCancel={handleBack}
          />
        )}
        {currentPage === DevicesManagementPageKey.CONFIGURATION && pageParams && (
          <DeviceConfiguration
            node={node}
            port={pageParams.port}
            channel={pageParams.channel}
            detectSource={pageParams.source}
            onConfirm={handleConfigureDevice}
            onCancel={handleBack}
          />
        )}
      </div>
    </>
  )
}

const getPrevPage: (
  key: DevicesManagementPageKey,
  value?: DevicesManagementPageParams,
) => { key?: DevicesManagementPageKey; params?: DevicesManagementPageParams } = (
  pageKey: DevicesManagementPageKey,
  value?: DevicesManagementPageParams,
) => {
  if (pageKey === DevicesManagementPageKey.LIST) {
    return { key: undefined }
  }

  if (pageKey === DevicesManagementPageKey.DETECTION) {
    return { key: DevicesManagementPageKey.LIST }
  }

  if (pageKey === DevicesManagementPageKey.CONFIGURATION) {
    if (value && value.isBleRequired && value.port === 'sdi') {
      return { key: DevicesManagementPageKey.DETECTION, params: value }
    } else {
      return { key: DevicesManagementPageKey.LIST }
    }
  }

  return { key: undefined }
}

const getTitle: (key: DevicesManagementPageKey) => string = (key: DevicesManagementPageKey) => {
  if (key === DevicesManagementPageKey.LIST) {
    return translate.phrases.placeholder('Manage Devices')
  }

  if (key === DevicesManagementPageKey.DETECTION) {
    return translate.phrases.placeholder('Configure New Device')
  }

  if (key === DevicesManagementPageKey.CONFIGURATION) {
    return translate.phrases.placeholder('Configure New Device')
  }

  return ''
}
