import type { ImperialXorMetric } from 'stores/userDetailsStore'

export const convertPressureFromPSIToKPa = ({
  desiredUnitIMPERIALxorMETRIC,
  psiAmount,
  roundingDecimalPlaces = 2,
}: {
  desiredUnitIMPERIALxorMETRIC: ImperialXorMetric
  psiAmount: number
  roundingDecimalPlaces?: number
}) => {
  switch (desiredUnitIMPERIALxorMETRIC) {
    case 'IMPERIAL':
      return psiAmount.toFixed(roundingDecimalPlaces)

    case 'METRIC':
      return (psiAmount * 6.895).toFixed(roundingDecimalPlaces)

    default:
      throw new Error('Unit can only be IMPERIAL or METRIC')
  }
}
