import { DropdownButton } from 'components/DropdownButton/DropdownButton'
import { IconGear } from 'components/icons/IconGear'
import { IconWarning } from 'components/icons/IconWarning'
import { RefillPointDatesModal } from 'components/RefillPointDatesModal/RefillPointDatesModal'
import { RootZoneDepthsModal } from 'components/RootZoneDepthsModal/RootZoneDepthsModal'
import { translate } from 'i18n/i18n'
import { detailsPanelStore } from 'stores/detailsPanelStore'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import { checkAuthorization } from 'utils/checkAuthorization'

export const SoilSettingsDropdownButton = () => {
  const selectedPropertyId = selectedFieldAssetsStore.useSelector((s) => s.property)
  const properties = fieldAssetStore.useSelector((s) => s.properties)

  const favoriteDepthsForAllStations =
    selectedPropertyId && properties
      ? Object.values(properties[selectedPropertyId]?.points || {})
          .filter((point) => !!point?.configuration?.soilProbeDepthsAvailable)
          .map((station) => {
            return station?.configuration?.soilDefaultProbeDepths ?? []
          })
      : []

  const showIconWarning = favoriteDepthsForAllStations.some((depths) => depths.length === 0)
  const options = []

  const includeRefillPoints =
    selectedPropertyId &&
    checkAuthorization({
      permission: 'EDIT_MANAGEMENT_ALLOWABLE_DEPLETION_DATES',
      entity: selectedPropertyId,
    })

  const includeRootZoneDepths =
    selectedPropertyId &&
    checkAuthorization({
      permission: 'EDIT_STATION_SOIL_SETTINGS',
      entity: selectedPropertyId,
    })

  if (!includeRefillPoints && !includeRootZoneDepths) {
    return null
  }

  if (includeRefillPoints) {
    options.push({
      content: (
        <span style={{ fontSize: '15px', fontWeight: 500 }}>
          {translate.phrases.banyanApp('Refill Point (MAD)')}
        </span>
      ),
      onClick: () => {
        detailsPanelStore.setState((prev) => {
          return {
            ...prev,
            refillPointDatesSettingsOpened: true,
          }
        })
      },
    })
  }

  if (includeRootZoneDepths) {
    options.push({
      content: (
        <span style={{ display: 'inline-flex', alignItems: 'center' }}>
          {showIconWarning && (
            <span style={{ display: 'inline-flex', alignItems: 'center', marginRight: '7px' }}>
              <IconWarning />
            </span>
          )}
          <span style={{ fontSize: '15px', fontWeight: 500 }}>
            {translate.phrases.banyanApp('Root Zone Depths')}
          </span>
        </span>
      ),
      onClick: () => {
        detailsPanelStore.setState((prev) => {
          return {
            ...prev,
            soilSettingsOpened: true,
          }
        })
      },
    })
  }

  return (
    <>
      <DropdownButton
        options={options}
        leftIcon={
          <span style={{ display: 'flex', alignItems: 'center' }}>
            {showIconWarning ? <IconWarning /> : <IconGear />}
          </span>
        }
        style={{ paddingLeft: '10px', paddingRight: '5px' }}
        showCaretIcon={true}
        variant="secondary"
      >
        <span>{translate.phrases.banyanApp('Settings')}</span>
      </DropdownButton>
      <RootZoneDepthsModal />
      <RefillPointDatesModal />
    </>
  )
}
