import { Select } from '@mantine/core'
import type { VC } from '@semios/app-platform-value-type-definitions'
import { translate } from 'i18n/i18n'
import { useMemo } from 'react'
import { SharedSettings } from 'settings/SharedSettings'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { mapControlsStore } from 'stores/mapControlsStore/mapControlsStore'
import { MAP_VISUAL } from 'stores/mapControlsStore/types'

type TFireBlightCurrentBlocksValueTypes =
  keyof VC.DomainTypes.FireBlight.TCurrentValuesReturnIgnoringKeying['blocks']

const updateMapVisuals = (newValueType: TFireBlightCurrentBlocksValueTypes) => {
  mapControlsStore.setState((s) => ({
    ...s,
    mapVisualValueGroup: {
      ...s.mapVisualValueGroup,
      [MAP_VISUAL.SCD]: {
        ...s.mapVisualValueGroup[MAP_VISUAL.SCD],
        fire_blight: {
          ...(s.mapVisualValueGroup[MAP_VISUAL.SCD].fire_blight ?? { mItem: 'M_InBlockPoint' }),
          valueType: newValueType,
        },
      },
      [MAP_VISUAL.BLOCK]: {
        ...s.mapVisualValueGroup[MAP_VISUAL.BLOCK],
        fire_blight: {
          ...(s.mapVisualValueGroup[MAP_VISUAL.BLOCK].fire_blight ?? { mItem: 'M_Block' }),
          valueType: newValueType,
        },
      },
      [MAP_VISUAL.PROPERTY]: {
        ...s.mapVisualValueGroup[MAP_VISUAL.PROPERTY],
        fire_blight: {
          ...(s.mapVisualValueGroup[MAP_VISUAL.PROPERTY].fire_blight ?? { mItem: 'M_Property' }),
          valueType: newValueType,
        },
      },
      [MAP_VISUAL.POINT]: {
        ...s.mapVisualValueGroup[MAP_VISUAL.POINT],
        fire_blight: {
          ...(s.mapVisualValueGroup[MAP_VISUAL.POINT].fire_blight ?? { mItem: 'M_InBlockPoint' }),
          valueType: newValueType,
        },
      },
    },
  }))
}

export const FireBlightSecondaryDropdown = () => {
  const value = mapControlsStore.useSelector(
    (s) => s.mapVisualValueGroup?.[MAP_VISUAL.BLOCK]?.fire_blight?.valueType,
  )

  const properties = fieldAssetStore.useSelector((s) => s.properties)

  const data = useMemo(() => {
    const returner: { value: TFireBlightCurrentBlocksValueTypes; label: string }[] = [
      // 7-day rolling cumulative
      {
        value: 'fireBlight_yesterday_trv',
        label: translate.phrases.templates('{{labelA}} ({{labelB}})', {
          labelA: translate.phrases.banyanApp('Max TRV'),
          labelB: translate.phrases.banyanApp('Yesterday'),
        }),
      },
      {
        value: 'fireBlight_today_trv',
        label: translate.phrases.templates('{{labelA}} ({{labelB}})', {
          labelA: translate.phrases.banyanApp('TRV'),
          labelB: translate.phrases.banyanApp('Current'),
        }),
      },
      {
        value: 'fireBlight_tomorrow_trv',
        label: translate.phrases.templates('{{labelA}} ({{labelB}})', {
          labelA: translate.phrases.banyanApp('Max TRV'),
          labelB: translate.phrases.banyanApp('Tomorrow'),
        }),
      },
      {
        value: 'fireBlight_next7DaysMax_trv',
        label: translate.phrases.templates('{{labelA}} ({{labelB}})', {
          labelA: translate.phrases.banyanApp('Max TRV'),
          labelB: translate.phrases.banyanApp('Next {{count}} Days', { count: 7 }),
        }),
      },
      {
        value: 'fireBlight_next14DaysMax_trv',
        label: translate.phrases.templates('{{labelA}} ({{labelB}})', {
          labelA: translate.phrases.banyanApp('Max TRV'),
          labelB: translate.phrases.banyanApp('Next {{count}} Days', { count: 14 }),
        }),
      },
    ]

    return returner
  }, [properties, value])

  return (
    <Select
      data={data}
      value={value}
      onChange={updateMapVisuals}
      styles={SharedSettings.MANTINE_SELECT_RIGHT_ICON_CHANGER}
      withinPortal
    />
  )
}
