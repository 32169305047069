import { Text } from '@mantine/core'
import type { NotificationProps } from '@mantine/notifications'
import { notifications } from '@mantine/notifications'
import type { TFieldAssetValueTypes } from '@semios/app-platform-banyan-route-definitions/dist/routes/userAppStartup'
import { WideHeader } from 'components/ModalDrawer/WideHeader/WideHeader'
import { translate } from 'i18n/i18n'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { fieldAssetStore } from '../../../stores/fieldAssetStore'
import { selectedFieldAssetsStore } from '../../../stores/selectedFieldAssetsStore'
import { MapContext } from '../../Map/MapContext/MapContext'
import { MapProvider } from '../../Map/MapContext/MapProvider'
import { BlockLayer } from '../Map/Layers/BlockLayer'
import { Footer } from '../NodeInstallation/Footer/Footer'
import type { TNodeType } from '../types'
import { isLocationSensitiveNode } from '../utils/constants/nodeType'
import { defaultNotificationProps } from '../utils/defaultNotificationProps'
import { InitialLocationMarker } from './PositioningMap/InitialLocationMarker'
import { PositioningMap } from './PositioningMap/PositioningMap'
import { RelocationMarker } from './PositioningMap/RelocationMarker'

const LNGatewayRangeCircles: React.FC<{
  coordinates: { lat: number; lng: number }
}> = ({ coordinates }) => {
  const { map } = useContext(MapContext)
  const CIRCLE_RADIUS = 800 // LN_GTWY has 800 meters range

  useEffect(() => {
    if (!map) return

    const circle1 = new window.google.maps.Circle({
      map,
      center: coordinates,
      radius: CIRCLE_RADIUS,
      fillColor: 'rgba(41, 205, 107)',
      fillOpacity: 0.2,
      strokeColor: '#ffffff',
      strokeOpacity: 0.4,
      strokeWeight: 2,
    })

    const circle2 = new window.google.maps.Circle({
      map,
      center: coordinates,
      radius: CIRCLE_RADIUS / 1.5,
      fillColor: 'rgba(41, 205, 107)',
      fillOpacity: 0.2,
      strokeColor: '#ffffff',
      strokeOpacity: 0.6,
      strokeWeight: 2,
    })

    const circle3 = new window.google.maps.Circle({
      map,
      center: coordinates,
      radius: CIRCLE_RADIUS / 2.5,
      fillColor: 'rgba(41, 205, 107)',
      fillOpacity: 0.4,
      strokeColor: '#ffffff',
      strokeOpacity: 0.8,
      strokeWeight: 2,
    })

    const circle4 = new window.google.maps.Circle({
      map,
      center: coordinates,
      radius: CIRCLE_RADIUS / 5,
      fillColor: 'rgba(41, 205, 107)',
      fillOpacity: 0.7,
      strokeColor: '#ffffff',
      strokeOpacity: 1,
      strokeWeight: 2,
    })

    // Update circle position when the map center changes
    map.addListener('center_changed', () => {
      const newCenter = map.getCenter()

      if (newCenter) {
        circle1.setCenter(newCenter)

        circle2.setCenter(newCenter)

        circle3.setCenter(newCenter)

        circle4.setCenter(newCenter)
      }
    })

    return () => {
      // Remove the all circles when the component unmounts
      circle1.setMap(null)

      circle2.setMap(null)

      circle3.setMap(null)

      circle4.setMap(null)
    }
  }, [map])

  return null
}

type NodePositioningModalProps = {
  initialCenter: google.maps.LatLngLiteral
  initialZoom?: number
  showInitialLocationMarker?: boolean
  headerTitle?: string
  onClose: () => void
  onSubmit?: (mapCenter: google.maps.LatLngLiteral) => Promise<void> | void
  disableSubmitButtonOnDefaultLocation?: boolean
  nodeType?: TNodeType
}

const NodePositioningModal: React.FC<NodePositioningModalProps> = ({
  onSubmit,
  onClose,
  headerTitle,
  initialCenter,
  initialZoom,
  showInitialLocationMarker = false,
  disableSubmitButtonOnDefaultLocation = false,
  nodeType,
}) => {
  const [mapCenter, setMapCenter] = useState<google.maps.LatLngLiteral>(initialCenter)
  const [isSaving, setIsSaving] = useState(false)
  const selectedPropertyIds = selectedFieldAssetsStore.useSelector((s) => (s.property ? [s.property] : []))
  const properties = fieldAssetStore.useSelector((s) => s?.properties)

  const handleCancel = () => {
    onClose()
  }

  const handleSubmit = async () => {
    if (!mapCenter) return

    setIsSaving(true)

    await onSubmit?.(mapCenter)

    setIsSaving(false)
  }

  const blocks = useMemo(() => {
    let propertiesBlocks: TFieldAssetValueTypes.TBlock[] = []

    selectedPropertyIds.forEach((propertyId) => {
      const property = properties?.[propertyId]

      if (!property?.blocks) return

      propertiesBlocks = propertiesBlocks.concat(Object.values(property.blocks))
    })

    return propertiesBlocks
  }, [selectedPropertyIds, properties])

  useEffect(() => {
    if (!nodeType || !isLocationSensitiveNode(nodeType)) return

    const settings: NotificationProps = {
      ...defaultNotificationProps,
      id: 'confirm-location-banner',
      autoClose: false,
      message: (
        <>
          <Text>
            <translate.Phrases.placeholder k="Changing the equipment location after installation may result in data loss" />
          </Text>
        </>
      ),
    }

    notifications.show(settings)

    return () => {
      if (settings.id) notifications.hide(settings.id)
    }
  }, [])

  return (
    <div>
      {headerTitle && (
        <WideHeader
          title={headerTitle}
          onClose={onClose}
          isSecondaryModal={true}
          style={{
            top: 0,
            left: 0,
            zIndex: 1,
            position: 'relative',
          }}
        />
      )}
      <MapProvider>
        <PositioningMap initialCenter={initialCenter} initialZoom={initialZoom} onCenterChange={setMapCenter}>
          {/* // Show the initial location marker sticks to the map */}
          {showInitialLocationMarker && <InitialLocationMarker coordinates={initialCenter} />}

          {/* // Display LN_GTWY range circles */}
          {nodeType === 'ln_gtwy' && <LNGatewayRangeCircles coordinates={initialCenter} />}

          {/* // Always center the relocation marker */}
          <div
            css={{
              top: '50vh',
              left: '50vw',
              transform: 'translate(-50%, -50%)',
              position: 'absolute',
            }}
          >
            <RelocationMarker />
          </div>

          <BlockLayer blocks={blocks} />
        </PositioningMap>
      </MapProvider>

      <Footer
        nextButtonLabel={translate.phrases.placeholder('Confirm')}
        nextButtonIcon={null}
        disableNextButton={disableSubmitButtonOnDefaultLocation}
        previousButtonLabel={translate.phrases.placeholder('Cancel')}
        previousButtonIcon={null}
        onPrevious={handleCancel}
        onNext={handleSubmit}
        loading={isSaving}
      />
    </div>
  )
}

export default NodePositioningModal
