import { translate } from 'i18n/i18n'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'

const EU_COUNTRIES = ['ES', 'IT', 'FR', 'DE', 'RO', 'NL']

export const OFMDisclaimerBody = () => {
  const propertyId = selectedFieldAssetsStore.getState().property

  if (propertyId === null) return null

  const country = fieldAssetStore.getState().properties?.[propertyId].country
  const state = fieldAssetStore.getState().properties?.[propertyId].gAdministrativeAreaLevel1

  return (
    <ul
      style={{
        display: 'inline-flex',
        flexDirection: 'column',
        margin: 'unset',
        padding: '0 30px',
      }}
    >
      {country === 'US' && (
        <>
          {state === 'CA' && (
            <li>
              {translate.phrases.banyanApp(
                'This model shows a generalized representation of OFM phenology in your region. It has been built using OFM trap capture data from multiple sources and years. This model is tailored exclusively to stone fruit in California, and it is not meant to be used on other crops.',
              )}
            </li>
          )}
          {state === 'WA' && (
            <li>
              {translate.phrases.banyanApp(
                'This model shows a generalized representation of OFM phenology in your region. It has been built using OFM trap capture data from multiple sources and years. This model is tailored exclusively to pome fruit in Washington, and it is not meant to be used on other crops.',
              )}
            </li>
          )}
        </>
      )}
      {typeof country === 'string' && EU_COUNTRIES.includes(country) && (
        <li>
          {translate.phrases.banyanApp(
            'This model shows a generalized representation of OFM phenology in your region. It has been built using OFM trap capture data from multiple sources and years. This model is tailored exclusively to pome and stone fruit, and it is not meant to be used on other crops.',
          )}
        </li>
      )}
    </ul>
  )
}
