import { Network } from '@capacitor/network'
import type { TFieldAssetValueTypes } from '@semios/app-platform-banyan-route-definitions/dist/routes/userAppStartup'
import { MapBase } from 'App/Map/MapContext/MapBase'
import { LOG_REFRESH_DELAY } from 'App/ServiceCenter/Map/_utils/getActiveNodeStatus'
import { SelectedEquipmentCard } from 'App/ServiceCenter/SelectedEquipmentCard/SelectedEquipmentCard'
import {
  downloadOfflineContents,
  loadMapData,
  loadMapDataInOffline,
  loadPropertiesOverview,
  loadStartupData,
} from 'App/ServiceCenter/utils/loadStartupData'
import { AboveAllModal } from 'components/AboveAllModalOverlay/AboveAllModalOverlay'
import { translate } from 'i18n/i18n'
import moment from 'moment-timezone'
import { useCallback, useContext, useEffect, useMemo, useRef } from 'react'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { selectedFieldAssetsStore } from '../../../stores/selectedFieldAssetsStore'
import { MapContext } from '../../Map/MapContext/MapContext'
import { MapActionIcons } from '../../Map/MapControls/MapActionIcons/MapActionIcons'
import { CurrentLocation } from '../../Map/_utils/CurrentLocation/CurrentLocation'
import { useBleManager } from '../BluetoothLowEnergy/BleManager'
import { OfflineContentDownloadBanner } from '../OfflineContentDownload/OfflineContentDownloadBanner'
import { ReloadMapButton } from '../ReloadMapButton/ReloadMapButton'
import { equipmentStatusPanelStore } from '../store/equipmentStatusPanelStore'
import { networkStore } from '../store/networkStore'
import { serviceCenterStore } from '../store/serviceCenterStore'
import { TNodeStatus } from '../types'
import { createErrorModal } from '../utils/createCommonModals'
import { ActiveEquipmentLayer } from './Layers/ActiveEquipment'
import { BlockLayer } from './Layers/BlockLayer'
import { PlannedEquipmentLayer } from './Layers/PlannedEquipment'
import { PropertiesOverviewLayer } from './Layers/PropertiesOverview'

export const ServiceCenterMap = (props: {
  hasAppStartupData: boolean // TODO: Move...
}) => {
  const { map, setLoadingData, loadingData } = useContext(MapContext)
  const refreshTimeout = useRef<ReturnType<typeof setTimeout>>()
  const selectedPropertyIds = selectedFieldAssetsStore.useSelector((s) => (s.property ? [s.property] : []))
  const connectionStatus = networkStore.useSelector(networkStore.selectors.getConnectionStatus)
  const properties = fieldAssetStore.useSelector((s) => s?.properties)
  const userPropertyIds = properties ? Object.keys(properties).map((id) => +id) : []
  const propertyOverviewZoomLevel = serviceCenterStore.useSelector((s) => s.propertyOverviewZoomLevel)
  const bleManager = useBleManager()

  useEffect(() => {
    async function fetchData() {
      try {
        // Load Service Center additional data
        await loadStartupData()
      } catch (error) {
        AboveAllModal.open(
          createErrorModal(translate.phrases.placeholder('Server Error'), (error as Error).message),
        )
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    async function fetchData() {
      try {
        // Load Service Center additional data
        await loadPropertiesOverview(userPropertyIds)
      } catch (error) {
        AboveAllModal.open(
          createErrorModal(translate.phrases.placeholder('Server Error'), (error as Error).message),
        )
      }
    }

    fetchData()

    const networkListner = Network.addListener('networkStatusChange', async (status) => {
      if (status.connected) {
        fetchData()
      }
    })

    return () => {
      networkListner && networkListner.remove()
    }
  }, [])

  useEffect(() => {
    const zoomChangeListner = map?.addListener('zoom_changed', () => {
      const zoom = map.getZoom()

      // if the zoom level is less than the propertyOverviewZoomLevel, get back to property overview mode
      if (zoom && zoom <= propertyOverviewZoomLevel && selectedPropertyIds.length) {
        selectedFieldAssetsStore.setState((s) => ({ ...s, property: null }))

        serviceCenterStore.actions.resetNodes()

        equipmentStatusPanelStore.actions.reset()
      }

      if (zoom && zoom >= 15) {
        if (selectedPropertyIds.length) return

        const mapBounds = map?.getBounds()

        if (mapBounds) {
          const propertyId = serviceCenterStore.actions.getPropertyIdByMapBounds(mapBounds)

          serviceCenterStore.setState((s) => ({ ...s, propertyOverviewZoomLevel: 12 }))

          if (propertyId) {
            selectedFieldAssetsStore.setState((s) => ({ ...s, property: propertyId }))
          }
        }
      }
    })

    return () => {
      zoomChangeListner && zoomChangeListner.remove()
    }
  }, [map, propertyOverviewZoomLevel, selectedPropertyIds])

  useEffect(() => {
    let intervalId: NodeJS.Timer
    // get static data (device/node help guides) only when the app is loaded for the first time
    // after first run keep downloading user properties data every 5 minutes

    downloadOfflineContents(userPropertyIds, true)

    intervalId = setInterval(() => {
      downloadOfflineContents(userPropertyIds, false)
    }, 300000)

    return () => {
      intervalId && clearInterval(intervalId)
    }
  }, [])

  bleManager.initialize()

  const refreshMapData = useCallback(async () => {
    try {
      if (refreshTimeout.current) window.clearTimeout(refreshTimeout.current)

      // Only fetch map data if there are selected properties
      if (selectedPropertyIds.length) {
        if (connectionStatus.connected) {
          await loadMapData(selectedPropertyIds)
        } else {
          await loadMapDataInOffline(selectedPropertyIds)
        }
      }

      // eslint-disable-next-line no-console
      console.log(
        `Next map refresh @ ${moment.tz().add(LOG_REFRESH_DELAY, 'milliseconds').local().format('HH:mm:ss')}`,
      )

      refreshTimeout.current = setTimeout(refreshMapData, LOG_REFRESH_DELAY)
    } catch (error) {
      AboveAllModal.open(
        createErrorModal(translate.phrases.placeholder('Server Error'), (error as Error).message),
      )
    }
  }, [selectedPropertyIds])

  useEffect(() => {
    setLoadingData(true)

    refreshMapData().then(() => setLoadingData(false))

    return function cleanup() {
      if (refreshTimeout.current) window.clearTimeout(refreshTimeout.current)

      serviceCenterStore.actions.unselectEquipment()
    }
  }, [selectedPropertyIds])

  const mapEquipmentStatuses = serviceCenterStore.useSelector(
    serviceCenterStore.selectors.getMapEquipmentStatuses,
  )

  const plannedNodes = serviceCenterStore.useSelector(serviceCenterStore.selectors.getPlannedNodes)
  const activeNodes = serviceCenterStore.useSelector(serviceCenterStore.selectors.getActiveNodes)

  const blocks = useMemo(() => {
    let propertiesBlocks: TFieldAssetValueTypes.TBlock[] = []

    selectedPropertyIds.forEach((propertyId) => {
      const property = properties?.[propertyId]

      if (!property?.blocks) return

      propertiesBlocks = propertiesBlocks.concat(Object.values(property.blocks))
    })

    return propertiesBlocks
  }, [selectedPropertyIds, properties])

  const showPlannedNodes = !mapEquipmentStatuses.length || mapEquipmentStatuses.includes(TNodeStatus.PLANNED)

  return (
    <MapBase hasAppStartupData={props.hasAppStartupData} loadingData={loadingData} onInit={undefined}>
      {!selectedPropertyIds.length && <PropertiesOverviewLayer />}
      {selectedPropertyIds.length && map ? (
        <>
          <BlockLayer blocks={blocks} />
          {showPlannedNodes && <PlannedEquipmentLayer plannedNodes={plannedNodes} />}
          <ActiveEquipmentLayer activeNodes={activeNodes} />
          <SelectedEquipmentCard />

          <MapActionIcons>
            <CurrentLocation enableHighAccuracy={false} />
            <ReloadMapButton />
          </MapActionIcons>
        </>
      ) : null}
      <OfflineContentDownloadBanner />
    </MapBase>
  )
}
