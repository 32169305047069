import type { GatewayDropInstallMeta } from '../serviceCenterGatewayDropInstall'
import type { GatewayRepositionMeta } from '../serviceCenterGatewayReposition'
import type { PreUploadImageMeta } from '../serviceCenterImagePreUpload'
import type { UploadImageMeta } from '../serviceCenterImageUpload'
import type { NodeConfigUpdateMeta } from '../serviceCenterNodeConfigSet'
import type { NodeDeviceInstallMeta } from '../serviceCenterNodeDeviceInstall'
import type { NodeDeviceRemoveMeta } from '../serviceCenterNodeDeviceRemove'
import type { NodeDropInstallMeta } from '../serviceCenterNodeDropInstall'
import type { AddNodeImagesMeta } from '../serviceCenterNodeImagesAdd'
import type { NodeRemoveMeta } from '../serviceCenterNodeRemove'
import type { NodeRepositionMeta } from '../serviceCenterNodeReposition'
import type { NodeServiceIssueResolutionActionsAddMeta } from '../serviceCenterNodeServiceIssueResolutionActionsAdd'
import type { NodeServiceIssueStatusUpdateMeta } from '../serviceCenterNodeServiceIssueStatusUpdate'
import type { NodeSwapMeta } from '../serviceCenterNodeSwap'
import type { PlannedGatewayInstallMeta } from '../serviceCenterPlannedGatewayInstall'
import type { PlannedGatewayRepositionMeta } from '../serviceCenterPlannedGatewayReposition'
import type { PlannedNodeInstallMeta } from '../serviceCenterPlannedNodeInstall'
import type { PlannedNodesRepositionMeta } from '../serviceCenterPlannedNodesReposition'

type ApiRequestMeta =
  | PreUploadImageMeta
  | UploadImageMeta
  | NodeDeviceInstallMeta
  | AddNodeImagesMeta
  | PlannedNodeInstallMeta
  | PlannedGatewayInstallMeta
  | PlannedNodesRepositionMeta
  | PlannedGatewayRepositionMeta
  | NodeServiceIssueResolutionActionsAddMeta
  | NodeServiceIssueStatusUpdateMeta
  | NodeSwapMeta
  | NodeRemoveMeta
  | NodeDropInstallMeta
  | GatewayDropInstallMeta
  | NodeDeviceRemoveMeta
  | NodeConfigUpdateMeta
  | NodeRepositionMeta
  | GatewayRepositionMeta

export interface TApiRequest {
  // Unique identifier for the request
  id: string
  // Hash of the request meta (used to identify duplicate requests)
  hash: string
  type: ApiRequestType
  status: ApiRequestStatus
  createdAt: string
  meta: ApiRequestMeta
  preRequestError?: string | boolean
  error?: Error
  retryCount?: number
  lastRetryAt?: string
  nextRetryAt?: string | null
}

export type MetaWithNodeIdentifier = {
  nodeIdentifier: string
}

export type MetaWithImageUrls = {
  // Total number of images to upload
  imagesCount: number
  // Uploaded image urls
  imageUrls: string[]
}

export enum ApiRequestType {
  INSTALL_NODE_DEVICE = 'INSTALL_NODE_DEVICE',
  REMOVE_NODE_DEVICE = 'REMOVE_NODE_DEVICE',
  DROP_INSTALL_NODE = 'DROP_INSTALL_NODE',
  DROP_INSTALL_GATEWAY = 'DROP_INSTALL_GATEWAY',
  INSTALL_PLANNED_NODE = 'INSTALL_PLANNED_NODE',
  INSTALL_PLANNED_GATEWAY = 'INSTALL_PLANNED_GATEWAY',
  PRE_UPLOAD_IMAGE = 'PRE_UPLOAD_IMAGE',
  UPLOAD_IMAGE = 'UPLOAD_IMAGE',
  ADD_NODE_IMAGES = 'ADD_NODE_IMAGES',
  REPOSITION_PSEUDO_NODES = 'REPOSITION_PSEUDO_NODES',
  REPOSITION_PSEUDO_GATEWAY = 'REPOSITION_PSEUDO_GATEWAY',
  REPOSITION_ACTIVE_NODE = 'REPOSITION_ACTIVE_NODE',
  REPOSITION_ACTIVE_GATEWAY = 'REPOSITION_ACTIVE_GATEWAY',
  ADD_RESOLUTION_ACTIONS = 'ADD_RESOLUTION_ACTIONS',
  UPDATE_SERVICE_ISSUE_STATUS = 'UPDATE_SERVICE_ISSUE_STATUS',
  SWAP_NODE = 'SWAP_NODE',
  REMOVE_NODE = 'REMOVE_NODE',
  SET_NODE_CONFIG = 'SET_NODE_CONFIG',
}

export enum ApiRequestStatus {
  PARTIAL = 'PARTIAL', // Missing information (eg. image urls)
  PENDING = 'PENDING', // Waiting to be processed
  PROCESSING = 'PROCESSING', // Being processed
  COMPLETED = 'COMPLETED', // Successfully processed
  ERROR = 'ERROR', // Failed request
}
