import type { routes } from '@semios/app-platform-banyan-route-definitions'
import type { TChartSeries } from 'components/StackedChart/types'
import { translate } from 'i18n/i18n'
import { isNil } from 'lodash'
import type { TRGBAColorWithOpacityAtTheEnd } from 'settings/colors'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import { makeRegionalSeriesFromRegularSeries } from '../../../../_utils/makeRegionalSeriesFromRegularSeries'
import { getTrapPercentageValueTypeIdForInsectId } from '../getValueTypesForInsectId'

export const buildTrapPercentageSeries = ({
  trapCatchesSeries,
  insectId,
  isFreeRegionalUser,
  regionName,
  regionsData,
  color,
}: {
  trapCatchesSeries: TChartSeries & { color?: TRGBAColorWithOpacityAtTheEnd }
  insectId: number
  isFreeRegionalUser: boolean
  regionName?: string | null
  regionsData?: NonNullable<routes.Values.Response['regions']>['string']
  color: TRGBAColorWithOpacityAtTheEnd
}) => {
  const insectTrapCatchesConverter = unitConverter.insectTrapCatchesPercentage

  let trapPercentageSeries: TChartSeries

  const timestampToCountDictionaryRS: Record<string, number | null> = {}
  const valueTypeId = getTrapPercentageValueTypeIdForInsectId(insectId)
  const trapsRS = regionsData?.values?.[valueTypeId] ?? []

  trapsRS[0]?.timeseries?.forEach((ts?: { timestamp: string; value: number | null }) => {
    const { timestamp, value } = ts ?? {}

    if (!isNil(timestamp)) {
      timestampToCountDictionaryRS[timestamp] = timestampToCountDictionaryRS[timestamp] ?? null

      if (!isNil(value)) {
        timestampToCountDictionaryRS[timestamp] = value
      }
    }
  })

  trapPercentageSeries = makeRegionalSeriesFromRegularSeries(trapCatchesSeries, {
    name: regionName
      ? `${regionName} (${translate.phrases.banyanApp('Trap Catches Percentage')})`
      : undefined,
    data: Object.entries(timestampToCountDictionaryRS).map(([timestamp, value]) => [
      +new Date(timestamp),
      insectTrapCatchesConverter(value).value(),
    ]),
    tooltip: {
      valueDecimals: insectTrapCatchesConverter().defaultNumberOfDecimalPlaces(),
      valueSuffix: insectTrapCatchesConverter().suffix(),
    },
    yAxis: isFreeRegionalUser ? 1 : 2,
    type: 'line',
    color,
  })

  return trapPercentageSeries
}
