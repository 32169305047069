import { useMantineTheme } from '@mantine/core'
import { Button } from 'components/Button/Button'
import { IconLeftArrowCircle } from 'components/icons/IconLeftArrowCircle'
import { IconRightArrowCircle } from 'components/icons/IconRightArrowCircle'
import { translate } from 'i18n/i18n'
import React from 'react'
import { smallStore } from 'stores/smallStore'

interface FooterProps {
  onPrevious?: () => void
  onNext: () => void
  previousButtonLabel?: string
  previousButtonIcon?: React.ReactNode | null
  nextButtonLabel?: string
  nextButtonIcon?: React.ReactNode | null
  loading?: boolean
  disableNextButton?: boolean
}

export const Footer: React.FC<FooterProps> = ({
  onPrevious,
  onNext,
  nextButtonLabel,
  nextButtonIcon = <IconRightArrowCircle />,
  previousButtonLabel,
  previousButtonIcon = <IconLeftArrowCircle />,
  loading = false,
  disableNextButton = false,
}) => {
  const theme = useMantineTheme()
  const safeArea = smallStore.useSelector((s) => s.safeArea)
  const footerHeight = 60 + safeArea.insets.bottom //px
  const showPreviousButton = !!onPrevious

  return (
    <>
      <div //padding for the footer, so it doesn't overlap with the content
        css={{
          height: footerHeight,
        }}
      />
      <div
        css={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          backgroundColor: theme.colors.grey[0],
          borderTop: `2px solid ${theme.colors.grey[1]}`,
          width: '100%',
          height: footerHeight,
          paddingLeft: safeArea.insets.left,
          paddingRight: safeArea.insets.right,
          zIndex: 10,
        }}
      >
        <div
          css={{
            padding: 10, //padding for the buttons
            display: 'flex',
            justifyContent: showPreviousButton ? 'space-between' : 'flex-end',
          }}
        >
          {showPreviousButton && (
            <Button
              variant="secondary"
              leftIcon={previousButtonIcon}
              css={{ flex: '0 0 auto' }}
              onClick={onPrevious}
            >
              {previousButtonLabel || translate.phrases.placeholder('Back')}
            </Button>
          )}

          <Button
            variant="primary"
            rightIcon={nextButtonIcon}
            css={{ marginLeft: 10, flex: '0 0 auto' }}
            onClick={onNext}
            disabled={disableNextButton}
            loading={loading}
          >
            {nextButtonLabel || translate.phrases.placeholder('Next')}
          </Button>
        </div>
      </div>
    </>
  )
}
