import { translate } from 'i18n/i18n'

export const OBLRDisclaimerBody = () => {
  return (
    <ul
      style={{
        display: 'inline-flex',
        flexDirection: 'column',
        margin: 'unset',
        padding: '0 30px',
      }}
    >
      <li>
        {translate.phrases.banyanApp(
          'OBLR overwinters as immature larvae. In the spring OBLR larvae will be active and capable of causing damage long before any adults are seen. The first opportunity to manage OBLR is these overwintering larvae.',
        )}
      </li>
      <li>
        {translate.phrases.banyanApp(
          'The management windows are taken from extension and scientific literature on OBLR management.',
        )}
      </li>
      <li>
        {translate.phrases.banyanApp(
          'Management windows are labelled according to the primary life-stages present.',
        )}
      </li>
    </ul>
  )
}
