import { IconClose } from 'components/icons/IconClose'
import { IconInfo } from 'components/icons/IconInfo'
import { alphaColorReplacer, colors } from 'settings/colors'
import { CMDisclaimerBody } from './CMDisclaimerBody'
import { OBLRDisclaimerBody } from './OBLRDisclaimerBody'
import { OFMDisclaimerBody } from './OFMDisclaimerBody'

export const ExpandableInformation = ({
  setInformationCollapsed,
  insectId,
}: {
  setInformationCollapsed: React.Dispatch<React.SetStateAction<boolean>>
  insectId: number
}) => {
  const informationContext = (insectId: number) => {
    if (insectId === 1) return <CMDisclaimerBody />
    else if (insectId === 7) return <OFMDisclaimerBody />
    else if (insectId === 11) return <OBLRDisclaimerBody />
  }

  return (
    <>
      <div
        style={{
          display: 'inline-flex',
          width: '100%',
          backgroundColor: alphaColorReplacer(colors.yellow, 0.3),
          padding: '15px',
          fontSize: '16px',
          position: 'relative',
          marginTop: '20px',
        }}
        onClick={() => setInformationCollapsed((prevState) => !prevState)}
      >
        <button
          style={{
            all: 'unset',
            cursor: 'pointer',
            position: 'absolute',
            right: 15,
            top: 15,
            fontSize: '14px',
          }}
        >
          <IconClose />
        </button>
        <div
          style={{
            display: 'inline-flex',
            fontSize: '18px',
            lineHeight: '20px',
            paddingRight: '5px',
            verticalAlign: 'middle',
            color: colors.yellow,
          }}
        >
          <IconInfo />
        </div>
        <div
          style={{
            display: 'inline-flex',
            fontSize: '16px',
            lineHeight: '20px',
          }}
        >
          {informationContext(insectId)}
        </div>
      </div>
    </>
  )
}
