import type { TNodeType } from '../types'

const MAC_REGEX = /^([0-9A-F]{16}$)/i //All SN nodes including gateways

//Provides the regex pattern for each node type, undefined for those that haven't been implemented yet
export const NodeIdPattern: Record<TNodeType, RegExp | undefined> = {
  scd: MAC_REGEX,
  gtwy: MAC_REGEX,
  rpt: MAC_REGEX,
  trp: MAC_REGEX,
  y: MAC_REGEX,
  dn_trp: /^([AT]{1})01([0-9]{2})([0-9A-Z]{1})([0-9A-Z]{8})$/,
  dn_y: /^([AT]{1})02([0-9]{2})([0-9A-Z]{1})([0-9A-Z]{8})$/,
  ln_scd: /^([B]{1})03([0-9]{2})([0-9A-Z]{1})([0-9A-Z]{8})$/,
  ln_gtwy: /^([B]{1})04([0-9]{2})([5-9Z]{1})([0-9A-Z]{8})$/,
  ln_r: /^([B]{1})09([0-9]{2})([0-9A-Z]{1})([0-9A-Z]{8})$/,
  nn_scd: /^([C]{1})03([0-9]{2})([0-9A-Z]{1})([0-9A-Z]{8})$/,
  ex_windmachine: undefined,
  ex_pump: undefined,
  ex_valve: undefined,
  ex_communication: undefined,
  ex_twig_valve: undefined,
  ex_twig_mc_gtwy: undefined,
  ex_twig_rpt: undefined,
  ex_fertigation: undefined,
  ex_filter: undefined,
}
