import type { TFieldAssetKeyTypes } from '@semios/app-platform-banyan-route-definitions/src/routes/userAppStartup'
import { getIdAndEmitterTypeFromZoneEmitterTypeKey } from 'App/Map/_utils/irrigationZoneEmitterTypeKeyUtil'
import { translate } from 'i18n/i18n'
import { isEmpty } from 'lodash'
import { irrigationEventsColorMap } from 'settings/colors'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import { alphabeticalSort } from 'utils/alphabeticalSort'
import { mapApiEmitterTypeToLabel } from 'utils/mapApiEmitterTypeToLabel'
import { checkIfDuplicateEmitters } from './checkIfDuplicateEmitters'

const fontSize = 12

const colorStyles = (color: string, fontSize: number) => ({
  margin: '0px 4px 0px 10px',
  height: fontSize,
  width: fontSize,
  background: color,
  display: 'inline-block',
  borderRadius: '3px',
})

export const IrrigationEventsLegendItems = ({
  emitters,
}: {
  emitters: TFieldAssetKeyTypes.TIrrigationZoneEmitterId[]
}) => {
  if (isEmpty(emitters)) return null

  const properties = fieldAssetStore.getState().properties
  const selectedProperty = selectedFieldAssetsStore.getState().property

  const irrigationZoneEmittersForProperty =
    (selectedProperty && properties?.[selectedProperty]?.irrigationZoneEmitters) || {}

  const hasDuplicateEmitters = checkIfDuplicateEmitters(emitters)

  return (
    <div
      css={{
        marginLeft: 'auto',
        margin: '10px 0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
      }}
    >
      {emitters
        .sort((a, b) => {
          const emitterA = irrigationZoneEmittersForProperty?.[a]?.name
          const emitterB = irrigationZoneEmittersForProperty?.[b]?.name

          return alphabeticalSort(emitterA, emitterB)
        })
        .sort((a, b) => {
          const emitterA = getIdAndEmitterTypeFromZoneEmitterTypeKey(a).emitterType
          const emitterB = getIdAndEmitterTypeFromZoneEmitterTypeKey(b).emitterType

          return alphabeticalSort(emitterA, emitterB)
        })
        .map((legendItem, i) => {
          const { emitterType } = getIdAndEmitterTypeFromZoneEmitterTypeKey(legendItem)
          const zoneName = irrigationZoneEmittersForProperty?.[legendItem]?.name

          return (
            <div css={{ display: 'flex', alignItems: 'center' }} key={`${legendItem}_${i}`}>
              <span
                css={{
                  ...colorStyles(irrigationEventsColorMap[emitterType], fontSize),
                }}
              />
              <span css={{ fontSize, display: 'flex', gap: 3 }}>
                <span>{mapApiEmitterTypeToLabel(emitterType)}</span>
                {hasDuplicateEmitters && zoneName ? <span>{` (${zoneName})`}</span> : null}
              </span>
            </div>
          )
        })}
      <div css={{ display: 'flex', alignItems: 'center' }}>
        <span css={{ ...colorStyles(irrigationEventsColorMap.rain, fontSize) }} />
        <span css={{ fontSize: '12px' }}>{translate.phrases.banyanApp('Rain')}</span>
      </div>
    </div>
  )
}
