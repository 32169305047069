import { IconInfo } from 'components/icons/IconInfo'
import { translate } from 'i18n/i18n'
import { alphaColorReplacer, colors } from 'settings/colors'

export const MoreInformationButton = ({
  isSmallScreen,
  setInformationCollapsed,
  isInfoButtonHovered,
  setIsInfoButtonHovered,
}: {
  isSmallScreen: boolean
  isInfoButtonHovered: boolean
  setInformationCollapsed: React.Dispatch<React.SetStateAction<boolean>>
  setIsInfoButtonHovered: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  return (
    <button
      style={{
        all: 'unset',
        cursor: 'pointer',
        borderColor: colors.midnight,
        borderStyle: 'solid',
        borderWidth: '2px',
        padding: isSmallScreen ? '8px 10px' : '3px 10px',
        bottom: 20,
        transition: 'box-shadow 0.3s ease',
        boxShadow: isInfoButtonHovered ? `2px 2px 8px ${alphaColorReplacer(colors.midnight, 0.3)}` : 'none',
        borderRadius: '3px',
        ...(isSmallScreen
          ? {
              display: 'flex',
              marginTop: '15px',
            }
          : {
              position: 'absolute',
              right: 0,
              bottom: 0,
            }),
      }}
      onMouseOver={() => {
        setIsInfoButtonHovered(true)
      }}
      onMouseOut={() => {
        setIsInfoButtonHovered(false)
      }}
      onClick={() => {
        setIsInfoButtonHovered(false)

        setInformationCollapsed((prevState) => !prevState)
      }}
    >
      <div
        style={{
          display: 'inline-flex',
          fontSize: '14px',
          lineHeight: '14px',
          paddingRight: '10px',
          verticalAlign: 'middle',
          color: colors.yellow,
        }}
      >
        <IconInfo />
      </div>
      <div
        style={{
          display: 'inline-flex',
          fontSize: '14px',
          lineHeight: '14px',
          fontWeight: 'bolder',
        }}
      >
        {translate.phrases.banyanApp('More Information')}
      </div>
    </button>
  )
}
