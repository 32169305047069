import type { Interpolation, Theme } from '@emotion/react'
import { Menu, Text } from '@mantine/core'
import { IconEllipsis } from 'components/icons/IconEllipsis'
import type { FC, ReactElement, ReactNode } from 'react'
import { colors } from 'settings/colors'
import { smallStore } from 'stores/smallStore'
import { BackIcon } from '../BackIcon/BackIcon'
import { CloseIcon } from '../CloseIcon/CloseIcon'

type WideHeaderProps = {
  onClose: () => void
  title: string
  rightIconButton?: ReactNode
  isSecondaryModal?: boolean
  style?: Interpolation<Theme>
  DropdownMenuItems?: ReactElement
}

export const WideHeader: FC<WideHeaderProps> = ({
  onClose,
  title,
  rightIconButton,
  isSecondaryModal,
  style,
  DropdownMenuItems,
}) => {
  const safeArea = smallStore.useSelector((s) => s.safeArea)
  const HeaderHeight = 60 + safeArea.insets.top //px

  return (
    <div
      css={[
        {
          position: 'sticky',
          width: '100%',
          top: 0,
          zIndex: 15,
          height: HeaderHeight,
          backgroundColor: colors.grey50,
          display: 'flex',
          alignItems: 'center',
          paddingTop: 'env(safe-area-inset-top)',
          paddingLeft: 'env(safe-area-inset-left)',
          paddingRight: 'env(safe-area-inset-right)',
        },
        style,
      ]}
    >
      <div
        css={{
          paddingLeft: 20,
          paddingRight: 20,
          flexDirection: 'row',
          display: 'flex',
          width: '100%',
        }}
      >
        <div
          css={{
            'marginRight': 25,
            'alignItems': 'center',
            'display': 'flex',
            '&:hover': {
              cursor: 'pointer',
            },
          }}
          onClick={onClose}
        >
          {isSecondaryModal ? <BackIcon /> : <CloseIcon />}
        </div>

        <div
          css={{
            flex: '1 1 auto',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            minWidth: 0,
          }}
        >
          <Text
            color={colors.midnight}
            weight={700}
            size={18}
            css={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              lineHeight: '24px',
              alignContent: 'center',
            }}
          >
            {title}
          </Text>

          {rightIconButton}
        </div>
        {DropdownMenuItems && (
          <Menu offset={{ crossAxis: -53 }}>
            <Menu.Target>
              <div css={{ position: 'fixed', right: '8px', top: '20px' }}>
                <IconEllipsis />
              </div>
            </Menu.Target>
            <Menu.Dropdown>{DropdownMenuItems}</Menu.Dropdown>
          </Menu>
        )}
      </div>
    </div>
  )
}
