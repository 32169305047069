import type { routes } from '@semios/app-platform-banyan-route-definitions'
import { sortByKey } from '@semios/app-platform-common'
import { FilterListPopover } from 'components/FilterListPopover/FilterListPopover'
import { translate } from 'i18n/i18n'
import moment from 'moment-timezone'
import type { Dispatch, SetStateAction } from 'react'

export const YearTitle = ({
  data,
  filterActiveForYears,
  filteredYears,
  setFilteredYears,
}: {
  data: routes.PheromonePurRecDownload.Response['files']
  filterActiveForYears: boolean
  filteredYears: { [year: string]: boolean }
  setFilteredYears: Dispatch<SetStateAction<{ [type: string]: boolean }>>
}) => {
  const yearsInData: { [year: string]: boolean } = data.reduce((a: { [year: string]: boolean }, b) => {
    const year = b.type === 'REC' ? b.year : moment.tz(b.dateAppliedYYYYMMDD, moment.tz.guess()).year()

    a[year] = true

    return a
  }, {})

  return (
    <div css={{ display: 'flex' }}>
      {translate.phrases.banyanApp('Year')}
      {data && !!data.length && (
        <FilterListPopover
          filterIsActive={filterActiveForYears}
          optionsGroups={[
            {
              options: Object.keys(yearsInData)
                .map((year) => ({
                  key: year,
                  title: year,
                  checked: !!filteredYears[year],
                  onChange: () =>
                    setFilteredYears({
                      ...filteredYears,
                      [year]: !filteredYears[year],
                    }),
                }))
                .sort(sortByKey('title')),
            },
          ]}
          onClear={() => setFilteredYears({})}
          showSearch={false}
          width={100}
        />
      )}
    </div>
  )
}
