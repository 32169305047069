import type { routes } from '@semios/app-platform-banyan-route-definitions'
import { apiFetch } from 'utils/apiFetch'

export const irrigationSchedulerEventsGet = async (request: routes.IrrigationSchedulerEventsGet.Request) => {
  const response = await apiFetch({
    url: '/irrigation-scheduler-events-get',
    body: request,
    params: { timeout: 180000 },
  })

  return response
}
