import { faCalendar } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type { DatePickerInputProps } from '@mantine/dates'
import { DatePickerInput } from '@mantine/dates'
import type { FC } from 'react'
import { colors } from 'settings/colors'

export const DatePicker: FC<DatePickerInputProps<'default'>> = (props) => {
  return (
    <DatePickerInput<'default'>
      weekendDays={[]} // Makes weekend days black instead of red
      rightSection={<FontAwesomeIcon css={{ color: colors.grey800, fontSize: 16 }} icon={faCalendar} />}
      {...props}
    />
  )
}
