import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { actions } from 'App/irrigation-scheduler/utils/store/actions/actions'
import { openDownloadModal } from 'App/Map/PheromonePurRecDownloadModal/PheromonePurRecDownloadModal'
import { openNodeInstallationModal } from 'App/ServiceCenter/NodeInstallation/NodeInstallation'
import { networkStore } from 'App/ServiceCenter/store/networkStore'
import { serviceCenterStore } from 'App/ServiceCenter/store/serviceCenterStore'
import { showModalWithCachedCheckbox } from 'App/ServiceCenter/utils/showModalWithCachedCheckbox'
import { Authorization } from 'components/Authorization/Authorization'
import { IconAlma } from 'components/icons/IconAlma'
import { IconCalendar } from 'components/icons/IconCalendar'
import { IconDataExport } from 'components/icons/IconDataExport'
import { IconHeatmap } from 'components/icons/IconHeatmap'
import { IconLibraryBooks } from 'components/icons/IconLibraryBooks'
import { IconNetworkOffline } from 'components/icons/IconNetworkOffline'
import { IconNotification } from 'components/icons/IconNotification'
import { IconPlusInCircle } from 'components/icons/IconPlusInCircle'
import { IconSun } from 'components/icons/IconSun'
import { IconAndTitle } from 'components/SideNav/IconAndTitle/IconAndTitle'
import { translate } from 'i18n/i18n'
import { layersNavigationMenuStore } from 'stores/layersNavigationMenuStore'
import { smallStore } from 'stores/smallStore'
import { userDetailsStore } from 'stores/userDetailsStore'
import { checkAuthorization } from 'utils/checkAuthorization'
import { useIsServiceCenterActive } from 'utils/useIsServiceCenterActive'
import { openHelpGuideModal } from '../../../ServiceCenter/HelpGuideModal/HelpGuideModal'
import { ComingSoonItem } from '../Layers/generateNavigationMenu/ComingSoonItem/ComingSoonItem'

export const QuickLinks = () => {
  const isServiceCenterActive = useIsServiceCenterActive()
  const connectionStatus = networkStore.useSelector(networkStore.selectors.getConnectionStatus)
  const userPermissions = userDetailsStore.useSelector((s) => s.permissions)
  const userCanCreateAlert = Object.keys(userPermissions).some((p) => p.match(/^CREATE_.*_ALERT$/))

  const openModal = () => {
    openNodeInstallationModal({
      payload: {},
      localState: {
        isLocationConfirmed: false,
      },
    })
  }

  const handleOpenDropInstallModal = () => {
    const key = 'hideDropInstallWarning'

    serviceCenterStore.actions.unselectEquipment()

    layersNavigationMenuStore.setState((s) => ({ ...s, menuCollapsed: true }))

    if (!localStorage.getItem(key)) {
      showModalWithCachedCheckbox({
        title: 'Install New Equipment',
        DescriptionElement: (
          <div>
            {translate.phrases.placeholder(
              'This install method is reserved for equipment that is not already planned on your property map.',
            )}
            <br />
            <br />
            {translate.phrases.placeholder(
              'If you are trying to install equipment that has already been planned, select the equipment on the map and follow the instructions.',
            )}
          </div>
        ),
        key,
        onConfirm: () => openModal(),
      })

      return
    }

    openModal()
  }

  return (
    <>
      <Authorization requires={{ permission: 'VIEW_WEATHER_DETAILS', entity: 'FOR_ANY_ENTITY' }}>
        <IconAndTitle
          icon={<IconSun />}
          onClick={() => smallStore.setState((s) => ({ ...s, showWeatherWidgetModal: true }))}
          title={translate.phrases.banyanApp('Weather Forecast')}
        />
      </Authorization>
      <Authorization requires={{ permission: 'VIEW_ALMA_CHAT', entity: 'FOR_ANY_ENTITY' }}>
        <IconAndTitle
          icon={<IconAlma />}
          title={translate.phrases.banyanApp('Alma')}
          onClick={() => smallStore.setState((s) => ({ ...s, showAlmaChat: true }))}
        />
      </Authorization>
      <Authorization requires={{ permission: 'VIEW_TEMPERATURE_HEATMAP', entity: 'FOR_ANY_ENTITY' }}>
        <IconAndTitle
          icon={<IconHeatmap />}
          onClick={() => smallStore.setState((s) => ({ ...s, showPropertyPlayback: true }))}
          title={translate.phrases.banyanApp('Property Playback')}
        />
      </Authorization>
      <Authorization requires={{ permission: 'VIEW_IRRIGATION_PLANNER', entity: 'FOR_ANY_ENTITY' }}>
        <IconAndTitle
          icon={<IconCalendar />}
          onClick={() => actions.main.setShowLauncher(true)}
          title={translate.phrases.banyanApp('Irrigation Scheduler')}
        />
      </Authorization>
      <Authorization requires={userCanCreateAlert}>
        <IconAndTitle
          icon={<IconNotification />}
          onClick={() =>
            smallStore.setState((s) => ({
              ...s,
              showSettingsModal: true,
              settingsModalSelectedTab: 'alerts',
            }))
          }
          title={translate.phrases.banyanApp('Alerts')}
        />
      </Authorization>
      <Authorization requires={{ permission: 'EXPORT_DATA_TO_CSV', entity: 'FOR_ANY_ENTITY' }}>
        <IconAndTitle
          icon={
            <div css={{ cursor: 'not-allowed !important' }}>
              <IconDataExport />
            </div>
          }
          onClick={() => null}
          title={
            <div css={{ cursor: 'not-allowed !important' }}>
              <ComingSoonItem translatedTitle={translate.phrases.banyanApp('Data Export')} />
            </div>
          }
        />
      </Authorization>
      <Authorization requires={{ permission: 'EXPORT_PHEROMONE_PUR_REC', entity: 'FOR_ANY_ENTITY' }}>
        <IconAndTitle
          icon={<FontAwesomeIcon icon={faDownload} size="lg" />}
          onClick={() => openDownloadModal()}
          title={translate.phrases.banyanApp('Pheromone PUR/REC Download')}
        />
      </Authorization>
      {isServiceCenterActive && (
        <IconAndTitle
          disabled={!checkAuthorization({ permission: 'EDIT_SSC_INSTALL_NODE', entity: 'FOR_ANY_ENTITY' })}
          onClick={handleOpenDropInstallModal}
          icon={<IconPlusInCircle />}
          title={translate.phrases.banyanApp('Install New Equipment')}
        />
      )}
      <Authorization requires={{ permission: 'VIEW_SSC_HELP_GUIDES', entity: 'FOR_ANY_ENTITY' }}>
        <IconAndTitle
          onClick={() => openHelpGuideModal()}
          icon={<IconLibraryBooks />}
          title={translate.phrases.banyanApp('Assembly & Install Guides')}
        />
      </Authorization>
      {isServiceCenterActive && (
        <IconAndTitle
          disabled={!connectionStatus.connected}
          onClick={() => serviceCenterStore.actions.setIsOfflineContentBannerOpened(true)}
          icon={<IconNetworkOffline />}
          title={translate.phrases.banyanApp('Download for Offline Usage')}
        />
      )}
    </>
  )
}
