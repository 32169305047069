import { Container, Flex, useMantineTheme } from '@mantine/core'
import { routes } from '@semios/app-platform-banyan-route-definitions'
import {
  FIRE_BLIGHT_DEFAULT,
  isEmpty,
  sortByKey,
  type FireBlightHistoricalRiskDatabaseId,
} from '@semios/app-platform-common'
import type { TFieldAssetValueTypes } from 'App/Map/types'
import { Authorization } from 'components/Authorization/Authorization'
import { Button } from 'components/Button/Button'
import { ExpandableChevronIndicator } from 'components/ExpandableChevronIndicator/ExpandableChevronIndicator'
import { ModalDrawer } from 'components/ModalDrawer/ModalDrawer'
import { Select } from 'components/Select/Select'
import { translate } from 'i18n/i18n'
import { keyBy } from 'lodash'
import { useEffect, useState } from 'react'
import { SharedSettings } from 'settings/SharedSettings'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import { apiFetch } from 'utils/apiFetch'
import { filterFieldAssetsByValueTypes } from 'utils/filterFieldAssetsByValueTypes'
import { useApiREST } from 'utils/useApiREST'
import { makeDiseaseHistoryOptions } from '../historicalRisk'
import { useBlocksFilter } from './useBlocksFilter'

export type BlockWithRiskHistory = TFieldAssetValueTypes.TBlock & {
  riskId?: FireBlightHistoricalRiskDatabaseId
}

type EditDiseaseHistoryLinkProp = {
  openDrawer: () => void
  alignRightFlexItem?: boolean
}

const EditDiseaseHistoryLink = ({ openDrawer }: EditDiseaseHistoryLinkProp) => {
  return (
    <Button onClick={openDrawer} variant="link">
      {translate.phrases.banyanApp('Edit Disease History')}
    </Button>
  )
}

type EditDiseaseHistoryDrawerProp = {
  isOpen: boolean
  loading: boolean
  closeDrawer: () => void
  blocks: BlockWithRiskHistory[]
  onApply: (fireBlightHistoricalRiskId: FireBlightHistoricalRiskDatabaseId, blockIds: string[]) => void
  riskId: FireBlightHistoricalRiskDatabaseId
}

const EditDiseaseHistoryDrawer = ({
  isOpen,
  loading,
  closeDrawer,
  blocks,
  onApply,
  riskId,
}: EditDiseaseHistoryDrawerProp) => {
  const theme = useMantineTheme()
  const [canApply, setCanApply] = useState(false)

  const [selectedHistoricalRiskId, setSelectedHistoricalRiskId] =
    useState<FireBlightHistoricalRiskDatabaseId>(riskId)

  const { FilterComponent, selectedBlocks, reset } = useBlocksFilter({ blocks, loading })

  useEffect(() => {
    setCanApply(selectedBlocks.length > 0 && Boolean(selectedHistoricalRiskId))
  }, [selectedBlocks, selectedHistoricalRiskId])

  const handleClose = () => {
    reset()

    setSelectedHistoricalRiskId(FIRE_BLIGHT_DEFAULT)

    closeDrawer()
  }

  return (
    <ModalDrawer
      title={translate.phrases.banyanApp('Edit Disease History')}
      opened={isOpen}
      onClose={handleClose}
      primaryButtonText={translate.phrases.banyanApp('Apply to {{size}} {{thing}}', {
        size:
          selectedBlocks.length === blocks.length
            ? translate.phrases.banyanApp('All')
            : selectedBlocks.length,
        thing:
          selectedBlocks.length === 1
            ? translate.phrases.banyanApp('Block')
            : translate.phrases.banyanApp('Blocks'),
      })}
      primaryButtonDisabled={!canApply}
      primaryButtonOnPress={() => {
        onApply(
          selectedHistoricalRiskId,
          selectedBlocks.map((block) => String(block.blockId)),
        )

        handleClose()
      }}
      secondaryButtonText="Cancel"
      secondaryButtonOnPress={handleClose}
      zIndex={SharedSettings.DEFAULT_MODAL_DRAWER_Z_INDEX}
    >
      <Flex direction="column" gap="md" w="100%" p="sm">
        <Container w="100%">
          <Select
            data-autofocus
            rightSection={<ExpandableChevronIndicator expanded={false} color={theme.colors.grey[2]} />}
            data={makeDiseaseHistoryOptions()}
            label={translate.phrases.banyanApp('Set Disease History')}
            labelStyle={{ fontWeight: 'normal' }}
            placeholder={translate.phrases.banyanApp('Select Disease History')}
            value={selectedHistoricalRiskId}
            handleOnChange={(id) => {
              setSelectedHistoricalRiskId(id as FireBlightHistoricalRiskDatabaseId)

              setCanApply(selectedBlocks.length > 0)
            }}
          />
        </Container>
        <Container w="100%">
          <div css={{ marginBottom: 10 }}>{translate.phrases.banyanApp('Apply to the following blocks')}</div>
          {FilterComponent}
        </Container>
      </Flex>
    </ModalDrawer>
  )
}

const blocksWithFireBlight = (valuesTimeseries: string[]) => {
  const propertyId = selectedFieldAssetsStore.useSelector((state) => state.property)
  const blocks = fieldAssetStore.getState().properties?.[Number(propertyId)].blocks || {}

  return filterFieldAssetsByValueTypes({
    fieldAssets: Object.values(blocks),
    valuesTimeseries,
  }).sort(sortByKey('name'))
}

const updateFireBlightSettings = async (riskId: string, blockIds: string[]) => {
  const requestBody = {
    fireBlightUpdate: blockIds.map((blockId) => ({
      blockId: parseInt(blockId),
      riskId: riskId,
    })),
  }

  await apiFetch({
    url: routes.FieldAssetSettingsSet.path,
    body: requestBody,
  })
}

type FireBlightSettingsProp = {
  onApply: (riskId: FireBlightHistoricalRiskDatabaseId, blockIds: string[]) => void
  riskId: FireBlightHistoricalRiskDatabaseId
  valuesTimeseries: string[]
}

export const FireBlightSettings = ({ onApply, riskId, valuesTimeseries }: FireBlightSettingsProp) => {
  const [isOpenedDrawer, setIsOpenedDrawer] = useState(false)
  const blocksWithFireBlightInputData = blocksWithFireBlight(valuesTimeseries)
  const propertyId = selectedFieldAssetsStore.useSelector((state) => state.property)
  const blockIds = blocksWithFireBlightInputData.map((b) => b.blockId)

  const { data: fireBlighData, loading: loadingFireBlighData } = useApiREST({
    url: routes.FieldAssetSettingsGet.path,
    body: { fireBlight: { blockIds } },
    shaper: (d) => {
      if (!d || !d.fireBlight || 'error' in d.fireBlight) return blocksWithFireBlightInputData

      const fireblightByBlock = keyBy(d.fireBlight || [], 'blockId')

      return blocksWithFireBlightInputData.map((b) => ({
        ...b,
        riskId: fireblightByBlock[b.blockId]?.riskId,
      }))
    },
    preventFetch: !propertyId || !isOpenedDrawer || isEmpty(blocksWithFireBlightInputData),
    initialState: [],
    watchers: [Number(propertyId), isOpenedDrawer, blockIds.join()],
  })

  return (
    <Authorization requires={{ permission: 'EDIT_FIRE_BLIGHT_STATION_HISTORY', entity: Number(propertyId) }}>
      <>
        <EditDiseaseHistoryLink openDrawer={() => setIsOpenedDrawer(true)} />
        <EditDiseaseHistoryDrawer
          loading={loadingFireBlighData}
          isOpen={isOpenedDrawer}
          closeDrawer={() => setIsOpenedDrawer(false)}
          blocks={fireBlighData}
          riskId={riskId}
          onApply={async (riskId, blockIds) => {
            await updateFireBlightSettings(riskId, blockIds)

            onApply(riskId, blockIds)
          }}
        />
      </>
    </Authorization>
  )
}
