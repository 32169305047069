import type { routes } from '@semios/app-platform-banyan-route-definitions'
import * as Sentry from '@sentry/react'
import _ from 'lodash'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { handleOfflineError } from 'utils/apiFetch'
import { checkAuthorization } from 'utils/checkAuthorization'
import { ionicStore } from '../../../ionicStorage'
import type { TServiceCenterStore } from '../store/serviceCenterStore'
import { serviceCenterStore } from '../store/serviceCenterStore'
import type {
  TActiveGateway,
  TActiveNode,
  THelpGuideStep,
  TNodeLog,
  TNodeServiceIssueSummary,
  TPropertyOverviewWithLocation,
} from '../types'
import { HelpGuideGroup, TServiceIssueStatus } from '../types'
import { serviceCenterActiveNodesGet } from './api/serviceCenterActiveNodesGet'
import { serviceCenterDevicesGet } from './api/serviceCenterDevicesGet'
import { serviceCenterHelpGuidesGet } from './api/serviceCenterHelpGuidesGet'
import { serviceCenterLuresGet } from './api/serviceCenterLuresGet'
import { serviceCenterNodeLogsGet } from './api/serviceCenterNodeLogsGet'
import { serviceCenterNodeRemoveReasonsGet } from './api/serviceCenterNodeRemoveReasonsGet'
import { serviceCenterNodeServiceIssuesGet } from './api/serviceCenterNodeServiceIssuesGet'
import { serviceCenterNodeSwapReasonsGet } from './api/serviceCenterNodeSwapReasonsGet'
import { serviceCenterPlannedNodesGet } from './api/serviceCenterPlannedNodesGet'
import { serviceCenterPropertiesOverviewGet } from './api/serviceCenterPropertiesOverviewGet'

async function loadImage(url: string): Promise<string> {
  return new Promise((resolve) => {
    const img = document.createElement('img')

    img.src = url

    // wait for image to be loaded before resolving the promise
    img.onload = () => {
      resolve(url)
    }
  })
}

async function loadPlannedNodes(propertyIds: number[]) {
  await handleOfflineError(async () => {
    const plannedNodes = await serviceCenterPlannedNodesGet(propertyIds)

    serviceCenterStore.actions.setPlannedNodes(plannedNodes)
  })
}

async function loadActiveNodes(propertyIds: number[]) {
  await handleOfflineError(async () => {
    const activeNodes = await serviceCenterActiveNodesGet(propertyIds)

    serviceCenterStore.actions.setActiveNodes(activeNodes)
  })
}

async function loadNodeLogs(propertyIds: number[]) {
  await handleOfflineError(async () => {
    const nodeLogs = await serviceCenterNodeLogsGet(propertyIds)

    serviceCenterStore.actions.setNodeLogs(nodeLogs)
  })
}

async function loadServiceIssues(propertyIds: number[]) {
  await handleOfflineError(async () => {
    const serviceIssues = await serviceCenterNodeServiceIssuesGet({
      propertyIds,
      issueStatuses: [TServiceIssueStatus.CREATED, TServiceIssueStatus.FLAGGED_FOR_SERVICE],
    })

    serviceCenterStore.actions.setServiceIssues(serviceIssues)
  })
}

async function loadDevices() {
  await handleOfflineError(async () => {
    if (checkAuthorization({ permission: 'VIEW_SSC_INSTALLABLE_DEVICES', entity: '*' })) {
      const devices = await serviceCenterDevicesGet()

      fieldAssetStore.setState((s) => ({ ...s, devices }))
    }
  })
}

async function loadLures() {
  await handleOfflineError(async () => {
    if (checkAuthorization({ permission: 'VIEW_SSC_LURES', entity: '*' })) {
      const lures = await serviceCenterLuresGet()

      serviceCenterStore.actions.setLures(lures)
    }
  })
}

// async function loadInstallationChecklists() {
//   await handleOfflineError(async () => {
//     if (checkAuthorization({ permission: 'VIEW_SSC_EQUIPMENT_INSTALLATION_INSTRUCTIONS', entity: '*' })) {
//       const installationChecklists = await serviceCenterInstallationChecklistsGet()
//
//       serviceCenterStore.actions.setInstallationChecklists(installationChecklists)
//     }
//   })
// }

export async function loadPropertiesOverview(propertyIds: number[]) {
  await handleOfflineError(async () => {
    if (propertyIds.length) {
      let apiRequests: Promise<Record<number, TPropertyOverviewWithLocation>>[] = []

      // if user has too many properties, we need to split the request into multiple requests
      const BATCH_SIZE = 50
      const chunkedArray = _.chunk(propertyIds, BATCH_SIZE)

      chunkedArray.forEach((chunk) => {
        apiRequests.push(serviceCenterPropertiesOverviewGet(chunk))
      })

      const propertiesOverviews = await Promise.all(apiRequests)
      const now = new Date()

      let result = {}

      propertiesOverviews.forEach((overview) => {
        result = { ...result, ...overview }
      })

      serviceCenterStore.actions.setPropertiesOverview({ lastLoaded: now, overviewData: result })
    }
  })
}

async function loadNodeSwapReasons() {
  await handleOfflineError(async () => {
    if (checkAuthorization({ permission: 'VIEW_SSC_SWAP_NODE_REASONS', entity: '*' })) {
      const swapReasons = await serviceCenterNodeSwapReasonsGet()

      serviceCenterStore.actions.setNodeSwapReasons(swapReasons)
    }
  })
}

async function loadNodeRemoveReasons() {
  await handleOfflineError(async () => {
    if (checkAuthorization({ permission: 'VIEW_SSC_REMOVE_NODE_REASONS', entity: '*' })) {
      const removeReasons = await serviceCenterNodeRemoveReasonsGet()

      serviceCenterStore.actions.setNodeRemoveReasons(removeReasons)
    }
  })
}

export async function loadStartupData() {
  await Promise.all([
    loadDevices(),
    loadLures(),
    // loadInstallationChecklists(),
    loadNodeSwapReasons(),
    loadNodeRemoveReasons(),
  ])
}

export async function loadMapData(propertyIds: number[]) {
  await Promise.all([
    loadPlannedNodes(propertyIds),
    loadActiveNodes(propertyIds),
    loadNodeLogs(propertyIds),
    loadServiceIssues(propertyIds),
  ])
}

const loadPlannedNodesInOffline = async (propertyIds: number[]) => {
  const plannedNodes = await ionicStore.get('serviceCenterStore').then((data: TServiceCenterStore) => {
    return data?.offlineContents.plannedNodes
  })

  if (!plannedNodes) return

  const filteredPlannedNodes = plannedNodes.filter((node) => propertyIds.includes(node.propertyId))

  serviceCenterStore.actions.setPlannedNodes(filteredPlannedNodes)
}

const loadActiveNodesInOffline = async (propertyIds: number[]) => {
  const activeNodes = await ionicStore
    .get('serviceCenterStore')
    .then((data: TServiceCenterStore) => data?.offlineContents.activeNodes)

  if (!activeNodes) return

  const filteredActiveNodes = activeNodes.filter((node) => {
    if ('gatewayType' in node) return propertyIds.some((id) => node.propertyIds.includes(id))

    return propertyIds.includes(node.propertyId)
  })

  serviceCenterStore.actions.setActiveNodes(filteredActiveNodes)

  const activeNodeIdentifiers = filteredActiveNodes
    .map(
      (node: TActiveNode | TActiveGateway) => (node as TActiveNode).nodeIdentifier || node.gatewayIdentifier,
    )
    .filter(Boolean)

  await loadServiceIssuesGetInOffline(activeNodeIdentifiers as string[])
}

const loadNodeLogsGetInOffline = async (propertyIds: number[]) => {
  const nodeLogs = await ionicStore
    .get('serviceCenterStore')
    .then((data: TServiceCenterStore) => data?.offlineContents.nodeLogs)

  if (!nodeLogs) return

  const result: Record<string, TNodeLog> = {}

  for (let nodeIdentifier in nodeLogs) {
    if (propertyIds.includes(nodeLogs[nodeIdentifier].propertyId)) {
      result[nodeIdentifier] = nodeLogs[nodeIdentifier]
    }
  }

  serviceCenterStore.actions.setNodeLogs(result)
}

const loadServiceIssuesGetInOffline = async (activeNodeIdentifiers: string[]) => {
  const serviceIssues = await ionicStore
    .get('serviceCenterStore')
    .then((data: TServiceCenterStore) => data.offlineContents.serviceIssues)

  const result: Record<string, TNodeServiceIssueSummary[]> = {}

  for (let nodeIdentifier in serviceIssues) {
    if (activeNodeIdentifiers.includes(nodeIdentifier)) {
      result[nodeIdentifier] = serviceIssues[nodeIdentifier]
    }
  }

  serviceCenterStore.actions.setServiceIssues(result)
}

export async function loadMapDataInOffline(propertyIds: number[]) {
  await Promise.all([
    loadPlannedNodesInOffline(propertyIds),
    loadActiveNodesInOffline(propertyIds),
    loadNodeLogsGetInOffline(propertyIds),
  ])
}

// download all the user properties data / static data (help guides) and save them in the store
export async function downloadOfflineContents(propertyIds: number[], getStaticData = true) {
  // start progress bar
  serviceCenterStore.actions.setOfflineContentsDownloadStatus('3')

  let apiRequests: Promise<
    | routes.ServiceCenterPlannedNodesGet.Response
    | routes.ServiceCenterActiveNodesGet.Response
    | routes.ServiceCenterNodeLogsGet.Response
    | routes.ServiceCenterNodeServiceIssuesGet.Response
  >[] = []

  const BATCH_SIZE = 15
  const chunkedArray = _.chunk(propertyIds, BATCH_SIZE)

  chunkedArray.forEach((chunk) => {
    apiRequests.push(
      serviceCenterPlannedNodesGet(chunk),
      serviceCenterActiveNodesGet(chunk),
      serviceCenterNodeLogsGet(chunk),
      serviceCenterNodeServiceIssuesGet({
        propertyIds: chunk,
        issueStatuses: [TServiceIssueStatus.CREATED, TServiceIssueStatus.FLAGGED_FOR_SERVICE],
      }),
    )
  })

  try {
    const results = await Promise.all(
      apiRequests.map(async (promise, _, arr) => {
        let data = await promise

        const progressionIncrements = Math.round(100 / arr.length).toString()

        serviceCenterStore.actions.setOfflineContentsDownloadStatus(progressionIncrements)

        return data
      }),
    )

    if (getStaticData) {
      const helpGuides = await serviceCenterHelpGuidesGet()

      const {
        [HelpGuideGroup.USER_GUIDES]: userGuides,
        [HelpGuideGroup.DEVICE_INSTALLATION]: deviceInstallationGuides,
        [HelpGuideGroup.STATION_INSTALLATION]: stationInstallationGuides,
      } = helpGuides

      const imagesToBeCached = [] as Promise<string>[]

      Object.values(userGuides).forEach((userGuide: THelpGuideStep[]) => {
        userGuide.forEach(({ image }) => {
          if (image.length) imagesToBeCached.push(loadImage(image))
        })
      })

      Object.values(deviceInstallationGuides).forEach((deviceGuide: THelpGuideStep[]) => {
        deviceGuide.forEach(({ image }) => {
          if (image.length) imagesToBeCached.push(loadImage(image))
        })
      })

      Object.values(stationInstallationGuides).forEach((stationGuide: THelpGuideStep[]) => {
        stationGuide.forEach(({ image }) => {
          if (image.length) imagesToBeCached.push(loadImage(image))
        })
      })

      await Promise.all(imagesToBeCached)

      serviceCenterStore.actions.setHelpGuides(helpGuides)
    }

    const resultsGroupedByChunk = _.chunk(results, 4)
    const pnodesResult = [] as TServiceCenterStore['plannedNodes']
    const activeNodesResult = [] as TServiceCenterStore['activeNodes']

    let nodeLogResult = {} as TServiceCenterStore['nodeLogs']
    let serviceIssuesResult = {} as TServiceCenterStore['serviceIssues']

    resultsGroupedByChunk.forEach((result) => {
      const [pnode, activeNode, nodeLog, serviceIssues] = result

      pnodesResult.push(...(pnode as TServiceCenterStore['plannedNodes']))

      activeNodesResult.push(...(activeNode as TServiceCenterStore['activeNodes']))

      nodeLogResult = { ...nodeLogResult, ...(nodeLog as TServiceCenterStore['nodeLogs']) }

      serviceIssuesResult = {
        ...serviceIssuesResult,
        ...(serviceIssues as TServiceCenterStore['serviceIssues']),
      }
    })

    serviceCenterStore.actions.setOfflineContents({
      plannedNodes: pnodesResult,
      activeNodes: activeNodesResult,
      nodeLogs: nodeLogResult,
      serviceIssues: serviceIssuesResult,
      lastTimeDownloaded: new Date().toISOString(),
      downloadStatus: 'success',
      isBannerOpened: false,
    })

    await loadPropertiesOverview(propertyIds)
  } catch (e) {
    serviceCenterStore.actions.setOfflineContentsDownloadStatus('error')

    Sentry.captureException(e)
  }
}
