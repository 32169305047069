import { Button } from 'components/Button/Button'
import { GoogleMap } from 'components/GoogleMap/GoogleMap'
import { translate } from 'i18n/i18n'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { MapContext } from '../../../Map/MapContext/MapContext'
import { CurrentLocation } from '../../../Map/_utils/CurrentLocation/CurrentLocation'

type PositioningMapProps = {
  initialCenter: google.maps.LatLngLiteral
  initialZoom?: number
  children?: React.ReactNode
  onCenterChange: (center: google.maps.LatLngLiteral) => void
}

export const PositioningMap: React.FC<PositioningMapProps> = ({
  initialCenter,
  initialZoom,
  children,
  onCenterChange,
}) => {
  const { map, setMap } = useContext(MapContext)
  const [hasMapMoved, setHasMapMoved] = useState(false)

  const mapOptions = useMemo(() => {
    return {
      mapTypeId: 'satellite',
      disableDefaultUI: true,
      fullscreenControl: false,
      mapTypeControl: false,
      streetViewControl: false,
      minZoom: 3,
      center: initialCenter,
      zoom: initialZoom || 13,
      disableDoubleClickZoom: true,
      tilt: 0,
    }
  }, [])

  useEffect(() => {
    if (map) {
      const eventListener = map.addListener('center_changed', () => {
        onCenterChange(map.getCenter()?.toJSON() || initialCenter)

        setHasMapMoved(true)
      })

      return () => {
        eventListener.remove()
      }
    }
  }, [map])

  const handleResetPosition = (e: React.MouseEvent) => {
    e.stopPropagation()

    if (initialCenter) {
      map?.setCenter(initialCenter)

      setHasMapMoved(false)

      onCenterChange(initialCenter)
    }
  }

  return (
    <>
      {/* Google map takes up the whole screen */}
      <GoogleMap
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
        }}
        width={'100vw'}
        height={'100vh'}
        mapOptions={mapOptions}
        onInit={setMap}
        defaultErrorMessage={translate.phrases.placeholder('Error loading map.')}
      />

      {!!map && children}

      {/* Use relative positioning for the map tool buttons so they are affected by the header height */}
      <div css={{ position: 'relative' }}>
        <Button
          size="lg"
          css={{
            top: 0,
            left: 0,
            margin: '20px',
          }}
          onClickCapture={handleResetPosition}
          variant={'tertiary'}
          disabled={!hasMapMoved}
        >
          {translate.phrases.placeholder('Reset')}
        </Button>

        {map && (
          <CurrentLocation
            enableHighAccuracy={true}
            enableFollowing={false}
            autoStart={true}
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              margin: '20px',
            }}
          />
        )}
      </div>
    </>
  )
}
