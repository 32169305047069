import type { SharedTypes } from '@semios/app-platform-banyan-route-definitions'
import { IRRIGATION_SCHEDULER_FIRST_DAY_OF_WEEK } from '@semios/app-platform-common'
import type { TFieldAssetKeyTypes, TFieldAssetValueTypes, TIrrigationDeviceStatus } from 'App/Map/types'
import type { TFertigationSettingsData } from 'App/Map/UserSettingsMenu/Shared/FertigationConfiguration/types'
import type { Moment } from 'moment-timezone'
import moment from 'moment-timezone'
import { init } from 'stores/_utils/simple-store'

export enum TScheduledEventEnum {
  PREVIEW = 'PREVIEW', // TODO
  DRAFT_CREATE = 'DRAFT_CREATE',
  DRAFT_DELETE = 'DRAFT_DELETE',
  SCHEDULED = 'SCHEDULED',
  STOPPED = 'STOPPED',
  PENDING_DELETE = 'PENDING_DELETE',
}

export type EVENT_ID = string

export type ISOString = string

export type DATE_RANGE = { dateFrom: Moment; dateTo: Moment }

export type EVENT = DATE_RANGE & {
  emitterType: TFieldAssetKeyTypes.TEmitterType
  irrigationZoneId: string
  irrigationZoneEmitterId: TFieldAssetKeyTypes.TIrrigationZoneEmitterId
  externalEventId?: string
}

export type CONTINUOUS_IRRIGATION_EVENT = EVENT & {
  eventId: EVENT_ID
  fertigationSchedules: (TFertigationEvent & {
    fertilizerName: string
    injectorName: string
    flowRate?: number | null
    flowRateUnit?: SharedTypes.TFertigationFlowRateUnit | null
  })[]
}

type TFertigationEvent = {
  fertigationStartDelay: number
  duration: number
  fertilizerId: number
  injectorId: number
  fertigationStartTime: Moment
  fertigationEndTime: Moment
}

type TAppliedFertigationEvents = {
  endTime: Moment
  fertigationType: string
  fertilizerName: string
  injectorName: string
  startTime: Moment
  unit: string
  volume: number
}

export type PULSE_IRRIGATION_EVENT = EVENT & {
  pulseEventId: EVENT_ID
  pulseIrrigationConfig: {
    onDuration: number
    offDuration: number
    numberOfCycles: number
  }
  pulseIrrigationEvents: {
    dateFrom: Moment
    dateTo: Moment
    eventId: string
    externalEventId?: string
    status: TScheduledEventEnum
  }[]
}

export type PUBLISHED_EVENT = EVENT & {
  eventId: EVENT_ID
  status: string
  appliedFertigationEvents: TAppliedFertigationEvents[]
}

// Applied events currently have no concept of pulse/continuous
export type APPLIED_EVENT = EVENT & {
  status: string
  appliedFertigationEvents: TAppliedFertigationEvents[]
}

export type SCHEDULED_PULSE_EVENT = PULSE_IRRIGATION_EVENT & {
  type: TScheduledEventEnum
}

export type SCHEDULED_CONTINUOUS_EVENT = CONTINUOUS_IRRIGATION_EVENT & {
  type: TScheduledEventEnum
}

export enum TDateViewEnum {
  DAY = 'DAY',
  WEEK = 'WEEK',
}

export const NO_3RD_PARTY_IRRIGATION_VENDOR = 'NO_3RD_PARTY_IRRIGATION_VENDOR'

export type TIrrigationEventType = 'CONTINUOUS' | 'PULSE'

export type TFormFertigationEvent = {
  fertigationStartDelayHours?: number
  fertigationStartDelayMinutes?: number
  fertigationDurationHours?: number
  fertigationDurationMinutes?: number
  fertilizerId?: number
  injectorId?: number
}

export type FormValuesCreate = {
  irrigationEventType: TIrrigationEventType
  irrigationZoneEmitterIds: TFieldAssetKeyTypes.TIrrigationZoneEmitterId[]
  startDate: Date | undefined
  durationHoursContinuous?: number
  durationMinutesContinuous?: number
  durationHoursPulseOn?: number
  durationMinutesPulseOn?: number
  durationHoursPulseOff?: number
  durationMinutesPulseOff?: number
  numberOfPulseIrrigationCycles?: number
  isRepeatSchedule: boolean
  repeatDays: number[] // array of day indexes
  repeatEndDate: Date | undefined
  fertigationEvents: Record<string, TFormFertigationEvent>
}

export type FormValuesEdit = {
  irrigationEventType: TIrrigationEventType
  irrigationZoneEmitterId: TFieldAssetKeyTypes.TIrrigationZoneEmitterId
  startDate: Date
  durationHoursContinuous?: number
  durationMinutesContinuous?: number
  durationHoursPulseOn?: number
  durationMinutesPulseOn?: number
  durationHoursPulseOff?: number
  durationMinutesPulseOff?: number
  numberOfPulseIrrigationCycles?: number
  fertigationEvents: Record<string, TFormFertigationEvent>
}

export type TIrrigationZoneEmitterWithCurrentEmitterStatus = TFieldAssetValueTypes.TIrrigationZoneEmitter & {
  currentEmitterStatus: TIrrigationDeviceStatus
}

export type TIrrigationSchedulerStoreState = {
  batchDelete: {
    visible: boolean
    events: {
      [eventId: string]:
        | (Omit<SCHEDULED_CONTINUOUS_EVENT, 'type'> & {
            type: TScheduledEventEnum.SCHEDULED | TScheduledEventEnum.DRAFT_CREATE
          })
        | (Omit<SCHEDULED_PULSE_EVENT, 'type'> & {
            type: TScheduledEventEnum.SCHEDULED | TScheduledEventEnum.DRAFT_CREATE
          })
    }
  }
  showPublishScheduleConfirmation: boolean
  showLauncher: boolean
  visible: boolean
  dateView: TDateViewEnum
  dateFrom: ISOString
  showPeakHours: boolean
  selectedIrrigationZoneEmitterIds: TFieldAssetKeyTypes.TIrrigationZoneEmitterId[]
  appliedEventsByIrrigationZoneEmitterId: {
    [irrigationZoneEmitterId: TFieldAssetKeyTypes.TIrrigationZoneEmitterId]: APPLIED_EVENT[]
  }
  debugPublishedEventsByIrrigationZoneEmitterId: {
    [irrigationZoneEmitterId: TFieldAssetKeyTypes.TIrrigationZoneEmitterId]: PUBLISHED_EVENT[]
  }
  debugPublishedPulseIrrigationEventsByIrrigationZoneEmitterId: {
    [irrigationZoneEmitterId: TFieldAssetKeyTypes.TIrrigationZoneEmitterId]: PUBLISHED_EVENT[]
  }
  fertigationSettings: TFertigationSettingsData[]
  scheduledEvents: Record<EVENT_ID, SCHEDULED_CONTINUOUS_EVENT>
  scheduledPulseIrrigationEvents: Record<EVENT_ID, SCHEDULED_PULSE_EVENT>
  stoppedIrrigationZoneEmitters: Record<string, ISOString>
  eventCreate: null | { irrigationZoneEmitterId?: TFieldAssetKeyTypes.TIrrigationZoneEmitterId }
  eventPanel: null | { eventId: EVENT_ID }
  pulseEventPanel: null | { pulseEventId: EVENT_ID }
  selectedPropertyId: null | number
  mostRecentApiResponseTimestamps: Record<
    | 'irrigation-scheduler-publish'
    | 'irrigation-scheduler-zones-stop'
    | 'irrigation-scheduler-zones-resume'
    | 'irrigation-scheduler-events-stop'
    | 'irrigation-scheduler-events-publish-timeout',
    null | string
  >
  debug: {
    show3rdPartyPublishedEvents: boolean
    showTimeOnBars: boolean
  }
  hasPendingDeletionEvents: boolean
  currentEmitterStatus: Record<TFieldAssetKeyTypes.TIrrigationZoneEmitterId, TIrrigationDeviceStatus>
}

export const getInitialState = (): TIrrigationSchedulerStoreState => ({
  batchDelete: { visible: false, events: {} },
  showPublishScheduleConfirmation: false,
  showLauncher: false,
  visible: false,
  dateView: TDateViewEnum.WEEK,
  dateFrom: moment
    .tz(moment.tz.guess())
    .day(IRRIGATION_SCHEDULER_FIRST_DAY_OF_WEEK)
    .startOf('day')
    .toISOString(),
  fertigationSettings: [],
  showPeakHours: false,
  selectedIrrigationZoneEmitterIds: [],
  appliedEventsByIrrigationZoneEmitterId: {},
  debugPublishedEventsByIrrigationZoneEmitterId: {},
  debugPublishedPulseIrrigationEventsByIrrigationZoneEmitterId: {},
  scheduledEvents: {},
  scheduledPulseIrrigationEvents: {},
  stoppedIrrigationZoneEmitters: {},
  eventCreate: null,
  eventPanel: null,
  pulseEventPanel: null,
  selectedPropertyId: null,
  mostRecentApiResponseTimestamps: {
    'irrigation-scheduler-publish': null,
    'irrigation-scheduler-zones-stop': null,
    'irrigation-scheduler-zones-resume': null,
    'irrigation-scheduler-events-stop': null,
    'irrigation-scheduler-events-publish-timeout': null,
  },
  debug: {
    show3rdPartyPublishedEvents: false,
    showTimeOnBars: false,
  },
  hasPendingDeletionEvents: false,
  currentEmitterStatus: {},
})

export const irrigationSchedulerStore = init<TIrrigationSchedulerStoreState>(getInitialState())
