import type { routes } from '@semios/app-platform-banyan-route-definitions'
import { apiFetch } from 'utils/apiFetch'

export const irrigationSchedulerCurrentStatus = async (
  request: routes.IrrigationSchedulerCurrentStatus.Request,
) => {
  const response = await apiFetch({
    url: '/irrigation-scheduler-current-status',
    body: request,
    params: { timeout: 120000 },
  })

  return response
}
