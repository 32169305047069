import type { routes } from '@semios/app-platform-banyan-route-definitions'
import { getTimezoneForSelectedPropertyOrRegion } from 'App/Map/PanelDetails/_utils/getTimezoneForSelectedPropertyOrRegion'
import { selectedRegionHasPermission } from 'App/Map/PanelDetails/_utils/selectedRegionHasPermission'
import type { StackedChartPestSection } from 'components/StackedChart/types'
import { detailsPanelStore } from 'stores/detailsPanelStore'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import type { TSelectedFieldAssetsStoreState } from 'stores/selectedFieldAssetsStore'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import type { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { doesSelectedRegionHaveValueTypes } from 'utils/doesSelectedFieldAssetHaveValueTypes'
import { isRegionDataEnabledForCharts } from 'utils/useIsRegionDataEnabled'
import { getDegreeDayValuesRequested } from './_utils/getDegreeDayValuesRequested'
import { getInsectTrapCatchesValuesRequested } from './_utils/getTrapCatchValuesRequested'
import { getTrapPercentageValueTypeIdForInsectId } from './_utils/getValueTypesForInsectId'
import { insectTrapAndDegreeContent } from './_utils/insectTrapAndDegree'

export const apiArgs = ({
  selectedFieldAssets,
  selectedValueGroups,
}: {
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}): Partial<routes.Values.Request> => {
  if (!selectedFieldAssets.region) return {}

  const degreeDaysValuesRequested = getDegreeDayValuesRequested({
    selectedValueGroups,
    doesTargetHaveValueTypes: doesSelectedRegionHaveValueTypes,
    permissionCheck: (permissionId) => selectedRegionHasPermission({ permission: permissionId }),
  })

  const trapCatchValuesRequested = getInsectTrapCatchesValuesRequested({
    selectedValueGroups,
    doesTargetHaveValueTypes: doesSelectedRegionHaveValueTypes,
    permissionCheck: (permissionId) => selectedRegionHasPermission({ permission: permissionId }),
    getTrapValueTypeFunction: (insectId) => getTrapPercentageValueTypeIdForInsectId(insectId),
  })

  const valuesToBeRequested: Partial<routes.Values.Request> = {}

  valuesToBeRequested.regions = {
    regionIds: [selectedFieldAssets.region],
    valuesRequested: { ...degreeDaysValuesRequested, ...trapCatchValuesRequested },
  }

  return valuesToBeRequested
}

export const buildRegionalSection = ({
  data,
  selectedValueGroups,
  compareSeasonsData,
}: {
  data: routes.Values.Response
  compareSeasonsData: routes.Values.Response
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
}): StackedChartPestSection[] => {
  // builds series for DD
  // builds series for trap %
  // puts into a chart
  // returns section with header etc

  const insects = fieldAssetStore.getState()?.insects

  if (!insects) return []

  const selectedFieldAssets = selectedFieldAssetsStore.getState()
  const selectedProperty = selectedFieldAssets.property
  const selectedRegion = selectedFieldAssets.region
  const timezone = getTimezoneForSelectedPropertyOrRegion()
  const { dateFrom, dateTo } = detailsPanelStore.getState()
  const regionsData = data?.regions?.[String(selectedRegion)]
  const regionName = fieldAssetStore.getState()?.regions?.[String(selectedRegion)]?.name ?? null
  const targetScopeId = String(selectedFieldAssets.region)
  const showRegionalData = isRegionDataEnabledForCharts()

  return insectTrapAndDegreeContent({
    insects,
    selectedValueGroups,
    targetScope: 'REGION',
    targetScopeId,
    targetScopeData: data?.regions,
    regionName,
    regionsData,
    compareSeasonsTargetScopeData: compareSeasonsData?.regions,
    doesTargetHaveValueTypes: doesSelectedRegionHaveValueTypes,
    pestSectionCategory: 'combined-property-level',
    showRegionalData,
    selectedProperty,
    dateFrom,
    dateTo,
    timezone,
  })
}
