import type { routes } from '@semios/app-platform-banyan-route-definitions'
import { serviceCenterStore } from 'App/ServiceCenter/store/serviceCenterStore'
import { apiFetch } from 'utils/apiFetch'
import type { GeoJSONPoint } from 'wellknown'
import { parse } from 'wellknown'
import type { TNodeType } from '../../types'
import { getIdentifier } from '../getIdentifier'

export type GatewayDropInstallMeta = routes.ServiceCenterGatewayDropInstall.Request

const request = async (meta: GatewayDropInstallMeta): Promise<GatewayDropInstallMeta> => {
  await apiFetch({
    url: '/service-center-gateway-drop-install',
    body: meta,
    params: {
      method: 'POST',
    },
  })

  return meta
}

const callback = (meta: GatewayDropInstallMeta): void => {
  const {
    node: { gatewayIdentifier, propertyId, maintenanceOwnerId, location, name },
  } = meta

  const activeNodes = serviceCenterStore.selectors.getActiveNodes(serviceCenterStore.getState())
  const updatedActiveNodes = [...activeNodes].filter((node) => getIdentifier(node) !== gatewayIdentifier)
  const parsedLocation = parse(location) as GeoJSONPoint

  parsedLocation.coordinates[0] = parseFloat(parsedLocation.coordinates[0].toFixed(6))

  parsedLocation.coordinates[1] = parseFloat(parsedLocation.coordinates[1].toFixed(6))

  const newActiveGateway = {
    gatewayType: 'ln_gtwy' as TNodeType,
    gatewayIdentifier,
    location: JSON.stringify(parsedLocation),
    propertyIds: [+propertyId],
    maintenanceOwnerId,
    name: name || null,
  }

  serviceCenterStore.actions.setActiveNodes([...updatedActiveNodes, newActiveGateway])
}

export const serviceCenterGatewayDropInstall = {
  request,
  callback,
}
