import type {
  TIrrigationSchedulerStoreState,
  TIrrigationZoneEmitterWithCurrentEmitterStatus,
} from 'App/irrigation-scheduler/utils/store/irrigationSchedulerStore'
import { irrigationSchedulerStore } from 'App/irrigation-scheduler/utils/store/irrigationSchedulerStore'
import type { TFieldAssetKeyTypes } from 'App/Map/types'
import type { TFieldAssetStoreState } from 'stores/fieldAssetStore'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { sortByKey } from 'utils/sortByKey'
import { sortByEmitterType } from '../../sortByEmitterType'

export const getVisibleIrrigationZoneEmitters = (
  fass: TFieldAssetStoreState = fieldAssetStore.getState(),
  isss: TIrrigationSchedulerStoreState = irrigationSchedulerStore.getState(),
): TIrrigationZoneEmitterWithCurrentEmitterStatus[] => {
  const irrigationZoneEmitters =
    fass.properties?.[Number(isss.selectedPropertyId)]?.irrigationZoneEmitters || {}

  const emitters = Object.entries(irrigationZoneEmitters).map(
    ([irrigationZoneEmitterId, irrigationZoneEmitter]) => ({
      ...irrigationZoneEmitter,
      irrigationZoneEmitterId: irrigationZoneEmitterId as TFieldAssetKeyTypes.TIrrigationZoneEmitterId,
      currentEmitterStatus:
        isss.currentEmitterStatus[irrigationZoneEmitterId as TFieldAssetKeyTypes.TIrrigationZoneEmitterId],
    }),
  )

  const filteredEmitters =
    isss.selectedIrrigationZoneEmitterIds.length > 0
      ? emitters.filter((emitter) =>
          isss.selectedIrrigationZoneEmitterIds.includes(emitter.irrigationZoneEmitterId),
        )
      : emitters

  return filteredEmitters.sort(sortByEmitterType).sort(sortByKey('name'))
}
