import type { StackedChartSectionItem, TIrrigationEventSeries } from 'components/StackedChart/types'
import type { Tooltip } from 'highcharts'
import { translate } from 'i18n/i18n'
import { upperYAxisTitleMaker } from 'utils/upperYAxisTitleMaker'
import { getXDateFormat } from '../../_utils/getXDateFormat'
import { makeWaterEventsTooltip } from './makeWaterEventsTooltip'

export const makeWaterEventsChart = ({
  waterEventsSeriesWithData,
  rainSeries,
  firstForecastTimestamp,
  timezone,
  isDaily,
}: {
  waterEventsSeriesWithData: TIrrigationEventSeries[]
  rainSeries: TIrrigationEventSeries
  firstForecastTimestamp: number
  timezone: string
  isDaily: boolean
}): StackedChartSectionItem => {
  return {
    chartConfig: {
      semiosHighchartsAdditions: {
        id: 'stackem-soil-water-events',
        firstForecastTimestamp,
      },
      chart: {
        type: 'spline',
        height: waterEventsSeriesWithData.length * 30 + 55,
        marginTop: 35,
      },
      lang: {
        noData: '',
      },
      series: waterEventsSeriesWithData,
      tooltip: {
        formatter: function (tooltip: Tooltip) {
          return makeWaterEventsTooltip({
            ctx: this,
            tooltip,
            waterEventsSeries: waterEventsSeriesWithData,
            rainSeries,
            firstForecastTimestamp,
            timezone,
          })
        },
        outside: true,
        xDateFormat: getXDateFormat(isDaily),
      },
      yAxis: {
        title: upperYAxisTitleMaker(translate.phrases.banyanApp('Water Events')),
        labels: {
          enabled: false,
        },
        tickPositions: waterEventsSeriesWithData.map((_, i) => i),
        minorTickLength: 0,
        tickLength: 0,
        type: 'category',
      },
      xAxis: {
        labels: {
          enabled: false,
        },
        gridLineWidth: waterEventsSeriesWithData.length > 0 ? 1 : 0,
        tickInterval: 24 * 3600 * 1000, // 1 day in milliseconds
        tickLength: 0,
        endOnTick: true,
        startOnTick: true,
      },
      plotOptions: {
        series: {
          enableMouseTracking: true,
          states: {
            inactive: {
              opacity: 1,
            },
          },
        },
      },
    },
  }
}
