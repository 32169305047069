import { SectionTitleBar } from 'components/SectionTitleBar/SectionTitleBar'
import { useState } from 'react'

export const NoDataProperty = ({ noDataText, title }: { noDataText: string; title: string }) => {
  const [collapsed, setCollapsed] = useState(true)

  return (
    <div>
      <SectionTitleBar collapsed={collapsed} onCollapse={() => setCollapsed((prev) => !prev)} title={title} />
      <div
        css={{
          opacity: collapsed ? 0 : 1,
          height: collapsed ? 0 : '100%',
          overflow: collapsed ? 'hidden' : 'unset',
          boxSizing: 'border-box',
          borderCollapse: 'collapse',
          transition: 'height 80ms, opacity 80ms',
          textAlign: 'center',
          padding: collapsed ? 0 : 30,
        }}
      >
        {noDataText}
      </div>
    </div>
  )
}
