import type { ActionIconProps } from '@mantine/core'
import { ActionIcon, Tooltip } from '@mantine/core'
import type { PolymorphicComponentProps } from '@mantine/utils'
import { translate } from 'i18n/i18n'
import type { FC } from 'react'
import { colors } from 'settings/colors'
import type { StatusType } from './CurrentLocation'

interface ControlButtonProps extends ActionIconProps {
  status: StatusType
  loading?: boolean
}

const CurrentLocationIcon = (props: { status: StatusType; loading?: boolean }) => {
  let arrowFill = colors.grey50
  let arrowStroke = colors.grey50

  switch (props.status) {
    case 'denied':
      arrowStroke = colors.red

      break

    case 'inactive':
      arrowFill = colors.grey50

      arrowStroke = colors.grey200

      break

    case 'watching':
      arrowFill = colors.grey50

      arrowStroke = colors.primary

      break

    case 'following':
      arrowFill = colors.primary

      arrowStroke = colors.primary

      break

    default:
  }

  return (
    <svg width="55" height="55" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <circle cx="27.5" cy="27.5" r="27" fill={colors.grey50} />
        <circle cx="27.5" cy="27.5" r="27" stroke={colors.grey500} />
      </g>

      <path
        d="M37.6971 18.1407L16.5814 26.975L16.5814 28.1377L24.597 31.2407L27.7001 39.2564L28.8627 39.2564L37.6971 18.1407Z"
        fill={arrowFill}
        stroke={arrowStroke}
        strokeWidth="2"
      >
        {props.loading && (
          <animate
            attributeName="opacity"
            from="1"
            to="1"
            dur="2s"
            begin="0s"
            values="1;0.5;0.1;0.5;1"
            repeatCount="indefinite"
          />
        )}
      </path>
    </svg>
  )
}

export const ControlButton: FC<PolymorphicComponentProps<'button', ControlButtonProps>> = (props) => {
  const { loading, ...restOfProps } = props

  let tooltipLabel = translate.phrases.placeholder('Current Location')

  if (props.status === 'denied') {
    tooltipLabel = translate.phrases.placeholder(
      'Please enable Location services to display your current location',
    )
  } else if (props.status === 'inactive') {
    tooltipLabel = translate.phrases.placeholder('Show current location')
  } else if (props.status === 'watching') {
    tooltipLabel = translate.phrases.placeholder('Follow current location')
  } else if (props.status === 'following') {
    tooltipLabel = translate.phrases.placeholder('Stop following current location')
  }

  if ((loading && props.status === 'watching') || props.status === 'following') {
    tooltipLabel = translate.phrases.placeholder('Retrieving current location')
  }

  return (
    <Tooltip label={tooltipLabel} position="left" withArrow>
      <ActionIcon size="xl" radius="xl" variant="outline" {...restOfProps}>
        <CurrentLocationIcon status={props.status} loading={props.loading} />
      </ActionIcon>
    </Tooltip>
  )
}
