import { Stack, Text, useMantineTheme } from '@mantine/core'
import { AdapterStatus } from 'App/ServiceCenter/BluetoothLowEnergy/types'
import { DeviceInstallationStatus } from 'App/ServiceCenter/DevicesManagement/DevicesList/DeviceInstallationStatus/DeviceInstallationStatus'
import { useLnrNodeStatus } from 'App/ServiceCenter/DevicesManagement/useLnrNodeStatus'
import type { EquipmentActivationPayload } from 'App/ServiceCenter/NodeInstallation/types'
import type { TNodeDeviceChannels, TNodeDevicePort } from 'App/ServiceCenter/types'
import { Button } from 'components/Button/Button'
import { translate } from 'i18n/i18n'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { NodeSummary } from '../../Overview/NodeSummary/NodeSummary'

export const ConnectionStatus: React.FC<{
  payload: Partial<EquipmentActivationPayload>
  isBleRequired: boolean
  footer: React.ReactElement
}> = ({
  payload,
  isBleRequired,
  footer,
}: {
  payload: Partial<EquipmentActivationPayload>
  isBleRequired: boolean
  footer: React.ReactElement
}) => {
  const { nodeIdentifier, devices: nodeDevices } = payload
  const allDeviceModels = fieldAssetStore.useSelector((s) => s.devices)
  const theme = useMantineTheme()

  const allDeviceSources = Object.entries(nodeDevices || {}).flatMap(
    ([connector, value]: [string, TNodeDeviceChannels]) => {
      return Object.entries(value).map(([chan, device]) => {
        return {
          connector: connector as TNodeDevicePort,
          channel: chan,
          installationStatus: device.installationStatus,
          source: device.source,
        }
      })
    },
  )

  const {
    adapterStatus,
    loraNetworkStatus,
    isReadingAllDevicesStatus,
    requestAllDevicesStatusReading,
    getDeviceStatus,
  } = useLnrNodeStatus(isBleRequired, payload.nodeIdentifier || '')

  const connectionStatus = isBleRequired ? { bleStatus: adapterStatus, loraNetworkStatus } : undefined

  return (
    <div css={{ padding: '10px' }}>
      <div
        css={{
          backgroundColor: theme.colors.grey[0],
          border: `1px solid ${theme.colors.grey[1]}`,
          borderRadius: 3,
          padding: 20,
        }}
      >
        <Stack spacing={'xs'}>
          <h3 css={{ margin: '0 0 10px' }}>{translate.phrases.placeholder('Connection Status')}</h3>
          <NodeSummary nodeId={nodeIdentifier} connectionStatus={connectionStatus}>
            <Stack>
              <h4 css={{ margin: '20px 0 10px' }}>{translate.phrases.placeholder('Devices')}</h4>

              {nodeDevices && allDeviceSources && (
                <>
                  <Stack spacing={'xs'}>
                    {allDeviceSources.map((device) => (
                      <div
                        key={`${device.connector}-${device.channel}`}
                        css={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Text>{allDeviceModels[device.source]?.name || device.source}</Text>
                        <DeviceInstallationStatus
                          status={getDeviceStatus(device.connector, parseInt(device.channel), device)}
                        />
                      </div>
                    ))}
                  </Stack>
                </>
              )}
              {allDeviceSources.length === 0 && translate.phrases.placeholder('No devices')}
              {adapterStatus === AdapterStatus.CONNECTED && (
                <Button
                  css={{ width: '100%' }}
                  variant="tertiary"
                  onClick={requestAllDevicesStatusReading}
                  loading={isReadingAllDevicesStatus}
                >
                  {translate.phrases.placeholder('Re-Scan Connections')}
                </Button>
              )}
            </Stack>
          </NodeSummary>
        </Stack>
      </div>
      {footer}
    </div>
  )
}
