import { apiFetch } from './apiFetch'

export const hasUserAgreedToLatestAcceptancePackage = async () => {
  const data = await apiFetch({
    url: '/has-agreed-to-current-acceptance-package',
    body: null,
  })

  return data
}
