import { arrayOfObjectsSearch } from '@semios/app-platform-common'
import { NoDataProperty } from 'App/Map/UserSettingsMenu/components/NoDataProperty'
import { SettingsSearchNotFound } from 'App/Map/UserSettingsMenu/components/SettingsSearchNotFound/SettingsSearchNotFound'
import { translate } from 'i18n/i18n'
import { useState } from 'react'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { sortByKey } from 'utils/sortByKey'
import { EditZoneModal } from './EditZoneModal'
import { PropertySection } from './PropertySection'

export type Zone = {
  zoneName: string
  irrigationZoneId: number
}

export const ZonesTab = ({
  filterPropertiesText,
  selectedPropertyId,
}: {
  filterPropertiesText: string
  selectedPropertyId: number | null
}) => {
  const properties = Object.values(fieldAssetStore.useSelector((s) => s.properties) ?? {})
  const [selectedZone, setSelectedZone] = useState<(Zone & { propertyId: number }) | null>(null)

  const filteredProperties = selectedPropertyId
    ? [properties.find((p) => p.propertyId === selectedPropertyId)]
    : arrayOfObjectsSearch(properties, filterPropertiesText, ['propertyName']).sort(sortByKey('propertyName'))

  const propertiesWithZones = filteredProperties.filter(
    (property) => Object.keys(property?.irrigationZoneEmitters ?? {}).length,
  )

  const propertiesWithoutZones = filteredProperties.filter(
    (property) => !Object.keys(property?.irrigationZoneEmitters ?? {}).length,
  )

  const noSearchResults =
    filterPropertiesText && !propertiesWithZones.length && !propertiesWithoutZones.length

  return (
    <div>
      {noSearchResults && <SettingsSearchNotFound />}
      {propertiesWithZones.map((property) => {
        if (!property) return null

        const zones = Object.values(
          Object.values(property.irrigationZoneEmitters ?? {}).reduce<Record<number, Zone>>(
            (acc, { irrigationZoneId, name }) => {
              if (acc[irrigationZoneId]) return acc

              acc[irrigationZoneId] = {
                zoneName: name,
                irrigationZoneId,
              }

              return acc
            },
            {},
          ),
        ).sort(sortByKey('zoneName'))

        return (
          zones.length && (
            <PropertySection
              key={property.propertyId}
              filterPropertiesText={filterPropertiesText}
              propertyId={property.propertyId}
              propertyName={property.propertyName}
              setSelectedZone={setSelectedZone}
              zones={zones}
            />
          )
        )
      })}
      {propertiesWithoutZones.map((property) => {
        if (!property) return null

        return (
          <NoDataProperty
            key={property.propertyId}
            title={property.propertyName}
            noDataText={translate.phrases.banyanApp('No zones have been created for this property yet.')}
          />
        )
      })}
      <EditZoneModal setSelectedZone={setSelectedZone} selectedZone={selectedZone} />
    </div>
  )
}
