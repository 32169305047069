import { IconDash } from 'components/icons/IconDash'
import { IconDot } from 'components/icons/IconDot'
import type Highcharts from 'highcharts'
import { translate } from 'i18n/i18n'
import React from 'react'
import { colors } from 'settings/colors'
import { MoreInformationButton } from './MoreInformationButton'

export const InsectPhenologyLegends = ({
  chart,
  series,
  propertyShouldIncludeSemiosModel,
  seriesSprayWindowsSemios,
  seriesSprayWindowsWSU,
  isSmallScreen,
  informationCollapsed,
  setInformationCollapsed,
  isInfoButtonHovered,
  setIsInfoButtonHovered,
  insectId,
}: {
  chart: Highcharts.Chart | undefined
  series: Highcharts.SeriesOptionsType[]
  propertyShouldIncludeSemiosModel: boolean | undefined
  seriesSprayWindowsSemios: { id: string; color: string; from: number | undefined; to: number | undefined }[]
  seriesSprayWindowsWSU: { id: string; color: string; from: number | undefined; to: number | undefined }[]
  isSmallScreen: boolean
  informationCollapsed: boolean
  setInformationCollapsed: React.Dispatch<React.SetStateAction<boolean>>
  isInfoButtonHovered: boolean
  setIsInfoButtonHovered: React.Dispatch<React.SetStateAction<boolean>>
  insectId: number
}) => {
  const seriesMap: Record<string, { index: number; series: Highcharts.SeriesOptionsType }> = {}

  series.forEach((s, index) => {
    if (s.id) seriesMap[s.id] = { index, series: s }
  })

  const modelSemiosSeries = seriesMap['RelativeFlightSemios']
  const modelWSUSeries = seriesMap['RelativeFlightWSU']
  const sprayWindowsSemiosSeries = seriesMap['sprayWindowSemios']
  const sprayWindowWSUSeries = seriesMap['sprayWindowWSU']
  const trapCatchSeries = seriesMap['TrapCatches']
  const isSemiosDataShown = propertyShouldIncludeSemiosModel !== false

  const gridTableStyle = {
    display: 'grid',
    gridTemplateColumns: isSmallScreen ? 'auto 180px 180px auto' : '50px 200px 180px 120px 1fr',
    width: '100%',
    gridTemplateAreas: isSmallScreen
      ? '"y a d z" "y b e z" "y c f z" "y g r z" "y h r z"'
      : '"l a d g r" "l b e h r" "l c f z r"',
  }

  const titleStyle = {
    fontSize: '14px',
    fontWeight: 'bold',
    paddingTop: '10px',
  }

  const iconScaleLarge = (
    <span style={{ display: 'inline-block', transform: 'scale(2)' }}>
      <IconDash />
    </span>
  )

  const iconScaleSmall = (
    <span style={{ display: 'inline-block', transform: 'scale(0.7)' }}>
      <IconDot />
    </span>
  )

  const moreInfoButton = (
    <div style={{ gridArea: 'r', position: 'relative' }}>
      <MoreInformationButton
        isSmallScreen={isSmallScreen}
        setIsInfoButtonHovered={setIsInfoButtonHovered}
        isInfoButtonHovered={isInfoButtonHovered}
        setInformationCollapsed={setInformationCollapsed}
      />
    </div>
  )

  const generateLegends = (insectId: number) => {
    const elements = {
      predictedRelativeFlight: (gridArea: string) => (
        <div style={{ ...titleStyle, gridArea }}>
          {translate.phrases.banyanApp('Predicted Relative Flight')}
        </div>
      ),
      modelSemios: (gridArea: string) =>
        isSemiosDataShown && (
          <div style={{ gridArea }}>
            <InsectPhenologyLegend
              chart={chart}
              series={modelSemiosSeries.series}
              index={modelSemiosSeries.index}
              icon={iconScaleLarge}
            />
          </div>
        ),
      modelWSU: (gridArea: string) => (
        <div style={{ gridArea }}>
          <InsectPhenologyLegend
            chart={chart}
            series={modelWSUSeries.series}
            index={modelWSUSeries.index}
            icon={iconScaleLarge}
          />
        </div>
      ),
      managementWindow: (gridArea: string) => (
        <div style={{ ...titleStyle, gridArea }}>{translate.phrases.banyanApp('Management Window')}</div>
      ),
      sprayWindowsSemios: (gridArea: string) =>
        isSemiosDataShown && (
          <div style={{ gridArea }}>
            <InsectPhenologyLegend
              chart={chart}
              series={sprayWindowsSemiosSeries.series}
              index={sprayWindowsSemiosSeries.index}
              plotBands={seriesSprayWindowsSemios}
              icon={iconScaleSmall}
            />
          </div>
        ),
      sprayWindowsWSU: (gridArea: string) => (
        <div style={{ gridArea }}>
          <InsectPhenologyLegend
            chart={chart}
            series={sprayWindowWSUSeries.series}
            index={sprayWindowWSUSeries.index}
            plotBands={seriesSprayWindowsWSU}
            icon={iconScaleSmall}
          />
        </div>
      ),
      trapCatchesTitle: (gridArea: string) => (
        <div style={{ ...titleStyle, gridArea }}>{translate.phrases.banyanApp('Trap Catches')}</div>
      ),
      trapCatches: (gridArea: string) => (
        <div style={{ gridArea }}>
          <InsectPhenologyLegend
            chart={chart}
            series={trapCatchSeries.series}
            index={trapCatchSeries.index}
            icon={iconScaleSmall}
          />
        </div>
      ),
    }

    switch (insectId) {
      case 1:
        return (
          <div style={gridTableStyle}>
            {elements.predictedRelativeFlight('a')}
            {elements.modelSemios('b')}
            {elements.modelWSU(isSemiosDataShown ? 'c' : 'b')}
            {elements.managementWindow('d')}
            {elements.sprayWindowsSemios('e')}
            {elements.sprayWindowsWSU(isSemiosDataShown ? 'f' : 'e')}
            {elements.trapCatchesTitle('g')}
            {elements.trapCatches('h')}
            {informationCollapsed && moreInfoButton}
          </div>
        )

      case 7:
        return (
          <div style={gridTableStyle}>
            {elements.predictedRelativeFlight('a')}
            {elements.modelSemios('b')}
            {elements.trapCatchesTitle('d')}
            {elements.trapCatches('e')}
            {informationCollapsed && moreInfoButton}
          </div>
        )

      case 11:
        return (
          <div style={gridTableStyle}>
            {elements.predictedRelativeFlight('a')}
            {elements.modelSemios('b')}
            {elements.managementWindow('d')}
            {elements.sprayWindowsSemios('e')}
            {elements.trapCatchesTitle('g')}
            {elements.trapCatches('h')}
            {informationCollapsed && moreInfoButton}
          </div>
        )

      default:
        return <></>
    }
  }

  return <div style={{ display: 'flex', justifyContent: 'center' }}>{generateLegends(insectId)}</div>
}

const InsectPhenologyLegend = ({
  chart,
  series,
  index,
  plotBands,
  icon,
}: {
  chart: Highcharts.Chart | undefined
  series: Highcharts.SeriesOptionsType
  index: number
  plotBands?: { id: string; color: string; from: number | undefined; to: number | undefined }[]
  icon: React.ReactNode
}) => {
  const [selected, setSelected] = React.useState(true)
  const seriesColor = String((series as Highcharts.SeriesLineOptions).color)
  const seriesName = (series as Highcharts.SeriesLineOptions).name

  const toggleSeries = (seriesIndex: number) => {
    if (!chart) return

    setSelected((prevState) => !prevState)

    const selectedSeries = chart.series[seriesIndex]

    if (selectedSeries.visible) {
      selectedSeries.hide()

      if (index === 2) {
        // @ts-ignore
        chart.xAxis?.[0]?.plotLinesAndBands.forEach((pl) => {
          if (pl?.id?.startsWith('sprayWindowSemios')) {
            chart.xAxis[0].removePlotLine(pl.id)
          }
        })
      } else if (index === 3) {
        // @ts-ignore
        chart.xAxis?.[0]?.plotLinesAndBands.forEach((pl) => {
          if (pl?.id?.startsWith('sprayWindowWSU')) {
            chart.xAxis[0].removePlotLine(pl.id)
          }
        })
      }
    } else {
      selectedSeries.show()

      if (plotBands && (index === 2 || index === 3)) {
        plotBands.forEach((pl) => {
          chart.xAxis[0].addPlotBand({
            ...pl,
            from: pl.from || 0,
            to: pl.to || 0,
          })
        })
      }
    }
  }

  return (
    <div onClick={() => toggleSeries(index)} style={{ color: selected ? '' : colors.gray }}>
      <span
        style={{
          lineHeight: '14px',
          verticalAlign: 'middle',
          color: selected ? seriesColor : colors.gray,
        }}
      >
        {icon}
      </span>
      <span
        style={{
          cursor: 'pointer',
          fontSize: '14px',
          fontWeight: 'bold',
          paddingLeft: '4px',
        }}
      >
        {seriesName}
      </span>
    </div>
  )
}
