import {
  DevicesManagement,
  DevicesManagementPageKey,
} from 'App/ServiceCenter/DevicesManagement/DevicesManagement'
import { serviceCenterStore } from 'App/ServiceCenter/store/serviceCenterStore'
import type { TActiveNode, TNodeDevices } from 'App/ServiceCenter/types'
import { translate } from 'i18n/i18n'
import type { FC } from 'react'
import { useState } from 'react'
import { Footer } from '../Footer/Footer'
import type { InstallationStepProp } from '../types'

export const DeviceInstallation: FC<InstallationStepProp<TNodeDevices | undefined>> = ({
  payload,
  isBleRequired,
  onSubmit,
  onClose,
}) => {
  const nodeIdentifier = payload.nodeIdentifier
  const activeNodes = serviceCenterStore.useSelector(serviceCenterStore.selectors.getActiveNodes)
  const node = activeNodes.find((node) => (node as TActiveNode).nodeIdentifier === nodeIdentifier)
  const [page, setPage] = useState<DevicesManagementPageKey>()
  const [busy, setBusy] = useState(false)

  if (!(node as TActiveNode).nodeIdentifier) {
    // eslint-disable-next-line no-console
    console.error('Node not found')
  }

  const handleSubmit = async (devices?: TNodeDevices) => {
    await onSubmit(devices)

    onClose(true)
  }

  return (
    <>
      {(node as TActiveNode).nodeIdentifier && (
        <DevicesManagement
          node={node as TActiveNode}
          isBleRequired={isBleRequired}
          onBusy={setBusy}
          onPageChange={setPage}
          onClose={() => onClose(false)}
        />
      )}
      {page === DevicesManagementPageKey.LIST && (
        <Footer
          onPrevious={() => {
            onClose(false)
          }}
          loading={busy}
          nextButtonLabel={translate.phrases.placeholder('Continue')}
          onNext={() => handleSubmit(node?.devices)}
        />
      )}
    </>
  )
}
