import { Checkbox, Group, Stack } from '@mantine/core'
import { AboveAllModal } from 'components/AboveAllModalOverlay/AboveAllModalOverlay'
import { Button } from 'components/Button/Button'
import { translate } from 'i18n/i18n'
import type { ReactElement } from 'react'

export function showModalWithCachedCheckbox(param: {
  title: string
  DescriptionElement: ReactElement
  key: string
  onConfirm: () => void
}) {
  const { title, DescriptionElement, key, onConfirm } = param
  const DIALOG_ID = key

  AboveAllModal.open({
    modalId: DIALOG_ID,
    fullScreen: false,
    withCloseButton: true,
    centered: true,
    title: title,
    children: (
      <>
        <Stack>
          {DescriptionElement}
          <Checkbox
            size={'md'}
            onChange={(event) => {
              event.target.checked ? localStorage.setItem(key, 'true') : localStorage.removeItem(key)
            }}
            css={{ fontWeight: 'bold', marginTop: '16px' }}
            label={translate.phrases.placeholder('Don’t show this again')}
          />
          <Group position={'apart'} color="grey.3" grow>
            <Button
              variant="secondary"
              css={{ flex: '0 0 auto' }}
              onClick={() => {
                AboveAllModal.close(DIALOG_ID)
              }}
            >
              {translate.phrases.placeholder('Cancel')}
            </Button>
            <Button
              variant="primary"
              css={{ marginLeft: 10, flex: '0 0 auto' }}
              onClick={() => {
                AboveAllModal.close(DIALOG_ID)

                onConfirm()
              }}
            >
              {translate.phrases.placeholder('Continue')}
            </Button>
          </Group>
        </Stack>
      </>
    ),
  })
}
