import * as irrigationActivity from 'App/Map/PanelDetails/SummaryGridTable/_utils/by-domain/irrigationActivity/irrigationActivity'
import { apiFetch } from 'utils/apiFetch'

export const irrigationSchedulerCurrentValues = async (selectedPropertyId: number | null) => {
  if (!selectedPropertyId) return {}

  const currentValuesApiArgs =
    irrigationActivity.getIrrigationActivityCurrentValuesApiArgsByPropertyId(selectedPropertyId)

  const response = await apiFetch({
    url: '/values-current',
    body: currentValuesApiArgs || {},
  })

  return response
}
